import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { insurancePdfStyles } from '../styles';
import moment from 'moment';

const PatientInfoFr = ({ patient }) => {
  return (
    <View>
      <View style={insurancePdfStyles.instructionsHeading}>
        <Text style={insurancePdfStyles.instructionsHeadingText}>
          3e PARTIE - RENSEIGNEMENTS SUR LE PATIENT
        </Text>
      </View>

      <View style={{ ...insurancePdfStyles.employeeBody, paddingTop: '5px' }}>
        <View style={insurancePdfStyles.employeeBodyContent}>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <Text
              style={{
                ...insurancePdfStyles.smallBold,
                marginRight: '1.5px',
              }}
            >
              1.
            </Text>

            <View>
              <Text style={insurancePdfStyles.smallBold}>
                PATIENT: LIEN DE PARENTÉ AVEC L’EMPLOYÉ/PARTICIPANT AU RÉGIME
                {'\n'}
                _________________________________________________________
              </Text>

              <View style={insurancePdfStyles.patientInfoDOBSection}>
                <Text style={insurancePdfStyles.smallBold}>
                  DATE DE NAISSANCE
                </Text>

                <View
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    transform: 'translateY(-5%)',
                  }}
                >
                  <View style={insurancePdfStyles.patientInfo3DOB}>
                    <Text style={insurancePdfStyles.pdfValue}>
                      {moment(patient?.dateOfBirth).format('DD')}
                    </Text>
                    <Text style={insurancePdfStyles.pdfValue}>
                      {moment(patient?.dateOfBirth).format('MM')}
                    </Text>
                    <Text style={insurancePdfStyles.pdfValue}>
                      {moment(patient?.dateOfBirth).format('YYYY')}
                    </Text>
                  </View>

                  <Text style={insurancePdfStyles.smallBold}>
                    JOUR{'        '}MOIS{'        '}ANNÉE
                  </Text>
                </View>

                <Text style={insurancePdfStyles.smallBold}>
                  S’IL S’AGIT D’UN ENFANT, EST-IL
                </Text>
              </View>

              <View
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  marginTop: '2px',
                }}
              >
                <View style={insurancePdfStyles.checkboxWrapper}>
                  <View style={insurancePdfStyles.checkboxAfter}>
                    <View style={insurancePdfStyles.checkboxBefore} />
                  </View>

                  <Text style={insurancePdfStyles.smallBold}>SCOLARISÉ</Text>
                </View>

                <View style={insurancePdfStyles.checkboxWrapper}>
                  <View style={insurancePdfStyles.checkboxAfter}>
                    <View style={insurancePdfStyles.checkboxBefore} />
                  </View>

                  <Text style={insurancePdfStyles.smallBold}>HANDICAPÉ</Text>
                </View>
              </View>

              <Text
                style={{
                  ...insurancePdfStyles.smallBold,
                  marginTop: '5px',
                }}
              >
                S’IL EST SCOLARISÉ, NOM DE L’ É TABLISSEMENT SCOLAIRE
                ________________________________
              </Text>

              <Text
                style={{
                  ...insurancePdfStyles.smallBold,
                  marginTop: '5px',
                }}
              >
                NO D’IDENTITÉ DU PATIENT
                ______________________________________________________________
              </Text>
            </View>
          </View>

          <View style={insurancePdfStyles.patientInfoQuestion}>
            <Text
              style={{
                ...insurancePdfStyles.smallBold,
                marginRight: '1.5px',
              }}
            >
              2.
            </Text>

            <View>
              <View>
                <Text style={insurancePdfStyles.smallBold}>
                  CES SOINS DENTAIRES SONT-ILS COUVERTS PAR UNE AUTRE ASSURANCE
                  COLLECTIVE
                </Text>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: '5px',
                  }}
                >
                  <Text style={insurancePdfStyles.smallBold}>
                    OU DENTAIRE , LA C.A.T. OU UN RÉGIME D’ASSURANCE D’ÉTAT?
                  </Text>

                  <View style={insurancePdfStyles.checkboxWrapper}>
                    <View style={insurancePdfStyles.checkboxAfter}>
                      <View style={insurancePdfStyles.checkboxBefore} />
                    </View>

                    <Text style={insurancePdfStyles.smallBold}>NON</Text>
                  </View>
                  <View style={insurancePdfStyles.checkboxWrapper}>
                    <View style={insurancePdfStyles.checkboxAfter}>
                      <View style={insurancePdfStyles.checkboxBefore} />
                    </View>

                    <Text style={insurancePdfStyles.smallBold}>OUI</Text>
                  </View>
                </View>
              </View>

              <Text
                style={{
                  ...insurancePdfStyles.smallBold,
                  marginTop: '10px',
                }}
              >
                NO DE LA POLICE_______________________________DATE DE NAISSANCE
                DU CONJOINT
              </Text>
              <Text>_______________________________</Text>

              <Text
                style={{
                  ...insurancePdfStyles.smallBold,
                  marginTop: '10px',
                }}
              >
                OM DE L’AUTRE ASSUREUR OU DU RÉGIME
                ________________________________________________
              </Text>
            </View>
          </View>
        </View>

        <View style={insurancePdfStyles.employeeBodyContent}>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <Text
              style={{
                ...insurancePdfStyles.smallBold,
                marginRight: '1.5px',
              }}
            >
              3.
            </Text>

            <View
              style={{
                ...insurancePdfStyles.flexBetween,
                justifyContent: 'flex-start',
              }}
            >
              <Text style={{ ...insurancePdfStyles.smallBold, width: '60%' }}>
                CES SOINS SONT-ILS REQUIS PAR SUITE D’UN ACCIDENT? SI OUI,
                INDIQUER LA DATE ET DONNER DES PRÉCISIONS SUR UNE FEUILLE
                SÉPARÉE.
              </Text>

              <View style={insurancePdfStyles.patientInfoQuestionCheckboxes}>
                <View style={insurancePdfStyles.checkboxWrapper}>
                  <View style={insurancePdfStyles.checkboxAfter}>
                    <View style={insurancePdfStyles.checkboxBefore} />
                  </View>

                  <Text style={insurancePdfStyles.smallBold}>NON</Text>
                </View>
                <View style={insurancePdfStyles.checkboxWrapper}>
                  <View style={insurancePdfStyles.checkboxAfter}>
                    <View style={insurancePdfStyles.checkboxBefore} />
                  </View>

                  <Text style={insurancePdfStyles.smallBold}>OUI</Text>
                </View>
              </View>
            </View>
          </View>

          <View style={insurancePdfStyles.patientInfoQuestion}>
            <Text
              style={{
                ...insurancePdfStyles.smallBold,
                marginRight: '1.5px',
              }}
            >
              4.
            </Text>

            <View
              style={{
                ...insurancePdfStyles.flexBetween,
                justifyContent: 'flex-start',
              }}
            >
              <Text style={{ ...insurancePdfStyles.smallBold, width: '60%' }}>
                SI LE TRAITEMENT COMPREND UNE PROTHÈSE, UNE COURRONNE OU UN
                PONT, EST-CE LA PREMIÈRE MISE EN BOUCHE? SI NON, INDIQUER LA
                DATE DE LA MISE EN BOUCHE PRÉCÉDENTE ET LES RAISONS DU
                REMPLACEMENT.
              </Text>

              <View style={insurancePdfStyles.patientInfoQuestionCheckboxes}>
                <View style={insurancePdfStyles.checkboxWrapper}>
                  <View style={insurancePdfStyles.checkboxAfter}>
                    <View style={insurancePdfStyles.checkboxBefore} />
                  </View>

                  <Text style={insurancePdfStyles.smallBold}>NON</Text>
                </View>
                <View style={insurancePdfStyles.checkboxWrapper}>
                  <View style={insurancePdfStyles.checkboxAfter}>
                    <View style={insurancePdfStyles.checkboxBefore} />
                  </View>

                  <Text style={insurancePdfStyles.smallBold}>OUI</Text>
                </View>
              </View>
            </View>
          </View>

          <View style={insurancePdfStyles.patientInfoQuestion}>
            <Text
              style={{
                ...insurancePdfStyles.smallBold,
                marginRight: '1.5px',
              }}
            >
              5.
            </Text>

            <View
              style={{
                ...insurancePdfStyles.flexBetween,
                justifyContent: 'flex-start',
              }}
            >
              <Text style={{ ...insurancePdfStyles.smallBold, width: '60%' }}>
                DES SOINS ORTHODONTIQUES SONT-ILS REQUIS?
              </Text>

              <View style={insurancePdfStyles.patientInfoQuestionCheckboxes}>
                <View style={insurancePdfStyles.checkboxWrapper}>
                  <View style={insurancePdfStyles.checkboxAfter}>
                    <View style={insurancePdfStyles.checkboxBefore} />
                  </View>

                  <Text style={insurancePdfStyles.smallBold}>NON</Text>
                </View>
                <View style={insurancePdfStyles.checkboxWrapper}>
                  <View style={insurancePdfStyles.checkboxAfter}>
                    <View style={insurancePdfStyles.checkboxBefore} />
                  </View>

                  <Text style={insurancePdfStyles.smallBold}>OUI</Text>
                </View>
              </View>
            </View>
          </View>

          <View style={insurancePdfStyles.patientInfoQuestion}>
            <Text
              style={{
                ...insurancePdfStyles.smallBold,
                marginRight: '1.5px',
              }}
            >
              6.
            </Text>

            <Text style={insurancePdfStyles.smallBold}>
              JE CONSENS À CE QUE TOUT RENSEIGNEMENT OU DOSSIER EXIGÉ AU SUJET
              DE LA PRÉSENTE {'\n'} SOIT DIVULGUÉ À L’ASSUREUR OU À
              L’ADMINISTRATEUR DU RÉGIME ET JE DÉCLARE QUE LES RENSEIGNEMENTS
              DONNÉS SONT, À MA CONNAISSANCE, VÉRIDIQUES, EXACTS ET COMPLETS.
            </Text>
          </View>

          <View
            style={{
              ...insurancePdfStyles.patientInfoDOBSection,
              alignSelf: 'flex-end',
            }}
          >
            <Text style={insurancePdfStyles.smallBold}>DATE</Text>

            <View style={{ display: 'flex', alignItems: 'center' }}>
              <View
                style={{
                  ...insurancePdfStyles.patientInfoDOB,
                  width: '100px',
                }}
              />
              <Text
                style={{
                  ...insurancePdfStyles.smallBold,
                  marginTop: '1px',
                }}
              >
                JOUR{'        '}MOIS{'        '}ANNÉE
              </Text>
            </View>
          </View>

          <View
            style={{
              ...insurancePdfStyles.patientInfoDOBSection,
              marginTop: '5px',
            }}
          >
            <View style={{ display: 'flex', alignItems: 'center' }}>
              <View
                style={{
                  ...insurancePdfStyles.patientInfoDOB,
                  width: '150px',
                }}
              />
              <Text
                style={{
                  ...insurancePdfStyles.smallBold,
                  marginTop: '1px',
                }}
              >
                SIGNATURE DE L’EMPLOYÉ / PARTICIPANT AU RÉGIME
              </Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

export default PatientInfoFr;
