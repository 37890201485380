import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { insurancePdfStyles } from '../styles';

const SignatureEn = () => {
  return (
    <View>
      <View style={insurancePdfStyles.instructionsHeading}>
        <Text style={insurancePdfStyles.instructionsHeadingText}>
          PART 4. - POLICY HOLDER/EMPLOYER (FOR COMPLETION ONLY IF APPLICABLE.
          SEE ABOVE*)
        </Text>
      </View>

      <View style={insurancePdfStyles.policyHolderBody}>
        <View style={insurancePdfStyles.flexBetween}>
          <View style={insurancePdfStyles.policyHolderTables}>
            <View style={{ width: '50%' }}>
              <Text
                style={{
                  ...insurancePdfStyles.smallBold,
                  marginTop: '5px',
                }}
              >
                1. DATE COVERAGE COMMENCED
              </Text>
              <Text
                style={{
                  ...insurancePdfStyles.smallBold,
                  marginTop: '5px',
                }}
              >
                2. DATE DEPENDENT COVERED
              </Text>
              <Text
                style={{
                  ...insurancePdfStyles.smallBold,
                  marginTop: '5px',
                }}
              >
                3. DATE TERMINATED
              </Text>
            </View>

            <View style={{ width: '50%' }}>
              <View style={insurancePdfStyles.policyHolderTable}>
                <View style={insurancePdfStyles.policyHolderTableHead}>
                  <Text style={insurancePdfStyles.smallBold}>DAY</Text>
                </View>

                <View style={insurancePdfStyles.policyHolderTableHead}>
                  <Text style={insurancePdfStyles.smallBold}>MONTH</Text>
                </View>

                <View
                  style={{
                    ...insurancePdfStyles.policyHolderTableHead,
                    borderRightWidth: 0,
                  }}
                >
                  <Text style={insurancePdfStyles.smallBold}>YEAR</Text>
                </View>
              </View>

              {Array(3)
                .fill('')
                .map((_, idx) => {
                  return (
                    <View
                      style={{
                        ...insurancePdfStyles.policyHolderTable,
                        borderTopWidth: 0,
                      }}
                      key={idx}
                    >
                      {Array(6)
                        .fill('')
                        .map((_, idx) => (
                          <View
                            style={{
                              ...insurancePdfStyles.policyHolderTableCell,
                              borderRightWidth: idx === 5 ? 0 : 0.9,
                            }}
                            key={idx}
                          />
                        ))}
                    </View>
                  );
                })}
            </View>
          </View>

          <View style={insurancePdfStyles.policyHolderTables}>
            <View style={{ width: '50%' }}>
              <Text
                style={{
                  ...insurancePdfStyles.smallBold,
                  marginTop: '5px',
                }}
              >
                4. CONTRACT HOLDER
              </Text>
            </View>

            <View style={{ width: '50%' }}>
              <View
                style={{
                  ...insurancePdfStyles.policyHolderTable,
                  justifyContent: 'center',
                }}
              >
                <Text style={insurancePdfStyles.smallBold}>DATE</Text>
              </View>

              <View
                style={{
                  ...insurancePdfStyles.policyHolderTable,
                  borderTopWidth: 0,
                }}
              >
                {Array(6)
                  .fill('')
                  .map((_, idx) => (
                    <View
                      style={{
                        ...insurancePdfStyles.policyHolderTableCell,
                        borderRightWidth: idx === 5 ? 0 : 0.9,
                      }}
                      key={idx}
                    />
                  ))}
              </View>

              <View
                style={{
                  ...insurancePdfStyles.policyHolderTable,
                  borderTopWidth: 0,
                }}
              >
                <View style={insurancePdfStyles.policyHolderTableHead}>
                  <Text style={insurancePdfStyles.smallBold}>DAY</Text>
                </View>

                <View style={insurancePdfStyles.policyHolderTableHead}>
                  <Text style={insurancePdfStyles.smallBold}>MONTH</Text>
                </View>

                <View
                  style={{
                    ...insurancePdfStyles.policyHolderTableHead,
                    borderRightWidth: 0,
                  }}
                >
                  <Text style={insurancePdfStyles.smallBold}>YEAR</Text>
                </View>
              </View>
            </View>
          </View>

          <View style={{ width: '28%' }}>
            <View style={insurancePdfStyles.policyHolderSignature}>
              <Text style={insurancePdfStyles.smallBold}>
                AUTHORIZED SIGNATURE
              </Text>
            </View>

            <View
              style={{
                ...insurancePdfStyles.policyHolderSignature,
                marginTop: '12px',
              }}
            >
              <Text style={insurancePdfStyles.smallBold}>
                (POSITION OR TITLE)
              </Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

export default SignatureEn;
