import React, { useEffect } from 'react';
import { TextField, Grid, IconButton } from '@mui/material';
import { DeleteOutline, Menu } from '@mui/icons-material';
import { useDraggableOptionStyles } from './styles';
import { useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';

export const DraggableOption = ({
  option,
  options,
  setOptions,
  isHovered,
  isDisabled,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const draggableOptionStyles = useDraggableOptionStyles();
  const currentIndex = options?.findIndex((item) => item?.id === option?.id);
  const { values, handleChange } = useFormik({
    initialValues: {
      anglophoneName: option?.name?.en || '',
      francophoneName: option?.name?.fr || '',
      index: option?.index,
    },
  });

  const handleDeleteOption = (id) => {
    let clonedOptions = [...options];

    clonedOptions = clonedOptions.filter((option) => {
      return option.id !== id;
    });
    setOptions(clonedOptions);
  };

  useEffect(() => {
    const updatedOptions = [...options];

    updatedOptions[currentIndex] = {
      ...updatedOptions[currentIndex],
      name: {
        en: values.anglophoneName,
        fr: values.francophoneName,
      },
      index: values.index,
    };

    setOptions(updatedOptions.sort((a, b) => a.index - b.index));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.anglophoneName, values.francophoneName, values.index]);

  return (
    <Grid
      container
      sx={{
        ...draggableOptionStyles.option,
        bgcolor: isHovered ? theme.palette.action.disabled : 'white',
        opacity: isHovered ? 0.7 : 1,
      }}
      columnGap={3}
    >
      <Grid item xs={0.5}>
        <IconButton disabled={isDisabled}>
          <Menu />
        </IconButton>
      </Grid>

      <Grid item xs={3}>
        <TextField
          label={t('draggableOptionAnglophoneName')}
          fullWidth
          variant="outlined"
          onChange={handleChange}
          value={values.anglophoneName}
          name="anglophoneName"
          disabled={isDisabled}
        />
      </Grid>

      <Grid item xs={3}>
        <TextField
          label={t('draggableOptionFrancophoneName')}
          fullWidth
          variant="outlined"
          onChange={handleChange}
          value={values.francophoneName}
          name="francophoneName"
          disabled={isDisabled}
        />
      </Grid>

      <Grid item xs={2}>
        <TextField
          id="index"
          name="index"
          fullWidth
          variant="outlined"
          type="number"
          onChange={handleChange}
          value={values.index}
          disabled={isDisabled}
        />
      </Grid>

      <Grid item xs={1} display={isDisabled ? 'none' : 'flex'}>
        <IconButton onClick={() => handleDeleteOption(option.id)}>
          <DeleteOutline color="error" />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default DraggableOption;
