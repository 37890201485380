import React from 'react';
import { Routes, Route } from 'react-router-dom';
import TemplatesSearch from './TemplatesSearch';
import TemplatesEdit from './TemplatesEdit';
import TestingEditor from './TestingEditor';

const Templates = () => {
  return (
    <Routes>
      <Route index element={<TemplatesSearch />} />
      <Route path=":id/edit" element={<TemplatesEdit />} />
      <Route path="editor" element={<TestingEditor />} />
    </Routes>
  );
};

export default Templates;
