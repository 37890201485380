import React, { useEffect } from 'react';
import {
  Grid,
  TextField,
  Button,
  Box,
  FormControlLabel,
  Switch,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  Fade,
  IconButton,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { DOCTORS_ADD_PRIMARY_CONTACT_METHOD } from '../../utils/doctors';
import { Close } from '@mui/icons-material';
import { useAddContactModalStyles } from './styles';
import ModalOverlay from '../ModalOverlay';
import { v4 as uuidv4 } from 'uuid';

const AddContactModal = ({
  selectedContact,
  contacts,
  handleCloseContactModal,
  handleUpdateContact,
  isAddContactModalOpen,
}) => {
  const { t } = useTranslation();
  const addContactModalStyles = useAddContactModalStyles();
  const { values, handleChange, setFieldValue, resetForm } = useFormik({
    initialValues: {
      type: 'Email',
      value: selectedContact?.id,
      primary: !!selectedContact?.primary || false,
    },
  });
  const RADIO_OPTIONS = [
    {
      label: 'addContactModalEmail',
      value: 'Email',
    },
    {
      label: 'addContactModalFax',
      value: 'Fax',
    },
    {
      label: 'addContactModalTelephone',
      value: 'Telephone',
    },
  ];

  const handleSaveContact = () => {
    let updatedContacts = [...contacts];
    const currentIndex = contacts.findIndex(
      (item) => item.id === selectedContact.id
    );
    const type = DOCTORS_ADD_PRIMARY_CONTACT_METHOD.find(
      (item) => item.value === values.type
    );

    if (updatedContacts?.length && values.primary) {
      updatedContacts = updatedContacts.map((contact) => {
        return {
          ...contact,
          primary: false,
        };
      });
    }

    if (!updatedContacts?.length || currentIndex === -1) {
      handleUpdateContact([
        ...updatedContacts,
        {
          id: uuidv4(),
          type,
          value: values.value,
          primary: !contacts?.length ? true : values.primary,
        },
      ]);
    } else if (currentIndex !== -1) {
      updatedContacts[currentIndex] = {
        id: selectedContact.id,
        type,
        value: values.value,
        primary: !contacts?.length ? true : values.primary,
      };

      handleUpdateContact(updatedContacts);
    }

    resetForm();
    handleCloseContactModal();
  };

  useEffect(() => {
    if (selectedContact) {
      setFieldValue('type', selectedContact?.type?.value || 'Email');
      setFieldValue('value', selectedContact?.value || '');
      setFieldValue('primary', !!selectedContact?.primary || false);
    }
  }, [selectedContact, setFieldValue]);

  return (
    <ModalOverlay
      isModalOpen={isAddContactModalOpen}
      handleCloseModal={handleCloseContactModal}
    >
      <Fade in={isAddContactModalOpen}>
        <Box sx={addContactModalStyles.modal}>
          <IconButton
            onClick={handleCloseContactModal}
            sx={addContactModalStyles.closeButton}
            disableRipple
          >
            <Close />
          </IconButton>

          <Box>
            <Grid container spacing={3} marginBottom={'24px'}>
              <Grid item xs={12} md={12}>
                <FormControl>
                  <FormLabel>{t('addContactModalContactMethod')}</FormLabel>

                  <RadioGroup
                    name="type"
                    row
                    onChange={handleChange}
                    value={values.type}
                  >
                    {RADIO_OPTIONS.map((option) => {
                      return (
                        <FormControlLabel
                          value={option.value}
                          control={<Radio />}
                          label={t(option.label)}
                          key={option.label}
                        />
                      );
                    })}
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={12}>
                <TextField
                  required
                  id={'value'}
                  name={'value'}
                  label={
                    values.type === 'email'
                      ? t('addContactModalEmail')
                      : values.type === 'fax'
                      ? t('addContactModalFax')
                      : values.type === 'telephone'
                      ? t('addContactModalTelephone')
                      : 'Enter Value'
                  }
                  fullWidth
                  variant="outlined"
                  onChange={handleChange}
                  value={values.value}
                />
              </Grid>

              <Grid item xs={12} md={12} sx={addContactModalStyles.switch}>
                <FormControlLabel
                  control={
                    <Switch
                      disabled={selectedContact?.primary}
                      checked={values.primary}
                      onChange={() => setFieldValue('primary', !values.primary)}
                    />
                  }
                  label={t('addContactModalPrimaryMethod')}
                />
                {selectedContact?.primary ? (
                  <Typography variant="caption">
                    {t('addContactModalDisclaimer')}
                  </Typography>
                ) : null}
              </Grid>
            </Grid>

            <Button
              variant="contained"
              size="large"
              onClick={handleSaveContact}
              disabled={!values.value?.length}
            >
              {t('addContactModalSaveBtn')}
            </Button>
          </Box>
        </Box>
      </Fade>
    </ModalOverlay>
  );
};

export default AddContactModal;
