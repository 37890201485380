import React, { useState, useEffect, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useLayout } from '../../contexts/LayoutContext';
import { ArrowCircleLeftOutlined, Menu as MenuIcon } from '@mui/icons-material';
import { Box, useMediaQuery, IconButton, Typography } from '@mui/material';
import { PAGES_TITLE } from '../../utils/main';
import { useTranslation } from 'react-i18next';
import { useMainStyles } from './styles';
import Sidebar from '../../components/Sidebar';
import { COLORS } from '../../utils/colors';

const Main = ({ children, style, notFound }) => {
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const mainStyles = useMainStyles();
  const { pageTitle, setPageTitle } = useLayout();
  const isMobileView = useMediaQuery('(max-width: 900px)');
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [activePage, setActivePage] = useState('');
  const isNestedRoute =
    params && params['*'] !== undefined && params['*'] !== '';
  let mainRoute = pathname.split('/');
  mainRoute = mainRoute[1] ? '/' + mainRoute[1] : '/';

  useEffect(() => {
    const currentPage = PAGES_TITLE.find((title) => {
      return mainRoute === title.path;
    });

    setPageTitle(currentPage.title);
  }, [mainRoute, setPageTitle]);

  useMemo(() => {
    setActivePage(mainRoute);
  }, [mainRoute]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Box sx={{ ...mainStyles.container, ...style }}>
      {notFound ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100vh"
          bgcolor={COLORS.LIGHT_GRAY}
          width="100%"
        >
          <Typography fontWeight={500} fontSize="20px" margin="auto">
            {t('mainNotFound')}
          </Typography>
        </Box>
      ) : (
        <>
          <Sidebar
            activePage={activePage}
            isMobileView={isMobileView}
            isSidebarOpen={isSidebarOpen}
            setIsSidebarOpen={setIsSidebarOpen}
          />

          <Box sx={mainStyles.body}>
            <Box sx={mainStyles.nav}>
              <Box sx={mainStyles.navContent}>
                {isNestedRoute && (
                  <IconButton
                    color="primary"
                    aria-label="Back"
                    sx={mainStyles.sidebarCloseButton}
                    onClick={() => navigate(-1)}
                  >
                    <ArrowCircleLeftOutlined />
                  </IconButton>
                )}
                <Typography
                  variant="h2"
                  sx={{
                    ...mainStyles.pageTitle,
                    fontSize: params.id ? 24 : 32,
                  }}
                >
                  {t(pageTitle)}
                </Typography>
              </Box>

              {isMobileView && (
                <IconButton
                  aria-label="Open Sidebar"
                  onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                >
                  <MenuIcon />
                </IconButton>
              )}
            </Box>

            <Box sx={mainStyles.children}>{children}</Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default Main;
