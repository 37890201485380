import { Typography, Box, Tabs, Tab, TextField, Button } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePatientsViewStyles } from './styles';
import { v4 as uuidv4 } from 'uuid';
import { DataGrid } from '@mui/x-data-grid';
import { ReportsSearchTable } from '../../Reports/ReportsSearch';
import { useFormik } from 'formik';
import { useLayout } from '../../../contexts/LayoutContext';
import moment from 'moment';
import { BiCheckDouble } from 'react-icons/bi';

const CustomTabPanel = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: '12px 0 12px 0' }}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const Logs = ({ patient }) => {
  const { t } = useTranslation();
  const patientsViewStyles = usePatientsViewStyles();
  const [value, setValue] = useState(0);
  const { patients, setPatients, reports, deletedReports } = useLayout();
  const { values, handleChange, resetForm } = useFormik({
    initialValues: {
      historyNote: '',
    },
  });
  const patientReports =
    reports.filter((report) => report?.patientId === patient?.id) || [];
  const deletedPatientReports =
    deletedReports.filter((report) => report?.patientId === patient?.id) || [];

  const historyColumns = [
    {
      field: 'date',
      headerName: t('patientsViewCreatedAt'),
      flex: 0.2,
      valueGetter: ({ row: { date } }) => {
        return `${moment(date).format('lll')}`;
      },
    },
    {
      field: 'insertedBy',
      headerName: t('patientsViewInsertedBy'),
      flex: 0.2,
      valueGetter: () => t('patientsViewDoctor'),
    },
    {
      field: 'notes',
      headerName: t('patientsViewNotes'),
      flex: 0.5,
    },
  ];

  const handleSetTabValue = (_event, newValue) => {
    setValue(newValue);
  };
  const handleAddNote = () => {
    if (values.historyNote) {
      const updatedItems = [...patients];
      const currentIndex = patients.findIndex(
        (item) => item.id === patient?.id
      );

      updatedItems[currentIndex] = {
        ...updatedItems[currentIndex],
        logs: {
          ...updatedItems[currentIndex]?.logs,
          history: [
            {
              id: uuidv4(),
              date: new Date(),
              insertedBy: t('patientsViewDoctor'),
              notes: values.historyNote,
            },
            ...updatedItems[currentIndex]?.logs.history,
          ],
        },
      };

      resetForm();
      setPatients(updatedItems);
    }
  };

  return (
    <Box sx={{ ...patientsViewStyles.flexBetween, marginBottom: 0 }}>
      <Box width="100%" minHeight="80vh">
        <Typography variant="h4" gutterBottom>
          {t('patientsViewLogs')}
        </Typography>

        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleSetTabValue}>
            <Tab label={t('patientsViewReports')} {...a11yProps(1)} />
            <Tab label={t('patientsViewHistory')} {...a11yProps(2)} />
            <Tab label={t('patientsViewDeletedReports')} {...a11yProps(3)} />
          </Tabs>
        </Box>

        <CustomTabPanel value={value} index={0}>
          <Box>
            <ReportsSearchTable patientReports={patientReports} />
          </Box>
        </CustomTabPanel>

        <CustomTabPanel value={value} index={1}>
          <TextField
            rows={8}
            size="large"
            fullWidth
            label={t('patientsViewNotes')}
            multiline
            name="historyNote"
            sx={{ marginTop: '12px' }}
            value={values.historyNote}
            onChange={handleChange}
          />
          <Button
            variant="contained"
            sx={{ marginBlock: '12px' }}
            onClick={handleAddNote}
            startIcon={<BiCheckDouble />}
          >
            {t('patientsViewSave')}
          </Button>

          <DataGrid
            autoHeight
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            rows={patient?.logs?.history}
            columns={historyColumns}
            disableRowSelectionOnClick
          />
        </CustomTabPanel>

        <CustomTabPanel value={value} index={2}>
          <Box>
            <ReportsSearchTable patientReports={deletedPatientReports} />
          </Box>
        </CustomTabPanel>
      </Box>
    </Box>
  );
};

export default Logs;
