import { Box, Typography, Switch } from '@mui/material';
import { useTemplatesEditStyles } from '../styles';
import { useLayout } from '../../../../contexts/LayoutContext';
import { useState } from 'react';

const PatientInformationCard = ({
  option,
  isSectionNameActive,
  options,
  setOptions,
  currentIndex,
}) => {
  const { currentLanguage } = useLayout();
  const templatesEditStyles = useTemplatesEditStyles();
  const [isActive, setIsActive] = useState(option?.isActive);

  const handleToggleSwitch = () => {
    setIsActive(!isActive);

    const updatedOptions = [...options];
    updatedOptions[currentIndex] = {
      ...updatedOptions[currentIndex],
      isActive: !isActive,
    };
    setOptions(updatedOptions);
  };

  return (
    <Box
      sx={{
        ...templatesEditStyles.patientInfoCard,
        opacity: isActive || !isSectionNameActive ? 1 : 0.5,
      }}
    >
      <Typography>
        {currentLanguage === 'en' ? option?.name?.en : option.name?.fr}
      </Typography>
      <Switch
        checked={isActive}
        onChange={handleToggleSwitch}
        disabled={!isSectionNameActive}
      />
    </Box>
  );
};

export default PatientInformationCard;
