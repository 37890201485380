import React, { useState } from 'react';
import {
  Button,
  FormHelperText,
  Select,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Box,
  Typography,
  useTheme,
  IconButton,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useTranslation } from 'react-i18next';
import {
  DOCTORS_ADD_PREFERRED_LANGUAGE,
  DOCTORS_ADD_SEX,
} from '../../utils/doctors';
import { useDoctorsFormStyles } from './styles';
import { v4 as uuidv4 } from 'uuid';
import { Close } from '@mui/icons-material';
import AddContactModal from '../AddContactModal';

const GeneralInformation = ({
  formik: { values, errors, touched, handleChange, handleBlur, setFieldValue },
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const doctorsFormStyles = useDoctorsFormStyles();
  const [isAddContactModalOpen, setIsAddContactModalOpen] = useState(false);
  const [selectedContact, setSelectedContact] = useState({});

  const handleCloseContactModal = () => setIsAddContactModalOpen(false);
  const handleUpdateContact = (updatedContacts) =>
    setFieldValue('contacts', updatedContacts);
  const handleSetSelectedContact = (contact) => {
    setSelectedContact(contact);
    setIsAddContactModalOpen(true);
  };

  const handleAddContact = () =>
    handleSetSelectedContact({
      id: uuidv4(),
      type: { value: '', name: '' },
      value: '',
      primary: false,
    });

  const handleDeleteContact = (id) => {
    const filteredArray = values?.contacts.filter(
      (contact) => id !== contact?.id
    );

    setFieldValue('contacts', filteredArray);
  };

  return (
    <Box mt="24px">
      <AddContactModal
        isAddContactModalOpen={isAddContactModalOpen}
        selectedContact={selectedContact}
        contacts={values.contacts}
        handleCloseContactModal={handleCloseContactModal}
        handleUpdateContact={handleUpdateContact}
      />

      <Typography variant="h4" sx={doctorsFormStyles.sectionHeading}>
        {t('doctorsFormHeadingGeneral')}
      </Typography>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid container spacing={3} marginBottom={'24px'}>
          <Grid item xs={12} md={4}>
            <TextField
              required
              id="firstName"
              name="firstName"
              label={t('doctorsFormFirstName')}
              fullWidth
              variant="outlined"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.firstName}
              error={errors.firstName && touched.firstName}
              helperText={
                errors.firstName && touched.firstName && errors.firstName
              }
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              id="middleName"
              name="middleName"
              label={t('doctorsFormMiddleName')}
              fullWidth
              variant="outlined"
              onChange={handleChange}
              value={values.middleName}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              required
              id="lastName"
              name="lastName"
              label={t('doctorsFormLastName')}
              fullWidth
              variant="outlined"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.lastName}
              error={errors.lastName && touched.lastName}
              helperText={
                errors.lastName && touched.lastName && errors.lastName
              }
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl error={errors.sex && touched.sex} required fullWidth>
              <InputLabel id="gender">{t('doctorsFormSex')}</InputLabel>
              <Select
                labelId="gender"
                id="gender"
                label={t('doctorsFormSex')}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.sex}
                name="sex"
              >
                {DOCTORS_ADD_SEX.map((item) => {
                  return (
                    <MenuItem key={item.value} value={item.value}>
                      {t(item.text)}
                    </MenuItem>
                  );
                })}
              </Select>
              {errors.sex && touched.sex && (
                <FormHelperText>{errors.sex}</FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <InputLabel id="preferredLanguage">
                {t('doctorsFormPreferredLanguage')}
              </InputLabel>
              <Select
                labelId="preferredLanguage"
                id="preferredLanguage"
                label={t('doctorsFormPreferredLanguage')}
                onChange={handleChange}
                value={values.preferredLanguage}
                name="preferredLanguage"
              >
                {DOCTORS_ADD_PREFERRED_LANGUAGE.map((item) => {
                  return (
                    <MenuItem key={item.value} value={item.value}>
                      {t(item.text)}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container columnSpacing={4}>
          {values.contacts
            .sort((_a, b) => {
              if (b.primary) return 1;
              return -1;
            })
            .map((contact) => {
              return (
                <Grid
                  item
                  minWidth="250px"
                  maxWidth="300px"
                  key={contact.id}
                  position="relative"
                >
                  <IconButton
                    onClick={() => handleDeleteContact(contact.id)}
                    sx={doctorsFormStyles.closeButton}
                  >
                    <Close sx={{ fontSize: '15px' }} />
                  </IconButton>

                  <Button
                    sx={doctorsFormStyles.address}
                    onClick={() => handleSetSelectedContact(contact)}
                  >
                    <span style={{ color: 'black' }}>
                      {t(contact.type.name)}
                    </span>
                    {contact.value}
                    {contact.primary && (
                      <span style={{ color: theme.palette.primary.main }}>
                        {t('doctorsFormPrimary')}
                      </span>
                    )}
                  </Button>
                </Grid>
              );
            })}

          <Grid item md={2.5}>
            <Button
              sx={doctorsFormStyles.addAddress}
              variant="outlined"
              onClick={handleAddContact}
            >
              {t('doctorsFormAddContact')}
            </Button>
          </Grid>
        </Grid>
        <FormHelperText sx={{ color: theme.palette.error.main }}>
          {errors.contacts && touched.contacts && errors.contacts}
        </FormHelperText>
      </LocalizationProvider>
    </Box>
  );
};

export default GeneralInformation;
