export const useMainStyles = () => {
  return {
    container: {
      display: 'flex',
      position: 'relative',
    },
    body: { width: '100%' },
    nav: {
      display: 'flex',
      justifyContent: 'space-between',
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
      marginBottom: '16px',
      minHeight: '75px',
      position: 'sticky',
      top: 0,
      paddingInline: '16px',
      bgcolor: 'white',
      zIndex: 99,
      alignItems: 'flex-end',
      boxShadow: `rgba(0, 0, 0, 0.16) 0px 1px 4px`,
    },
    navContent: {
      display: 'flex',
      alignItems: 'center',
    },
    pageTitle: {
      fontWeight: 600,
      marginBottom: '3px',
    },
    children: {
      paddingInline: '16px',
      paddingBottom: '32px',
      position: 'relative',
    },
  };
};
