export const ATTRIBUTES_TYPES = [
  { name: 'attributesTypeTextbox', value: 'textbox' },
  { name: 'attributesTypeSingleSelect', value: 'list' },
  { name: 'attributesTypeRadioButton', value: 'radio-button' },
  { name: 'attributesTypeCheckboxes', value: 'checkboxes' },
  { name: 'attributesTypeDate', value: 'date' },
  { name: 'attributesTypeSwitch', value: 'switch' },
  { name: 'attributesTypeMultiSelect', value: 'multi-select' },
  { name: 'attributesTypeFormattedText', value: 'formatted-text' },
];

export const ATTRIBUTES_NAMES = [
  {
    name: 'attributesNameHorizontalResorption',
    value: 'horizontal-resorption',
  },
  { name: 'attributesNameVerticalResorption', value: 'vertical-resorption' },
  { name: 'attributesNameLeucoplast', value: 'leucoplast' },
  { name: 'attributesNameRecommendations', value: 'recommendations' },
  { name: 'attributesNameProstheticType', value: 'prosthetic-type' },
  { name: 'attributesNamePartialDenture', value: 'partial-denture' },
  {
    name: 'attributesNameRemovableProstheticBars',
    value: 'removable-prosthetic-bars',
  },
  { name: 'attributesNameFixedProstheticBars', value: 'fixed-prosthetic-bars' },
  { name: 'attributesNameRadiographs', value: 'radiographs' },
  { name: 'attributesNameRevaluation', value: 'revaluation' },
  { name: 'attributesNameImplants', value: 'implants' },
  { name: 'attributesNameSinusLift', value: 'sinus-lift' },
  { name: 'attributesNameBiopsyType', value: 'biopsy-type' },
  { name: 'attributesNameFrenectomy', value: 'frenectomy' },
  { name: 'attributesNameMaintenancePhase', value: 'maintenance-phase' },
  { name: 'attributesNameMaintenanceProgram', value: 'maintenance-program' },
];
