export const REPORTS_FILTER_FORM_STATUSES = [
  { value: 'sent-by-email', text: 'reportsSearchSentByEmail' },
  { value: 'published', text: 'reportsSearchPublished' },
  { value: 'in-progress', text: 'reportsSearchInProgress' },
];

export const REPORTS_FILTER_FORM_REPORT_TYPES = [
  { value: 'surgery', text: 'reportsSearchSurgery' },
  { value: 'exam', text: 'reportsSearchExam' },
  { value: 'reevaluation', text: 'reportsSearchReevalutation' },
  { value: 'recall', text: 'reportsSearchRecall' },
];

export const REPORTS_FILTER_PREFERRED_CONTACT_METHOD = [
  { value: 'Email', name: 'reportsSearchEmail' },
  { value: 'Fax', name: 'reportsSearchFax' },
  { value: 'Telephone', name: 'reportsSearchTelephone' },
];
