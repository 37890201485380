import React, { useRef } from 'react';
import AttributeTemplate from '.';
import { Box, Button } from '@mui/material';
import { Editor } from '@tinymce/tinymce-react';
import { useAttributeTypeStyles } from '../styles';
import { useTranslation } from 'react-i18next';
import { BiCheckDouble } from 'react-icons/bi';

const FormattedTextAttribute = ({
  attributeLabel,
  attributeValue,
  setAttributeValue,
}) => {
  let editorRef = useRef(null);
  const { t } = useTranslation();
  const attributeTypeStyles = useAttributeTypeStyles();

  const handleGetContent = () => {
    const content = editorRef?.current?.getContent();
    setAttributeValue(content);
  };

  return (
    <AttributeTemplate attributeLabel={attributeLabel}>
      <Box width={'80%'} display="flex" flexDirection="column">
        <Editor
          onInit={(_evt, editor) => (editorRef.current = editor)}
          initialValue={attributeValue}
          apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
          init={attributeTypeStyles.editor}
          id={'attributeName sectionIdx'}
        />

        <Button
          variant="outlined"
          sx={attributeTypeStyles.removeAll}
          onClick={handleGetContent}
          startIcon={<BiCheckDouble />}
        >
          {t('attributeTypeSave')}
        </Button>
      </Box>
    </AttributeTemplate>
  );
};

export default FormattedTextAttribute;
