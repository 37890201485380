import React, { useState, useRef } from 'react';
import { Button, Typography, Box } from '@mui/material';
import { FileCopy, DeleteOutlined } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import DeleteModal from '../../../components/DeleteModal';
import { useLayout } from '../../../contexts/LayoutContext';
import { v4 as uuidv4 } from 'uuid';
import { useTemplatesSearchStyles } from './styles';
import { useTranslation } from 'react-i18next';
import { toggleToast } from '../../../utils/helpers/toast';

const MappedTemplates = ({
  filteredTemplates,
  setSearchValue,
  setFilteredTemplates,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const templatesSearchStyles = useTemplatesSearchStyles();
  const { currentLanguage, templates, setTemplates } = useLayout();
  const dropIndex = useRef(null);
  const draggedItemIndex = useRef(null);
  const [templateId, setTemplateId] = useState('');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleDeleteModalOpen = () => setIsDeleteModalOpen(true);
  const handleCloseDeleteModal = () => setIsDeleteModalOpen(false);

  const handleDeleteTemplate = (template) => {
    setTemplateId(template.id);
    handleDeleteModalOpen();
  };

  const handleConfirmDelete = (id) => {
    const updatedTemplates = [...templates].filter((section) => {
      return section.id !== id;
    });

    setTemplates(updatedTemplates);
    setIsDeleteModalOpen(false);
    toggleToast('success', t('templatesSearchDeletedSuccessfully'));
  };

  const handleCopyTemplate = (template, idx) => {
    let clonedTemplates = [...templates];
    const copiedTemplate = {
      ...template,
      id: uuidv4(),
      name: {
        en: `${template.name.en} copy`,
        fr: `${template.name.fr} copie`,
      },
    };

    clonedTemplates.splice(idx + 1, 0, copiedTemplate);
    setSearchValue('');
    setTemplates(clonedTemplates);
  };

  const handleDragStart = (index) => {
    draggedItemIndex.current = index;
  };

  const handleDragEnter = (index) => {
    dropIndex.current = index;
    let clonedTemplates = [...templates];
    const draggedItem = clonedTemplates.splice(draggedItemIndex.current, 1)[0];

    clonedTemplates.splice(dropIndex.current, 0, draggedItem);
    setFilteredTemplates(clonedTemplates);
  };

  const handleDragEnd = () => {
    let clonedTemplates = [...templates];
    const draggedItem = clonedTemplates.splice(draggedItemIndex.current, 1)[0];

    clonedTemplates.splice(dropIndex.current, 0, draggedItem);
    draggedItemIndex.current = null;
    dropIndex.current = null;

    setTemplates(clonedTemplates);
  };

  return (
    <Box maxHeight="80vh" overflow="auto">
      <DeleteModal
        isDeleteModalOpen={isDeleteModalOpen}
        handleCloseDeleteModal={handleCloseDeleteModal}
        handleConfirm={() => handleConfirmDelete(templateId)}
        isProtected
      />
      {filteredTemplates.map((template, idx) => {
        const isHovered = dropIndex.current === idx;

        return (
          <div
            style={{
              ...templatesSearchStyles.template,
              backgroundColor: isHovered
                ? theme.palette.action.disabled
                : 'white',
              opacity: isHovered ? 0.5 : 1,
            }}
            key={template.id}
            draggable
            onDragStart={() => handleDragStart(idx)}
            onDragEnter={() => handleDragEnter(idx)}
            onDragOver={(e) => e.preventDefault()}
            onDragEnd={handleDragEnd}
          >
            <Box sx={templatesSearchStyles.templateName}>
              <Box
                sx={{
                  ...templatesSearchStyles.theme,
                  bgcolor: template.theme,
                }}
              >
                <Typography>
                  {currentLanguage === 'en'
                    ? template.abbreviation.en
                    : template.abbreviation.fr}
                </Typography>
              </Box>

              <Link
                style={templatesSearchStyles.link}
                to={`${template.id}/edit`}
              >
                <Typography sx={templatesSearchStyles.name}>
                  {currentLanguage === 'en'
                    ? t(template.name.en)
                    : t(template.name.fr)}
                </Typography>
              </Link>
            </Box>

            <Box>
              <Button onClick={() => handleCopyTemplate(template, idx)}>
                <FileCopy />
              </Button>

              <Button
                color="error"
                onClick={() => handleDeleteTemplate(template)}
              >
                <DeleteOutlined />
              </Button>
            </Box>
          </div>
        );
      })}
    </Box>
  );
};

export default MappedTemplates;
