import React from 'react';
import { Routes, Route } from 'react-router-dom';
import UsersSearch from './UsersSearch';
import UsersEdit from './UsersEdit';
import UsersAdd from './UsersAdd';

const Users = () => {
  return (
    <Routes>
      <Route index element={<UsersSearch />} />
      <Route path=":id/edit" element={<UsersEdit />} />
      <Route path="add" element={<UsersAdd />} />
    </Routes>
  );
};

export default Users;
