import { useTheme } from '@mui/material';

export const useSidebarStyles = () => {
  const theme = useTheme();

  return {
    wrapper: {
      width: '250px',
      padding: '32px',

      '& .MuiDrawer-paper': {
        boxSizing: 'border-box',
        border: 'none',
        boxShadow: '2px 0 3px -2px #888',
        width: '250px',
      },
    },
    heading: {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'space-between',
      paddingInline: '32px',
      minHeight: '75px',
      width: '100%',
      borderBottom: `1px solid ${theme.palette.action.focus}`,
      boxShadow: `rgba(0, 0, 0, 0.16) 0px 1px 4px`,
    },
    list: {
      marginTop: '8px',
    },
    listItem: {
      borderLeft: `3px solid transparent`,
    },
    listItemButton: {
      paddingLeft: '32px',
    },
    listItemIcon: {
      fontSize: '20px',
    },
    closeButton: { marginRight: 1 },
    dropdown: {
      height: '48px',
      m: 0,
      p: 0,
      paddingLeft: '35px',
    },
    dropdownOptions: {
      height: '48px',
      width: '95%',

      '& .MuiOutlinedInput-input': { p: 0 },
      '.MuiOutlinedInput-notchedOutline': {
        border: 0,
      },
      '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: 0,
      },
    },
    logout: {
      display: 'flex',
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      paddingLeft: '35px',
      cursor: 'pointer',
      borderTop: `1px solid ${theme.palette.divider}`,
    },
    accordion: { width: '100%', boxShadow: 'none' },
    accordionSummary: {
      maxHeight: '46.29px',
      paddingLeft: '32px',
      '&.MuiAccordionSummary-root.Mui-expanded': {
        minHeight: '46.29px',
      },
    },
  };
};
