import { useTheme } from '@mui/material';

export const useTemplatesEditStyles = () => {
  const theme = useTheme();

  return {
    templateTheme: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: '10px',
      borderRadius: '5px',
    },
    templateThemeIcon: {
      position: 'relative',
    },
    templateThemeIconColor: {
      height: '30px',
      width: '30px',
      borderRadius: '15px',
    },
    templateThemePicker: {
      position: 'absolute',
      background: 'white',
      zIndex: 99,
      borderRadius: '12px',
      padding: '12px',
      border: `1px solid ${theme.palette.action.focus}`,
      top: '0',
    },
    link: { textDecoration: 'none', marginLeft: '8px' },
    codesWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignSelf: 'flex-start',
    },
    codesWrapperHeading: {
      display: 'flex',
      marginBottom: '5px',
    },
    codes: {
      marginRight: '12px',
      padding: '2px 5px',
      bgcolor: theme.palette.action.focus,
    },
    precisionsWrapper: {
      display: 'flex',
      alignSelf: 'flex-start',
    },
    sections: { marginTop: 3 },
    sortButton: {
      marginBottom: 2,
      marginTop: '32px',
      display: 'flex',
    },
    medicalHistory: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '12px',
      borderRadius: '5px',
      marginBottom: 2,
      border: '1px solid transparent',
      bgcolor: theme.palette.action.hover,
    },
    patientInfo: {
      display: 'flex',
      alignItems: 'center',
      padding: '12px',
      borderRadius: '5px',
      flexDirection: 'column',
      marginBottom: 2,
      border: '1px solid transparent',
      bgcolor: theme.palette.action.hover,
    },
    patientInfoHeading: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      paddingBottom: '12px',
      borderBottom: `1px solid ${theme.palette.action.focus}`,
    },
    patientInfoCard: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '2px 16px',
      marginTop: 1,
      border: '1px solid transparent',

      width: '100%',
      bgcolor: theme.palette.action.hover,
    },
    addSection: { marginTop: '12px' },
    addSectionButton: {
      marginRight: '12px',
    },
    addSectionButtonIcon: {
      marginRight: '8px',
    },
    section: {
      padding: '12px',
      borderRadius: '5px',
      marginBottom: 2,
    },
    sectionTextFields: {
      marginBlock: '32px',
    },
    textField: {
      bgcolor: 'white',
    },
    accordionWrapper: {
      marginTop: '18px',
    },
    accordionSummary: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    },
    accordionSummaryContent: {
      display: 'flex',
      alignItems: 'center',
    },
    accordionMenuIcon: {
      marginInline: '12px',
    },
    accordionAttributeType: {
      borderRadius: '5px',
      padding: '5px 10px',
      marginLeft: '28px',
      bgcolor: 'white',
    },
    accordionDetails: {
      paddingTop: '32px',
    },
    modal: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      bgcolor: 'background.paper',
      borderRadius: 2,
      boxShadow: 24,
      p: 4,
      width: '50%',
      flexDirection: 'column',
      alignItems: 'center',
      display: 'flex',
    },
    modalHeading: {
      alignSelf: 'flex-start',
      width: '100%',
      paddingBottom: '20px',
      borderBottom: `1px solid ${theme.palette.action.focus}`,
    },
    modalOptions: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderBottom: `1px solid ${theme.palette.action.focus}`,
    },
    draggableOption: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingBlock: '12px',
      marginBottom: '12px',
      cursor: 'grab',
      borderBottom: `1px solid ${theme.palette.action.focus}`,
    },
    sectionHeading: {
      display: 'flex',
      alignItems: 'center',
    },
    sectionHeadingIcons: {
      marginRight: '12px',
      flexDirection: 'column',
      display: 'flex',
    },
    addSectionWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    templateName: { display: 'flex', alignItems: 'center' },
    templateWrapper: {
      height: '40px',
      width: '40px',
      borderRadius: '20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: '9px',
      color: 'white',
    },
    importSectionWrapper: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '60%',
      bgcolor: 'background.paper',
      borderRadius: 2,
      boxShadow: 24,
      p: 4,
      height: '90%',
    },
    templateSection: {
      margin: '6px 0 0 24px',
      borderRadius: '5px',
      bgcolor: theme.palette.action.hover,
      color: theme.palette.primary.main,
    },
    closeButton: {
      position: 'absolute',
      top: 0,
      right: 0,
    },
    editCodes: {
      color: theme.palette.primary.main,
      '&:hover': { color: theme.palette.info.dark },
    },
    importTemplateName: {
      color: theme.palette.primary.main,
      '&:hover': { color: theme.palette.info.dark },
      marginRight: '8px',
    },
  };
};
