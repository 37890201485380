import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, IconButton, TextField, Typography } from '@mui/material';
import { useTreatmentPlanStyles } from './styles';
import { AddCircleOutline, DeleteOutline, FileCopy } from '@mui/icons-material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { useFormik } from 'formik';
import Proceeding from './Proceeding';
import { BiSolidDownArrow, BiSolidUpArrow } from 'react-icons/bi';
import { v4 as uuidv4 } from 'uuid';

const Appointment = ({ setAppointments, appointments, appointment }) => {
  const { t } = useTranslation();
  const treatmentPlanStyles = useTreatmentPlanStyles();
  const currentIndex = appointments.findIndex(
    (item) => item.id === appointment?.id
  );
  const dropIndex = useRef(null);
  const draggedItemIndex = useRef(null);
  const [proceedings, setProceedings] = useState(appointment.proceedings);
  const { setFieldValue, values } = useFormik({
    initialValues: {
      requestedDate: new Date(appointment?.requestedDate) || new Date(),
      bookedDate: new Date(appointment?.bookedDate) || new Date(),
      completed: appointment?.completed || false,
    },
  });

  const handleDeleteAppointment = () => {
    const clonedAppointments = appointments.filter((item) => {
      return item.id !== appointment.id;
    });

    setAppointments(clonedAppointments);
  };

  const handleMoveSection = (direction, selectedIndex) => {
    const clonedAppointments = [...appointments];

    if (direction === 'up' && selectedIndex > 0) {
      const temp = clonedAppointments[selectedIndex - 1];
      clonedAppointments[selectedIndex - 1] = clonedAppointments[selectedIndex];
      clonedAppointments[selectedIndex] = temp;
    } else if (
      direction === 'down' &&
      selectedIndex < clonedAppointments.length - 1
    ) {
      const temp = clonedAppointments[selectedIndex + 1];

      clonedAppointments[selectedIndex + 1] = clonedAppointments[selectedIndex];
      clonedAppointments[selectedIndex] = temp;
    }

    setAppointments(clonedAppointments);
  };

  const handleCloneAppointment = () => {
    let clonedAppointments = [...appointments];
    const copiedAppointment = {
      ...appointment,
      id: uuidv4(),
    };

    clonedAppointments.splice(currentIndex + 1, 0, copiedAppointment);
    setAppointments(clonedAppointments);
  };

  const handleAddProceeding = () => {
    const proceeding = {
      id: uuidv4(),
      time: '',
      price: '',
      region: '',
      responsible: '',
      templateId: '',
      selectedRegions: [],
    };
    setProceedings([...proceedings, proceeding]);
  };

  const handleDragStart = (index) => {
    draggedItemIndex.current = index;
  };

  const handleDragEnter = (index) => {
    dropIndex.current = index;
    let clonedProceedings = [...proceedings];
    const draggedItem = clonedProceedings.splice(
      draggedItemIndex.current,
      1
    )[0];

    clonedProceedings.splice(dropIndex.current, 0, draggedItem);
  };

  const handleDragEnd = () => {
    let clonedProceedings = [...proceedings];
    const draggedItem = clonedProceedings.splice(
      draggedItemIndex.current,
      1
    )[0];

    clonedProceedings.splice(dropIndex.current, 0, draggedItem);
    draggedItemIndex.current = null;
    dropIndex.current = null;

    setProceedings(clonedProceedings);
  };

  useEffect(() => {
    const updatedAppointments = [...appointments];

    updatedAppointments[currentIndex] = {
      ...updatedAppointments[currentIndex],
      proceedings,
      requestedDate: values.requestedDate,
      bookedDate: values.bookedDate,
      completed: values.completed,
    };

    setAppointments(updatedAppointments);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proceedings, values.requestedDate, values.bookedDate, values.completed]);

  return (
    <Box mb="48px">
      <div>
        <Box sx={treatmentPlanStyles.appointmentTop}>
          <Box display={'flex'} alignItems={'center'}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                inputFormat="DD/MM/YYYY"
                onChange={(value) =>
                  setFieldValue('requestedDate', value.$d, true)
                }
                value={values.requestedDate}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    id="requestedDate"
                    name="requestedDate"
                    label={t('treatmentPlanRequestedDate')}
                    error={false}
                  />
                )}
              />

              <DesktopDatePicker
                inputFormat="DD/MM/YYYY"
                onChange={(value) =>
                  setFieldValue('bookedDate', value.$d, true)
                }
                value={values.bookedDate}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    id="bookedDate"
                    name="bookedDate"
                    label={t('treatmentPlanBookedDate')}
                    sx={{ marginInline: '12px' }}
                    error={false}
                  />
                )}
              />
            </LocalizationProvider>
          </Box>

          <Typography fontSize={'12'}>{`${proceedings.length} ${t(
            'treatmentPlanProceedingsLength'
          )}`}</Typography>
        </Box>

        <div style={treatmentPlanStyles.appointmentContent}>
          <Box width={'4%'} marginLeft={'5px'}>
            <Box sx={treatmentPlanStyles.appointmentMoveIcons}>
              <IconButton
                size="small"
                onClick={() => handleMoveSection('up', currentIndex)}
              >
                <BiSolidUpArrow />
              </IconButton>

              <IconButton
                size="small"
                onClick={() => handleMoveSection('down', currentIndex)}
              >
                <BiSolidDownArrow />
              </IconButton>
            </Box>
          </Box>

          <Box width={'15%'}>
            <Typography fontWeight={600}>
              {t('treatmentPlanTemplate')}
            </Typography>
          </Box>

          <Box width={'10%'}>
            <Typography fontWeight={600}>{t('treatmentPlanTime')}</Typography>
          </Box>

          <Box width={'10%'}>
            <Typography fontWeight={600}>
              {t('treatmentPlanResponsible')}
            </Typography>
          </Box>

          <Box width={'10%'}>
            <Typography fontWeight={600}>{t('treatmentPlanPrice')}</Typography>
          </Box>

          <Box width={'2%'}>
            <IconButton onClick={handleCloneAppointment}>
              <FileCopy color="primary" />
            </IconButton>
          </Box>

          <Box width={'2%'}>
            <IconButton onClick={handleDeleteAppointment}>
              <DeleteOutline color="error" />
            </IconButton>
          </Box>
        </div>

        {proceedings?.map((proceeding, idx) => {
          return (
            <div
              draggable
              onDragStart={() => handleDragStart(idx)}
              onDragEnter={() => handleDragEnter(idx)}
              onDragOver={(e) => e.preventDefault()}
              onDragEnd={handleDragEnd}
              key={proceeding.id}
            >
              <Proceeding
                proceedings={proceedings}
                setProceedings={setProceedings}
                proceeding={proceeding}
              />
            </div>
          );
        })}
      </div>

      <Button
        sx={{ my: '12px' }}
        variant="outlined"
        onClick={handleAddProceeding}
        startIcon={<AddCircleOutline />}
      >
        {t('treatmentPlanAddProceeding')}
      </Button>
    </Box>
  );
};

export default Appointment;
