import {
  Accordion,
  AccordionSummary,
  Box,
  useTheme,
  Typography,
  IconButton,
  AccordionDetails,
  Switch,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useReportsEditStyles } from './styles';
import { DeleteOutline, ExpandMore, FileCopy } from '@mui/icons-material';
import { useLayout } from '../../../contexts/LayoutContext';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import AttributeType from '../../../components/AttributeType';
import TreatmentPlanCard from '../../Patients/PatientsView/TreatmentPlan/TreatmentPlanCard';
import { hasInvalidAttributeValue } from '../../../utils/helpers';

const ReportSections = ({ section, sections, setSections, patient }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { currentLanguage } = useLayout();
  const reportsEditStyles = useReportsEditStyles();
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(false);
  const [reportAttributes, setReportAttributes] = useState(
    section?.attributes || []
  );
  const [isSectionNameActive, setIsSectionNameActive] = useState(
    section.isSectionNameActive || false
  );
  const isPatientsInfoSection = section.type === 'patientInfo';
  const PATIENT_INFO_OPTIONS = isPatientsInfoSection && section.options;
  const [patientsInfoOptions, setPatientsInfoOptions] = useState(
    PATIENT_INFO_OPTIONS || []
  );
  const currentIndex = sections.findIndex(({ id }) => section.id === id);
  const isNotEditableSection =
    section?.type !== 'patientInfo' &&
    section?.type !== 'phaseII' &&
    section?.type !== 'treatmentPlan';
  const allDoNotHaveValidValue =
    isNotEditableSection &&
    section?.attributes?.every((attribute) =>
      hasInvalidAttributeValue(attribute)
    );

  const handleToggleAccordion = () =>
    setIsAccordionExpanded(!isAccordionExpanded);

  const handleTogglePatientsInfoOption = (index) => {
    const newSwitchValues = [...patientsInfoOptions];
    newSwitchValues[index] = {
      ...newSwitchValues[index],
      isActive: !newSwitchValues[index].isActive,
    };

    setPatientsInfoOptions(newSwitchValues);
  };

  const handleCopySection = (event, section, idx) => {
    event?.stopPropagation();
    let clonedSections = [...sections];
    const copiedSection = {
      ...section,
      id: uuidv4(),
    };

    clonedSections.splice(idx + 1, 0, copiedSection);
    setSections(clonedSections);
  };

  const handleDeleteSection = (event, id) => {
    event?.stopPropagation();
    const clonedSections = sections.filter((section) => {
      return section.id !== id;
    });

    setSections(clonedSections);
  };

  useEffect(() => {
    if (isNotEditableSection) {
      if (allDoNotHaveValidValue) {
        setIsSectionNameActive(false);
      }
    }
  }, [allDoNotHaveValidValue, isNotEditableSection]);

  useEffect(() => {
    const updatedSections = [...sections];
    const updatedSection = {
      ...updatedSections[currentIndex],
      isSectionNameActive,
    };

    if (isPatientsInfoSection) {
      updatedSection.options = patientsInfoOptions;
    } else {
      updatedSection.attributes = reportAttributes;
    }

    updatedSections[currentIndex] = updatedSection;
    setSections(updatedSections);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSectionNameActive, patientsInfoOptions, reportAttributes]);

  return (
    <Box>
      <Accordion
        sx={{
          marginBottom: '12px',
        }}
        expanded={isAccordionExpanded}
        onChange={handleToggleAccordion}
      >
        <AccordionSummary sx={{ bgcolor: theme.palette.action.focus }}>
          <Box sx={reportsEditStyles.accordionSummary}>
            <Box sx={reportsEditStyles.accordionSummaryContent}>
              <IconButton onClick={handleToggleAccordion}>
                <ExpandMore />
              </IconButton>

              <Typography fontWeight={600}>
                {currentLanguage === 'en' ? section.name.en : section.name.fr}
              </Typography>
            </Box>

            <Box sx={reportsEditStyles.accordionSummaryContent}>
              <IconButton
                onClick={(event) =>
                  handleCopySection(event, section, currentIndex)
                }
              >
                <FileCopy color="primary" />
              </IconButton>

              <IconButton
                onClick={(event) => handleDeleteSection(event, section.id)}
              >
                <DeleteOutline color="error" />
              </IconButton>
            </Box>
          </Box>
        </AccordionSummary>

        <AccordionDetails>
          <Box sx={reportsEditStyles.sectionName}>
            <Typography>{t('reportsEditShowSectionName')}</Typography>
            <Switch
              checked={isSectionNameActive}
              onChange={() => setIsSectionNameActive(!isSectionNameActive)}
              disabled={allDoNotHaveValidValue}
            />
          </Box>

          {section.type === 'patientInfo' ? (
            <Box>
              {section.options.map((option, index) => {
                return (
                  <Box sx={reportsEditStyles.sectionAttribute} key={option.id}>
                    <Typography
                      fontWeight={500}
                      sx={reportsEditStyles.sectionAttributeName}
                    >
                      {option.name[currentLanguage]}
                    </Typography>

                    <Switch
                      checked={patientsInfoOptions[index].isActive}
                      onChange={() => handleTogglePatientsInfoOption(index)}
                    />
                  </Box>
                );
              })}
            </Box>
          ) : section.type === 'treatmentPlan' || section.type === 'phaseII' ? (
            section?.attributes?.map((plan) => (
              <TreatmentPlanCard
                key={plan.id}
                plan={plan}
                patient={patient}
                showNewReportButton={false}
              />
            ))
          ) : (
            reportAttributes?.map((attribute, index) => {
              return (
                <AttributeType
                  key={index}
                  attributes={reportAttributes}
                  setAttributes={setReportAttributes}
                  attribute={attribute}
                />
              );
            })
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default ReportSections;
