import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import * as Layout from '../../../layouts';
import DoctorsForm from '../../../components/DoctorsForm';
import { useTranslation } from 'react-i18next';
import { useLayout } from '../../../contexts/LayoutContext';

const DoctorsEdit = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { setPageTitle, doctors } = useLayout();
  const doctor = doctors.find((doctor) => doctor?.id === id);

  useEffect(() => {
    setPageTitle(
      `${t('doctorsEditHeading')} - ${doctor?.lastName}, ${doctor?.firstName}`
    );
  }, [doctor, setPageTitle, t]);

  return (
    <Layout.Main notFound={!doctor?.id}>
      <DoctorsForm doctor={doctor} />
    </Layout.Main>
  );
};

export default DoctorsEdit;
