import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import * as Layout from '../../../layouts';
import UsersForm from '../../../components/UsersForm';
import { useTranslation } from 'react-i18next';
import { useLayout } from '../../../contexts/LayoutContext';

const UsersEdit = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { setPageTitle, users } = useLayout();
  const user = users.find((user) => user.id === id);

  useEffect(() => {
    setPageTitle(`${t('usersEditHeading')} - ${user?.name}`);
  }, [user, setPageTitle, t]);

  return (
    <Layout.Main notFound={!user?.id}>
      <UsersForm user={user} />
    </Layout.Main>
  );
};

export default UsersEdit;
