import React from 'react';
import AttributeTemplate from '.';
import { Autocomplete, TextField } from '@mui/material';
import { useAttributeTypeStyles } from '../styles';
import { useLayout } from '../../../contexts/LayoutContext';

const MultiSelectAttribute = ({
  attributeLabel,
  attributeValue,
  setAttributeValue,
  attribute,
}) => {
  const attributeTypeStyles = useAttributeTypeStyles();
  const { currentLanguage } = useLayout();

  return (
    <AttributeTemplate attributeLabel={attributeLabel}>
      <Autocomplete
        size="small"
        multiple
        id="multi-select"
        options={attribute?.options}
        getOptionLabel={(option) => option?.name[currentLanguage]}
        defaultValue={[]}
        sx={attributeTypeStyles.sectionAttributeTextFields}
        onChange={(_e, value) => setAttributeValue(value)}
        renderInput={(params) => <TextField {...params} variant="outlined" />}
        value={attributeValue || []}
      />
    </AttributeTemplate>
  );
};

export default MultiSelectAttribute;
