import React from 'react';
import AttributeTemplate from '.';
import { useLayout } from '../../../contexts/LayoutContext';
import {
  Box,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { useAttributeTypeStyles } from '../styles';
import { useTranslation } from 'react-i18next';
import { MdOutlinePlaylistRemove } from 'react-icons/md';

const RadioAttribute = ({
  attributeLabel,
  attributeValue,
  setAttributeValue,
  attribute,
}) => {
  const { currentLanguage } = useLayout();
  const { t } = useTranslation();
  const attributeTypeStyles = useAttributeTypeStyles();

  return (
    <AttributeTemplate attributeLabel={attributeLabel}>
      <Box display="flex" flexDirection="column">
        <RadioGroup
          value={attributeValue}
          onChange={({ target }) => setAttributeValue(target.value)}
        >
          {attribute.options.map((option) => {
            return (
              <FormControlLabel
                key={option.id}
                value={option?.name[currentLanguage]}
                control={<Radio />}
                label={option?.name[currentLanguage]}
              />
            );
          })}
        </RadioGroup>

        <Button
          variant="outlined"
          sx={attributeTypeStyles.removeAll}
          onClick={() => setAttributeValue('')}
          startIcon={<MdOutlinePlaylistRemove />}
        >
          {t('attributeTypeRemoveAll')}
        </Button>
      </Box>
    </AttributeTemplate>
  );
};

export default RadioAttribute;
