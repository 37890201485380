import { useEffect, useState } from 'react';
import { useLayout } from '../../../contexts/LayoutContext';
import * as Layout from '../../../layouts';
import { useTranslation } from 'react-i18next';
import { Box, useTheme, Button } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useTemplatesEditStyles } from './styles';
import { TbDragDrop2 } from 'react-icons/tb';
import { RiUserAddFill } from 'react-icons/ri';
import { useFormik } from 'formik';
import AddPatientSectionModal from './AddPatientSectionModal';
import ImportSectionModal from './ImportSectionModal';
import { v4 as uuidv4 } from 'uuid';
import Profile from './Profile';
import Sections from './Sections';
import ConfirmationModal from '../../../components/ConfirmationModal';
import { compare } from '../../../utils/helpers';
import { toggleToast } from '../../../utils/helpers/toast';
import { AddCircleOutline } from '@mui/icons-material';
import { BiCheckDouble, BiReset } from 'react-icons/bi';

const TemplatesEdit = () => {
  const theme = useTheme();
  const { id } = useParams();
  const { t } = useTranslation();
  const { currentLanguage } = useLayout();
  const navigate = useNavigate();
  const templatesEditStyles = useTemplatesEditStyles();
  const { setPageTitle, templates, setTemplates } = useLayout();
  const [isAddPatientSectionOpen, setIsAddPatientSectionOpen] = useState(false);
  const [isImportSectionModalOpen, setIsImportSectionModalOpen] =
    useState(false);
  const [isResetTemplateModalOpen, setIsResetTemplateModalOpen] =
    useState(false);
  const template = templates.find((template) => {
    return template.id === id;
  });
  const [sections, setSections] = useState(template?.sections);
  const formik = useFormik({
    initialValues: {
      anglophoneName: template?.name?.en,
      francophoneName: template?.name?.fr,
      anglophoneAbbreviation: template?.abbreviation.en,
      francophoneAbbreviation: template?.abbreviation.fr,
      reportModel: template?.reportModel,
      theme: template?.theme,
    },
    validateOnChange: false,
  });
  const { values } = formik;
  const updatedTemplate = {
    id: template?.id,
    name: { en: values.anglophoneName, fr: values.francophoneName },
    abbreviation: {
      en: values.anglophoneAbbreviation,
      fr: values.francophoneAbbreviation,
    },
    theme: values.theme,
    codes: template?.codes,
    precisions: template?.precisions,
    reportModel: values.reportModel,
    sections,
  };
  const isSameTemplate = compare(updatedTemplate, template);

  const handleOpenAddPatientSection = () => setIsAddPatientSectionOpen(true);
  const handleCloseAddPatientSection = () => setIsAddPatientSectionOpen(false);
  const handleOpenResetTemplateModal = () => setIsResetTemplateModalOpen(true);
  const handleCloseResetTemplateModal = () =>
    setIsResetTemplateModalOpen(false);
  const handleOpenImportSectionModal = () => setIsImportSectionModalOpen(true);
  const handleCloseImportSectionModal = () =>
    setIsImportSectionModalOpen(false);

  const handleAddCustomSection = () => {
    const section = {
      id: uuidv4(),
      heading: 'templatesEditSectionName',
      name: {
        en: `New Section`,
        fr: `Nouvelle rubrique`,
      },
      isSectionNameActive: true,
      isSignatureActive: false,
      type: 'custom',
      attributes: [],
    };

    setSections([section, ...sections]);
  };

  const handleSaveTemplate = () => {
    const updatedItems = [...templates];
    const currentIndex = templates.findIndex(
      (item) => item.id === template?.id
    );

    updatedItems[currentIndex] = {
      ...updatedItems[currentIndex],
      name: { en: values.anglophoneName, fr: values.francophoneName },
      abbreviation: {
        en: values.anglophoneAbbreviation,
        fr: values.francophoneAbbreviation,
      },
      theme: values.theme,
      reportModel: values.reportModel,
      sections,
    };

    setTemplates(updatedItems);
    toggleToast('success', t('templatedEditSavedSuccessfully'));
    navigate('/templates');
  };

  const handleResetTemplate = () => {
    window.location.reload();
  };

  useEffect(() => {
    setPageTitle(
      `${t('templatesEditHeading')} - ${template.name[currentLanguage]}`
    );
  }, [t, setPageTitle, template.name, currentLanguage]);

  return (
    <Layout.Main notFound={!template?.id}>
      <ConfirmationModal
        isModalOpen={isResetTemplateModalOpen}
        handleCloseModal={handleCloseResetTemplateModal}
        handleConfirm={handleResetTemplate}
        handleCancel={handleCloseResetTemplateModal}
        message={t('templatesEditResetConfirmation')}
        confirmLabel={t('templatesEditReset')}
      />
      <AddPatientSectionModal
        isAddPatientSectionOpen={isAddPatientSectionOpen}
        handleCloseAddPatientSection={handleCloseAddPatientSection}
        sections={sections}
        setSections={setSections}
      />
      <ImportSectionModal
        isImportSectionModalOpen={isImportSectionModalOpen}
        handleCloseImportSectionModal={handleCloseImportSectionModal}
        setSections={setSections}
        sections={sections}
        templateId={id}
      />
      <Box pb="12px" sx={templatesEditStyles.addSectionWrapper}>
        <Box>
          <Button
            variant="contained"
            style={templatesEditStyles.addSectionButton}
            onClick={handleAddCustomSection}
            startIcon={<AddCircleOutline />}
          >
            {t('templatesEditAddSection')}
          </Button>

          <Button
            variant="contained"
            sx={{
              ...templatesEditStyles.addSectionButton,
              bgcolor: theme.palette.info.dark,
            }}
            onClick={handleOpenAddPatientSection}
            startIcon={<RiUserAddFill />}
          >
            {t('templatesEditAddPatientSection')}
          </Button>

          <Button
            variant="contained"
            sx={templatesEditStyles.addSectionButton}
            color="secondary"
            onClick={handleOpenImportSectionModal}
            startIcon={<TbDragDrop2 />}
          >
            {t('templatesEditImportSection')}
          </Button>
        </Box>

        <Box>
          <Button
            variant="outlined"
            sx={templatesEditStyles.addSectionButton}
            onClick={handleOpenResetTemplateModal}
            disabled={isSameTemplate}
            color="error"
            startIcon={<BiReset />}
          >
            {t('templatesEditReset')}
          </Button>

          <Button
            variant="contained"
            disabled={isSameTemplate}
            sx={{ color: 'white' }}
            color="success"
            onClick={handleSaveTemplate}
            startIcon={<BiCheckDouble />}
          >
            {t('templatesEditSave')}
          </Button>
        </Box>
      </Box>

      <Box maxHeight="80vh" overflow="auto" pt="24px">
        <Profile template={template} formik={formik} />
        <Sections
          formik={formik}
          template={template}
          sections={sections}
          setSections={setSections}
        />
      </Box>
    </Layout.Main>
  );
};

export default TemplatesEdit;
