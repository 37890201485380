import { sidebarEn, sidebarFr } from './components/Sidebar/locales';
import {
  patientsSearchEn,
  patientsSearchFr,
} from './pages/Patients/PatientsSearch/locales';
import {
  patientsViewEn,
  patientsViewFr,
} from './pages/Patients/PatientsView/locales';
import {
  patientsAddEn,
  patientsAddFr,
} from './pages/Patients/PatientsAdd/locales';
import {
  patientsEditEn,
  patientsEditFr,
} from './pages/Patients/PatientsEdit/locales';
import {
  doctorsEditEn,
  doctorsEditFr,
} from './pages/Doctors/DoctorsEdit/locales';
import {
  patientsFormEn,
  patientsFormFr,
} from './components/PatientsForm/locales';
import {
  doctorsSearchEn,
  doctorsSearchFr,
} from './pages/Doctors/DoctorsSearch/locales';
import {
  doctorsViewEn,
  doctorsViewFr,
} from './pages/Doctors/DoctorsView/locales';
import { deleteModalEn, deleteModalFr } from './components/DeleteModal/locales';
import { doctorsAddEn, doctorsAddFr } from './pages/Doctors/DoctorsAdd/locales';
import { doctorsFormEn, doctorsFormFr } from './components/DoctorsForm/locales';
import {
  attributesGeneralEn,
  attributesGeneralFr,
} from './pages/Attributes/locales';
import {
  attributesSearchEn,
  attributesSearchFr,
} from './pages/Attributes/AttributesSearch/locales';
import {
  attributesEditEn,
  attributesEditFr,
} from './pages/Attributes/AttributesEdit/locales';
import {
  addAttributeModalEn,
  addAttributeModalFr,
} from './components/AddAttributeModal/locales';
import {
  attributeViewEn,
  attributeViewFr,
} from './components/AttributeView/locales';
import {
  reportsSearchEn,
  reportsSearchFr,
} from './pages/Reports/ReportsSearch/locales';
import {
  templatesSearchEn,
  templatesSearchFr,
} from './pages/Templates/TemplatesSearch/locales';
import {
  templatesEditEn,
  templatesEditFr,
} from './pages/Templates/TemplatesEdit/locales';
import {
  patientsAttributesEn,
  patientsAttributesFr,
} from './pages/Patients/PatientsAttribute/locales';
import {
  customTemplateSectionEn,
  customTemplateSectionFr,
} from './components/CustomTemplatesSection/locales';
import {
  reportsEditEn,
  reportsEditFr,
} from './pages/Reports/ReportsEdit/locales';
import { precisionsEn, precisionsFr } from './pages/Precisions/locales';
import { codesEn, codesFr } from './pages/Codes/locales';
import {
  treatmentPlanEn,
  treatmentPlanFr,
} from './pages/Patients/PatientsAttribute/TreatmentPlan/locales';
import {
  usersSearchEn,
  usersSearchFr,
} from './pages/Users/UsersSearch/locales';
import { usersEditEn, usersEditFr } from './pages/Users/UsersEdit/locales';
import { usersAddEn, usersAddFr } from './pages/Users/UsersAdd/locales';
import { UsersFormEn, UsersFormFr } from './components/UsersForm/locales';
import {
  reportsEmailEn,
  reportsEmailFr,
} from './pages/Reports/ReportsEmail/locales';
import {
  reportsShowEn,
  reportsShowFr,
} from './pages/Reports/ReportsShow/locales';
import {
  attributeTypeEn,
  attributeTypeFr,
} from './components/AttributeType/locales';
import {
  addAddressModalEn,
  addAddressModalFr,
} from './components/AddAddressModal/locales';
import {
  addContactModalEn,
  addContactModalFr,
} from './components/AddContactModal/locales';
import {
  confirmationModalEn,
  confirmationModalFr,
} from './components/ConfirmationModal/locales';
import { loginEn, loginFr } from './pages/Login/locales';
import { homeEn, homeFr } from './pages/Home/locales';
import { lineChartEn, lineChartFr } from './components/LineChart/locales';
import { pieChartEn, pieChartFr } from './components/PieChart/locales';
import { barChartEn, barChartFr } from './components/BarChart/locales';
import { unauthorizedEn, unauthorizedFr } from './pages/Unauthorized/locales';
import { mainEn, mainFr } from './layouts/Main/locales';
import {
  draggableOptionEn,
  draggableOptionFr,
} from './components/DraggableOption/locales';
import { reportsPdfEn, reportsPdfFr } from './pages/Reports/ReportsPdf/locales';

const translations = {
  en: {
    translation: {
      ...patientsSearchEn,
      ...patientsViewEn,
      ...patientsAddEn,
      ...sidebarEn,
      ...patientsFormEn,
      ...patientsEditEn,
      ...doctorsSearchEn,
      ...doctorsViewEn,
      ...deleteModalEn,
      ...doctorsAddEn,
      ...doctorsFormEn,
      ...doctorsEditEn,
      ...attributesGeneralEn,
      ...attributesSearchEn,
      ...addAttributeModalEn,
      ...attributesEditEn,
      ...reportsSearchEn,
      ...templatesSearchEn,
      ...templatesEditEn,
      ...attributeViewEn,
      ...patientsAttributesEn,
      ...customTemplateSectionEn,
      ...reportsEditEn,
      ...precisionsEn,
      ...codesEn,
      ...treatmentPlanEn,
      ...usersSearchEn,
      ...usersEditEn,
      ...UsersFormEn,
      ...usersAddEn,
      ...reportsEmailEn,
      ...attributeTypeEn,
      ...addAddressModalEn,
      ...addContactModalEn,
      ...confirmationModalEn,
      ...loginEn,
      ...homeEn,
      ...lineChartEn,
      ...reportsShowEn,
      ...pieChartEn,
      ...barChartEn,
      ...unauthorizedEn,
      ...mainEn,
      ...draggableOptionEn,
      ...reportsPdfEn,
    },
  },
  fr: {
    translation: {
      ...patientsSearchFr,
      ...patientsViewFr,
      ...patientsAddFr,
      ...sidebarFr,
      ...patientsFormFr,
      ...patientsEditFr,
      ...doctorsSearchFr,
      ...doctorsViewFr,
      ...deleteModalFr,
      ...doctorsAddFr,
      ...doctorsFormFr,
      ...doctorsEditFr,
      ...attributesGeneralFr,
      ...attributesSearchFr,
      ...addAttributeModalFr,
      ...attributesEditFr,
      ...reportsSearchFr,
      ...templatesSearchFr,
      ...templatesEditFr,
      ...attributeViewFr,
      ...patientsAttributesFr,
      ...customTemplateSectionFr,
      ...reportsEditFr,
      ...precisionsFr,
      ...codesFr,
      ...treatmentPlanFr,
      ...usersSearchFr,
      ...usersEditFr,
      ...UsersFormFr,
      ...usersAddFr,
      ...reportsEmailFr,
      ...attributeTypeFr,
      ...addAddressModalFr,
      ...addContactModalFr,
      ...confirmationModalFr,
      ...loginFr,
      ...homeFr,
      ...lineChartFr,
      ...reportsShowFr,
      ...pieChartFr,
      ...barChartFr,
      ...unauthorizedFr,
      ...mainFr,
      ...draggableOptionFr,
      ...reportsPdfFr,
    },
  },
};

export default translations;
