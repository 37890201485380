import { COLORS } from '../../../utils/colors';

export const useHomeStyles = () => {
  return {
    pageWrapper: {
      background: COLORS.LIGHT_GRAY,
      position: 'fixed',
      top: 0,
      right: 0,
      botton: 0,
      left: 0,
      height: '100vh',
    },
    chart: {
      boxShadow: '1px 1px 2px 0px rgba(0, 0, 0, 0.5)',
      height: '41vh',
      borderRadius: '10px',
      background: 'white',
    },
    statsBox: {
      borderRadius: '10px',
      boxShadow: '1px 1px 2px 0px rgba(0, 0, 0, 0.5)',
      background: 'white',
      p: 2,
      width: '100%',
      mb: '12px',
    },
  };
};
