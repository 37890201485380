import React from 'react';
import { Typography, Box, useTheme } from '@mui/material';
import * as Layout from '../../layouts';
import { useHomeStyles } from './styles';
import { useTranslation } from 'react-i18next';
import LineChart from '../../components/LineChart';
import PieChart from '../../components/PieChart';
import BarChart from '../../components/BarChart';
import { DataGrid } from '@mui/x-data-grid';
import { useHome } from './useHome';

const Home = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const homeStyles = useHomeStyles();
  const { HOME_STATS, upcomingAppointmentsColumns, upcomingAppointments } =
    useHome();

  return (
    <>
      <Box sx={homeStyles.pageWrapper} />
      <Layout.Main>
        <Box display="flex" flexDirection="column">
          <Box display="flex" justifyContent="space-between">
            <Box width="69%" sx={homeStyles.chart} bgcolor="red" pb="20px">
              <LineChart />
            </Box>

            <Box width="30%" sx={homeStyles.chart} p="12px">
              <Typography fontWeight="bold">
                {t('homeActivePersonnel')}
              </Typography>

              {<PieChart />}
            </Box>
          </Box>

          <Box mt="25px" display="flex" justifyContent="space-between">
            <Box p="12px" width="39%" sx={homeStyles.chart}>
              <Typography fontWeight="bold">
                {t('homeAppointmentAttendanceRate')}
              </Typography>

              <BarChart />
            </Box>

            <Box p="12px" width="39%" sx={homeStyles.chart} overflow="auto">
              <Typography fontWeight="bold" mb="12px">
                {t('homeUpcomingAppointments')}
              </Typography>

              <DataGrid
                autoHeight
                rows={upcomingAppointments}
                columns={upcomingAppointmentsColumns}
                disableRowSelectionOnClick
              />
            </Box>

            <Box width="20%" display="flex" flexDirection="column">
              {HOME_STATS.map(({ title, icon, percentage, value }) => (
                <Box key={title} sx={homeStyles.statsBox}>
                  <Box display="flex" alignItems="center">
                    {icon}
                    <Typography ml="7px" fontSize="18px" fontWeight="bold">
                      {title}
                    </Typography>
                  </Box>
                  <Typography fontWeight="bolder" fontSize="24px">
                    {value}
                  </Typography>
                  {value > 0 ? (
                    <Box display="flex" alignItems="center">
                      <Typography
                        color={theme.palette.success.main}
                        mr="12px"
                      >{`+${percentage}%`}</Typography>
                      <Typography fontStyle="italic" fontSize="12px" mr="12px">
                        {t('homeTotalSinceLastMonth')}
                      </Typography>
                    </Box>
                  ) : null}
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Layout.Main>
    </>
  );
};

export default Home;
