import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { Button, Box, Grid, Chip, TextField } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import {
  AddCircleOutline,
  DeleteOutline,
  FilterAlt,
} from '@mui/icons-material';
import * as Layout from '../../../layouts';
import { useTranslation } from 'react-i18next';
import DoctorsFilterForm from './DoctorsFilterForm';
import DeleteModal from '../../../components/DeleteModal';
import { useDoctorsSearchStyles } from './styles';
import CustomFilterModal from '../../../components/CustomFilterModal';
import { transformParams } from '../../../utils/helpers';
import { useFormik } from 'formik';
import { DOCTORS_FILTER_CHIP_TRANSLATIONS } from '../../../utils/doctors';
import { useLayout } from '../../../contexts/LayoutContext';
import { useDoctorsSearch } from './useDoctorsSearch';

const DoctorsSearch = () => {
  const { t } = useTranslation();
  const { doctors, patients } = useLayout();
  const { doctorsSearchColumns } = useDoctorsSearch();
  const doctorsSearchStyles = useDoctorsSearchStyles();
  const [filteredDoctors, setFilteredDoctors] = useState([]);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeSearchParams, setActiveSearchParams] = useState([]);
  const [isChipDeleted, setIsChipDeleted] = useState(false);
  const queryFirstName = searchParams.get('firstName');
  const queryLastName = searchParams.get('lastName');
  const queryAddress = searchParams.get('address');
  const queryPatientsCount = searchParams.get('patients');
  const queryName = searchParams.get('name');

  const { values, setFieldValue, handleChange } = useFormik({
    initialValues: {
      firstName: queryFirstName || '',
      lastName: queryLastName || '',
      address: queryAddress || '',
      patients: queryPatientsCount || '',
      name: queryName || '',
    },
  });

  const handleOpen = () => setIsFilterModalOpen(true);
  const handleClose = () => setIsFilterModalOpen(false);
  const handleDeleteModalOpen = () => setIsDeleteModalOpen(true);
  const handleCloseDeleteModal = () => setIsDeleteModalOpen(false);
  const handleFilter = (event) => {
    event?.preventDefault();
    const queryParams = {};
    const filterKeys = ['firstName', 'lastName', 'address', 'patients', 'name'];

    filterKeys.forEach((key) => {
      if (values[key]) {
        queryParams[key] = values[key];
      }
    });

    setSearchParams(queryParams);
    handleClose();
    setIsChipDeleted(false);
  };

  const handleDeleteSearchParam = (param) => {
    setFieldValue(param, '');
    const url = window.location.href;
    const urlObj = new URL(url);
    urlObj.searchParams.delete(param);
    const newUrl = urlObj.toString();
    window.history.replaceState({}, '', newUrl);
    setIsChipDeleted(true);
  };

  useEffect(() => {
    const activeParams = transformParams();
    setActiveSearchParams(activeParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href]);

  useEffect(() => {
    handleFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChipDeleted]);

  useEffect(() => {
    let filteredList = doctors;

    if (queryFirstName)
      filteredList = filteredList.filter((item) =>
        item.firstName.toUpperCase().startsWith(queryFirstName.toUpperCase())
      );

    if (queryLastName)
      filteredList = filteredList.filter((item) =>
        item.lastName.toUpperCase().startsWith(queryLastName.toUpperCase())
      );

    if (queryAddress)
      filteredList = filteredList.filter((item) =>
        item.addresses.find((item) =>
          item.addressLine.toUpperCase().startsWith(queryAddress.toUpperCase())
        )
      );

    if (queryPatientsCount)
      filteredList = filteredList.filter(({ id }) => {
        const relatedPatients = patients?.filter((patient) => {
          return patient?.doctors.find((doctorId) => id === doctorId);
        });

        return Number(queryPatientsCount) === relatedPatients?.length;
      });

    if (queryName)
      filteredList = filteredList.filter(({ firstName, lastName }) => {
        return (
          `${lastName} ${firstName}`
            .toUpperCase()
            .startsWith(queryName.toUpperCase()) ||
          `${firstName} ${lastName}`
            .toUpperCase()
            .startsWith(queryName.toUpperCase()) ||
          firstName.toUpperCase().startsWith(queryName.toUpperCase()) ||
          lastName.toUpperCase().startsWith(queryName.toUpperCase())
        );
      });

    setFilteredDoctors(filteredList);
  }, [
    queryFirstName,
    queryLastName,
    queryAddress,
    queryPatientsCount,
    queryName,
    doctors,
    patients,
  ]);

  return (
    <>
      <CustomFilterModal
        isFilterModalOpen={isFilterModalOpen}
        handleClose={handleClose}
        modalTitle={t('doctorsFilterHeading')}
      >
        <DoctorsFilterForm
          onClose={handleClose}
          handleFilter={handleFilter}
          values={values}
          handleChange={handleChange}
        />
      </CustomFilterModal>

      <DeleteModal
        handleCloseDeleteModal={handleCloseDeleteModal}
        isDeleteModalOpen={isDeleteModalOpen}
        handleConfirm={handleCloseDeleteModal}
        isProtected
      />

      <Layout.Main>
        <Box display="flex" flexDirection="column">
          <Grid container sx={doctorsSearchStyles.header}>
            <Grid item>
              <Button
                startIcon={<AddCircleOutline />}
                variant="contained"
                component={Link}
                to="add"
              >
                {t('doctorsNewButton')}
              </Button>
            </Grid>

            <Box sx={doctorsSearchStyles.headerContent}>
              <form
                onSubmit={handleFilter}
                style={doctorsSearchStyles.searchBar}
              >
                <TextField
                  fullWidth
                  variant="outlined"
                  onChange={handleChange}
                  value={values.name}
                  name="name"
                  label={t('doctorsSearchInputLabel')}
                  size="small"
                />
              </form>
              {selectedRows.length ? (
                <Button
                  variant="contained"
                  sx={doctorsSearchStyles.deleteButton}
                  color="error"
                  onClick={handleDeleteModalOpen}
                  startIcon={<DeleteOutline />}
                >
                  {t('doctorsSearchDeleteButton')} {selectedRows.length}
                  {t('doctorsDeleteButtonText')}
                </Button>
              ) : null}

              <Button
                variant="text"
                onClick={handleOpen}
                sx={doctorsSearchStyles.sort}
                startIcon={<FilterAlt />}
              >
                {t('doctorsSearchFilter')}
              </Button>
            </Box>
          </Grid>

          <Box sx={doctorsSearchStyles.searchParams}>
            {activeSearchParams?.map(({ param, title, value }) => {
              return (
                <Chip
                  key={title}
                  label={`${t(
                    DOCTORS_FILTER_CHIP_TRANSLATIONS[param]
                  )}: ${value}`}
                  onDelete={() => handleDeleteSearchParam(param)}
                  sx={doctorsSearchStyles.searchParamsItem}
                />
              );
            })}
          </Box>
        </Box>

        <DataGrid
          autoHeight
          sx={{ maxHeight: '80vh' }}
          rows={filteredDoctors}
          columns={doctorsSearchColumns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10]}
          disableRowSelectionOnClick
          onRowSelectionModelChange={(selectedRowsArray) => {
            setSelectedRows(selectedRowsArray);
          }}
        />
      </Layout.Main>
    </>
  );
};

export default DoctorsSearch;
