import { Box, Button, IconButton } from '@mui/material';
import { usePatientsAttributesStyles } from './styles';
import { useLayout } from '../../../contexts/LayoutContext';
import { useNavigate, useParams } from 'react-router-dom';
import * as Layout from '../../../layouts';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import AddAttributeModal from '../../../components/AddAttributeModal';
import EditAttributesModal from './EditAttributesModal';
import { toggleToast } from '../../../utils/helpers/toast';
import { DeleteOutline } from '@mui/icons-material';
import AttributeType from '../../../components/AttributeType';
import { BiCheckDouble, BiSolidEditAlt } from 'react-icons/bi';

const Attribute = ({ attributeName, pageTitle }) => {
  const patientsAttributesStyles = usePatientsAttributesStyles();
  const { id } = useParams();
  const { t } = useTranslation();
  const { patients, setPatients, setPageTitle } = useLayout();
  const navigate = useNavigate();
  const patient = patients.find((patient) => patient.id === id);
  const [attributes, setAttributes] = useState(
    patient?.id ? patient[attributeName]?.attributes : []
  );
  const [isEditAttributeModalOpen, setIsEditAttributeModalOpen] =
    useState(false);
  const [stage, setStage] = useState(1);

  const handleOpenEditAttributeModal = () => setIsEditAttributeModalOpen(true);
  const handleCloseEditAttributeModal = () =>
    setIsEditAttributeModalOpen(false);

  const handleSave = () => {
    const updatedItems = [...patients];
    const currentIndex = updatedItems.findIndex((item) => item.id === id);

    updatedItems[currentIndex] = {
      ...updatedItems[currentIndex],
      [attributeName]: {
        ...updatedItems[currentIndex][attributeName],
        attributes,
      },
    };

    setPatients(updatedItems);
    navigate(`/patients/${patient.id}`);
    toggleToast('success', t('patientsAttributesEditSavedSuccessfully'));
  };

  const handleDeleteAttribute = (id) => {
    const updatedAttributes = [...attributes].filter((attribute) => {
      return attribute.id !== id;
    });

    setAttributes(updatedAttributes);
  };

  useEffect(
    () =>
      setPageTitle(
        `${t(pageTitle)} - ${patient?.lastName || ''}, ${
          patient?.firstName || ''
        }`
      ),
    [t, setPageTitle, pageTitle, patient?.lastName, patient?.firstName]
  );

  return (
    <Layout.Main notFound={!patient?.id}>
      <AddAttributeModal
        isAddAttributeModalOpen={stage === 2}
        handleCloseNewAttributeModal={() => setStage(stage - 1)}
        setAttributes={setAttributes}
        attributes={attributes}
      />
      <EditAttributesModal
        isEditAttributeModalOpen={isEditAttributeModalOpen}
        handleCloseEditAttributeModal={handleCloseEditAttributeModal}
        attributes={attributes}
        setAttributes={setAttributes}
        handleAddAttribute={() => setStage(stage + 1)}
      />
      <Box
        width="100%"
        display="flex"
        justifyContent="space-between"
        pb="12px"
        sx={patientsAttributesStyles.header}
      >
        <Box>
          <Button
            startIcon={<BiSolidEditAlt />}
            variant="outlined"
            onClick={handleOpenEditAttributeModal}
          >
            {t('patientsAttributesEditAttributesBtn')}
          </Button>
        </Box>

        <Box>
          <Button
            startIcon={<BiCheckDouble />}
            variant="contained"
            onClick={handleSave}
          >
            {t('patientsAttributesSaveBtn')}
          </Button>
        </Box>
      </Box>
      <Box
        maxHeight="80vh"
        overflow="auto"
        pt="24px"
        sx={patientsAttributesStyles.entry}
      >
        {attributes?.map((attribute) => {
          return (
            <Box key={attribute.id}>
              <IconButton onClick={() => handleDeleteAttribute(attribute.id)}>
                <DeleteOutline color="error" />
              </IconButton>
              <AttributeType
                key={attribute.id}
                attributes={attributes}
                setAttributes={setAttributes}
                attribute={attribute}
              />
            </Box>
          );
        })}
      </Box>
    </Layout.Main>
  );
};

export default Attribute;
