import { toast, Slide } from 'react-toastify';
import { COLORS } from '../../../utils/colors';

export const toggleToast = (status, message) => {
  toast(message, {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    type: status,
    transition: Slide,
    icon: false,
    style: {
      background:
        status === 'success' ? COLORS.TOAST_SUCCESS : COLORS.TOAST_ERROR,
      color: 'white',
      fontSize: '15px',
      fontFamily: 'Poppins',
    },
  });
};
