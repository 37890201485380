import React from 'react';
import { Document, Page, Font, View, Text } from '@react-pdf/renderer';
import { insurancePdfStyles } from '../styles';
import LogosFr from './LogosFr';
import DoctorPatientInfoFr from './DoctorPatientInfoFr';
import DoctorUseFr from './DoctorUseFr';
import TreatmentPlanFr from './TreatmentPlanFr';
import InsuranceTotalsFr from './InsuranceTotalsFr';
import HowToFr from './HowToFr';
import EmployerInfoFr from './EmployerInfoFr';
import PatientInfoFr from './PatientInfoFr';
import SignatureFr from './SignatureFr';

const InsurancePdfFr = ({ patient, patientDoctor, record }) => {
  Font.register({
    family: 'Anonymous Pro',
    fonts: [
      {
        src: 'https://fonts.gstatic.com/s/anonymouspro/v21/rP2Bp2a15UIB7Un-bOeISG3pLlw89CH98Ko.ttf',
      },
      {
        src: 'https://fonts.gstatic.com/s/anonymouspro/v21/rP2cp2a15UIB7Un-bOeISG3pFuAT0CnW7KOywKo.ttf',
        fontWeight: 700,
      },
      {
        src: 'https://fonts.gstatic.com/s/anonymouspro/v21/rP2fp2a15UIB7Un-bOeISG3pHl428AP44Kqr2Q.ttf',
        fontStyle: 'italic',
      },
      {
        src: 'https://fonts.gstatic.com/s/anonymouspro/v21/rP2ap2a15UIB7Un-bOeISG3pHl4OTCzc6IG30KqB9Q.ttf',
        fontWeight: 700,
        fontStyle: 'italic',
      },
    ],
  });
  const patientAddress = patient?.addresses[0].addressLine;
  const doctorAddress = patientDoctor?.addresses[0].addressLine;
  const totalFeeSubmitted = record
    .reduce((accumulator, currentValue) => {
      return accumulator + parseFloat(currentValue.total.replace('$', ''));
    }, 0)
    .toFixed(2);

  return (
    <Document>
      <Page size={'A4'} style={insurancePdfStyles.page}>
        <LogosFr />

        <View style={insurancePdfStyles.body}>
          <DoctorPatientInfoFr
            patientAddress={patientAddress}
            doctorAddress={doctorAddress}
            patient={patient}
            patientDoctor={patientDoctor}
          />
          <DoctorUseFr />

          <View style={{ ...insurancePdfStyles.section, borderBottomWidth: 0 }}>
            <TreatmentPlanFr
              record={record}
              totalFeeSubmitted={totalFeeSubmitted}
            />

            <InsuranceTotalsFr />
          </View>

          <HowToFr />

          <EmployerInfoFr />

          <PatientInfoFr patient={patient} />
          <SignatureFr />
        </View>

        <View style={insurancePdfStyles.flexBetween}>
          <Text style={{ fontSize: '6px' }}>COPYRIGHT 09/03</Text>
          <Text style={{ fontSize: '6px' }}>
            L’ INFORMATION DANS CE FORMULAIRE EST CONFIDENTIELLE
          </Text>
          <Text></Text>
        </View>
      </Page>
    </Document>
  );
};

export default InsurancePdfFr;
