import React from 'react';
import moment from 'moment';
import {
  Box,
  Typography,
  Button,
  styled,
  useTheme,
  Table,
  TableCell,
  TableRow,
  TableBody,
  useMediaQuery,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { usePatientsViewStyles } from './styles';
import { useNavigate } from 'react-router-dom';
import { useLayout } from '../../../contexts/LayoutContext';
import { BiSolidEditAlt } from 'react-icons/bi';

const GeneralInfo = ({ patient }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { doctors } = useLayout();
  const navigate = useNavigate();
  const patientsViewStyles = usePatientsViewStyles();
  const customBreakpoint = useMediaQuery('(max-width: 1200px)');
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  }));

  const handleCreateRowData = (key, value) => {
    return { key, value };
  };

  const rows = [
    handleCreateRowData(
      t('patientsViewName'),
      `${patient?.lastName}, ${patient?.firstName} ${patient?.middleName}` || ''
    ),
    handleCreateRowData(
      t('patientsViewDateOfBirth'),
      moment(patient?.dateOfBirth).format('LL') || ''
    ),
    handleCreateRowData(
      t('patientsViewAddress'),
      patient?.addresses.sort((_a, b) => {
        if (b.primary) return 1;
        return -1;
      }) || []
    ),
    handleCreateRowData(
      'Contact',
      patient?.contacts.sort((_a, b) => {
        if (b.primary) return 1;
        return -1;
      }) || []
    ),
    handleCreateRowData(
      t('patientsViewDoctors'),
      doctors.filter((doctor) => patient?.doctors.includes(doctor?.id)) || []
    ),
  ];

  return (
    <Box marginRight={customBreakpoint ? 0 : 2}>
      <Box sx={{ ...patientsViewStyles.flexBetween, marginTop: 0 }}>
        <Typography variant="h4" fontWeight={600}>
          {t('patientsViewHeadingGeneral')}
        </Typography>

        <Button
          startIcon={<BiSolidEditAlt />}
          variant="outlined"
          size="small"
          onClick={() => navigate(`/patients/edit/${patient?.id}`)}
        >
          {t('patientsViewEditButton')}
        </Button>
      </Box>

      <Table
        sx={{
          border: `1px solid ${theme.palette.action.focus}`,
        }}
      >
        <TableBody>
          {rows.map(({ key, value }) => {
            return (
              <StyledTableRow key={key}>
                <TableCell
                  component="th"
                  scope="row"
                  sx={patientsViewStyles.tableCell}
                >
                  {key}
                </TableCell>

                <TableCell component="th" scope="row">
                  {key === t('patientsViewAddress')
                    ? value.map((item) => {
                        const { id, city, addressLine, province } = item;
                        return (
                          <span key={id}>
                            <span>{addressLine}</span>
                            {province && <span>{`, ${province}`}</span>}
                            {city && <span>{`, ${city}`}</span>}
                            {item?.primary && (
                              <span style={{ fontWeight: 'bold' }}>
                                {` (${t('patientsViewPrimary')})`}
                              </span>
                            )}
                            <span key={id}>
                              {id ===
                              patient?.addresses[patient?.addresses.length - 1]
                                .id
                                ? ''
                                : ' | '}
                            </span>
                          </span>
                        );
                      })
                    : key === 'Contact'
                    ? value.map((item) => {
                        const { id, value } = item;

                        return (
                          <span key={id}>
                            {`${value}`}
                            {item?.primary && (
                              <span style={{ fontWeight: 'bold' }}>
                                {` (${t('patientsViewPrimary')})`}
                              </span>
                            )}
                            {id ===
                            patient?.contacts[patient?.contacts.length - 1].id
                              ? ''
                              : ', '}
                          </span>
                        );
                      })
                    : key === t('patientsViewDoctors')
                    ? value?.map(({ lastName, firstName, id }, index) => {
                        const doctorName = `${lastName} ${firstName}`;

                        return (
                          <span
                            key={id}
                            style={patientsViewStyles.doctors}
                            onMouseOver={(e) => {
                              e.target.style.color = theme.palette.info.dark;
                            }}
                            onMouseOut={(e) => {
                              e.target.style.color = theme.palette.primary.main;
                            }}
                            onClick={() => navigate(`/doctors/${id}`)}
                          >
                            {index === patient?.doctors.length - 1
                              ? doctorName + ''
                              : doctorName + ', '}
                          </span>
                        );
                      })
                    : value}
                </TableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </Box>
  );
};

export default GeneralInfo;
