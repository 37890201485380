import React from 'react';
import ModalOverlay from '../../../components/ModalOverlay';
import { useReportsEditStyles } from './styles';
import { Box, IconButton, Button } from '@mui/material';
import { Close } from '@mui/icons-material';
import { PDFViewer } from '@react-pdf/renderer';
import ReportsPdfFull from '../ReportsPdf/ReportsPdfFull';
import { useTranslation } from 'react-i18next';
import { useLayout } from '../../../contexts/LayoutContext';
import { MdUploadFile } from 'react-icons/md';
import { BiCheckDouble } from 'react-icons/bi';

const PreviewModal = ({
  isPreviewModalOpen,
  handleClosePreviewModal,
  patient,
  report,
  handlePublishReport,
  handleSaveReport,
}) => {
  const { t } = useTranslation();
  const { currentLanguage, doctors } = useLayout();
  const reportsEditStyles = useReportsEditStyles();

  return (
    <ModalOverlay
      isModalOpen={isPreviewModalOpen}
      handleCloseModal={handleClosePreviewModal}
    >
      <Box sx={{ ...reportsEditStyles.modal, width: '90%' }}>
        <IconButton
          onClick={handleClosePreviewModal}
          sx={reportsEditStyles.closeButton}
          disableRipple
        >
          <Close />
        </IconButton>

        <Box>
          <Button
            variant="contained"
            sx={reportsEditStyles.headerButton}
            onClick={handlePublishReport}
            startIcon={<MdUploadFile />}
          >
            {t('reportsEditPublish')}
          </Button>

          <Button
            color="success"
            sx={{ color: 'white' }}
            variant="contained"
            onClick={handleSaveReport}
            startIcon={<BiCheckDouble />}
          >
            {t('reportsEditSave')}
          </Button>
        </Box>

        <PDFViewer style={reportsEditStyles.pdf}>
          <ReportsPdfFull
            report={report}
            patient={patient}
            currentLanguage={currentLanguage}
            doctors={doctors}
          />
        </PDFViewer>
      </Box>
    </ModalOverlay>
  );
};

export default PreviewModal;
