import {
  Grid,
  TextField,
  Button,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Box,
} from '@mui/material';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { object, string, ref } from 'yup';
import { DeleteOutline, Visibility, VisibilityOff } from '@mui/icons-material';
import { useLayout } from '../../contexts/LayoutContext';
import { v4 as uuidv4 } from 'uuid';
import { toggleToast } from '../../utils/helpers/toast';
import { useNavigate } from 'react-router-dom';
import { USERS_ACCOUNT_TYPE } from '../../utils/users';
import DeleteModal from '../DeleteModal';
import { BiCheckDouble } from 'react-icons/bi';

const UsersForm = ({ user }) => {
  const { t } = useTranslation();
  const { users, setUsers } = useLayout();
  const navigate = useNavigate();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false);
  const isExistingUser = !!user?.id;
  const validationSchema = object().shape({
    name: string().required(t('usersFormRequiredFieldText')),
    email: string()
      .email(t('usersFormInvalidEmail'))
      .required(t('usersFormRequiredFieldText')),
    type: string().required(t('usersFormRequiredFieldText')),
    password: string()
      .required(t('usersFormRequiredFieldText'))
      .min(6, t('usersFormPasswordMinimum')),
    confirmPassword: string()
      .required(t('usersFormRequiredFieldText'))
      .oneOf([ref('password')], t('usersFormPasswordMismatch')),
  });
  const { handleChange, values, handleBlur, touched, errors, handleSubmit } =
    useFormik({
      initialValues: {
        id: user?.id || uuidv4(),
        name: user?.name || '',
        email: user?.email || '',
        type: user?.type || '',
        password: '',
        confirmPassword: '',
      },
      validationSchema,
      onSubmit: () => {
        const user = {
          id: values.id,
          name: values.name,
          email: values.email,
          type: values.type,
        };

        if (isExistingUser) {
          const clonedUsers = [...users];
          const exisitingUserIndex = clonedUsers.findIndex(
            ({ id }) => id === user?.id
          );

          if (exisitingUserIndex !== -1) {
            clonedUsers.splice(exisitingUserIndex, 1);
            clonedUsers.splice(exisitingUserIndex, 0, user);
          }

          setUsers(clonedUsers);
          toggleToast('success', t('usersFormEditedSuccessfully'));
        } else {
          setUsers([user, ...users]);
          toggleToast('success', t('usersFormAddedSuccessfully'));
        }

        navigate('/users');
      },
    });

  const handleCloseDeleteModal = () => setIsDeleteModalOpen(false);
  const handleOpenDeleteModal = () => setIsDeleteModalOpen(true);
  const handleTogglePasswordVisibility = (name) => {
    if (name === 'password') {
      setIsPasswordVisible(!isPasswordVisible);
    } else {
      setIsConfirmPasswordVisible(!isConfirmPasswordVisible);
    }
  };

  const handleDeleteUser = () => {
    const updatedUsers = users.filter(({ id }) => id !== user?.id);
    setUsers(updatedUsers);

    toggleToast('success', t('usersFormDeletedSuccessfully'));
    navigate('/users');
  };

  const ADD_USERS_TEXT_FIELDS = [
    { name: 'name', label: 'usersFormName', md: 12 },
    { name: 'email', label: 'usersFormEmail' },
    { name: 'type', label: 'usersFormType' },
    { name: 'password', label: 'usersFormPassword' },
    { name: 'confirmPassword', label: 'usersFormConfirmPassword' },
  ];

  return (
    <form onSubmit={(event) => event?.preventDefault()}>
      <DeleteModal
        isDeleteModalOpen={isDeleteModalOpen}
        handleCloseDeleteModal={handleCloseDeleteModal}
        handleConfirm={handleDeleteUser}
        isProtected
      />
      <Grid container spacing={3} marginBottom={'24px'}>
        {ADD_USERS_TEXT_FIELDS.map(({ name, label, md = 6 }) => {
          return name === 'type' ? (
            <Grid item md={6} key={label}>
              <FormControl
                error={errors.type && touched.type}
                required
                fullWidth
              >
                <InputLabel id="gender">{t('usersFormType')}</InputLabel>
                <Select
                  label={t('usersFormType')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.type}
                  name="type"
                >
                  {USERS_ACCOUNT_TYPE.map((item) => {
                    return (
                      <MenuItem key={item.value} value={item.value}>
                        {t(item.name)}
                      </MenuItem>
                    );
                  })}
                </Select>
                {errors.type && touched.type && (
                  <FormHelperText>{errors.type}</FormHelperText>
                )}
              </FormControl>
            </Grid>
          ) : (
            <Grid item md={md}>
              <TextField
                required
                name={name}
                label={t(label)}
                fullWidth
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values[name]}
                error={errors[name] && touched[name]}
                helperText={errors[name] && touched[name] && errors[name]}
                type={
                  (name === 'password' && !isPasswordVisible) ||
                  (name === 'confirmPassword' && !isConfirmPasswordVisible)
                    ? 'password'
                    : 'text'
                }
                InputProps={{
                  endAdornment: (name === 'password' ||
                    name === 'confirmPassword') && (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => handleTogglePasswordVisibility(name)}
                      >
                        {(name === 'password' && isPasswordVisible) ||
                        (name === 'confirmPassword' &&
                          isConfirmPasswordVisible) ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          );
        })}
      </Grid>

      <Box mb="48px">
        {isExistingUser ? (
          <Button
            sx={{ mr: '12px' }}
            variant="contained"
            size="large"
            onClick={handleOpenDeleteModal}
            startIcon={<DeleteOutline />}
            color="error"
          >
            {t('usersFormDeleteUser')}
          </Button>
        ) : null}

        <Button
          startIcon={<BiCheckDouble />}
          variant="contained"
          size="large"
          onClick={handleSubmit}
        >
          {t('usersFormSave')}
        </Button>
      </Box>
    </form>
  );
};

export default UsersForm;
