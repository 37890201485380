import React, { useEffect, useState } from 'react';
import * as Layout from '../../../layouts';
import { Button, TextField, Grid, Typography, Box, Chip } from '@mui/material';
import { AddCircleOutline, DeleteOutline } from '@mui/icons-material';
import { Link, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DataGrid } from '@mui/x-data-grid';
import DeleteModal from '../../../components/DeleteModal';
import { useAttributesSearchStyles } from './styles';
import { useLayout } from '../../../contexts/LayoutContext';
import AddAttributeModal from '../../../components/AddAttributeModal';
import {
  capitalizeFirstLetters,
  transformParams,
} from '../../../utils/helpers';
import { ATTRIBUTES_FILTER_CHIP_TRANSLATIONS } from '../../../utils/attributes';

const AttributesSearch = () => {
  const attributesSearchStyles = useAttributesSearchStyles();
  const { t } = useTranslation();
  const { currentLanguage, setAttributes, attributes } = useLayout();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryName = searchParams.get('name');
  const [isAddAttributeModalOpen, setIsAddAttributeModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [searchValue, setSearchValue] = useState(queryName || '');
  const [isChipDeleted, setIsChipDeleted] = useState(false);
  const [filteredAttributes, setFilteredAttributes] = useState([]);
  const [activeSearchParams, setActiveSearchParams] = useState([]);

  const handleOpenNewAttributeModal = () => setIsAddAttributeModalOpen(true);
  const handleCloseNewAttributeModal = () => setIsAddAttributeModalOpen(false);
  const handleDeleteModalOpen = () => setIsDeleteModalOpen(true);
  const handleCloseDeleteModal = () => setIsDeleteModalOpen(false);

  const columns = [
    {
      field: 'name',
      headerName: t('attributesSearchName'),
      flex: 0.8,
      renderCell: (params) => (
        <Link
          style={attributesSearchStyles.link}
          to={{
            pathname: `${params.row.id}/edit`,
          }}
        >
          <Typography sx={attributesSearchStyles.attributeName}>
            {t(params.row.name)}
          </Typography>
        </Link>
      ),
    },
    {
      field: 'type',
      headerName: t('attributesSearchAttributeType'),
      flex: 0.2,
    },
  ];

  const rows = filteredAttributes.map((attribute) => {
    return {
      name:
        currentLanguage === 'en' ? t(attribute.name.en) : t(attribute.name.fr),
      type: capitalizeFirstLetters(attribute.type?.replace('-', ' ')),
      id: attribute.id,
    };
  });

  const handleDeleteSearchParam = () => {
    const param = 'name';
    const url = window.location.href;
    const urlObj = new URL(url);
    const newUrl = urlObj.toString();

    setSearchValue('');
    urlObj.searchParams.delete(param);
    window.history.replaceState({}, '', newUrl);
    setIsChipDeleted(true);
  };

  const handleFilter = (event) => {
    event?.preventDefault();
    const queryParams = {};

    if (searchValue) {
      queryParams.name = searchValue;
    }
    setSearchParams(queryParams);
    setIsChipDeleted(false);
  };

  useEffect(() => {
    const activeParams = transformParams();
    setActiveSearchParams(activeParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href]);

  useEffect(() => {
    handleFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChipDeleted]);

  useEffect(() => {
    let filteredList = attributes;

    if (queryName)
      filteredList = filteredList.filter(({ name: { en, fr } }) => {
        return currentLanguage === 'en'
          ? t(en).toLowerCase().startsWith(queryName.toLowerCase())
          : t(fr).toLowerCase().startsWith(queryName.toLowerCase());
      });

    setFilteredAttributes(filteredList);
  }, [queryName, t, attributes, currentLanguage]);

  return (
    <Layout.Main>
      <AddAttributeModal
        isAddAttributeModalOpen={isAddAttributeModalOpen}
        handleCloseNewAttributeModal={handleCloseNewAttributeModal}
        attributes={attributes}
        setAttributes={setAttributes}
      />

      <DeleteModal
        isDeleteModalOpen={isDeleteModalOpen}
        handleCloseDeleteModal={handleCloseDeleteModal}
        handleConfirm={handleCloseDeleteModal}
      />

      <Grid container sx={attributesSearchStyles.header}>
        <Grid item>
          <Button
            startIcon={<AddCircleOutline />}
            variant="contained"
            onClick={handleOpenNewAttributeModal}
          >
            {t('attributesNewButton')}
          </Button>
        </Grid>

        <Box sx={attributesSearchStyles.headerContent}>
          {selectedRows.length ? (
            <Button
              variant="contained"
              color="error"
              onClick={handleDeleteModalOpen}
              sx={attributesSearchStyles.deleteButton}
              startIcon={<DeleteOutline />}
            >
              {`
                ${t('attributesSearchDeleteButton')} ${selectedRows.length}
                ${t('attributesSearchDeleteButtonText')}`}
            </Button>
          ) : null}

          <Box display="flex">
            <Box sx={attributesSearchStyles.searchParams}>
              {activeSearchParams?.map(({ param, title, value }) => {
                return (
                  <Chip
                    key={title}
                    label={`${t(
                      ATTRIBUTES_FILTER_CHIP_TRANSLATIONS[param]
                    )}: ${value}`}
                    onDelete={() => handleDeleteSearchParam(param)}
                    sx={attributesSearchStyles.searchParamsItem}
                  />
                );
              })}
            </Box>

            <form
              onSubmit={(event) => handleFilter(event)}
              style={attributesSearchStyles.searchBar}
            >
              <TextField
                fullWidth
                variant="outlined"
                onChange={({ target }) => setSearchValue(target.value)}
                value={searchValue}
                label={t('templatesSearchInputLabel')}
                size="small"
              />
            </form>
          </Box>
        </Box>
      </Grid>

      <DataGrid
        autoHeight
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        disableRowSelectionOnClick
        onRowSelectionModelChange={(selectedRowsArray) => {
          setSelectedRows(selectedRowsArray);
        }}
      />
    </Layout.Main>
  );
};

export default AttributesSearch;
