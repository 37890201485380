import { StyleSheet } from '@react-pdf/renderer';

export const insurancePdfStyles = StyleSheet.create({
  page: {
    width: '210mm',
    height: '290mm',
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: 10,
    fontFamily: 'Anonymous Pro',
  },
  head: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  smallBold: {
    fontWeight: 900,
    fontSize: '6px',
    flexWrap: 'wrap',
  },
  flexBetween: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    flexDirection: 'row',
  },
  mainLogo: {
    width: '96px',
  },
  secondary: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  secondaryLogo: {
    width: '38px',
  },
  secondaryText: {
    fontWeight: 900,
    paddingBottom: '0.25cm',
    paddingLeft: '0.5cm',
  },
  formTitle: {
    fontSize: '12px',
    fontWeight: 900,
    width: '100px',
    textAlign: 'center',
  },
  body: {
    width: '100%',
    border: '0.9px solid black',
    marginTop: '2px',
  },
  section: {
    borderBottom: '0.9px solid black',
    display: 'flex',
    flexDirection: 'row',
  },
  infoSectionPatient: {
    borderRight: '0.9px solid black',
    width: '36%',
    height: '100px',
  },
  infoSectionPatientTop: {
    borderBottom: '0.9px solid black',
    padding: '3px',
    height: '28px',
  },
  infoSectionPatientTopText: { fontWeight: 900 },
  infoSectionPatientMain: {
    padding: '2px',
    display: 'flex',
    flexDirection: 'row',
  },
  infoSectionPatientMainText: {
    fontWeight: 900,
    fontSize: '8px',
    marginRight: '12px',
  },
  infoSectionDoctorTop: {
    display: 'flex',
    borderBottom: '0.9px solid black',
    height: '28px',
    justifyContent: 'space-between',
  },
  infoSectionDoctorTopRows: {
    display: 'flex',
    flexDirection: 'row',
  },
  infoSectionDoctorTopText: {
    fontSize: '9px',
    fontFamily: 'Anonymous Pro',
    padding: '3px',
  },
  infoSectionDoctorTopUnique: {
    borderRight: '0.9px solid black',
    width: '33.3333%',
    padding: '3px',
  },
  infoSectionDoctorTopSpec: {
    borderRight: '0.9px solid black',
    width: '20.8333%',
    padding: '3px',
    marginHorizontal: '2px',
  },
  infoSectionDoctorTopOffice: {
    padding: '3px',
  },
  subscriber: {
    padding: '3px',
    display: 'flex',
    alignItems: 'center',
    width: '28%',
  },
  subscriberMain: {
    borderBottom: '0.9px solid black',
    marginBottom: '3px',
    height: '80px',
    width: '100%%',
  },

  doctorUseAdditional: {
    width: '50%',
    borderRight: '0.9px solid black',
    padding: '3px',
  },
  doctorUseUndertaking: {
    display: 'flex',
  },
  doctorUseUndertakingText: { padding: '3px', width: '50%' },
  doctorUseSignature: {
    borderTop: '0.9px solid black',
    display: 'flex',
    alignItems: 'center',
    marginTop: '12px',
    transform: 'translateX(138px)',
  },
  doctorUseVerification: {
    borderTop: '0.9px solid black',
    width: '100%',
    display: 'flex',
    padding: '3px',
  },

  treatmentPlanRecord: {
    width: '65%',
  },
  treatmentPlanRecordHeading: {
    padding: '5px',
    borderBottom: '0.9px solid black',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    flexDirection: 'row',
  },
  treatmentPlanHeadingText: {
    fontWeight: 900,
    fontSize: '6px',
    flexWrap: 'wrap',
    textAlign: 'center',
    width: '10%',
  },
  treatmentPlanValue: {
    fontSize: '6px',
    fontFamily: 'Anonymous Pro',
    flexWrap: 'wrap',
    width: '12%',
    textAlign: 'center',
  },
  treatmentPlanDateOfService: {
    display: 'flex',
    alignItems: 'center',
  },
  treatmentPlanRecordBody: {
    minHeight: '130px',
    borderBottom: '0.9px solid black',
  },
  treatmentPlanRecordFooterLeft: {
    width: '50%',
    padding: '3px 3px 5px 3px',
    borderRight: '0.9px solid black',
    minHeight: '10px',
  },
  treatmentPlanCarrier: {
    borderTop: '15px solid black',
    borderLeft: '15px solid black',
    width: '35%',
    textAlign: 'center',
  },
  treatmentPlanCells: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  treatmentPlanCellItem: {
    borderBottom: '0.9px solid black',
    borderRight: '0.9px solid black',
    height: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  treatmentPlanTotalFee: {
    padding: '3px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  treatmentPlanChequeSection: {
    padding: '3px 3px 12px 3px',
    width: '50%',
    borderRight: '0.9px solid black',
    textAlign: 'left',
  },
  treatmentPlanDeductibleSection: {
    padding: '3px 3px 12px 3px',
    width: '33.3%',
    borderRight: '0.9px solid black',
  },
  instructionsHeading: {
    backgroundColor: 'black',
    padding: '2px 3px',
  },
  instructionsHeadingText: {
    fontWeight: 900,
    color: 'white',
  },
  employeeBody: {
    padding: '12px 3px 3px 3px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  employeeBodyContent: {
    width: '50%',
  },
  patientInfoDOBSection: {
    marginTop: '8px',
    display: 'flex',
    flexDirection: 'row',
  },
  patientInfoDOB: {
    borderBottom: '0.5px solid black',
    height: '7px',
    width: '70px',
  },
  patientInfo3DOB: {
    borderBottom: '0.5px solid black',
    width: '80px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  checkboxWrapper: {
    marginLeft: '5px',
    display: 'flex',
    flexDirection: 'row',
  },
  checkboxAfter: {
    border: '.1px solid black',
    height: '7px',
    width: '7px',
    position: 'relative',
    backgroundColor: 'black',
    marginRight: '4px',
  },
  checkboxBefore: {
    border: '.1px solid black',
    position: 'absolute',
    height: '7px',
    width: '7px',
    backgroundColor: 'white',
    bottom: 1,
    right: 1,
  },
  patientInfoQuestion: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '8px',
  },
  patientInfoQuestionCheckboxes: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '5px',
  },
  policyHolderBody: {
    padding: '3px',
  },
  policyHolderTables: {
    display: 'flex',
    flexDirection: 'row',
    width: '33%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  policyHolderTable: {
    display: 'flex',
    flexDirection: 'row',
    border: '0.7px solid black',
  },
  policyHolderTableHead: {
    width: '32%',
    alignItems: 'center',
    borderRight: '0.7px solid black',
  },
  policyHolderTableCell: {
    width: '16%',
    height: '10px',
    borderRight: '0.7px solid black',
  },
  policyHolderSignature: {
    width: '100%',
    borderTop: '0.7px solid black',
    marginTop: '8px',
    alignItems: 'center',
  },
  pdfValue: {
    fontSize: '9px',
    fontFamily: 'Anonymous Pro',
    flexWrap: 'wrap',
    maxWidth: '120px',
  },
});
