import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  Grid,
} from '@mui/material';
import { ATTRIBUTES_TYPES } from '../../utils/attributes';
import { useFormik } from 'formik';
import { useAttributeViewStyles } from './styles';
import { v4 as uuidv4 } from 'uuid';
import { AddCircleOutline, DeleteOutline } from '@mui/icons-material';
import DeleteModal from '../DeleteModal';
import { toggleToast } from '../../utils/helpers/toast';
import { useNavigate } from 'react-router-dom';
import DraggableOption from '../DraggableOption';

const AttributeView = ({
  attribute,
  setAttributes,
  attributes,
  templatesView,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const attributeViewStyles = useAttributeViewStyles();
  const dropIndex = useRef(null);
  const draggedItemIndex = useRef(null);
  const [options, setOptions] = useState(attribute?.options);
  const [hasOptions, setHasOptions] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { values, handleChange } = useFormik({
    initialValues: {
      anglophoneName: attribute?.name?.en || '',
      francophoneName: attribute?.name?.fr || '',
      attributeType: attribute?.type || '',
    },
  });

  const handleCloseDeleteModal = () => setIsDeleteModalOpen(false);

  const handleDragStart = (currentIndex) => {
    draggedItemIndex.current = currentIndex;
  };

  const handleDragEnter = (index) => {
    const clonedOptions = [...options];
    const draggedItem = clonedOptions.splice(draggedItemIndex.current, 1)[0];

    dropIndex.current = index;
    clonedOptions.splice(dropIndex.current, 0, draggedItem);
  };

  const handleDragEnd = () => {
    const clonedOptions = [...options];
    const draggedItem = clonedOptions.splice(draggedItemIndex.current, 1)[0];

    clonedOptions.splice(dropIndex.current, 0, draggedItem);
    draggedItemIndex.current = null;
    dropIndex.current = null;
    setOptions(clonedOptions);
  };

  const handleAddOption = () => {
    const updatedOptions = [
      {
        name: { en: '', fr: '' },
        id: uuidv4(),
        index: options?.length,
      },
      ...options,
    ];

    setOptions(updatedOptions);
  };

  const handleDeleteAttribute = () => {
    const updatesAttributes = attributes.filter(
      ({ id }) => id !== attribute?.id
    );
    setAttributes(updatesAttributes);
    toggleToast('success', t('attributesViewDeletedSuccessfully'));
    navigate('/custom-fields');
  };

  useEffect(() => {
    const currentIndex = attributes?.findIndex(
      (item) => item.id === attribute.id
    );

    const updatedAttributes = [...attributes];
    updatedAttributes[currentIndex] = {
      ...updatedAttributes[currentIndex],
      name: {
        en: values.anglophoneName,
        fr: values.francophoneName,
      },
      type: values.attributeType,
      options,
    };

    setAttributes(updatedAttributes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values.anglophoneName,
    values.francophoneName,
    values.attributeType,
    options,
  ]);

  useEffect(() => {
    switch (attribute?.type) {
      case 'list':
      case 'checkboxes':
      case 'radio-button':
      case 'multi-select':
        setHasOptions(true);
        break;
      default:
        setHasOptions(false);
        setOptions([]);
    }
  }, [attribute?.type]);

  return (
    <form style={attributeViewStyles.form}>
      <DeleteModal
        isDeleteModalOpen={isDeleteModalOpen}
        handleCloseDeleteModal={handleCloseDeleteModal}
        handleConfirm={handleDeleteAttribute}
        isProtected
      />

      {!templatesView && (
        <Box width="100%" display="flex" pb="12px">
          <Button
            size="large"
            sx={{ ...attributeViewStyles.button, marginRight: '24px' }}
            color="error"
            variant="contained"
            startIcon={<DeleteOutline />}
            onClick={() => setIsDeleteModalOpen(true)}
          >
            {t('attributeViewButtonDelete')}
          </Button>
        </Box>
      )}

      <Box maxHeight="80vh" overflow="auto" pt="24px">
        <Box>
          <Typography variant="h4" sx={attributeViewStyles.sectionHeading}>
            {t('attributeViewCustomFields')}
          </Typography>

          <Grid container>
            <Grid item md={9}>
              <TextField
                id="anglophoneName"
                name="anglophoneName"
                label={t('attributeViewAnglophoneName')}
                fullWidth
                variant="outlined"
                onChange={handleChange}
                value={values.anglophoneName}
                sx={attributeViewStyles.textField}
                disabled={!templatesView}
              />
            </Grid>

            <Grid item md={9}>
              <TextField
                id="francophoneName"
                name="francophoneName"
                label={t('attributeViewFrancophoneName')}
                fullWidth
                variant="outlined"
                onChange={handleChange}
                value={values.francophoneName}
                sx={attributeViewStyles.textField}
                disabled={!templatesView}
              />
            </Grid>
          </Grid>
        </Box>

        <FormControl sx={attributeViewStyles.dropdown}>
          <InputLabel id="attributeType">
            {t('attributeViewAttributeType')}
          </InputLabel>

          <Select
            labelId="attributeType"
            id="attributeType"
            label={t('attributeViewAttributeType')}
            onChange={handleChange}
            value={values.attributeType}
            name="attributeType"
            disabled={!templatesView}
          >
            {ATTRIBUTES_TYPES.map((type) => {
              return (
                <MenuItem key={type.value} value={type.value}>
                  {t(type?.name)}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        {hasOptions || options?.length ? (
          <Box sx={attributeViewStyles.options}>
            {options?.map((option) => {
              const currentIndex = options?.findIndex(
                (item) => item.id === option.id
              );
              const isHovered = dropIndex.current === currentIndex;
              const isDisabled = !!templatesView === false && option?.name;

              return (
                <div
                  draggable
                  onDragStart={() =>
                    !isDisabled && handleDragStart(currentIndex)
                  }
                  onDragEnter={() =>
                    !isDisabled && handleDragEnter(currentIndex)
                  }
                  onDragEnd={() => !isDisabled && handleDragEnd()}
                  onDragOver={(e) => e.preventDefault()}
                  key={option.id}
                >
                  <DraggableOption
                    option={option}
                    options={options}
                    setOptions={setOptions}
                    isHovered={isHovered}
                    isDisabled={isDisabled}
                  />
                </div>
              );
            })}

            {!!templatesView === true && (
              <Button
                sx={attributeViewStyles.button}
                variant="outlined"
                onClick={handleAddOption}
                startIcon={<AddCircleOutline />}
              >
                {t('attributeViewAddOption')}
              </Button>
            )}
          </Box>
        ) : null}
      </Box>
    </form>
  );
};

export default AttributeView;
