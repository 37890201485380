import { Typography, Box, IconButton } from '@mui/material';
import React from 'react';
import { customFilterModalStyles } from './styles';
import { Close } from '@mui/icons-material';
import ModalOverlay from '../ModalOverlay';

const CustomFilterModal = ({
  isFilterModalOpen,
  handleClose,
  children,
  modalTitle,
}) => {
  return (
    <ModalOverlay
      isModalOpen={isFilterModalOpen}
      handleCloseModal={handleClose}
    >
      <Box sx={customFilterModalStyles.modal}>
        <IconButton
          onClick={handleClose}
          sx={customFilterModalStyles.closeButton}
          disableRipple
        >
          <Close />
        </IconButton>

        <Typography
          mb="12px"
          fontWeight={500}
          variant="h5"
          component="h2"
          gutterBottom
        >
          {modalTitle}
        </Typography>

        {children}
      </Box>
    </ModalOverlay>
  );
};

export default CustomFilterModal;
