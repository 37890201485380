import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLayout } from './contexts/LayoutContext';
import { CssBaseline, ThemeProvider } from '@mui/material';
import NavigationRoutes from './routes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { appTheme } from './theme';
import './App.css';

function App() {
  const { i18n } = useTranslation();
  const { currentLanguage } = useLayout();

  useEffect(() => {
    i18n.changeLanguage(currentLanguage);
  }, [i18n, currentLanguage]);

  return (
    <ThemeProvider theme={appTheme}>
      <ToastContainer />
      <CssBaseline />
      <NavigationRoutes />
    </ThemeProvider>
  );
}

export default App;
