import { useTheme } from '@mui/material';

export const useReportsEditStyles = () => {
  const theme = useTheme();

  return {
    header: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      position: 'sticky',
      top: '75px',
      bgcolor: 'white',
      zIndex: 99,
      paddingBlock: '20px',
      paddingInline: '5px',
    },
    headerButton: { marginRight: '12px' },
    body: { marginTop: '16px' },
    codeColor: {
      minHeight: '40px',
      minWidth: '40px',
      borderRadius: '20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: '9px',
      color: 'white',
    },
    abbreviation: { fontSize: '14px' },
    modal: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '80%',
      bgcolor: 'background.paper',
      borderRadius: 2,
      boxShadow: 24,
      p: 4,
      overflow: 'auto',
      height: '90%',
    },
    modalHeading: {
      alignSelf: 'flex-start',
      width: '100%',
      paddingBottom: '20px',
      borderBottom: `1px solid ${theme.palette.action.focus}`,
    },
    modalBody: {
      marginTop: '12px',
    },
    accordionSummary: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    },
    accordionSummaryContent: {
      display: 'flex',
      alignItems: 'center',
    },
    sectionName: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '2px 16px',
      marginTop: 1,
      width: '100%',
      bgcolor: theme.palette.action.hover,
    },
    pdf: { width: '100%', height: '100%', marginTop: '24px' },
    sectionAttribute: {
      padding: '24px 12px 24px 24px',
      display: 'flex',
      alignItems: 'center',
      borderBottom: `1px solid ${theme.palette.action.focus}`,
      justifyContent: 'space-between',
    },
    sectionAttributeName: {
      marginRight: '24px',
      width: '20%',
    },
    closeButton: {
      position: 'absolute',
      top: 0,
      right: 0,
    },
  };
};
