import { useTheme } from '@mui/material';

export const patientsFilterFormStyles = {
  form: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '1rem',
  },
};

export const usePatientsSearchStyles = () => {
  const theme = useTheme();

  return {
    link: { textDecoration: 'none' },
    modal: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '80%',
      bgcolor: 'background.paper',
      borderRadius: 2,
      boxShadow: 24,
      p: 4,
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      // marginBottom: '16px',
    },
    buttonIcon: { marginRight: '8px' },
    headerContent: {
      display: 'flex',
      alignItems: 'center',
    },
    deleteButton: { mx: '12px' },
    searchParams: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      mt: '12px',
      mb: '12px',
    },
    searchParamsItem: {
      mr: '5px',
      fontSize: '14px',
    },
    searchBar: { marginRight: '12px', width: '300px' },
    patientName: {
      color: theme.palette.primary.main,
      '&:hover': { color: theme.palette.info.dark },
    },
  };
};
