import React from 'react';
import { Document, Page, Font, View, Text } from '@react-pdf/renderer';
import { insurancePdfStyles } from '../styles';
import LogosEn from './LogosEn';
import DoctorPatientInfoEn from './DoctorPatientInfoEn';
import DoctorUseEn from './DoctorUseEn';
import TreatmentPlanEn from './TreatmentPlanEn';
import InsuranceTotalsEn from './InsuranceTotalsEn';
import HowToEn from './HowToEn';
import EmployerInfoEn from './EmployerInfoEn';
import PatientInfoEn from './PatientInfoEn';
import SignatureEn from './SignatureEn';

const InsurancePdfEn = ({ patient, patientDoctor, record }) => {
  Font.register({
    family: 'Anonymous Pro',
    fonts: [
      {
        src: 'https://fonts.gstatic.com/s/anonymouspro/v21/rP2Bp2a15UIB7Un-bOeISG3pLlw89CH98Ko.ttf',
      },
      {
        src: 'https://fonts.gstatic.com/s/anonymouspro/v21/rP2cp2a15UIB7Un-bOeISG3pFuAT0CnW7KOywKo.ttf',
        fontWeight: 700,
      },
      {
        src: 'https://fonts.gstatic.com/s/anonymouspro/v21/rP2fp2a15UIB7Un-bOeISG3pHl428AP44Kqr2Q.ttf',
        fontStyle: 'italic',
      },
      {
        src: 'https://fonts.gstatic.com/s/anonymouspro/v21/rP2ap2a15UIB7Un-bOeISG3pHl4OTCzc6IG30KqB9Q.ttf',
        fontWeight: 700,
        fontStyle: 'italic',
      },
    ],
  });
  const patientAddress = patient?.addresses[0].addressLine;
  const doctorAddress = patientDoctor?.addresses[0].addressLine;
  const totalFeeSubmitted = record
    .reduce((accumulator, currentValue) => {
      return accumulator + parseFloat(currentValue.total.replace('$', ''));
    }, 0)
    .toFixed(2);

  return (
    <Document>
      <Page size={'A4'} style={insurancePdfStyles.page}>
        <LogosEn />

        <View style={insurancePdfStyles.body}>
          <DoctorPatientInfoEn
            patient={patient}
            patientDoctor={patientDoctor}
            patientAddress={patientAddress}
            doctorAddress={doctorAddress}
          />
          <DoctorUseEn />

          <View style={{ ...insurancePdfStyles.section, borderBottomWidth: 0 }}>
            <TreatmentPlanEn
              totalFeeSubmitted={totalFeeSubmitted}
              record={record}
            />

            <InsuranceTotalsEn />
          </View>

          <HowToEn />
          <EmployerInfoEn />
          <PatientInfoEn patient={patient} />
          <SignatureEn />
        </View>

        <View style={insurancePdfStyles.flexBetween}>
          <Text style={{ fontSize: '6px' }}>COPYRIGHT 09/03</Text>
          <Text style={{ fontSize: '6px' }}>
            ALL INFORMATION RECORDED ON THIS FORM IS CONFIDENTIAL
          </Text>
          <Text />
        </View>
      </Page>
    </Document>
  );
};

export default InsurancePdfEn;
