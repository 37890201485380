import { useTranslation } from 'react-i18next';
import mockBarChartData from '../../mock/barChart.json';

export const useBarChart = () => {
  const { t } = useTranslation();

  const barChartData = mockBarChartData.map((item) => {
    return {
      ...item,
      month: t(item.month),
    };
  });

  return { barChartData };
};
