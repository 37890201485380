import { View, Text } from '@react-pdf/renderer';
import Html from 'react-pdf-html';
import { reportsPdfFullStyles } from '../styles';
import { transformAttributeValue } from '../../../../utils/helpers';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const ReportsPdfSections = ({
  report,
  section,
  currentLanguage,
  patient,
  doctors,
}) => {
  const { t } = useTranslation();

  return (
    <View>
      {section?.type === 'patientInfo' ? (
        section?.options?.map((option) => {
          const { name, type, isActive } = option;
          const primaryContact = patient?.contacts?.find(
            (contact) => contact.primary
          );
          let matchedDoctors = doctors?.filter((doctor) =>
            report?.assignedDoctors?.includes(doctor?.id)
          );

          matchedDoctors = matchedDoctors
            ?.map((doctor) => `${doctor?.lastName} ${doctor?.firstName}`)
            .join(', ');

          return (
            <View key={option.id}>
              {isActive ? (
                type === 'dateOfBirth' ? (
                  <Text>
                    <Text>{`${name[currentLanguage]}: `}</Text>
                    {`${moment(patient?.dateOfBirth).format('LL')}`}
                  </Text>
                ) : type === 'primaryContact' ? (
                  <Text>
                    <Text>{`${name[currentLanguage]}: `}</Text>
                    {`${primaryContact?.value}`}
                  </Text>
                ) : type === 'doctors' ? (
                  <Text>
                    <Text>{`${name[currentLanguage]}: `}</Text>
                    {matchedDoctors}
                  </Text>
                ) : null
              ) : null}
            </View>
          );
        })
      ) : section?.type === 'treatmentPlan' || section?.type === 'phaseII' ? (
        section?.attributes?.map((attribute) => {
          const totalTime =
            attribute?.proceedings?.reduce((accumulator, currentValue) => {
              return accumulator + Number(currentValue.time);
            }, 0) || 0;
          const totalPrice = attribute?.proceedings
            ?.reduce((accumulator, currentValue) => {
              return accumulator + Number(currentValue.price);
            }, 0)
            .toFixed(2);

          return (
            <View style={reportsPdfFullStyles.treatmentPlan} key={attribute.id}>
              <Text style={reportsPdfFullStyles.planIndicator}>•</Text>
              <View style={reportsPdfFullStyles.plans}>
                <Text>
                  <Text>{`${t('reportsPdfRequestedDate')}: `}</Text>

                  {attribute?.requestedDate
                    ? ` ${moment(attribute?.requestedDate).format('l')}`
                    : ''}
                </Text>

                <Text>
                  <Text>{`${t('reportsPdfBookedDate')}: `}</Text>

                  {attribute?.bookedDate
                    ? `${moment(attribute?.bookedDate).format('l')}`
                    : ''}
                </Text>

                <Text>
                  <Text>{`${t('reportsPdfTime')}: `}</Text>

                  {`${totalTime}min(s)`}
                </Text>

                <Text>
                  <Text>{`${t('reportsPdfPrice')}: `}</Text>

                  {`$${totalPrice}`}
                </Text>

                <Text>
                  <Text>{`${t('reportsPdfStatus')}: `}</Text>
                  {`${
                    attribute?.completed
                      ? t('reportsPdfCompleted')
                      : t('reportsPdfInProgress')
                  }`}
                </Text>
              </View>
            </View>
          );
        })
      ) : section?.type === 'maintenancePhase' ? (
        <View style={{ marginTop: 5 }}>
          <Text>{section?.attributes[0].value}</Text>
        </View>
      ) : (
        section?.attributes?.map((attribute) => {
          return attribute?.value?.length ||
            attribute?.type === 'date' ||
            attribute?.type === 'switch' ? (
            <View key={attribute.id} style={reportsPdfFullStyles.attribute}>
              <Text key={attribute.id}>
                <Text>
                  <Text>{`• ${attribute.name[currentLanguage]}: `}</Text>
                  {attribute?.type === 'formatted-text' && attribute?.value ? (
                    <Html
                      stylesheet={{
                        p: {
                          fontSize: 11,
                        },
                        b: {
                          fontFamily: 'Anonymous Pro',
                          fontSize: 11,
                          fontWeight: 700,
                        },
                        em: {
                          fontFamily: 'Anonymous Pro',
                          fontStyle: 'italic',
                        },
                      }}
                    >
                      {attribute?.value}
                    </Html>
                  ) : (
                    `${transformAttributeValue(attribute, currentLanguage)}`
                  )}
                </Text>
              </Text>
            </View>
          ) : null;
        })
      )}
    </View>
  );
};

export default ReportsPdfSections;
