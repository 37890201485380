import React, { useState } from 'react';
import {
  Button,
  Grid,
  Box,
  Typography,
  useTheme,
  FormHelperText,
  IconButton,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { useDoctorsFormStyles } from './styles';
import { Close } from '@mui/icons-material';
import AddAddressModal from '../AddAddressModal';

const Address = ({ formik: { values, errors, touched, setFieldValue } }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const doctorsFormStyles = useDoctorsFormStyles();
  const [isAddAddressModalOpen, setIsAddAddressModalOpen] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState({});

  const handleCloseAddAddressModal = () => setIsAddAddressModalOpen(false);
  const handleUpdateAddress = (updatedAddresses) =>
    setFieldValue('addresses', updatedAddresses);
  const handleSetSelectedAddress = (address) => {
    setSelectedAddress(address);
    setIsAddAddressModalOpen(true);
  };

  const handleAddAddress = () =>
    handleSetSelectedAddress({
      id: uuidv4(),
      addressLine: '',
      city: '',
      province: '',
      zipCode: '',
      primary: false,
    });

  const handleDeleteAddress = (id) => {
    const filteredArray = values?.addresses.filter(
      (address) => id !== address?.id
    );
    setFieldValue('addresses', filteredArray);
  };

  return (
    <Box mt="24px">
      <AddAddressModal
        isAddAddressModalOpen={isAddAddressModalOpen}
        selectedAddress={selectedAddress}
        addresses={values.addresses}
        handleCloseAddAddressModal={handleCloseAddAddressModal}
        handleUpdateAddress={handleUpdateAddress}
      />

      <Typography variant="h4" sx={doctorsFormStyles.sectionHeading}>
        {t('doctorsFormHeadingAddress')}
      </Typography>
      <Grid container columnSpacing={4}>
        {values.addresses
          .sort((_a, b) => {
            if (b.primary) return 1;
            return -1;
          })
          .map((address) => {
            return (
              <Grid
                item
                minWidth="250px"
                maxWidth="300px"
                key={address.id}
                position="relative"
              >
                <IconButton
                  onClick={() => handleDeleteAddress(address.id)}
                  sx={doctorsFormStyles.closeButton}
                >
                  <Close sx={{ fontSize: '15px' }} />
                </IconButton>
                <Button
                  sx={doctorsFormStyles.address}
                  onClick={() => handleSetSelectedAddress(address)}
                >
                  {address.addressLine}
                  {address.primary && (
                    <span style={{ color: theme.palette.primary.main }}>
                      {t('doctorsFormPrimaryAddress')}
                    </span>
                  )}
                </Button>
              </Grid>
            );
          })}

        <Grid item md={2.5}>
          <Button
            sx={doctorsFormStyles.addAddress}
            variant="outlined"
            onClick={handleAddAddress}
          >
            {t('doctorsFormAddAddress')}
          </Button>
        </Grid>
      </Grid>
      <FormHelperText sx={{ color: theme.palette.error.main }}>
        {errors.addresses && touched.addresses && errors.addresses}
      </FormHelperText>
    </Box>
  );
};

export default Address;
