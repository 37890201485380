import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  TextField,
  Box,
  Typography,
  useTheme,
  Button,
  Switch,
  IconButton,
  Divider,
} from '@mui/material';
import { useCustomTemplateSectionStyles } from './styles';
import SectionAttribute from './SectionAttribute';
import { useFormik } from 'formik';
import { BiSolidUpArrow, BiSolidDownArrow } from 'react-icons/bi';
import AddAttributeModal from '../AddAttributeModal';
import { useLayout } from '../../contexts/LayoutContext';
import { COLORS } from '../../utils/colors';
import { AddCircleOutline, DeleteOutline } from '@mui/icons-material';

const CustomTemplateSection = ({
  section,
  sections,
  setSections,
  currentIndex,
  isSameSection,
  hasIndexChanged,
  handleMoveSection,
  handleDeleteSection,
  showArrows,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { currentLanguage } = useLayout();
  const customTemplateSectionStyles = useCustomTemplateSectionStyles();
  const [isAddAttributeModalOpen, setIsAddAttributeModalOpen] = useState(false);
  const [attributes, setAttributes] = useState(section?.attributes);
  const { values, handleChange } = useFormik({
    initialValues: {
      anglophoneName: section?.name.en,
      francophoneName: section?.name.fr,
      isSectionNameActive: section?.isSectionNameActive,
      isSignatureActive: section?.isSignatureActive,
    },
    validateOnChange: false,
  });
  const isFixedSection =
    section?.type === 'treatmentPlan' ||
    section?.type === 'phaseII' ||
    section?.type === 'maintenancePhase';
  const showNameFields =
    !isFixedSection &&
    section?.type !== 'medicalHistory' &&
    section?.type !== 'clinicalExam';

  const handleOpenNewAttributeModal = () => setIsAddAttributeModalOpen(true);
  const handleCloseNewAttributeModal = () => setIsAddAttributeModalOpen(false);

  useEffect(() => {
    const updatedItems = [...sections];
    updatedItems[currentIndex] = {
      ...section,
      name: {
        en: values.anglophoneName,
        fr: values.francophoneName,
      },
      isSectionNameActive: values.isSectionNameActive,
      isSignatureActive: values.isSignatureActive,
      attributes,
    };

    setSections(updatedItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values.anglophoneName,
    values.francophoneName,
    values.isSectionNameActive,
    values.isSignatureActive,
    attributes,
  ]);

  return (
    <Box mb={2}>
      <Box
        sx={{
          ...customTemplateSectionStyles.section,
          borderColor:
            hasIndexChanged || !isSameSection
              ? theme.palette.success.light
              : 'transparent',
        }}
      >
        <AddAttributeModal
          isAddAttributeModalOpen={isAddAttributeModalOpen}
          handleCloseNewAttributeModal={handleCloseNewAttributeModal}
          attributes={attributes}
          setAttributes={setAttributes}
        />

        <Box sx={customTemplateSectionStyles.patientInfoHeading}>
          <Box sx={customTemplateSectionStyles.sectionHeading}>
            {showArrows && (
              <Box sx={customTemplateSectionStyles.sectionHeadingIcons}>
                <IconButton
                  size="small"
                  onClick={() => handleMoveSection('up', currentIndex)}
                >
                  <BiSolidUpArrow />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={() => handleMoveSection('down', currentIndex)}
                >
                  <BiSolidDownArrow />
                </IconButton>
              </Box>
            )}
            <Typography fontWeight={600}>
              {currentLanguage === 'en'
                ? values.anglophoneName
                : values.francophoneName}
            </Typography>
          </Box>

          <Button
            variant="outlined"
            color="error"
            onClick={() => handleDeleteSection(section.id)}
            startIcon={<DeleteOutline />}
          >
            {t('customTemplateSectionDeleteSection')}
          </Button>
        </Box>
        <Box
          sx={{
            ...customTemplateSectionStyles.patientInfoCard,
            opacity: values.isSectionNameActive ? 1 : 0.5,
          }}
        >
          <Typography>{t('customTemplateSectionShowSectionName')}</Typography>
          <Switch
            name="isSectionNameActive"
            checked={values.isSectionNameActive}
            onChange={handleChange}
          />
        </Box>
        {showNameFields ? (
          <Grid
            container
            columnSpacing={3}
            sx={customTemplateSectionStyles.sectionTextFields}
          >
            <Grid item xs={12} md={6}>
              <TextField
                name="anglophoneName"
                label={t('customTemplateSectionAnglophoneName')}
                fullWidth
                variant="outlined"
                sx={customTemplateSectionStyles.textField}
                value={values.anglophoneName}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                name="francophoneName"
                label={t('customTemplateSectionFrancophoneName')}
                fullWidth
                variant="outlined"
                sx={customTemplateSectionStyles.textField}
                value={values.francophoneName}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        ) : null}
        {/* <Box
          sx={{
            ...customTemplateSectionStyles.patientInfoCard,
            opacity: values.isSignatureActive ? 1 : 0.5,
          }}
        >
          <Typography>{t('customTemplateSectionSignature')}</Typography>
          <Switch
            name="isSignatureActive"
            checked={values.isSignatureActive}
            onChange={handleChange}
          />
        </Box> */}
        <Box>
          {attributes.map((attribute) => {
            const currentIndex = attributes.findIndex(
              (item) => item.id === attribute.id
            );

            return (
              <div
                key={attribute.id}
                style={customTemplateSectionStyles.accordionWrapper}
              >
                <SectionAttribute
                  attribute={attribute}
                  currentIndex={currentIndex}
                  attributes={attributes}
                  setAttributes={setAttributes}
                />
              </div>
            );
          })}
        </Box>

        {!isFixedSection ? (
          <Box style={customTemplateSectionStyles.addSection}>
            <Button
              variant="outlined"
              sx={customTemplateSectionStyles.addSectionButton}
              onClick={handleOpenNewAttributeModal}
              startIcon={<AddCircleOutline />}
            >
              {t('customTemplateSectionAddAttribute')}
            </Button>
          </Box>
        ) : null}
      </Box>
      <Divider sx={{ bgcolor: COLORS.LIGHT_GRAY }} />
    </Box>
  );
};

export default CustomTemplateSection;
