import React from 'react';
import parse from 'html-react-parser';
import { transformAttributeValue } from '../../../utils/helpers';
import { useLayout } from '../../../contexts/LayoutContext';
import { usePatientsViewStyles } from './styles';
import { Box, Typography } from '@mui/material';

const MappedAttributes = ({ attributes }) => {
  const { currentLanguage } = useLayout();
  const patientsViewStyles = usePatientsViewStyles();

  return (
    <Box sx={patientsViewStyles.medicalHistory}>
      {attributes.map((attribute) => {
        return (
          <Typography
            key={attribute.id}
            sx={patientsViewStyles.medicalHistoryText}
          >
            {
              <Typography variant="caption" fontSize="13px">
                <span style={patientsViewStyles.medicalHistoryName}>
                  {`• ${attribute.name[currentLanguage]}:`}
                </span>
                {attribute?.type === 'formatted-text' && attribute?.value
                  ? parse(attribute?.value)
                  : `${transformAttributeValue(attribute, currentLanguage)}`}
              </Typography>
            }
          </Typography>
        );
      })}
    </Box>
  );
};

export default MappedAttributes;
