import { Box, Button, Typography } from '@mui/material';
import { useLayout } from '../../../../contexts/LayoutContext';
import { usePatientsViewStyles } from '../styles';
import { useTranslation } from 'react-i18next';
import { TREATMENT_PLAN_RESPONSIBILITY_OPTIONS } from '../../../../utils/patients';
import { useNavigate } from 'react-router-dom';
import { AddCircleOutline } from '@mui/icons-material';

export const useTreatmentPlan = (patient, showNewReportButton) => {
  const { templates, currentLanguage } = useLayout();
  const patientsViewStyles = usePatientsViewStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleStartReport = (templateId) => {
    const template = templates.find((item) => {
      return item.id === templateId;
    });

    navigate(`/reports/${template.id}/edit/`, {
      state: {
        patientId: patient?.id,
        templateId: template.id,
      },
    });
  };

  const treatmentPlanColumns = [
    {
      field: 'template',
      headerName: '',
      flex: 0.2,
      renderCell: ({ row: { templateId } }) => {
        const template = templates.find((item) => {
          return item.id === templateId;
        });

        return (
          <Box
            sx={{
              ...patientsViewStyles.codeColor,
              bgcolor: template?.theme,
            }}
          >
            <Typography sx={patientsViewStyles.abbreviation}>
              {template?.abbreviation[currentLanguage]}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: 'time',
      headerName: t('patientsViewTime'),
      flex: 0.2,
      valueGetter: ({ row: { time } }) => `${time || 0}min(s)`,
    },
    {
      field: 'responsible',
      headerName: t('patientsViewResponsible'),
      flex: 0.2,
      valueGetter: ({ row: { responsible } }) => {
        const responsibility = TREATMENT_PLAN_RESPONSIBILITY_OPTIONS.find(
          (item) => item.value === responsible
        );

        return t(responsibility?.name);
      },
    },
    {
      field: 'price',
      headerName: t('patientsViewPrice'),
      flex: 0.2,
      valueGetter: ({ row: { price } }) => `$${price || 0}`,
    },
    {
      field: 'button',
      headerName: '',
      flex: 0.2,
      renderCell: ({ row: { templateId } }) =>
        showNewReportButton && (
          <Button
            disabled={!templateId}
            variant="contained"
            onClick={() => handleStartReport(templateId)}
            startIcon={<AddCircleOutline />}
          >
            {t('patientsViewNewReport')}
          </Button>
        ),
    },
  ];

  return {
    treatmentPlanColumns,
  };
};
