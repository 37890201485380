import React, { useEffect, useState } from 'react';
import * as Layout from '../../../../layouts';
import { useLayout } from '../../../../contexts/LayoutContext';
import { Box, FormControl, MenuItem, InputLabel, Select } from '@mui/material';
import { PDFViewer } from '@react-pdf/renderer';
import { useTreatmentPlanStyles } from './styles';
import InsurancePdfEn from './InsurancePdf/InsurancePdfEn';
import InsurancePdfFr from './InsurancePdf/InsurancePdfFr';
import { useTranslation } from 'react-i18next';
import {
  TREATMENT_PLAN_INSURANCE_FORMAT,
  TREATMENT_PLAN_CODES,
} from '../../../../utils/patients';
import { useParams } from 'react-router-dom';
import { generateRandomId } from '../../../../functions';
import moment from 'moment';

const Insurance = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { setPageTitle, currentLanguage, patients, doctors } = useLayout();
  const [format, setFormat] = useState('estimate');
  const treatmentPlanStyles = useTreatmentPlanStyles();
  const patient = patients.find((patient) => patient?.id === id);
  const patientDoctor = doctors.find(
    (doctor) => doctor?.id === patient?.doctors[0]
  );
  const allProceedings =
    patient?.treatmentPlan?.flatMap((plan) => plan.proceedings) || [];
  const date = `${moment().format('DD')}  ${moment(patient?.dateOfBirth).format(
    'MM'
  )}  ${moment(patient?.dateOfBirth).format('YYYY')}`;
  const estimate = allProceedings.map((proceeding) => {
    const procedureCode = TREATMENT_PLAN_CODES.find(
      (code) => code.value === proceeding.code
    );
    const toothSurfaces = proceeding.selectedRegions.join(', ');

    return {
      id: generateRandomId(),
      dateOfService: format === 'estimate' ? 'ESTIMATE' : date,
      procedureCode: t(procedureCode?.name).slice(0, 5),
      toothCode: proceeding.region,
      toothSurfaces,
      doctorFee: proceeding.price ? `$${proceeding.price}` : '$0.00',
      labCharge: proceeding.fees ? `$${proceeding.fees}` : '$0.00',
      total: `$${(
        parseFloat(proceeding.price || 0) + parseFloat(proceeding.fees || 0)
      ).toFixed(2)}`,
    };
  });

  useEffect(() => {
    setPageTitle(t('treatmentPlanInsurance'));
  }, [setPageTitle, t]);

  return (
    <Layout.Main notFound={!patient?.id}>
      <FormControl sx={{ width: '20%' }} size="small">
        <InputLabel id="format">{t('treatmentPlanFormat')}</InputLabel>

        <Select
          labelId="format"
          id="format"
          label={t('treatmentPlanFormat')}
          size="small"
          name="format"
          value={format}
          onChange={({ target }) => setFormat(target.value)}
        >
          {TREATMENT_PLAN_INSURANCE_FORMAT.map((time) => {
            return (
              <MenuItem key={time.value} value={time.value}>
                {t(time.name)}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>

      <Box sx={treatmentPlanStyles.pdfWrapper}>
        <PDFViewer style={treatmentPlanStyles.pdf}>
          {currentLanguage === 'en' ? (
            <InsurancePdfEn
              patient={patient}
              patientDoctor={patientDoctor}
              record={estimate}
            />
          ) : (
            <InsurancePdfFr
              patient={patient}
              patientDoctor={patientDoctor}
              record={estimate}
            />
          )}
        </PDFViewer>
      </Box>
    </Layout.Main>
  );
};

export default Insurance;
