import { useTheme } from '@mui/material';

export const usePatientsViewStyles = () => {
  const theme = useTheme();

  return {
    flexBetween: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBlock: '36px 12px',
    },
    tableCell: {
      fontWeight: 600,
      borderRight: `1px solid ${theme.palette.action.focus}`,
    },
    doctors: { cursor: 'pointer', color: theme.palette.primary.main },
    sidebar: {
      position: 'fixed',
      marginTop: '-16px',
      marginLeft: '-16px',
      minWidth: '30px',
      paddingInline: '12px',
      bgcolor: 'white',
      height: 'calc(100vh - 75px)',
      display: 'flex',
      flexDirection: 'column',
      zIndex: 99,
      boxShadow: '2px 0 3px -2px #888',
      transition: 'transform 0.3s ease-in-out, opacity 0.3s ease-in-out',
    },
    list: {
      marginTop: '52px',
      height: '100%',
      overflowY: 'auto',
      top: 0,
      bottom: 0,
      cursor: 'pointer',
    },
    templates: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '12px',
    },
    templatesAbbr: {
      height: '40px',
      width: '40px',
      borderRadius: '20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    iconButton: { color: 'white' },
    menuButton: { position: 'absolute', top: 12 },
    link: {
      textDecoration: 'none',
      marginLeft: '12px',
      color: theme.palette.primary.main,
      '&:hover': { color: theme.palette.info.dark },
    },
    medicalHistory: {
      marginTop: '8px',
      maxHeight: '350px',
      overflowY: 'auto',
    },
    medicalHistoryText: {
      fontSize: '13px',
      display: 'flex',
      alignItems: 'center',
      marginBottom: '5px',
    },
    medicalHistoryName: { fontWeight: 600, marginRight: '3px' },
    codeColor: {
      minHeight: '40px',
      minWidth: '40px',
      borderRadius: '20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: '9px',
      color: 'white',
    },
    abbreviation: { fontSize: '14px' },
    missingData: {
      display: 'flex',
      justifyContent: 'center',
      padding: '12px',
      marginTop: '12px',
      bgcolor: theme.palette.warning.light,
    },
    medicalHistoryHeading: {
      borderBottom: `1px solid ${theme.palette.action.focus}`,
      paddingBottom: '12px',
      marginTop: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  };
};
