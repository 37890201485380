import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { insurancePdfStyles } from '../styles';
import moment from 'moment';

const PatientInfoEn = ({ patient }) => {
  return (
    <View>
      <View style={insurancePdfStyles.instructionsHeading}>
        <Text style={insurancePdfStyles.instructionsHeadingText}>
          PART 3 - PATIENT INFORMATION
        </Text>
      </View>

      <View style={{ ...insurancePdfStyles.employeeBody, paddingTop: '5px' }}>
        <View style={insurancePdfStyles.employeeBodyContent}>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <Text
              style={{
                ...insurancePdfStyles.smallBold,
                marginRight: '1.5px',
              }}
            >
              1.
            </Text>

            <View>
              <Text style={insurancePdfStyles.smallBold}>
                PATIENT: RELATIONSHIP TO{'\n'}
                EMPLOYEE/ PLAN
                MEMBER/SUBSCRIBER__________________________________________________________
              </Text>

              <View style={insurancePdfStyles.patientInfoDOBSection}>
                <Text style={insurancePdfStyles.smallBold}>DATE OF BIRTH</Text>

                <View
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    transform: 'translateY(-5%)',
                  }}
                >
                  <View style={insurancePdfStyles.patientInfo3DOB}>
                    <Text style={insurancePdfStyles.pdfValue}>
                      {moment(patient?.dateOfBirth).format('DD')}
                    </Text>
                    <Text style={insurancePdfStyles.pdfValue}>
                      {moment(patient?.dateOfBirth).format('MM')}
                    </Text>
                    <Text style={insurancePdfStyles.pdfValue}>
                      {moment(patient?.dateOfBirth).format('YYYY')}
                    </Text>
                  </View>

                  <Text style={insurancePdfStyles.smallBold}>
                    DAY{'        '}MONTH{'        '}YEAR
                  </Text>
                </View>

                <Text style={insurancePdfStyles.smallBold}>
                  IF CHILD INDICATE:
                </Text>

                <View style={insurancePdfStyles.checkboxWrapper}>
                  <View style={insurancePdfStyles.checkboxAfter}>
                    <View style={insurancePdfStyles.checkboxBefore} />
                  </View>

                  <Text style={insurancePdfStyles.smallBold}>STUDENT</Text>
                </View>

                <View style={insurancePdfStyles.checkboxWrapper}>
                  <View style={insurancePdfStyles.checkboxAfter}>
                    <View style={insurancePdfStyles.checkboxBefore} />
                  </View>

                  <Text style={insurancePdfStyles.smallBold}>HANDICAPPED</Text>
                </View>
              </View>

              <Text
                style={{
                  ...insurancePdfStyles.smallBold,
                  marginTop: '7px',
                }}
              >
                IF STUDENT, INDICATE SCHOOL
                __________________________________________________________________
              </Text>

              <Text
                style={{
                  ...insurancePdfStyles.smallBold,
                  marginTop: '7px',
                }}
              >
                PATIENT I.D. NO.
                ________________________________________________________________________________
              </Text>
            </View>
          </View>

          <View style={insurancePdfStyles.patientInfoQuestion}>
            <Text
              style={{
                ...insurancePdfStyles.smallBold,
                marginRight: '1.5px',
              }}
            >
              2.
            </Text>

            <View>
              <View>
                <Text style={insurancePdfStyles.smallBold}>
                  ARE ANY MEDICAL BENEFITS OR SERVICES PROVIDED UNDER ANY OTHER
                  GROUP INSURACE OR MEDICAL
                </Text>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: '5px',
                  }}
                >
                  <Text style={insurancePdfStyles.smallBold}>
                    PLAN, W.C.B. OR GOV’T PLAN?
                  </Text>

                  <View style={insurancePdfStyles.checkboxWrapper}>
                    <View style={insurancePdfStyles.checkboxAfter}>
                      <View style={insurancePdfStyles.checkboxBefore} />
                    </View>

                    <Text style={insurancePdfStyles.smallBold}>NO</Text>
                  </View>
                  <View style={insurancePdfStyles.checkboxWrapper}>
                    <View style={insurancePdfStyles.checkboxAfter}>
                      <View style={insurancePdfStyles.checkboxBefore} />
                    </View>

                    <Text style={insurancePdfStyles.smallBold}>YES</Text>
                  </View>
                </View>
              </View>

              <Text
                style={{
                  ...insurancePdfStyles.smallBold,
                  marginTop: '10px',
                }}
              >
                POLICY NO._______________________________SPOUSE DATE OF BIRTH
                _______________________________
              </Text>

              <Text
                style={{
                  ...insurancePdfStyles.smallBold,
                  marginTop: '10px',
                }}
              >
                NAME OF OTHER INSURING AGENCY OR PLAN
                _____________________________________________________
              </Text>
            </View>
          </View>
        </View>

        <View style={insurancePdfStyles.employeeBodyContent}>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <Text
              style={{
                ...insurancePdfStyles.smallBold,
                marginRight: '1.5px',
              }}
            >
              3.
            </Text>

            <View
              style={{
                ...insurancePdfStyles.flexBetween,
                justifyContent: 'flex-start',
              }}
            >
              <Text style={{ ...insurancePdfStyles.smallBold, width: '60%' }}>
                IS ANY TREATMENT REQUIRED AS THE RESULT OF AN ACCIDENT? IF YES,
                GIVE DATE AND DETAILS SEPERATELY.
              </Text>

              <View style={insurancePdfStyles.patientInfoQuestionCheckboxes}>
                <View style={insurancePdfStyles.checkboxWrapper}>
                  <View style={insurancePdfStyles.checkboxAfter}>
                    <View style={insurancePdfStyles.checkboxBefore} />
                  </View>

                  <Text style={insurancePdfStyles.smallBold}>NO</Text>
                </View>
                <View style={insurancePdfStyles.checkboxWrapper}>
                  <View style={insurancePdfStyles.checkboxAfter}>
                    <View style={insurancePdfStyles.checkboxBefore} />
                  </View>

                  <Text style={insurancePdfStyles.smallBold}>YES</Text>
                </View>
              </View>
            </View>
          </View>

          <View style={insurancePdfStyles.patientInfoQuestion}>
            <Text
              style={{
                ...insurancePdfStyles.smallBold,
                marginRight: '1.5px',
              }}
            >
              4.
            </Text>

            <View
              style={{
                ...insurancePdfStyles.flexBetween,
                justifyContent: 'flex-start',
              }}
            >
              <Text style={{ ...insurancePdfStyles.smallBold, width: '60%' }}>
                IF DENTURE, CROWN OR BRIDGE, IS THIS INITIAL PLACEMENT? GIVE
                DATE OF PRIOR PLACEMENT AND REASON FOR REPLACEMENT.
              </Text>

              <View style={insurancePdfStyles.patientInfoQuestionCheckboxes}>
                <View style={insurancePdfStyles.checkboxWrapper}>
                  <View style={insurancePdfStyles.checkboxAfter}>
                    <View style={insurancePdfStyles.checkboxBefore} />
                  </View>

                  <Text style={insurancePdfStyles.smallBold}>NO</Text>
                </View>
                <View style={insurancePdfStyles.checkboxWrapper}>
                  <View style={insurancePdfStyles.checkboxAfter}>
                    <View style={insurancePdfStyles.checkboxBefore} />
                  </View>

                  <Text style={insurancePdfStyles.smallBold}>YES</Text>
                </View>
              </View>
            </View>
          </View>

          <View style={insurancePdfStyles.patientInfoQuestion}>
            <Text
              style={{
                ...insurancePdfStyles.smallBold,
                marginRight: '1.5px',
              }}
            >
              5.
            </Text>

            <View
              style={{
                ...insurancePdfStyles.flexBetween,
                justifyContent: 'flex-start',
              }}
            >
              <Text style={{ ...insurancePdfStyles.smallBold, width: '60%' }}>
                IS ANY TREATMENT REQUIRED FOR ORTHODONTIC PURPOSES?
              </Text>

              <View style={insurancePdfStyles.patientInfoQuestionCheckboxes}>
                <View style={insurancePdfStyles.checkboxWrapper}>
                  <View style={insurancePdfStyles.checkboxAfter}>
                    <View style={insurancePdfStyles.checkboxBefore} />
                  </View>

                  <Text style={insurancePdfStyles.smallBold}>NO</Text>
                </View>
                <View style={insurancePdfStyles.checkboxWrapper}>
                  <View style={insurancePdfStyles.checkboxAfter}>
                    <View style={insurancePdfStyles.checkboxBefore} />
                  </View>

                  <Text style={insurancePdfStyles.smallBold}>YES</Text>
                </View>
              </View>
            </View>
          </View>

          <View style={insurancePdfStyles.patientInfoQuestion}>
            <Text
              style={{
                ...insurancePdfStyles.smallBold,
                marginRight: '1.5px',
              }}
            >
              6.
            </Text>

            <Text style={insurancePdfStyles.smallBold}>
              I AUTHORIZE THE RELEASE OF ANY INFORMATION OR RECORDS REQUESTED IN
              RESPECT OF THIS CLAIM TO THE INSURER / PLAN ADMINISTRATOR AND
              CERTIFY THAT THE INFORMATION GIVEN IS TRUE, CORRECT AND COMPLETE
              TO THE BEST OF MY KNOWLEDGE.
            </Text>
          </View>

          <View
            style={{
              ...insurancePdfStyles.patientInfoDOBSection,
              alignSelf: 'flex-end',
            }}
          >
            <Text style={insurancePdfStyles.smallBold}>DATE</Text>

            <View style={{ display: 'flex', alignItems: 'center' }}>
              <View
                style={{
                  ...insurancePdfStyles.patientInfoDOB,
                  width: '100px',
                }}
              />
              <Text
                style={{
                  ...insurancePdfStyles.smallBold,
                  marginTop: '1px',
                }}
              >
                DAY{'        '}MONTH{'        '}YEAR
              </Text>
            </View>
          </View>

          <View
            style={{
              ...insurancePdfStyles.patientInfoDOBSection,
              marginTop: '5px',
            }}
          >
            <View style={{ display: 'flex', alignItems: 'center' }}>
              <View
                style={{
                  ...insurancePdfStyles.patientInfoDOB,
                  width: '150px',
                }}
              />
              <Text
                style={{
                  ...insurancePdfStyles.smallBold,
                  marginTop: '1px',
                }}
              >
                SIGNATURE OF EMPLOYEE/PLAN MEMBER/SUBSCRIBER
              </Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

export default PatientInfoEn;
