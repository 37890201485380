import { useTheme } from '@mui/material';

export const useTemplatesSearchStyles = () => {
  const theme = useTheme();

  return {
    link: { textDecoration: 'none' },
    header: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      pb: '16px',
      width: '100%',
    },
    buttonIcon: { marginRight: '8px' },
    headerContent: {
      display: 'flex',
      alignItems: 'center',
    },
    deleteButton: { width: '100%' },
    searchBar: {
      width: '300px',
      ml: '12px',
    },
    template: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderBottom: '1px solid #e0e0e0',
      padding: '7px',
    },
    templateName: {
      display: 'flex',
      alignItems: 'center',
    },
    theme: {
      height: '40px',
      width: '40px',
      borderRadius: '20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: '9px',
      color: 'white',
    },
    searchParams: {
      display: 'flex',
      alignItems: 'center',
      mr: '12px',
    },
    searchParamsItem: {
      mr: '5px',
      fontSize: '14px',
    },
    name: {
      color: theme.palette.primary.main,
      '&:hover': { color: theme.palette.info.dark },
    },
  };
};
