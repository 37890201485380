import { Box } from '@mui/material';
import TextBoxAttribute from './AttributeTypeTemplates/TextBoxAttribute';
import ListAttribute from './AttributeTypeTemplates/ListAttribute';
import RadioAttribute from './AttributeTypeTemplates/RadioAttribute';
import CheckboxAttribute from './AttributeTypeTemplates/CheckboxAttribute';
import DateAttribute from './AttributeTypeTemplates/DateAttribute';
import SwitchAttribute from './AttributeTypeTemplates/SwitchAttribute';
import MultiSelectAttribute from './AttributeTypeTemplates/MultiSelectAttribute';
import FormattedTextAttribute from './AttributeTypeTemplates/FormattedTextAttribute';
import { useEffect, useState } from 'react';
import { useLayout } from '../../contexts/LayoutContext';

const AttributeType = ({ attributes, setAttributes, attribute }) => {
  const attributeType = attribute.type;
  const { currentLanguage } = useLayout();
  const [attributeValue, setAttributeValue] = useState(attribute?.value || '');
  const attributeLabel =
    currentLanguage === 'en' ? attribute?.name?.en : attribute?.name?.fr;

  useEffect(() => {
    const updatedItems = [...attributes];
    const currentIndex = updatedItems.findIndex(
      (item) => item.id === attribute.id
    );
    updatedItems[currentIndex] = {
      ...updatedItems[currentIndex],
      value: attributeValue,
    };

    setAttributes(updatedItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attributeValue]);

  return (
    <Box>
      {attributeType === 'textbox' ? (
        <TextBoxAttribute
          attributeLabel={attributeLabel}
          attributeValue={attributeValue}
          setAttributeValue={setAttributeValue}
        />
      ) : attributeType === 'list' ? (
        <ListAttribute
          attributeLabel={attributeLabel}
          attributeValue={attributeValue}
          setAttributeValue={setAttributeValue}
          attribute={attribute}
        />
      ) : attributeType === 'radio-button' ? (
        <RadioAttribute
          attributeLabel={attributeLabel}
          attributeValue={attributeValue}
          setAttributeValue={setAttributeValue}
          attribute={attribute}
        />
      ) : attributeType === 'checkboxes' ? (
        <CheckboxAttribute
          attributeLabel={attributeLabel}
          attributeValue={attributeValue}
          setAttributeValue={setAttributeValue}
          attribute={attribute}
        />
      ) : attributeType === 'date' ? (
        <DateAttribute
          attributeLabel={attributeLabel}
          attributeValue={attributeValue}
          setAttributeValue={setAttributeValue}
        />
      ) : attributeType === 'switch' ? (
        <SwitchAttribute
          attributeLabel={attributeLabel}
          attributeValue={attributeValue}
          setAttributeValue={setAttributeValue}
        />
      ) : attributeType === 'multi-select' ? (
        <MultiSelectAttribute
          attributeLabel={attributeLabel}
          attributeValue={attributeValue}
          setAttributeValue={setAttributeValue}
          attribute={attribute}
        />
      ) : attributeType === 'formatted-text' ? (
        <FormattedTextAttribute
          attributeLabel={attributeLabel}
          attributeValue={attributeValue}
          setAttributeValue={setAttributeValue}
        />
      ) : null}
    </Box>
  );
};

export default AttributeType;
