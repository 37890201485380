export const TEMPLATES_REPORT_MODELS = [
  { name: 'templatesEditGeneralLayout', value: 'general' },
  { name: 'templatesEditPrescriptionLayout', value: 'prescription' },
  { name: 'templatesEditPatientLayout', value: 'patient' },
];

export const TEMPLATES_PATIENTS_SECTIONS = [
  {
    name: {
      en: 'The medical history reveals',
      fr: 'Les antécédents médicaux révèlent',
    },
    type: 'medicalHistory',
  },
  {
    name: { en: 'Clinical Exam', fr: 'Examen clinique' },
    type: 'clinicalExam',
  },
  {
    name: {
      en: 'Treatment Plan',
      fr: 'Plan de traitement',
    },
    type: 'treatmentPlan',
  },
  {
    name: {
      en: 'Treatment Plan (Phase II)',
      fr: 'Plan de traitement (Phase II)',
    },
    type: 'phaseII',
  },
  {
    name: {
      en: 'Maintenance Phase',
      fr: `Phase d'entretien`,
    },
    type: 'maintenancePhase',
  },
];
