import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import * as Layout from '../../../layouts';
import { Editor } from '@tinymce/tinymce-react';
import { useRef, useState } from 'react';
import { reportsEmailStyles } from './styles';
import { useTranslation } from 'react-i18next';
import { AddCircleOutline, DeleteOutline } from '@mui/icons-material';
import { generateRandomId } from '../../../functions';
import { useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { useLayout } from '../../../contexts/LayoutContext';
import moment from 'moment';
import { toggleToast } from '../../../utils/helpers/toast';
import { RiMailSendLine } from 'react-icons/ri';

const ReportsEmail = () => {
  const theme = useTheme();
  let editorRef = useRef(null);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { reportId } = useParams();
  const { patients, reports, setReports } = useLayout();
  const report = reports.find((report) => reportId === report?.id);
  const patient = patients.find((patient) => patient?.id === report?.patientId);
  const [attachments, setAttachments] = useState([]);
  const [emailCCItems, setCCItems] = useState([]);
  const [emailToItems, setEmailToItems] = useState([]);
  const { values, handleChange } = useFormik({
    initialValues: {
      subject: `${patient?.lastName} ${patient?.firstName}. ${moment(
        report?.appointmentDate
      ).format('LL')}`,
      emailTo: '',
      emailCC: '',
    },
  });

  const handleInputKeyPress = (event, field) => {
    if (event.key === 'Enter' || event.key === ',') {
      event.preventDefault();

      if (field === 'cc') {
        if (values.emailCC.trim() !== '') {
          setCCItems([...emailCCItems, values.emailCC.trim()]);
          handleChange('emailCC')('');
        }
      } else if (field === 'to') {
        if (values.emailTo.trim() !== '') {
          setEmailToItems([...emailToItems, values.emailTo.trim()]);
          handleChange('emailTo')('');
        }
      }
    }
  };

  const handleDeleteItem = (itemIndex, field) => {
    if (field === 'cc') {
      const updatedItems = [...emailCCItems];
      updatedItems.splice(itemIndex, 1);
      setCCItems(updatedItems);
    } else if (field === 'to') {
      const updatedItems = [...emailToItems];
      updatedItems.splice(itemIndex, 1);
      setEmailToItems(updatedItems);
    }
  };

  const handleFileChange = (index, event) => {
    const updatedFileFields = [...attachments];
    updatedFileFields[index].file = event.target.files[0];
    setAttachments(updatedFileFields);
  };

  const handleAddFile = () => {
    const newFile = { id: generateRandomId(), file: null };
    setAttachments([...attachments, newFile]);
  };

  const handleDeleteFile = (id) => {
    const updatedFileFields = attachments.filter((field) => field.id !== id);
    setAttachments(updatedFileFields);
  };

  const handleSendMail = () => {
    const currentIndex = reports.findIndex((item) => item.id === report.id);

    const updatedReports = [...reports];
    updatedReports[currentIndex] = {
      ...report,
      status: 'sent-by-email',
    };
    setReports(updatedReports);
    toggleToast('success', t('reportsEmailSentSuccessfully'));
    navigate('/reports');
  };

  return (
    <Layout.Main notFound={!report?.id}>
      <Box pb="12px" sx={reportsEmailStyles.optionButtons}>
        <Button
          variant="contained"
          color="primary"
          sx={reportsEmailStyles.sendBtn}
          onClick={handleSendMail}
          startIcon={<RiMailSendLine />}
        >
          {t('reportsEditSend')}
        </Button>
      </Box>

      <Box maxHeight="80vh" overflow="auto" pt="24px">
        <Grid container spacing={3}>
          <Grid item md={12}>
            <TextField
              size="small"
              fullWidth
              name="subject"
              label={t('reportsEmailSubject')}
              value={values.subject}
            />
          </Grid>
          <Grid item md={12}>
            <TextField
              size="small"
              fullWidth
              name="emailTo"
              label={t('reportsEmailTo')}
              onChange={handleChange}
              onKeyUp={(event) => handleInputKeyPress(event, 'to')}
              value={values.emailTo}
              InputProps={{
                startAdornment: (
                  <Box display={'flex'}>
                    {emailToItems.map((item, index) => (
                      <Chip
                        key={index}
                        label={item}
                        onDelete={() => handleDeleteItem(index, 'to')}
                        size="small"
                        sx={{ mr: '5px' }}
                      />
                    ))}
                  </Box>
                ),
              }}
            />
          </Grid>

          <Grid item md={12}>
            <TextField
              size="small"
              fullWidth
              name="emailCC"
              label="CC"
              onChange={handleChange}
              onKeyUp={(event) => handleInputKeyPress(event, 'cc')}
              value={values.emailCC}
              InputProps={{
                startAdornment: (
                  <Box display={'flex'}>
                    {emailCCItems.map((item, index) => (
                      <Chip
                        key={index}
                        label={item}
                        onDelete={() => handleDeleteItem(index, 'cc')}
                        size="small"
                        sx={{ mr: '5px' }}
                      />
                    ))}
                  </Box>
                ),
              }}
            />

            <Typography fontSize={12} sx={{ marginTop: '5px' }}>
              {t('reportsEmailCCText')}
            </Typography>
          </Grid>
        </Grid>

        <Box sx={reportsEmailStyles.editorWrapper}>
          <Editor
            onInit={(_evt, editor) => (editorRef.current = editor)}
            initialValue=""
            apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
            init={reportsEmailStyles.editor}
          />
        </Box>

        <Box sx={reportsEmailStyles.preAttached}>
          <Typography>{t('reportsEmailPreAttached')}</Typography>

          <TextField
            disabled
            size="small"
            sx={{
              bgcolor: theme.palette.action.focus,
              ...reportsEmailStyles.preAttachedText,
            }}
            value={`${patient?.lastName} ${patient?.firstName}_${moment(
              report?.appointmentDate
            ).format('LL')}.pdf`}
            fullWidth
          />
        </Box>

        <Box>
          <Typography>{t('reportsEmailAdditional')}</Typography>

          <Box sx={reportsEmailStyles.additional}>
            {attachments.map((attachment, index) => {
              return (
                <Box
                  sx={reportsEmailStyles.additionalFiles}
                  key={attachment.id}
                >
                  <TextField
                    name="attachments"
                    variant="outlined"
                    onChange={(event) => handleFileChange(index, event)}
                    type="file"
                    InputLabelProps={{ shrink: true }}
                    size="small"
                  />

                  <IconButton onClick={() => handleDeleteFile(attachment.id)}>
                    <DeleteOutline color="error" />
                  </IconButton>
                </Box>
              );
            })}
          </Box>

          <Button
            startIcon={<AddCircleOutline />}
            size="small"
            variant="outlined"
            onClick={handleAddFile}
          >
            {t('reportsEditAddAttachment')}
          </Button>
        </Box>
      </Box>
    </Layout.Main>
  );
};

export default ReportsEmail;
