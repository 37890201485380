import React from 'react';
import { Button, Box, Typography } from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { object, string, array } from 'yup';
import { useDoctorsFormStyles } from './styles';
import GeneralInformation from './GeneralInformation';
import Address from './Address';
import { useLayout } from '../../contexts/LayoutContext';
import { toggleToast } from '../../utils/helpers/toast';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { BiCheckDouble } from 'react-icons/bi';

const DoctorsForm = ({ doctor, popup, handleCloseNewDoctorModal }) => {
  const { t } = useTranslation();
  const { doctors, setDoctors } = useLayout();
  const navigate = useNavigate();
  const doctorsFormStyles = useDoctorsFormStyles();
  const validationSchema = object().shape({
    firstName: string()
      .min(2, t('doctorsFormMinimumFieldError'))
      .required(t('doctorsFormRequiredFieldError')),
    lastName: string()
      .min(2, t('doctorsFormMinimumFieldError'))
      .required(t('doctorsFormRequiredFieldError')),
    addresses: array().min(1, t('patientsFormRequiredFieldError')),
    contacts: array().min(1, t('patientsFormRequiredFieldError')),
    sex: string().required(t('doctorsFormRequiredFieldError')),
  });
  const isExistingDoctor = !!doctor?.id;
  const formik = useFormik({
    initialValues: {
      id: doctor?.id || uuidv4(),
      firstName: doctor?.firstName || '',
      middleName: doctor?.middleName || '',
      lastName: doctor?.lastName || '',
      sex: doctor?.sex || '',
      preferredLanguage: doctor?.preferredLanguage || '',
      dateOfBirth: moment(doctor?.dateOfBirth) || moment(),
      addresses: doctor?.addresses || [],
      contacts: doctor?.contacts || [],
    },
    validationSchema,
    onSubmit: () => {
      if (isExistingDoctor) {
        const clonedDoctors = [...doctors];
        const existingDoctorIndex = clonedDoctors.findIndex(
          ({ id }) => id === doctor?.id
        );

        if (existingDoctorIndex !== -1) {
          clonedDoctors.splice(existingDoctorIndex, 1);
          clonedDoctors.splice(existingDoctorIndex, 0, values);
        }

        setDoctors(clonedDoctors);
        toggleToast('success', t('doctorsFormEditedSuccessfully'));
      } else {
        setDoctors([values, ...doctors]);
        toggleToast('success', t('doctorsFormAddedSuccessfully'));
      }

      if (popup) {
        handleCloseNewDoctorModal();
      } else {
        navigate('/doctors');
      }
    },
  });
  const { values, handleSubmit } = formik;

  return (
    <Box>
      <Typography sx={doctorsFormStyles.requiredText}>
        * {t('doctorsFormRequiredFieldText')}
      </Typography>

      <form onSubmit={(event) => event?.preventDefault()}>
        <GeneralInformation formik={formik} />
        <Address formik={formik} />
        <Button
          variant="contained"
          sx={doctorsFormStyles.submitButton}
          size="large"
          onClick={handleSubmit}
          startIcon={<BiCheckDouble />}
        >
          {t('doctorsFormSave')}
        </Button>
      </form>
    </Box>
  );
};

export default DoctorsForm;
