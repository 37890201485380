import React from 'react';
import { Backdrop, Fade, Modal } from '@mui/material';

const ModalOverlay = ({ isModalOpen, handleCloseModal, children, style }) => {
  return (
    <Modal
      open={isModalOpen}
      onClose={handleCloseModal}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 300,
        },
      }}
    >
      {<Fade in={isModalOpen}>{children}</Fade>}
    </Modal>
  );
};

export default ModalOverlay;
