import moment from 'moment';

export const transformParams = () => {
  const queryString = window.location.search;
  const urlSearchParams = new URLSearchParams(queryString);
  const params = {};
  let transformedObj = {};
  let transformedParams = [];

  for (const [key, value] of urlSearchParams) {
    params[key] = value;
  }

  for (let key in params) {
    if (Object.hasOwnProperty.call(params, key)) {
      let transformedKey = key
        .split(/(?=[A-Z])/)
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(' ');
      transformedObj[transformedKey] = params[key];
    }
  }

  for (let key in transformedObj) {
    const param = Object.keys(params).find(
      (k) => transformedObj[key] === params[k]
    );

    if (Object.hasOwnProperty.call(transformedObj, key)) {
      transformedParams.push({
        title: key,
        value: transformedObj[key],
        param,
      });
    }
  }

  return transformedParams;
};

export const capitalizeFirstLetters = (text = '') => {
  return text?.replace(/\b\w/g, (match) => {
    return match.toUpperCase();
  });
};

export const compare = (item1, item2) => {
  if (typeof item1 !== 'object' || typeof item2 !== 'object') {
    return item1 === item2; // Compare non-object types using ===
  }

  const keys1 = Object.keys(item1);
  const keys2 = Object.keys(item2);
  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (!compare(item1[key], item2[key])) {
      return false;
    }
  }

  return true;
};

export const transformAttributeValue = (attribute, currentLanguage) => {
  if (
    !attribute?.value &&
    !attribute?.type === 'date' &&
    !attribute?.type === 'switch'
  ) {
    return '';
  }
  const { value, type } = attribute;

  if (type === 'multi-select') {
    return value.map((item) => item?.name[currentLanguage]).join(', ');
  }
  if (type === 'date') {
    return moment(value?.$d).format('LL');
  }
  if (typeof value === 'string') {
    return value;
  }
  if (typeof value === 'boolean' || type === 'switch') {
    return (!!value).toString().toUpperCase();
  }
  if (Array.isArray(value)) {
    return value.join(', ');
  }

  return '';
};

export const hasInvalidAttributeValue = (attribute) =>
  !attribute.hasOwnProperty('value') ||
  attribute.value === undefined ||
  attribute.value === null ||
  (Array.isArray(attribute.value) && attribute.value.length === 0) ||
  (typeof attribute.value === 'string' && attribute.value.trim() === '');
