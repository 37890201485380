import React from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { usersFilterFormStyles } from './styles';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { USERS_ACCOUNT_TYPE } from '../../../utils/users';
import { capitalizeFirstLetters } from '../../../utils/helpers';
import { MdOutlinePlaylistRemove } from 'react-icons/md';
import { LuFilter } from 'react-icons/lu';

const UsersFilterForm = ({ onClose, handleFilter, values, handleChange }) => {
  const { t } = useTranslation();

  const handleReset = () => {
    handleChange('name')('');
    handleChange('email')('');
    handleChange('type')('');
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={4}>
        <TextField
          id="name"
          name="name"
          label={t('usersSearchName')}
          fullWidth
          variant="outlined"
          value={values.name}
          onChange={handleChange}
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <TextField
          id="email"
          name="email"
          label={t('usersSearchEmail')}
          fullWidth
          variant="outlined"
          value={values.email}
          onChange={handleChange}
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <FormControl fullWidth>
          <InputLabel id="type">{t('usersSearchType')}</InputLabel>

          <Select
            labelId="type"
            id="type"
            name="type"
            value={values.type}
            onChange={handleChange}
            label={t('usersSearchType')}
          >
            {USERS_ACCOUNT_TYPE.map((item) => {
              return (
                <MenuItem
                  key={item.value}
                  value={capitalizeFirstLetters(item.value)}
                >
                  {t(item.name)}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={12}>
        <Box sx={usersFilterFormStyles.form}>
          <Button variant="text" onClick={onClose}>
            {t('usersSearchCancel')}
          </Button>
          <Button
            startIcon={<MdOutlinePlaylistRemove />}
            variant="outlined"
            onClick={handleReset}
          >
            {t('usersSearchClear')}
          </Button>
          <Button
            startIcon={<LuFilter />}
            variant="contained"
            onClick={handleFilter}
          >
            {t('usersSearchFilter')}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default UsersFilterForm;
