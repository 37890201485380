import React, { useEffect } from 'react';
import {
  Grid,
  TextField,
  Button,
  Box,
  FormControlLabel,
  Switch,
  IconButton,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useAddAddressModalStyles } from './styles';
import { Close } from '@mui/icons-material';
import ModalOverlay from '../ModalOverlay';

const AddAddressModal = ({
  isAddAddressModalOpen,
  selectedAddress,
  addresses,
  handleCloseAddAddressModal,
  handleUpdateAddress,
}) => {
  const { t } = useTranslation();
  const addAddressModalStyles = useAddAddressModalStyles();
  const { values, handleChange, resetForm, setFieldValue } = useFormik({
    initialValues: {
      addressLine: selectedAddress?.addressLine || '',
      city: selectedAddress?.city || '',
      province: selectedAddress?.province || '',
      zipCode: selectedAddress?.zipCode || '',
      primary: selectedAddress?.primary || false,
    },
  });
  const index = addresses.findIndex((item) => item.id === selectedAddress?.id);

  const handleSaveAddress = () => {
    let updatedAddresses = [...addresses];

    if (updatedAddresses.length && values.primary) {
      updatedAddresses = updatedAddresses.map((address) => {
        return {
          ...address,
          primary: false,
        };
      });
    }

    if (!updatedAddresses.length || index === -1) {
      handleUpdateAddress([
        ...updatedAddresses,
        {
          id: selectedAddress?.id,
          addressLine: values.addressLine,
          city: values.city,
          province: values.province,
          zipCode: values.zipCode,
          primary: !addresses?.length ? true : values.primary,
        },
      ]);
    } else if (index !== -1) {
      updatedAddresses[index] = {
        id: selectedAddress.id,
        addressLine: values.addressLine,
        city: values.city,
        province: values.province,
        zipCode: values.zipCode,
        primary: !addresses?.length ? true : values.primary,
      };

      handleUpdateAddress(updatedAddresses);
    }

    resetForm();
    handleCloseAddAddressModal();
  };

  useEffect(() => {
    if (selectedAddress) {
      setFieldValue('addressLine', selectedAddress?.addressLine || '');
      setFieldValue('city', selectedAddress?.city || '');
      setFieldValue('province', selectedAddress?.province || '');
      setFieldValue('zipCode', selectedAddress?.zipCode || '');
      setFieldValue('primary', !!selectedAddress?.primary || false);
    }
  }, [selectedAddress, setFieldValue]);

  return (
    <ModalOverlay
      handleCloseModal={handleCloseAddAddressModal}
      isModalOpen={isAddAddressModalOpen}
    >
      <Box sx={addAddressModalStyles.modal}>
        <IconButton
          onClick={handleCloseAddAddressModal}
          sx={addAddressModalStyles.closeButton}
          disableRipple
        >
          <Close />
        </IconButton>
        <Box>
          <Grid container spacing={3} marginBottom={'24px'}>
            <Grid item xs={12}>
              <TextField
                id={`addressLine`}
                name={`addressLine`}
                label={t('addAddressModalAddressLine')}
                fullWidth
                variant="outlined"
                onChange={handleChange}
                value={values.addressLine}
                required
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                id={`city`}
                name={`city`}
                label={t('addAddressModalCity')}
                fullWidth
                variant="outlined"
                onChange={handleChange}
                value={values.city}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                id={`province`}
                name={`province`}
                label={'Province'}
                fullWidth
                variant="outlined"
                onChange={handleChange}
                value={values.province}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                id={`zipCode`}
                name={`zipCode`}
                label={t('addAddressModalZipCode')}
                fullWidth
                variant="outlined"
                onChange={handleChange}
                value={values.zipCode}
              />
            </Grid>

            <Grid item xs={12} md={12} sx={addAddressModalStyles.switch}>
              <FormControlLabel
                control={
                  <Switch
                    disabled={selectedAddress?.primary}
                    checked={values.primary}
                    onChange={() => setFieldValue('primary', !values.primary)}
                  />
                }
                label={t('addAddressModalPrimaryAddress')}
              />
              {selectedAddress?.primary ? (
                <Typography variant="caption">
                  {t('addAddressModalDisclaimer')}
                </Typography>
              ) : null}
            </Grid>
          </Grid>

          <Button
            variant="contained"
            size="large"
            onClick={handleSaveAddress}
            disabled={!values.addressLine.length}
          >
            {t('addAddressModalSaveBtn')}
          </Button>
        </Box>
      </Box>
    </ModalOverlay>
  );
};

export default AddAddressModal;
