import React, { useEffect } from 'react';
import * as Layout from '../../../layouts';
import DoctorsForm from '../../../components/DoctorsForm';
import { useTranslation } from 'react-i18next';
import { useLayout } from '../../../contexts/LayoutContext';

const DoctorsAdd = () => {
  const { t } = useTranslation();
  const { setPageTitle } = useLayout();

  useEffect(() => {
    setPageTitle(t('doctorsAddHeading'));
  });

  return (
    <Layout.Main>
      <DoctorsForm />
    </Layout.Main>
  );
};

export default DoctorsAdd;
