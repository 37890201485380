import React from 'react';
import AttributeTemplate from '.';
import { TextField } from '@mui/material';
import { useAttributeTypeStyles } from '../styles';

const TextBoxAttribute = ({
  attributeLabel,
  attributeValue,
  setAttributeValue,
}) => {
  const attributeTypeStyles = useAttributeTypeStyles();

  return (
    <AttributeTemplate attributeLabel={attributeLabel}>
      <TextField
        size="large"
        sx={attributeTypeStyles.sectionAttributeTextFields}
        multiline
        rows={8}
        value={attributeValue}
        onChange={({ target }) => setAttributeValue(target.value)}
      />
    </AttributeTemplate>
  );
};

export default TextBoxAttribute;
