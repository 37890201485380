import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useTreatmentPlanStyles } from './styles';
import { DeleteOutline, FileCopy } from '@mui/icons-material';
import { useFormik } from 'formik';
import {
  TREATMENT_PLAN_TIME_OPTIONS,
  TREATMENT_PLAN_RESPONSIBILITY_OPTIONS,
} from '../../../../utils/patients';
import { useLayout } from '../../../../contexts/LayoutContext';
import { v4 as uuidv4 } from 'uuid';

const Proceeding = ({ setProceedings, proceedings, proceeding }) => {
  const { t } = useTranslation();
  const { currentLanguage, templates } = useLayout();
  const treatmentPlanStyles = useTreatmentPlanStyles();
  const { values, handleChange } = useFormik({
    initialValues: {
      templateId: proceeding.templateId || '',
      region: proceeding.region || '',
      time: proceeding.time || '',
      responsible: proceeding.responsible || '',
      price: proceeding.price || '',
      isActive: proceeding.isActive || true,
      selectedRegions: proceeding.selectedRegions || [],
    },
  });
  const template = templates.find((template) => {
    return template.id === values.templateId;
  });
  const currentIndex = proceedings.findIndex(
    (item) => item.id === proceeding?.id
  );

  const handleCloneProceeding = (index) => {
    let clonedProceedings = [...proceedings];
    const copiedProceeding = {
      ...proceeding,
      id: uuidv4(),
      selectedRegions: values.selectedRegions,
      templateId: values.templateId,
      region: values.region,
      time: values.time,
      responsible: values.responsible,
      price: values.price,
      isActive: values.isActive,
    };

    clonedProceedings.splice(index + 1, 0, copiedProceeding);
    setProceedings(clonedProceedings);
  };

  const handleDeleteProceeding = () => {
    const clonedProceedings = [...proceedings].filter((item) => {
      return item.id !== proceeding.id;
    });

    setProceedings(clonedProceedings);
  };

  useEffect(() => {
    const updatedProceedings = [...proceedings];

    updatedProceedings[currentIndex] = {
      ...updatedProceedings[currentIndex],
      selectedRegions: values.selectedRegions,
      templateId: values.templateId,
      region: values.region,
      time: values.time,
      responsible: values.responsible,
      price: values.price,
      isActive: values.isActive,
    };

    setProceedings(updatedProceedings);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values.fees,
    values.isActive,
    values.price,
    values.region,
    values.responsible,
    values.selectedRegions,
    values.templateId,
    values.time,
  ]);

  return (
    <Box sx={treatmentPlanStyles.proceeding}>
      <Box width={'4%'}>
        {template && (
          <Box
            sx={{
              ...treatmentPlanStyles.templateColor,
              bgcolor: template.theme,
            }}
          >
            <Typography>{template.abbreviation[currentLanguage]}</Typography>
          </Box>
        )}
      </Box>
      <Box width={'15%'}>
        <FormControl fullWidth>
          <Select
            size="small"
            id="templateId"
            onChange={handleChange}
            value={values.templateId}
            name="templateId"
            fullWidth
          >
            {templates.map((template) => {
              return (
                <MenuItem key={template.id} value={template.id}>
                  {template.name[currentLanguage]}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
      {/* <Box width={'13%'}>
        <TextField
          size="small"
          name="region"
          fullWidth
          onChange={handleChange}
          value={values.region}
        />
        <Box sx={treatmentPlanStyles.regions}>
          {regions.map((region) => {
            const isSelected = values?.selectedRegions?.includes(region);

            return (
              <IconButton
                key={region}
                onClick={() => handleUpdateRegion(region)}
                sx={{
                  ...treatmentPlanStyles.regionButton,
                  bgcolor: isSelected ? '#fff38a' : theme.palette.action.focus,
                }}
                size="small"
              >
                {region}
              </IconButton>
            );
          })}
        </Box>
      </Box> */}
      <Box width={'10%'}>
        <FormControl fullWidth>
          <Select
            size="small"
            id="time"
            onChange={handleChange}
            value={values.time}
            name="time"
            fullWidth
          >
            {TREATMENT_PLAN_TIME_OPTIONS.map((time) => {
              return (
                <MenuItem key={time.value} value={time.value}>
                  {t(time.name)}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
      <Box width={'10%'}>
        <FormControl fullWidth>
          <Select
            size="small"
            id="responsible"
            onChange={handleChange}
            value={values.responsible}
            name="responsible"
            fullWidth
          >
            {TREATMENT_PLAN_RESPONSIBILITY_OPTIONS.map((option) => {
              return (
                <MenuItem key={option.value} value={option.value}>
                  {t(option.name)}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
      <Box width={'10%'}>
        <TextField
          size="small"
          name="price"
          fullWidth
          onChange={handleChange}
          value={values.price}
          type="number"
        />
      </Box>
      <Box width={'2%'}>
        <IconButton onClick={() => handleCloneProceeding(currentIndex)}>
          <FileCopy color="primary" />
        </IconButton>
      </Box>
      <Box width={'2%'}>
        <IconButton onClick={handleDeleteProceeding}>
          <DeleteOutline color="error" />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Proceeding;
