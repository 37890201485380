import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Box, Grid, Chip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { DeleteOutline, FilterAlt } from '@mui/icons-material';
import ReportsFilterForm from './ReportsFilterForm';
import * as Layout from '../../../layouts';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import DeleteModal from '../../../components/DeleteModal';
import { useReportsSearchStyles } from './styles';
import CustomFilterModal from '../../../components/CustomFilterModal';
import { useFormik } from 'formik';
import { transformParams } from '../../../utils/helpers';
import { REPORTS_FILTER_CHIP_TRANSLATIONS } from '../../../utils/reports';
import { useLayout } from '../../../contexts/LayoutContext';
import { useReportsSearch } from './useReportsSearch';

export const ReportsSearchTable = ({ patientReports }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const reportsSearchStyles = useReportsSearchStyles();
  const { reportsSearchColumns } = useReportsSearch();
  const { doctors, reports, patients } = useLayout();
  const iframeRef = useRef(null);
  const [isChipDeleted, setIsChipDeleted] = useState(false);
  const [activeSearchParams, setActiveSearchParams] = useState([]);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [filteredReports, setFilteredReports] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const queryFirstName = searchParams.get('firstName');
  const queryLastName = searchParams.get('lastName');
  const queryAppointmentDate = searchParams.get('appointmentDate');
  const queryStatus = searchParams.get('status');
  const queryPrimaryContact = searchParams.get('primaryContact');
  const queryDoctor = searchParams.get('doctor');
  const { values, setFieldValue, handleChange } = useFormik({
    initialValues: {
      firstName: queryFirstName || '',
      lastName: queryLastName || '',
      appointmentDate: queryAppointmentDate || null,
      status: queryStatus || '',
      primaryContact: queryPrimaryContact || '',
      doctor: queryDoctor || '',
    },
  });

  const handleOpen = () => setIsFilterModalOpen(true);
  const handleClose = () => setIsFilterModalOpen(false);
  const handleDeleteModalOpen = () => setIsDeleteModalOpen(true);
  const handleCloseDeleteModal = () => setIsDeleteModalOpen(false);

  const handleNavigate = ({ field, row }) => {
    if (field !== 'patient') {
      navigate(`/reports/show/${row.id}`);
    }
  };

  const handleDeleteSearchParam = (param) => {
    setFieldValue(param, '');
    const url = window.location.href;
    const urlObj = new URL(url);
    urlObj.searchParams.delete(param);
    const newUrl = urlObj.toString();
    window.history.replaceState({}, '', newUrl);
    setIsChipDeleted(true);
  };

  const handleFilter = () => {
    if (!patientReports) {
      const queryParams = {};
      const filterKeys = [
        'firstName',
        'lastName',
        'reportType',
        'appointmentDate',
        'region',
        'status',
        'primaryContact',
        'doctor',
      ];

      filterKeys.forEach((key) => {
        if (values[key]) {
          queryParams[key] = values[key];
        }
      });

      setSearchParams(queryParams);
      handleClose();
      setIsChipDeleted(false);
    }
  };

  useEffect(() => {
    let activeParams = transformParams();
    const doctorParamIndex = activeParams.findIndex(
      ({ param }) => param === 'doctor'
    );
    const selectedDoctor = doctors.find(
      (doc) => doc.id === activeParams[doctorParamIndex]?.value
    );

    if (doctorParamIndex !== -1 && selectedDoctor) {
      const updatedDoctorParam = {
        ...activeParams[doctorParamIndex],
        value: `${selectedDoctor?.lastName} ${selectedDoctor?.firstName}`,
      };

      activeParams = [
        ...activeParams.slice(0, doctorParamIndex),
        updatedDoctorParam,
        ...activeParams.slice(doctorParamIndex + 1),
      ];
    }

    setActiveSearchParams(activeParams);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href]);

  useEffect(() => {
    handleFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChipDeleted]);

  useEffect(() => {
    let filteredList = patientReports || reports;

    if (queryFirstName)
      filteredList = filteredList.filter((item) => {
        const patient = patients.find(
          (patient) => patient.id === item.patientId
        );

        return patient?.firstName
          ?.toUpperCase()
          .startsWith(queryFirstName.toUpperCase());
      });

    if (queryLastName)
      filteredList = filteredList.filter((item) => {
        const patient = patients.find(
          (patient) => patient.id === item.patientId
        );

        return patient?.lastName
          .toUpperCase()
          .startsWith(queryLastName.toUpperCase());
      });

    if (queryAppointmentDate)
      filteredList = filteredList.filter((item) => {
        return (
          moment(item.appointmentDate).format('L') ===
          moment(queryAppointmentDate).format('L')
        );
      });

    if (queryStatus)
      filteredList = filteredList.filter((item) => {
        const statusTranslated =
          item?.status === 'in-progress'
            ? 'reportsSearchInProgress'
            : item?.status === 'published'
            ? 'reportsSearchPublished'
            : item?.status === 'sent-by-email'
            ? 'reportsSearchSentByEmail'
            : '';

        return t(statusTranslated) === queryStatus;
      });

    if (queryPrimaryContact)
      filteredList = filteredList.filter((item) => {
        const patient = patients.find(
          (patient) => patient.id === item.patientId
        );
        const {
          type: { value: primaryContact },
        } = patient?.contacts?.find((contact) => contact.primary);

        return primaryContact === queryPrimaryContact;
      });

    if (queryDoctor)
      filteredList = filteredList.filter((item) => {
        return item.assignedDoctors.includes(queryDoctor);
      });

    setFilteredReports(filteredList);
  }, [
    queryAppointmentDate,
    queryDoctor,
    queryFirstName,
    queryLastName,
    queryPrimaryContact,
    queryStatus,
    reports,
    t,
    patients,
    patientReports,
  ]);

  return (
    <>
      <Grid container sx={reportsSearchStyles.header}>
        <CustomFilterModal
          isFilterModalOpen={isFilterModalOpen}
          handleClose={handleClose}
          modalTitle={t('reportsFilterHeading')}
        >
          <ReportsFilterForm
            onClose={handleClose}
            values={values}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
            handleFilter={handleFilter}
          />
        </CustomFilterModal>

        <DeleteModal
          isDeleteModalOpen={isDeleteModalOpen}
          handleCloseDeleteModal={handleCloseDeleteModal}
          handleConfirm={handleCloseDeleteModal}
          isProtected
        />

        <Box sx={reportsSearchStyles.headerContent}>
          <Box sx={reportsSearchStyles.searchParams}>
            {activeSearchParams?.map(({ param, title, value }) => {
              return (
                <Chip
                  key={title}
                  label={`${t(
                    REPORTS_FILTER_CHIP_TRANSLATIONS[param]
                  )}: ${value}`}
                  onDelete={() => handleDeleteSearchParam(param)}
                  sx={reportsSearchStyles.searchParamsItem}
                />
              );
            })}
          </Box>
          {selectedRows.length ? (
            <Button
              variant="contained"
              sx={reportsSearchStyles.deleteButton}
              color="error"
              onClick={handleDeleteModalOpen}
              startIcon={<DeleteOutline />}
            >
              {t('reportsSearchDeleteButton')} {selectedRows.length}
              {t('reportsSearchDeleteButtonText')}
            </Button>
          ) : null}

          {!patientReports ? (
            <Button
              startIcon={<FilterAlt />}
              variant="text"
              onClick={handleOpen}
            >
              {t('reportsSearchFilter')}
            </Button>
          ) : null}
        </Box>
      </Grid>

      <DataGrid
        autoHeight
        sx={{ maxHeight: '80vh', cursor: 'pointer' }}
        rows={filteredReports}
        columns={reportsSearchColumns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[10]}
        disableRowSelectionOnClick
        onCellClick={handleNavigate}
        onRowSelectionModelChange={(selectedRowsArray) => {
          setSelectedRows(selectedRowsArray);
        }}
      />

      <iframe
        ref={iframeRef}
        style={reportsSearchStyles.iFrame}
        title="title"
      />
    </>
  );
};

const ReportsSearch = () => {
  return (
    <Layout.Main>
      <ReportsSearchTable />
    </Layout.Main>
  );
};

export default ReportsSearch;
