import React from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useTranslation } from 'react-i18next';
import { doctorsFilterFormStyles } from './styles';
import { MdOutlinePlaylistRemove } from 'react-icons/md';
import { LuFilter } from 'react-icons/lu';

const DoctorsFilterForm = ({ onClose, handleFilter, values, handleChange }) => {
  const { t } = useTranslation();

  const handleReset = () => {
    handleChange('firstName')('');
    handleChange('lastName')('');
    handleChange('address')('');
    handleChange('patients')('');
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <TextField
            id="firstName"
            name="firstName"
            label={t('doctorsFilterFirstName')}
            fullWidth
            variant="outlined"
            value={values.firstName}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            id="lastName"
            name="lastName"
            label={t('doctorsFilterLastName')}
            fullWidth
            variant="outlined"
            value={values.lastName}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            id="address"
            name="address"
            label={t('doctorsSearchAddress')}
            fullWidth
            variant="outlined"
            value={values.address}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            id="patients"
            name="patients"
            label={t('doctorsSearchPatientsCount')}
            fullWidth
            variant="outlined"
            value={values.patients}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Box sx={doctorsFilterFormStyles.form}>
            <Button variant="text" onClick={onClose}>
              {t('doctorsFilterCancel')}
            </Button>
            <Button
              startIcon={<MdOutlinePlaylistRemove />}
              variant="outlined"
              onClick={handleReset}
            >
              {t('doctorsFilterClear')}
            </Button>
            <Button
              startIcon={<LuFilter />}
              variant="contained"
              onClick={handleFilter}
            >
              {t('doctorsSearchFilter')}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
};

export default DoctorsFilterForm;
