import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { usePatientsSearchStyles } from './styles';
import { useLayout } from '../../../contexts/LayoutContext';

export const usePatientsSearch = () => {
  const { t } = useTranslation();
  const { doctors } = useLayout();
  const patientsSearchStyles = usePatientsSearchStyles();

  const patientsSearchColumns = [
    {
      field: 'fullName',
      headerName: t('patientsSearchName'),
      flex: 1,
      minWidth: 150,
      renderCell: ({ row: { id, lastName, firstName } }) => (
        <Link style={patientsSearchStyles.link} to={`${id}`}>
          <Typography sx={patientsSearchStyles.patientName}>
            {lastName}, {firstName}
          </Typography>
        </Link>
      ),
      valueGetter: ({ row: { lastName, firstName } }) => {
        return `${lastName} ${firstName}`;
      },
    },
    {
      field: 'addresses',
      flex: 1.5,
      minWidth: 200,
      headerName: t('patientsSearchAddress'),
      valueGetter: ({ row: { addresses } }) => {
        return addresses?.find(({ primary }) => primary)?.addressLine;
      },
    },
    {
      field: 'contact',
      flex: 1,
      minWidth: 150,
      headerName: t('patientsSearchContact'),
      valueGetter: ({ row: { contacts } }) => {
        return contacts?.find(({ primary }) => primary)?.value;
      },
    },
    {
      field: 'office',
      flex: 0.75,
      minWidth: 100,
      headerName: t('patientsSearchOffice'),
    },
    {
      field: 'doctors',
      flex: 1.5,
      minWidth: 150,
      headerName: t('patientsSearchDoctor'),
      valueGetter: ({ row }) => {
        const matchedDoctors = doctors.filter((doctor) =>
          row?.doctors.includes(doctor?.id)
        );
        return matchedDoctors
          ?.map(({ lastName, firstName }) => `${lastName} ${firstName}`)
          .join(', ');
      },
    },
  ];

  return { patientsSearchColumns };
};
