import React from 'react';
import { useAttributeTypeStyles } from '../styles';
import AttributeTemplate from '.';
import { MenuItem, Select } from '@mui/material';
import { useLayout } from '../../../contexts/LayoutContext';

const ListAttribute = ({
  attributeLabel,
  attributeValue,
  setAttributeValue,
  attribute,
}) => {
  const attributeTypeStyles = useAttributeTypeStyles();
  const { currentLanguage } = useLayout();

  return (
    <AttributeTemplate attributeLabel={attributeLabel}>
      <Select
        fullWidth
        size="small"
        sx={attributeTypeStyles.sectionAttributeTextFields}
        defaultValue={''}
        value={attributeValue}
        onChange={({ target }) => setAttributeValue(target.value)}
      >
        {attribute?.options.map((option) => {
          return (
            <MenuItem key={option.id} value={option?.name[currentLanguage]}>
              {option?.name[currentLanguage]}
            </MenuItem>
          );
        })}
      </Select>
    </AttributeTemplate>
  );
};

export default ListAttribute;
