import React, { useEffect, useState } from 'react';
import * as Layout from '../../../layouts';
import { Box } from '@mui/material';
import { useLayout } from '../../../contexts/LayoutContext';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { useFormik } from 'formik';
import HeaderButtons from './HeaderButtons';
import InputFields from './InputFields';
import { v4 as uuidv4 } from 'uuid';
import ReportSections from './ReportSections';

const ReportsEdit = () => {
  const { state } = useLocation();
  const isExistingReport = state?.isExistingReport;
  const {
    setPageTitle,
    templates,
    patients,
    doctors,
    reports,
    currentLanguage,
  } = useLayout();
  const patient = patients.find((patient) => patient?.id === state?.patientId);
  const reportTemplate = isExistingReport
    ? reports.find((report) => report?.id === state?.reportId)
    : templates.find((template) => template?.id === state?.templateId);
  const [sections, setSections] = useState([]);
  const matchedDoctors = doctors?.filter((doctor) =>
    isExistingReport
      ? reportTemplate?.assignedDoctors?.includes(doctor?.id)
      : patient?.doctors?.includes(doctor?.id)
  );
  const formik = useFormik({
    initialValues: {
      id: isExistingReport ? reportTemplate?.id : uuidv4(),
      anglophoneName: reportTemplate?.name?.en || '',
      francophoneName: reportTemplate?.name?.fr || '',
      appointmentDate: new Date(),
      doctors: matchedDoctors || [],
      notes: reportTemplate?.notes || '',
      letterHeading: reportTemplate?.letterHeading
        ? reportTemplate?.letterHeading
        : currentLanguage === 'en'
        ? 'New Report'
        : 'Nouveau rapport',
      reportingDoctor: reportTemplate?.reportingDoctor || '',
    },
  });

  useEffect(() => {
    setPageTitle(
      patient?.id
        ? `${patient?.lastName || ''}, ${patient?.firstName || ''} - ${moment(
            patient?.dateOfBirth || ''
          ).format('YYYY-MM-DD')} ${patient?.preferredLanguage ? '-' : ''} ${
            patient?.preferredLanguage.toUpperCase() || ''
          }`
        : ''
    );
  }, [
    patient?.preferredLanguage,
    patient?.dateOfBirth,
    patient?.firstName,
    patient?.lastName,
    patient?.id,
    setPageTitle,
  ]);

  useEffect(() => {
    const updatedSections = reportTemplate?.sections.map((section) => {
      if (section?.type === 'medicalHistory' && !isExistingReport) {
        return {
          ...section,
          attributes: [
            ...patient?.medicalHistory?.attributes,
            ...section.attributes,
          ],
        };
      } else if (section?.type === 'clinicalExam' && !isExistingReport) {
        return {
          ...section,
          attributes: [
            ...patient?.clinicalExam?.attributes,
            ...section.attributes,
          ],
        };
      } else if (section?.type === 'treatmentPlan' && !isExistingReport) {
        return { ...section, attributes: [...patient?.treatmentPlan] };
      } else if (section?.type === 'phaseII' && !isExistingReport) {
        return { ...section, attributes: [...patient?.phaseII] };
      } else if (section?.type === 'maintenancePhase' && !isExistingReport) {
        return {
          ...section,
          attributes: [
            {
              name: {
                en: 'Maintenance Phase',
                fr: "Phase d'entretien",
              },
              isAttributeActive: true,
              value: patient?.maintenancePhase,
              type: 'textbox',
            },
          ],
        };
      } else {
        return section;
      }
    });

    setSections(updatedSections);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient?.clinicalExam?.attributes, patient?.medicalHistory?.attributes]);

  useEffect(() => {
    const updatedSections = sections?.filter(
      (section) =>
        !section?.hasOwnProperty('attributes') ||
        (Array.isArray(section.attributes) && section.attributes.length > 0)
    );

    if (JSON.stringify(updatedSections) !== JSON.stringify(sections)) {
      setSections(updatedSections);
    }
  }, [sections]);

  return (
    <Layout.Main notFound={!reportTemplate?.id}>
      <HeaderButtons
        sections={sections}
        patient={patient}
        values={formik.values}
        reportTemplate={reportTemplate}
        isExistingReport={isExistingReport}
      />
      <InputFields formik={formik} />
      <Box sx={{ marginBlock: '36px 12px' }}>
        {sections?.map((section) => {
          return (
            <ReportSections
              key={section?.id}
              section={section}
              setSections={setSections}
              sections={sections}
              patient={patient}
            />
          );
        })}
      </Box>
    </Layout.Main>
  );
};

export default ReportsEdit;
