import React, { useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';

export default function App() {
  const editorRef = useRef(null);

  return (
    <>
      <Editor
        onInit={(_evt, editor) => (editorRef.current = editor)}
        initialValue="<p>This is the initial content of the editor.</p>"
        apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
        init={{
          height: 500,
          menubar: false,
          plugins: ['image', 'lists', 'export'],
          toolbar: `bold italic | bullist numlist | image | export`,
          content_style:
            'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
        }}
      />
    </>
  );
}
