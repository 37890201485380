import React from 'react';
import { CircularProgress } from '@mui/material';

const Spinner = ({ color, size = 30 }) => {
  return (
    <CircularProgress
      size={size}
      sx={{
        color,
      }}
    />
  );
};

export default Spinner;
