import React from 'react';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useTranslation } from 'react-i18next';
import { patientsFilterFormStyles } from './styles';
import { PATIENTS_ADD_OFFICE } from '../../../utils/patients';
import { useLayout } from '../../../contexts/LayoutContext';
import { MdOutlinePlaylistRemove } from 'react-icons/md';
import { LuFilter } from 'react-icons/lu';

const PatientsFilterForm = ({
  onClose,
  handleFilter,
  values,
  handleChange,
}) => {
  const { t } = useTranslation();
  const { doctors } = useLayout();

  const handleReset = () => {
    handleChange('firstName')('');
    handleChange('lastName')('');
    handleChange('office')('');
    handleChange('doctor')('');
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <TextField
            id="firstName"
            name="firstName"
            label={t('patientsFilterFirstName')}
            fullWidth
            variant="outlined"
            value={values.firstName}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            id="lastName"
            name="lastName"
            label={t('patientsFilterLastName')}
            fullWidth
            variant="outlined"
            value={values.lastName}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel id="office">{t('patientsSearchOffice')}</InputLabel>
            <Select
              labelId="office"
              id="office"
              name="office"
              value={values.office}
              onChange={handleChange}
              label={t('patientsSearchOffice')}
            >
              {PATIENTS_ADD_OFFICE.map((item) => {
                return (
                  <MenuItem key={item.value} value={item.value}>
                    {t(item.text)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel id="doctor">{t('patientsSearchDoctor')}</InputLabel>

            <Select
              labelId="doctor"
              id="doctor"
              name="doctor"
              value={values.doctor}
              onChange={handleChange}
              label={t('patientsSearchDoctor')}
            >
              {doctors.map((item) => {
                return (
                  <MenuItem key={item.id} value={item.id}>
                    {`${item.lastName} ${item.firstName}`}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={12}>
          <Box sx={patientsFilterFormStyles.form}>
            <Button variant="text" onClick={onClose}>
              {t('patientsFilterCancel')}
            </Button>

            <Button
              startIcon={<MdOutlinePlaylistRemove />}
              variant="outlined"
              onClick={handleReset}
            >
              {t('patientsFilterClear')}
            </Button>

            <Button
              startIcon={<LuFilter />}
              variant="contained"
              onClick={handleFilter}
            >
              {t('patientsSearchFilter')}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
};

export default PatientsFilterForm;
