import React, { useEffect, useState } from 'react';
import { useLayout } from '../../../../contexts/LayoutContext';
import { useTranslation } from 'react-i18next';
import { toggleToast } from '../../../../utils/helpers/toast';
import { useNavigate } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import Appointment from './Appointment';
import { v4 as uuidv4 } from 'uuid';
import { AddCircleOutline } from '@mui/icons-material';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { BiCheckDouble } from 'react-icons/bi';

const TreatmentPlan = ({ phase, patient, title }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setPageTitle, setPatients, patients } = useLayout();
  const [appointments, setAppointments] = useState(patient[phase] || []);

  const handleAddAppointment = () => {
    const appointment = {
      id: uuidv4(),
      requestedDate: new Date(),
      bookedDate: new Date(),
      status: 'in-progress',
      completed: false,
      time: '',
      price: '',
      isActive: true,
      proceedings: [
        {
          id: uuidv4(),
          time: '',
          price: '',
          region: '',
          responsible: '',
          templateId: '',
          selectedRegions: [],
        },
      ],
    };

    setAppointments([appointment, ...appointments]);
  };

  const handleSave = () => {
    const clonedPatients = [...patients];
    const index = clonedPatients.findIndex((opt) => opt.id === patient?.id);

    if (phase === 'treatmentPlan') {
      clonedPatients[index] = {
        ...clonedPatients[index],
        treatmentPlan: appointments,
      };
    } else {
      clonedPatients[index] = {
        ...clonedPatients[index],
        phaseII: appointments,
      };
    }

    setPatients(clonedPatients);
    toggleToast('success', t('patientsAttributesEditSavedSuccessfully'));
    navigate(`/patients/${patient.id}`);
  };

  useEffect(
    () =>
      setPageTitle(
        `${t(title)} - ${patient?.lastName || ''}, ${patient?.firstName || ''}`
      ),
    [patient?.firstName, patient?.lastName, setPageTitle, t, title]
  );

  return (
    <Box>
      <Box width="100%" display="flex" justifyContent="space-between" pb="12px">
        <Box>
          <Button
            startIcon={<IoDocumentTextOutline />}
            variant="contained"
            onClick={() =>
              navigate(
                `/patients/${patient?.id}/attributes/treatment-plan/form`
              )
            }
          >
            {t('treatmentPlanViewInsurance')}
          </Button>

          <Button
            color="secondary"
            variant="contained"
            sx={{ ml: '12px' }}
            onClick={handleAddAppointment}
            startIcon={<AddCircleOutline />}
          >
            {t('treatmentPlanAddAppointment')}
          </Button>
        </Box>

        <Button
          onClick={handleSave}
          variant="contained"
          color="success"
          sx={{ color: 'white' }}
          startIcon={<BiCheckDouble />}
        >
          {t('treatmentPlanSaveAll')}
        </Button>
      </Box>

      <Box maxHeight="80vh" overflow="auto" pt="24px">
        {appointments?.map((appointment) => {
          return (
            <Appointment
              key={appointment.id}
              setAppointments={setAppointments}
              appointments={appointments}
              appointment={appointment}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default TreatmentPlan;
