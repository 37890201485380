import React from 'react';
import { Document, Page, Text, Font } from '@react-pdf/renderer';
import { reportsPdfEnvelopeStyles } from './styles';
import moment from 'moment';

const ENVELOPE_WIDTH = 684;
const ENVELOPE_HEIGHT = 306;

const ReportsPdfEnvelop = ({ report, patient }) => {
  Font.register({
    family: 'Anonymous Pro',
    fonts: [
      {
        src: 'https://fonts.gstatic.com/s/anonymouspro/v21/rP2Bp2a15UIB7Un-bOeISG3pLlw89CH98Ko.ttf',
      },
      {
        src: 'https://fonts.gstatic.com/s/anonymouspro/v21/rP2cp2a15UIB7Un-bOeISG3pFuAT0CnW7KOywKo.ttf',
        fontWeight: 700,
      },
      {
        src: 'https://fonts.gstatic.com/s/anonymouspro/v21/rP2fp2a15UIB7Un-bOeISG3pHl428AP44Kqr2Q.ttf',
        fontStyle: 'italic',
      },
      {
        src: 'https://fonts.gstatic.com/s/anonymouspro/v21/rP2ap2a15UIB7Un-bOeISG3pHl4OTCzc6IG30KqB9Q.ttf',
        fontWeight: 700,
        fontStyle: 'italic',
      },
    ],
  });

  const primaryAddress = patient?.addresses?.find((address) => address.primary);

  return report ? (
    <Document>
      <Page
        size={{ width: ENVELOPE_WIDTH, height: ENVELOPE_HEIGHT }}
        style={reportsPdfEnvelopeStyles.page}
      >
        <Text
          style={reportsPdfEnvelopeStyles.header}
        >{`${patient?.lastName}, ${patient?.firstName} ${patient?.middleName}`}</Text>
        <Text style={reportsPdfEnvelopeStyles.header}>{`${moment(
          report.appointmentDate
        ).format('LL')}`}</Text>
        <Text style={reportsPdfEnvelopeStyles.header}>{`${
          primaryAddress?.addressLine?.trimEnd() || ''
        }`}</Text>
        <Text style={reportsPdfEnvelopeStyles.header}>{`${
          primaryAddress?.province?.trimEnd() || ''
        }`}</Text>
        <Text style={reportsPdfEnvelopeStyles.header}>{`${
          primaryAddress?.city?.trimEnd() || ''
        }`}</Text>
      </Page>
    </Document>
  ) : (
    <Document>
      <Page
        size={{ width: ENVELOPE_WIDTH, height: ENVELOPE_HEIGHT }}
        style={reportsPdfEnvelopeStyles.page}
      >
        <Text style={reportsPdfEnvelopeStyles.header}>Dr. Benoit Gareau</Text>
        <Text style={reportsPdfEnvelopeStyles.header}>August 17, 2024</Text>
        <Text style={reportsPdfEnvelopeStyles.header}>
          101-5803 rue Sherbrooke E.
        </Text>
        <Text style={reportsPdfEnvelopeStyles.header}>
          Montréal, QC, H1N 1B3
        </Text>
      </Page>
    </Document>
  );
};

export default ReportsPdfEnvelop;
