export const PAGES_TITLE = [
  {
    path: '/',
    title: 'sidebarHome',
  },
  {
    path: '/patients',
    title: 'Patients',
  },
  {
    path: '/doctors',
    title: 'sidebarDoctors',
  },
  {
    path: '/reports',
    title: 'sidebarReports',
  },
  {
    path: '/custom-fields',
    title: 'sidebarAttributes',
  },
  {
    path: '/templates',
    title: 'sidebarTemplates',
  },
  // {
  //   path: '/codes',
  //   title: 'sidebarCodes',
  // },
  // {
  //   path: '/precisions',
  //   title: 'sidebarPrecisions',
  // },
  {
    path: '/users',
    title: 'sidebarUsers',
  },
];
