import React, { useEffect } from 'react';
import * as Layout from '../../../layouts';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PatientsForm from '../../../components/PatientsForm';
import { useLayout } from '../../../contexts/LayoutContext';

const PatientsEdit = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { setPageTitle, patients } = useLayout();
  const patient = patients.find((patient) => patient?.id === id);

  useEffect(() => {
    setPageTitle(
      `${t('patientsEditHeading')} - ${patient?.lastName}, ${
        patient?.firstName
      }`
    );
  }, [patient, setPageTitle, t]);

  return (
    <Layout.Main notFound={!patient?.id}>
      <PatientsForm patient={patient} />
    </Layout.Main>
  );
};

export default PatientsEdit;
