export const useHelperTextStyles = (color, style) => {
  return {
    text: {
      fontSize: '12px',
      display: 'table',
      margin: '0 auto',
      color,
      ...style,
    },
  };
};
