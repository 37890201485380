import { useTheme } from '@mui/material';

export const useAttributeTypeStyles = () => {
  const theme = useTheme();
  return {
    sectionAttribute: {
      padding: '24px 12px 24px 24px',
      display: 'flex',
      alignItems: 'center',
      borderBottom: `1px solid ${theme.palette.action.focus}`,
    },
    sectionAttributeName: {
      marginRight: '24px',
      width: '20%',
    },
    editor: {
      height: 250,
      width: '100%',
      menubar: false,
      toolbar: `bold italic underline`,
      content_style:
        'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
    },
    sectionAttributeTextFields: {
      bgcolor: 'white',
      width: '80%',
    },
    removeAll: { marginTop: '18px', width: '150px' },
  };
};
