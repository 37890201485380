import React from 'react';
import { Routes, Route } from 'react-router-dom';
import PatientsSearch from './PatientsSearch';
import PatientsView from './PatientsView';
import PatientsAdd from './PatientsAdd';
import PatientsEdit from './PatientsEdit';
import PatientsAttributes from './PatientsAttribute';

const Patients = () => {
  return (
    <Routes>
      <Route index element={<PatientsSearch />} />
      <Route path=":id" element={<PatientsView />} />
      <Route path="add" element={<PatientsAdd />} />
      <Route path="edit/:id" element={<PatientsEdit />} />
      <Route path=":id/attributes/*" element={<PatientsAttributes />} />
    </Routes>
  );
};

export default Patients;
