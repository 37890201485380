import { useTheme } from '@mui/material';

export const reportsFilterFormStyles = {
  form: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '1rem',
  },
};

export const useReportsSearchStyles = () => {
  const theme = useTheme();

  return {
    appointmentDate: { fontSize: '14px' },
    headerIcons: { marginRight: '5px', maxHeight: '36.5px' },
    headerButtons: {
      width: '36.5px',
      maxHeight: '36.5px',
      marginRight: '5px',
      fontSize: '18px',
    },
    iFrame: {
      display: 'none',
    },
    link: { textDecoration: 'none', zIndex: 999 },
    modal: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '80%',
      bgcolor: 'background.paper',
      borderRadius: 2,
      boxShadow: 24,
      p: 4,
    },
    deleteButton: { mx: '12px' },
    header: {
      display: 'flex',
      marginBottom: '16px',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
    },
    buttonIcon: { marginRight: '8px' },
    headerContent: {
      display: 'flex',
      alignItems: 'center',
      alignSelf: 'flex-end',
    },
    preferredMethod: {
      display: 'flex',
      alignItems: 'center',
      borderRadius: '16px',
      padding: '3px 8px',
      border: `1px solid ${theme.palette.primary.main}`,
    },
    preferredMethodIcon: {
      fontSize: '20px',
      marginRight: '3px',
      color: theme.palette.primary.main,
    },
    reportColor: {
      minHeight: '40px',
      minWidth: '40px',
      borderRadius: '20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: '9px',
      color: 'white',
    },
    searchParams: {
      display: 'flex',
      alignItems: 'center',
    },
    searchParamsItem: {
      mr: '5px',
      fontSize: '14px',
    },
  };
};
