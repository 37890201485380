import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLayout } from '../../../contexts/LayoutContext';
import AttributeView from '../../../components/AttributeView';
import { useParams } from 'react-router-dom';
import * as Layout from '../../../layouts';
import { Box, Typography } from '@mui/material';
import { attributesEditStyles } from './styles';

const AttributesEdit = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const {
    setPageTitle,
    currentLanguage,
    attributes,
    setAttributes,
    templates,
  } = useLayout();
  const attribute = attributes.find((attribute) => {
    return attribute.id === id;
  });

  const getRelatedTemplates = () => {
    const relatedTemplates = [];

    templates?.forEach((template) => {
      if (
        template?.sections?.some((section) =>
          section?.attributes?.some(
            (item) =>
              item?.name[currentLanguage] === attribute?.name[currentLanguage]
          )
        )
      ) {
        relatedTemplates.push(template);
      }
    });

    return relatedTemplates;
  };
  const relatedTemplates = getRelatedTemplates();

  useEffect(() => {
    setPageTitle(t('attributesEditHeading'));
  }, [setPageTitle, t]);

  return (
    <Layout.Main notFound={!attribute?.id}>
      <Box justifyContent="space-around" display="flex">
        <Box width="65%">
          <AttributeView
            attribute={attribute}
            setAttributes={setAttributes}
            attributes={attributes}
          />
        </Box>

        <Box display="flex" flexDirection="column" width="35%">
          <Typography sx={attributesEditStyles.templatesHeading}>
            {t('attributesEditRelatedTemplates')}
          </Typography>

          <Box
            display="flex"
            flexDirection="column"
            sx={attributesEditStyles.templatesWrapper}
          >
            {relatedTemplates?.map(({ id, theme, abbreviation, name }) => {
              return (
                <Box key={id} sx={attributesEditStyles.template}>
                  <Box
                    sx={{
                      ...attributesEditStyles.templateColor,
                      bgcolor: theme,
                    }}
                  >
                    <Typography>
                      {currentLanguage === 'en'
                        ? abbreviation.en
                        : abbreviation.fr}
                    </Typography>
                  </Box>

                  <Typography sx={attributesEditStyles.templateTitle}>
                    {currentLanguage === 'en' ? t(name.en) : t(name.fr)}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
    </Layout.Main>
  );
};

export default AttributesEdit;
