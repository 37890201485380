import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ReportsSearch from './ReportsSearch';
import ReportsShow from './ReportsShow';
import ReportsEdit from './ReportsEdit';
import ReportsEmail from './ReportsEmail';

const Reports = () => {
  return (
    <Routes>
      <Route index element={<ReportsSearch />} />
      <Route path="show/:reportId" element={<ReportsShow />} />
      <Route path=":id/edit" element={<ReportsEdit />} />
      <Route path="email/:reportId/original" element={<ReportsEmail />} />
    </Routes>
  );
};

export default Reports;
