import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { Button, Box, Grid, Chip, TextField } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import {
  DeleteOutline,
  AddCircleOutline,
  FilterAlt,
} from '@mui/icons-material';
import PatientsFilterForm from './PatientsFilterForm';
import * as Layout from '../../../layouts';
import { useTranslation } from 'react-i18next';
import DeleteModal from '../../../components/DeleteModal';
import { usePatientsSearchStyles } from './styles';
import CustomFilterModal from '../../../components/CustomFilterModal';
import { useFormik } from 'formik';
import { transformParams } from '../../../utils/helpers';
import { PATIENTS_FILTER_CHIP_TRANSLATIONS } from '../../../utils/patients';
import { usePatientsSearch } from './usePatientsSearch';
import { toggleToast } from '../../../utils/helpers/toast';
import { useLayout } from '../../../contexts/LayoutContext';

const PatientsSearch = () => {
  const { t } = useTranslation();
  const { patients, setPatients, doctors } = useLayout();
  const [selectedRows, setSelectedRows] = useState([]);
  const { patientsSearchColumns } = usePatientsSearch();
  const patientsSearchStyles = usePatientsSearchStyles();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isChipDeleted, setIsChipDeleted] = useState(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [activeSearchParams, setActiveSearchParams] = useState([]);
  const [filteredPatients, setFilteredPatients] = useState([]);
  const queryFirstName = searchParams.get('firstName');
  const queryLastName = searchParams.get('lastName');
  const queryOffice = searchParams.get('office');
  const queryDoctor = searchParams.get('doctor');
  const queryName = searchParams.get('name');
  const { values, setFieldValue, handleChange } = useFormik({
    initialValues: {
      firstName: queryFirstName || '',
      lastName: queryLastName || '',
      office: queryOffice || '',
      doctor: queryDoctor || '',
      name: queryName || '',
    },
  });

  const handleOpen = () => setIsFilterModalOpen(true);
  const handleClose = () => setIsFilterModalOpen(false);
  const handleDeleteModalOpen = () => setIsDeleteModalOpen(true);
  const handleCloseDeleteModal = () => setIsDeleteModalOpen(false);
  const handleFilter = (event) => {
    event?.preventDefault();
    const queryParams = {};
    const filterKeys = ['firstName', 'lastName', 'doctor', 'office', 'name'];

    filterKeys.forEach((key) => {
      if (values[key]) {
        queryParams[key] = values[key];
      }
    });

    setSearchParams(queryParams);
    handleClose();
    setIsChipDeleted(false);
  };

  const handleDeleteSearchParam = (param) => {
    setFieldValue(param, '');
    const url = window.location.href;
    const urlObj = new URL(url);
    urlObj.searchParams.delete(param);
    const newUrl = urlObj.toString();
    window.history.replaceState({}, '', newUrl);
    setIsChipDeleted(true);
  };

  const handleDeletePatients = () => {
    const filteredArray = patients.filter(
      (patient) => !selectedRows.includes(patient?.id)
    );
    setPatients(filteredArray);

    toggleToast(
      'success',
      `${selectedRows?.length} ${t('patientsSearchPatientsDeleted')}`
    );
  };

  useEffect(() => {
    let activeParams = transformParams();
    const doctorParamIndex = activeParams.findIndex(
      ({ param }) => param === 'doctor'
    );
    const selectedDoctor = doctors.find(
      (doc) => doc.id === activeParams[doctorParamIndex]?.value
    );

    if (doctorParamIndex !== -1 && selectedDoctor) {
      const updatedDoctorParam = {
        ...activeParams[doctorParamIndex],
        value: `${selectedDoctor?.lastName} ${selectedDoctor?.firstName}`,
      };

      activeParams = [
        ...activeParams.slice(0, doctorParamIndex),
        updatedDoctorParam,
        ...activeParams.slice(doctorParamIndex + 1),
      ];
    }

    setActiveSearchParams(activeParams);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href]);

  useEffect(() => {
    handleFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChipDeleted]);

  useEffect(() => {
    let filteredList = patients;

    if (queryFirstName)
      filteredList = filteredList.filter((item) =>
        item.firstName.toUpperCase().startsWith(queryFirstName.toUpperCase())
      );

    if (queryLastName)
      filteredList = filteredList.filter((item) =>
        item.lastName.toUpperCase().startsWith(queryLastName.toUpperCase())
      );

    if (queryDoctor)
      filteredList = filteredList.filter((item) =>
        item.doctors.includes(queryDoctor)
      );

    if (queryOffice)
      filteredList = filteredList.filter(
        (item) => item.office.toUpperCase() === queryOffice.toUpperCase()
      );

    if (queryName)
      filteredList = filteredList.filter(({ firstName, lastName }) => {
        return (
          `${lastName} ${firstName}`
            .toUpperCase()
            .startsWith(queryName.toUpperCase()) ||
          `${firstName} ${lastName}`
            .toUpperCase()
            .startsWith(queryName.toUpperCase()) ||
          firstName.toUpperCase().startsWith(queryName.toUpperCase()) ||
          lastName.toUpperCase().startsWith(queryName.toUpperCase())
        );
      });

    setFilteredPatients(filteredList);
  }, [
    queryFirstName,
    queryLastName,
    queryOffice,
    queryDoctor,
    queryName,
    patients,
  ]);

  return (
    <Layout.Main>
      <CustomFilterModal
        isFilterModalOpen={isFilterModalOpen}
        handleClose={handleClose}
        modalTitle={t('patientsFilterHeading')}
      >
        <PatientsFilterForm
          onClose={handleClose}
          values={values}
          handleChange={handleChange}
          handleFilter={handleFilter}
        />
      </CustomFilterModal>

      <DeleteModal
        isDeleteModalOpen={isDeleteModalOpen}
        handleCloseDeleteModal={handleCloseDeleteModal}
        handleConfirm={handleDeletePatients}
        isProtected
      />

      <Box display="flex" flexDirection="column">
        <Grid container sx={patientsSearchStyles.header}>
          <Grid item md={3}>
            <Button
              startIcon={<AddCircleOutline />}
              variant="contained"
              component={Link}
              to="add"
            >
              {t('patientsNewButton')}
            </Button>
          </Grid>

          <Box sx={patientsSearchStyles.headerContent}>
            <form
              onSubmit={handleFilter}
              style={patientsSearchStyles.searchBar}
            >
              <TextField
                fullWidth
                variant="outlined"
                onChange={handleChange}
                value={values.name}
                name="name"
                label={t('patientsSearchInputLabel')}
                size="small"
              />
            </form>
            {selectedRows.length ? (
              <Button
                variant="contained"
                sx={patientsSearchStyles.deleteButton}
                color="error"
                onClick={handleDeleteModalOpen}
                startIcon={<DeleteOutline />}
              >
                {t('patientsSearchDeleteButton')} {selectedRows.length}{' '}
                Patient(s)
              </Button>
            ) : null}

            <Button
              startIcon={<FilterAlt />}
              variant="text"
              onClick={handleOpen}
            >
              {t('patientsSearchFilter')}
            </Button>
          </Box>
        </Grid>

        <Box sx={patientsSearchStyles.searchParams}>
          {activeSearchParams?.map(({ param, title, value }) => {
            return (
              <Chip
                key={title}
                label={`${t(
                  PATIENTS_FILTER_CHIP_TRANSLATIONS[param]
                )}: ${value}`}
                onDelete={() => handleDeleteSearchParam(param)}
                sx={patientsSearchStyles.searchParamsItem}
              />
            );
          })}
        </Box>
      </Box>

      <DataGrid
        autoHeight
        rows={filteredPatients}
        columns={patientsSearchColumns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[10]}
        disableRowSelectionOnClick
        onRowSelectionModelChange={(arr) => setSelectedRows(arr)}
      />
    </Layout.Main>
  );
};

export default PatientsSearch;
