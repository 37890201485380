import React, { useState } from 'react';
import { Button, Box, Typography, IconButton } from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { object, string, array } from 'yup';
import { usePatientsFormStyles } from './styles';
import { Close } from '@mui/icons-material';
import GeneralInformation from './GeneralInformation';
import Address from './Address';
import MedicalInformation from './MedicalInformation';
import { useLayout } from '../../contexts/LayoutContext';
import { toggleToast } from '../../utils/helpers/toast';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import DoctorsForm from '../DoctorsForm';
import ModalOverlay from '../ModalOverlay';
import { BiCheckDouble } from 'react-icons/bi';

const PatientsForm = ({ patient }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { patients, setPatients, doctors } = useLayout();
  const patientsFormStyles = usePatientsFormStyles();
  const [isNewDoctorModalOpen, setIsNewDoctorModalOpen] = useState(false);
  const matchedDoctors = doctors.filter((doctor) =>
    patient?.doctors.includes(doctor?.id)
  );
  const validationSchema = object().shape({
    firstName: string()
      .min(2, t('patientsFormMinimumFieldError'))
      .required(t('patientsFormRequiredFieldError')),
    lastName: string()
      .min(2, t('patientsFormMinimumFieldError'))
      .required(t('patientsFormRequiredFieldError')),
    sex: string().required(t('patientsFormRequiredFieldError')),
    doctors: array().min(1, t('patientsFormRequiredFieldError')),
    addresses: array().min(1, t('patientsFormRequiredFieldError')),
    contacts: array().min(1, t('patientsFormRequiredFieldError')),
  });
  const isExistingPatient = !!patient?.id;
  const formik = useFormik({
    initialValues: {
      id: patient?.id || uuidv4(),
      firstName: patient?.firstName || '',
      middleName: patient?.middleName || '',
      lastName: patient?.lastName || '',
      sex: patient?.sex || '',
      preferredLanguage: patient?.preferredLanguage || '',
      dateOfBirth: moment(patient?.dateOfBirth) || moment(),
      insuranceNumber: patient?.insuranceNumber || '',
      certificateNumber: patient?.certificateNumber || '',
      insuranceProvider: patient?.insuranceProvider || { value: '' },
      policyNumber: patient?.policyNumber || '',
      referringDoctor: patient?.referringDoctor || '',
      referringDoctorAddress: patient?.referringDoctorAddress || '',
      referringDoctorContact: patient?.referringDoctorContact || '',
      memberNumber: patient?.memberNumber || '',
      cardNumber: patient?.cardNumber || '',
      office: patient?.office || '',
      doctors: matchedDoctors || [],
      addresses: patient?.addresses || [],
      contacts: patient?.contacts || [],
      medicalHistory: patient?.medicalHistory || { attributes: [] },
      clinicalExam: patient?.clinicalExam || { attributes: [] },
      treatmentPlan: patient?.treatmentPlan || [],
      phaseII: patient?.phaseII || [],
      maintenancePhase: patient?.maintenancePhase || '',
      logs: patient?.logs || { history: [] },
    },
    validationSchema,
    onSubmit: () => {
      const mappedDoctors = values.doctors?.map(({ id }) => id);

      if (isExistingPatient) {
        const clonedPatients = [...patients];
        const existingPatientIndex = clonedPatients.findIndex(
          ({ id }) => id === patient?.id
        );

        if (existingPatientIndex !== -1) {
          clonedPatients.splice(existingPatientIndex, 1);
          clonedPatients.splice(existingPatientIndex, 0, {
            ...values,
            doctors: mappedDoctors,
          });
        }

        setPatients(clonedPatients);
        toggleToast('success', t('patientsFormEditedSuccessfully'));
      } else {
        setPatients([
          {
            ...values,
            doctors: mappedDoctors,
          },
          ...patients,
        ]);
        toggleToast('success', t('patientsFormAddedSuccessfully'));
      }

      navigate('/patients');
    },
  });
  const { values, handleSubmit } = formik;

  const handleOpenNewDoctorModal = () => setIsNewDoctorModalOpen(true);
  const handleCloseNewDoctorModal = () => setIsNewDoctorModalOpen(false);

  return (
    <Box>
      <ModalOverlay
        isModalOpen={isNewDoctorModalOpen}
        handleCloseModal={handleCloseNewDoctorModal}
      >
        <Box sx={{ ...patientsFormStyles.modal, width: '80%' }}>
          <IconButton
            onClick={handleCloseNewDoctorModal}
            sx={patientsFormStyles.closeButton}
            disableRipple
          >
            <Close />
          </IconButton>
          <DoctorsForm
            popup
            handleCloseNewDoctorModal={handleCloseNewDoctorModal}
          />
        </Box>
      </ModalOverlay>

      <Box width="100%" display="flex" justifyContent="space-between" pt="12px">
        <Typography sx={patientsFormStyles.requiredText}>
          * {t('patientsFormRequiredFieldText')}
        </Typography>

        <Button
          variant="contained"
          sx={patientsFormStyles.submitButton}
          size="large"
          onClick={handleSubmit}
          startIcon={<BiCheckDouble />}
        >
          {t('patientsFormSave')}
        </Button>
      </Box>

      <Box maxHeight="80vh" overflow="auto" pt="24px">
        <form onSubmit={(event) => event?.preventDefault()}>
          <GeneralInformation formik={formik} />
          <Address formik={formik} />
          <MedicalInformation
            formik={formik}
            handleOpenNewDoctorModal={handleOpenNewDoctorModal}
          />
        </form>
      </Box>
    </Box>
  );
};

export default PatientsForm;
