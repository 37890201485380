export const useConfirmationModalStyles = () => {
  return {
    paper: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      bgcolor: 'background.paper',
      boxShadow: 24,
      width: '50%',
      flexDirection: 'column',
      alignItems: 'center',
      display: 'flex',
      background: 'white',
      paddingBlock: '32px',
      borderRadius: '10px',
    },
    divider: {
      width: '80%',
      marginTop: '12px',
    },
  };
};
