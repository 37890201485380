import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { insurancePdfStyles } from '../styles';

const EmployerInfoEn = () => {
  return (
    <View>
      <View style={insurancePdfStyles.instructionsHeading}>
        <Text style={insurancePdfStyles.instructionsHeadingText}>
          PART 2 - EMPLOYEE/PLAN MEMBER/SUBSCRIBER
        </Text>
      </View>

      <View style={insurancePdfStyles.employeeBody}>
        <View style={insurancePdfStyles.employeeBodyContent}>
          <Text style={insurancePdfStyles.smallBold}>
            1. GROUP POLICY/PLAN
            NO._____________________________DIVISION/SECTION
            NO._______________________
          </Text>

          <Text style={{ ...insurancePdfStyles.smallBold, marginTop: '10px' }}>
            EMPLOYER_______________________________________________________________________________________
          </Text>

          <Text style={{ ...insurancePdfStyles.smallBold, marginTop: '10px' }}>
            NAME OF INSURING AGENCY OR PLAN
            ______________________________________________________________
          </Text>
        </View>

        <View
          style={{
            ...insurancePdfStyles.employeeBodyContent,
            display: 'flex',
            alignItems: 'flex-end',
          }}
        >
          <Text style={insurancePdfStyles.smallBold}>
            2. YOUR NAME (PLEASE
            PRINT)_____________________________________________________________________
          </Text>

          <Text style={{ ...insurancePdfStyles.smallBold, marginTop: '10px' }}>
            YOUR CERT. NO. OR S.I.N. OR I.D.
            NO.________________________________________________________________
          </Text>

          <View
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Text
              style={{
                ...insurancePdfStyles.smallBold,
                marginTop: '10px',
              }}
            >
              YOUR DATE OF BIRTH
              _____________________________________________________________________________
            </Text>

            <Text
              style={{
                ...insurancePdfStyles.smallBold,
                marginTop: '3px',
              }}
            >
              DAY{'        '}MONTH{'        '}YEAR
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
};

export default EmployerInfoEn;
