import React, { useEffect, useRef, useState } from 'react';
import * as Layout from '../../../layouts';
import { useLayout } from '../../../contexts/LayoutContext';
import { Box, Button, IconButton } from '@mui/material';
import {
  FileDownloadOutlined,
  LocalPrintshopOutlined,
  DraftsOutlined,
  DeleteOutline,
} from '@mui/icons-material';
import { BlobProvider, PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import ReportsPdfFull from '../ReportsPdf/ReportsPdfFull';
import { useReportsShowStyles } from './styles';
import ReportsPdfEnvelop from '../ReportsPdf/ReportsPdfEnvelop';
import { useNavigate } from 'react-router-dom';
import { TbMailDown } from 'react-icons/tb';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DeleteModal from '../../../components/DeleteModal';
import { BiMailSend, BiSolidEditAlt } from 'react-icons/bi';
import moment from 'moment';
import { toggleToast } from '../../../utils/helpers/toast';

const ReportsShow = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const reportsShowStyles = useReportsShowStyles();
  const {
    setPageTitle,
    reports,
    patients,
    setReports,
    setDeletedReports,
    deletedReports,
    currentLanguage,
    doctors,
  } = useLayout();
  const iframeRef = useRef(null);
  const { reportId } = useParams();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const report = reports.find((report) => report.id === reportId);
  const patient = patients.find((patient) => patient.id === report?.patientId);

  const handleDeleteModalOpen = () => setIsDeleteModalOpen(true);
  const handleCloseDeleteModal = () => setIsDeleteModalOpen(false);
  const handleEditReport = () => {
    if (patient?.id) {
      navigate(`/reports/${reportId}/edit`, {
        state: {
          patientId: report.patientId,
          reportId,
          isExistingReport: true,
        },
      });
    }
  };
  const handleSendMail = () => {
    navigate(`/reports/email/${reportId}/original`);
  };
  const handlePrintPdf = (pdfBlob) => {
    if (pdfBlob && iframeRef.current) {
      const pdfUrl = URL.createObjectURL(pdfBlob);

      iframeRef.current.onload = () => {
        iframeRef.current.contentWindow.print();
      };

      iframeRef.current.src = pdfUrl;
    }
  };
  const handleDeleteReport = () => {
    const updatedReports = reports?.filter(({ id }) => id !== report?.id);
    const deletedReport = reports?.find(({ id }) => id === report?.id);

    setReports(updatedReports);
    setDeletedReports([deletedReport, ...deletedReports]);
    toggleToast('success', t('reportsShowDeletedSuccessfully'));
    navigate('/reports');
  };

  useEffect(() => {
    setPageTitle(
      patient?.id
        ? `${patient?.lastName || ''}, ${patient?.firstName || ''}`
        : ''
    );
  }, [setPageTitle, t, patient]);

  return (
    <Layout.Main notFound={!report?.id}>
      <DeleteModal
        isDeleteModalOpen={isDeleteModalOpen}
        handleCloseDeleteModal={handleCloseDeleteModal}
        handleConfirm={handleDeleteReport}
        isProtected
      />

      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box>
          <PDFDownloadLink
            document={
              <ReportsPdfFull
                report={report}
                patient={patient}
                currentLanguage={currentLanguage}
              />
            }
            fileName={`${patient?.lastName} ${patient?.firstName} | ${moment(
              report?.appointmentDate
            ).format('LL')}`}
          >
            <IconButton sx={reportsShowStyles.headerIcons}>
              <FileDownloadOutlined />
            </IconButton>
          </PDFDownloadLink>

          <PDFDownloadLink
            document={<ReportsPdfEnvelop report={report} patient={patient} />}
            fileName={`${patient?.lastName} ${patient?.firstName} | ${moment(
              report?.appointmentDate
            ).format('LL')}`}
          >
            <IconButton sx={reportsShowStyles.headerIcons}>
              <TbMailDown />
            </IconButton>
          </PDFDownloadLink>
          <IconButton
            sx={reportsShowStyles.headerIcons}
            onClick={handleSendMail}
            disabled={!patient?.id}
          >
            <BiMailSend />
          </IconButton>
          <BlobProvider
            document={
              <ReportsPdfFull
                report={report}
                patient={patient}
                currentLanguage={currentLanguage}
              />
            }
          >
            {({ blob }) => (
              <IconButton
                sx={reportsShowStyles.headerIcons}
                onClick={() => handlePrintPdf(blob)}
              >
                <LocalPrintshopOutlined />
              </IconButton>
            )}
          </BlobProvider>
          <BlobProvider
            document={<ReportsPdfEnvelop report={report} patient={patient} />}
          >
            {({ blob }) => (
              <IconButton
                sx={reportsShowStyles.headerIcons}
                onClick={() => handlePrintPdf(blob)}
              >
                <DraftsOutlined />
              </IconButton>
            )}
          </BlobProvider>
        </Box>

        <Box>
          <Button
            variant="contained"
            sx={reportsShowStyles.deleteButton}
            color="error"
            onClick={handleDeleteModalOpen}
            disabled={!patient?.id}
            startIcon={<DeleteOutline />}
          >
            {t('reportsShowDeleteButton')}
          </Button>
          <Button
            disabled={!patient?.id}
            variant="outlined"
            onClick={handleEditReport}
            startIcon={<BiSolidEditAlt />}
          >
            {t('reportsShowEditButton')}
          </Button>
        </Box>
      </Box>

      <Box sx={reportsShowStyles.pdfWrapper}>
        <PDFViewer style={reportsShowStyles.pdf}>
          <ReportsPdfFull
            report={report}
            patient={patient}
            currentLanguage={currentLanguage}
            doctors={doctors}
          />
        </PDFViewer>
      </Box>

      <iframe ref={iframeRef} style={reportsShowStyles.iFrame} title="title" />
    </Layout.Main>
  );
};

export default ReportsShow;
