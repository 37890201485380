import React, { useState } from 'react';
import {
  Typography,
  Box,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  useTheme,
  Select,
  MenuItem,
  ListItemButton,
} from '@mui/material';
import { useLoginStyles } from './styles';
import { useFormik } from 'formik';
import { string, object } from 'yup';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import HelperText from '../../components/HelperText';
import Spinner from '../../components/Spinner';
import { useLayout } from '../../contexts/LayoutContext';
import { toggleToast } from '../../utils/helpers/toast';
import { useTranslation } from 'react-i18next';

const Login = () => {
  const theme = useTheme();
  const loginStyles = useLoginStyles();
  const { t } = useTranslation();
  const { setIsSignedIn, currentLanguage, setCurrentLanguage } = useLayout();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const validationSchema = object({
    email: string()
      .email(t('loginFormEmailInvalid'))
      .required(t('loginFormEmailRequired')),
    password: string()
      .required(t('loginFormPasswordRequired'))
      .min(6, t('loginFormPasswordMinimum')),
  });
  const { values, handleChange, errors, handleSubmit, resetForm, setErrors } =
    useFormik({
      initialValues: {
        email: '',
        password: '',
      },
      validationSchema,
      onSubmit: async () => {
        const { email } = values;
        setIsLoading(true);

        if (email === 'incorrect@affixdot.com') {
          setTimeout(() => {
            setHasError(true);
            setIsLoading(false);
          }, 1000);
        } else {
          setTimeout(() => {
            setIsSignedIn('true');
            setIsLoading(false);
            resetForm();
            toggleToast('success', t('loginFormLoginSuccessful'));
          }, 1000);
        }
      },
      validateOnChange: false,
      validateOnBlur: false,
    });

  const handleTogglePasswordVisibility = () =>
    setIsPasswordVisible(!isPasswordVisible);
  const handleSelectLanguage = (target) => {
    setCurrentLanguage(target.value);
    setErrors('');
  };

  return (
    <Box sx={loginStyles.page}>
      <ListItemButton sx={loginStyles.dropdown}>
        <Select
          value={currentLanguage}
          onChange={({ target }) => handleSelectLanguage(target)}
          sx={loginStyles.dropdownOptions}
        >
          <MenuItem value="fr">FR</MenuItem>
          <MenuItem value="en">EN</MenuItem>
        </Select>
      </ListItemButton>

      <Box style={loginStyles.formWrapper}>
        <Typography color="primary" style={loginStyles.formHeading}>
          {t('loginFormHeading')}
        </Typography>
        <form
          style={loginStyles.form}
          noValidate
          onSubmit={(e) => e.preventDefault()}
        >
          <TextField
            variant="outlined"
            required
            fullWidth
            label={t('loginFormEmail')}
            name={'email'}
            onChange={handleChange}
            value={values.email}
            style={loginStyles.textFields}
            error={!!errors.email}
            type={'email'}
            helperText={
              errors.email ? (
                <HelperText color={theme.palette.error.main}>
                  {errors.email}
                </HelperText>
              ) : (
                ''
              )
            }
            autoComplete={'Email Address'}
          />

          <TextField
            variant="outlined"
            required
            fullWidth
            label={t('loginFormPassword')}
            name={'password'}
            onChange={handleChange}
            value={values.password}
            style={loginStyles.textFields}
            error={!!errors.password}
            type={isPasswordVisible ? 'text' : 'password'}
            helperText={
              errors.password ? (
                <HelperText color={theme.palette.error.main}>
                  {errors.password}
                </HelperText>
              ) : (
                ''
              )
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleTogglePasswordVisibility}>
                    {isPasswordVisible ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            autoComplete={'Password'}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            style={loginStyles.submit}
            onClick={handleSubmit}
            size="large"
            disabled={isLoading}
          >
            <Box minWidth="100px" height="100%" className="flex-center">
              {isLoading ? <Spinner color="#fff" /> : t('loginFormButton')}
            </Box>
          </Button>

          {hasError && (
            <Box alignItems="center" display="flex" justifyContent="center">
              <Typography color={theme.palette.error.main} textAlign="center">
                {t('loginFormIncorrectDetails')}
              </Typography>
            </Box>
          )}
        </form>
      </Box>

      {/* <Copyright /> */}
    </Box>
  );
};

export default Login;
