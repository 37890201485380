export const attributesEditStyles = {
  template: {
    display: 'flex',
    alignItems: 'center',
    mb: '12px',
  },
  templateColor: {
    height: '40px',
    minWidth: '40px',
    borderRadius: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '9px',
    color: 'white',
  },
  templatesHeading: {
    fontWeight: 500,
    fontSize: '18px',
    mb: '12px',
  },
  templatesWrapper: {
    maxHeight: 'calc(100vh - 150px)',
    maxWidth: '500px',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  templateTitle: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
};
