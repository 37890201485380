export const useDeleteModalStyles = () => {
  return {
    modal: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      bgcolor: 'background.paper',
      borderRadius: 2,
      boxShadow: 24,
      p: 4,
      width: '50%',
      flexDirection: 'column',
      alignItems: 'center',
      display: 'flex',
    },
    modalButtons: {
      marginTop: '30px',
      alignSelf: 'flex-end',
    },
    modalButtonsCancel: {
      marginRight: '16px',
    },
    modalButtonsIconDelete: {
      marginRight: '8px',
    },
  };
};
