import { Typography, Box, Button } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { usePatientsViewStyles } from '../styles';
import { useNavigate } from 'react-router-dom';
import TreatmentPlanCard from './TreatmentPlanCard';
import { BiSolidEditAlt } from 'react-icons/bi';

const TreatmentPlan = ({ patient, phase, title, route }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const patientsViewStyles = usePatientsViewStyles();

  return (
    <Box>
      <Box sx={{ ...patientsViewStyles.flexBetween, marginBottom: 0 }}>
        <Typography variant="h4" fontWeight={600}>
          {t(title)}
        </Typography>

        <Button
          startIcon={<BiSolidEditAlt />}
          variant="outlined"
          onClick={() => navigate(`attributes/${route}`)}
        >
          {t('patientsViewEditButton')}
        </Button>
      </Box>

      {patient[phase]?.map((plan) => {
        return (
          <TreatmentPlanCard key={plan.id} plan={plan} patient={patient} />
        );
      })}
    </Box>
  );
};

export default TreatmentPlan;
