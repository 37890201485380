import { useTheme } from '@mui/material';

export const useDoctorsViewStyles = () => {
  const theme = useTheme();

  return {
    table: {
      marginTop: '12px',
      border: `1px solid ${theme.palette.action.focus}`,
    },
    tableCell: {
      fontWeight: 'bold',
      borderRight: `1px solid ${theme.palette.action.focus}`,
    },
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '12px',
    },
    link: {
      textDecoration: 'none',
      fontSize: '14px',
      color: theme.palette.primary.main,
      '&:hover': { color: theme.palette.info.dark },
    },
    patient: { cursor: 'pointer', color: theme.palette.primary.main },
  };
};
