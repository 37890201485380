import { useEffect, useState } from 'react';
import DeleteModal from '../../../components/DeleteModal';
import * as Layout from '../../../layouts';
import { useSearchParams, Link } from 'react-router-dom';
import { useUsersSearchStyles } from './styles';
import { Button, Box, Grid, Typography, Chip, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DataGrid } from '@mui/x-data-grid';
import {
  AddCircleOutline,
  DeleteOutline,
  FilterAlt,
} from '@mui/icons-material';
import UsersFilterForm from './UsersFilterForm';
import CustomFilterModal from '../../../components/CustomFilterModal';
import { useFormik } from 'formik';
import { transformParams } from '../../../utils/helpers';
import { USERS_FILTER_CHIP_TRANSLATIONS } from '../../../utils/users';
import { useLayout } from '../../../contexts/LayoutContext';

const UsersSearch = () => {
  const { t } = useTranslation();
  const usersSearchStyles = useUsersSearchStyles();
  const { users } = useLayout();
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeSearchParams, setActiveSearchParams] = useState([]);
  const [isChipDeleted, setIsChipDeleted] = useState(false);
  const queryName = searchParams.get('name');
  const queryEmail = searchParams.get('email');
  const queryType = searchParams.get('type');
  const { values, handleChange, setFieldValue } = useFormik({
    initialValues: {
      name: queryName || '',
      email: queryEmail || '',
      type: queryType || '',
    },
  });
  const columns = [
    {
      field: 'name',
      headerName: t('usersSearchName'),
      flex: 1,
      renderCell: ({ row: { id, name } }) => (
        <Link to={`${id}/edit`} style={usersSearchStyles.link}>
          <Typography sx={usersSearchStyles.userName}>{name}</Typography>
        </Link>
      ),
      valueGetter: ({ row: { name } }) => {
        return `${name}`;
      },
    },
    {
      field: 'email',
      headerName: t('usersSearchEmail'),
      flex: 1,
      renderCell: ({ row: { email } }) => (
        <Link
          style={usersSearchStyles.link}
          rel="noreferrer"
          target="_blank"
          to={`mailto:${email}`}
        >
          <Typography sx={usersSearchStyles.userName} fontSize={'13px'}>
            {email}
          </Typography>
        </Link>
      ),
      valueGetter: ({ row: { email } }) => {
        return `${email}`;
      },
    },
    {
      field: 'type',
      headerName: t('usersSearchType'),
      flex: 0.5,
      valueGetter: (params) => {
        return params.row.type === 'admin'
          ? t('usersSearchAdminUser')
          : t('usersSearchReportUser');
      },
    },
  ];

  const handleOpen = () => setIsFilterModalOpen(true);
  const handleClose = () => setIsFilterModalOpen(false);
  const handleDeleteModalOpen = () => setIsDeleteModalOpen(true);
  const handleCloseDeleteModal = () => setIsDeleteModalOpen(false);

  const handleFilter = (event) => {
    event?.preventDefault();
    const queryParams = {};
    const filterKeys = ['name', 'email', 'type'];

    filterKeys.forEach((key) => {
      if (values[key]) {
        queryParams[key] = values[key];
      }
    });

    setSearchParams(queryParams);
    handleClose();
    setIsChipDeleted(false);
  };

  const handleDeleteSearchParam = (param) => {
    setFieldValue(param, '');
    const url = window.location.href;
    const urlObj = new URL(url);
    const newUrl = urlObj.toString();

    urlObj.searchParams.delete(param);
    window.history.replaceState({}, '', newUrl);
    setIsChipDeleted(true);
  };

  useEffect(() => {
    const activeParams = transformParams();
    setActiveSearchParams(activeParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href]);

  useEffect(() => {
    handleFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChipDeleted]);

  useEffect(() => {
    let filteredList = users;

    if (queryName)
      filteredList = filteredList.filter((item) =>
        item.name.toUpperCase().startsWith(queryName.toUpperCase())
      );

    if (queryEmail)
      filteredList = filteredList.filter((item) =>
        item.email.toUpperCase().startsWith(queryEmail.toUpperCase())
      );

    if (queryType)
      filteredList = filteredList.filter(
        (item) => item.type.toUpperCase() === queryType.toUpperCase()
      );

    setFilteredUsers(filteredList);
  }, [queryEmail, queryName, queryType, users]);

  return (
    <Layout.Main>
      <CustomFilterModal
        isFilterModalOpen={isFilterModalOpen}
        handleClose={handleClose}
        modalTitle={t('usersSearchFilterHeading')}
      >
        <UsersFilterForm
          onClose={handleClose}
          handleFilter={handleFilter}
          values={values}
          handleChange={handleChange}
        />
      </CustomFilterModal>

      <DeleteModal
        handleCloseDeleteModal={handleCloseDeleteModal}
        isDeleteModalOpen={isDeleteModalOpen}
        handleConfirm={handleCloseDeleteModal}
        isProtected
      />

      <Grid container sx={usersSearchStyles.header}>
        <Grid item>
          <Button
            variant="contained"
            component={Link}
            to="add"
            startIcon={<AddCircleOutline />}
          >
            {t('usersNewButton')}
          </Button>
        </Grid>

        <Box sx={usersSearchStyles.headerContent}>
          <Box sx={usersSearchStyles.searchParams}>
            {activeSearchParams?.map(({ param, title, value }) => {
              return (
                <Chip
                  key={title}
                  label={`${t(
                    USERS_FILTER_CHIP_TRANSLATIONS[param]
                  )}: ${value}`}
                  onDelete={() => handleDeleteSearchParam(param)}
                  sx={usersSearchStyles.searchParamsItem}
                />
              );
            })}
          </Box>{' '}
          <form onSubmit={handleFilter} style={usersSearchStyles.searchBar}>
            <TextField
              fullWidth
              variant="outlined"
              onChange={handleChange}
              value={values.name}
              name="name"
              label={t('patientsSearchInputLabel')}
              size="small"
            />
          </form>
          {selectedRows.length ? (
            <Button
              variant="contained"
              sx={usersSearchStyles.deleteButton}
              color="error"
              onClick={handleDeleteModalOpen}
              startIcon={<DeleteOutline />}
            >
              {`${t('usersSearchDeleteButton')} ${selectedRows.length}
                ${t('usersDeleteButtonText')}`}
            </Button>
          ) : null}
          <Button
            variant="text"
            onClick={handleOpen}
            sx={usersSearchStyles.sort}
            startIcon={<FilterAlt />}
          >
            {t('usersSearchFilter')}
          </Button>
        </Box>
      </Grid>

      <DataGrid
        autoHeight
        rows={filteredUsers}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[10]}
        disableRowSelectionOnClick
        onRowSelectionModelChange={(selectedRowsArray) => {
          setSelectedRows(selectedRowsArray);
        }}
      />
    </Layout.Main>
  );
};

export default UsersSearch;
