import React from 'react';
import { Routes, Route } from 'react-router-dom';
import AttributesSearch from './AttributesSearch';
import AttributesEdit from './AttributesEdit';

const Attributes = () => {
  return (
    <Routes>
      <Route index element={<AttributesSearch />} />
      <Route path=":id/edit" element={<AttributesEdit />} />
    </Routes>
  );
};

export default Attributes;
