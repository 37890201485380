import React from 'react';
import ModalOverlay from '../ModalOverlay';
import { Box, Typography, Divider } from '@mui/material';
import { useConfirmationModalStyles } from './styles';
import CustomButton from '../CustomButton';
import { useTranslation } from 'react-i18next';

const ConfirmationModal = ({
  isModalOpen,
  handleCloseModal,
  message,
  confirmLabel,
  cancelLabel,
  handleConfirm,
  handleCancel,
}) => {
  const confirmationModalStyles = useConfirmationModalStyles();
  const { t } = useTranslation();

  return (
    <ModalOverlay handleCloseModal={handleCloseModal} isModalOpen={isModalOpen}>
      <Box style={confirmationModalStyles.paper}>
        <Typography textAlign="center">{message}</Typography>
        <Divider style={confirmationModalStyles.divider} />

        <Box mt="24px">
          <CustomButton
            style={{ marginRight: '12px' }}
            handleClick={() => handleCancel()}
            variant="text"
          >
            {cancelLabel || t('confirmationModalCancel')}
          </CustomButton>
          <CustomButton
            variant="contained"
            handleClick={handleConfirm}
            style={{ marginLeft: '12px' }}
          >
            {confirmLabel}
          </CustomButton>
        </Box>
      </Box>
    </ModalOverlay>
  );
};

export default ConfirmationModal;
