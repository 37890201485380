import { Box } from '@mui/material';
import React from 'react';
import TreatmentPlan from '.';

const PhaseI = ({ patient }) => {
  return (
    <Box>
      <TreatmentPlan
        patient={patient}
        route="treatment-plan"
        title="patientsViewTreatmentPlan"
        phase="treatmentPlan"
      />
    </Box>
  );
};

export default PhaseI;
