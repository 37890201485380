import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { insurancePdfStyles } from '../styles';

const DoctorPatientInfoFr = ({
  patientAddress,
  doctorAddress,
  patient,
  patientDoctor,
}) => {
  return (
    <View style={insurancePdfStyles.section}>
      <View style={insurancePdfStyles.infoSectionPatient}>
        <View
          style={{
            ...insurancePdfStyles.infoSectionPatientTop,
            height: '23px',
          }}
        >
          <Text style={insurancePdfStyles.infoSectionPatientTopText}>
            1re PARTIE - MÉDICIN
          </Text>
        </View>

        <View style={insurancePdfStyles.infoSectionPatientMain}>
          <Text style={insurancePdfStyles.infoSectionPatientMainText}>
            P{'\n'}A{'\n'}T{'\n'}I{'\n'}E{'\n'}N{'\n'}T{'\n'}
          </Text>

          <View>
            <Text
              style={insurancePdfStyles.pdfValue}
            >{`${patient?.lastName}, ${patient?.firstName}`}</Text>
            <Text style={insurancePdfStyles.pdfValue}>{patientAddress}</Text>
          </View>
        </View>
      </View>

      <View style={insurancePdfStyles.infoSectionPatient}>
        <View
          style={{
            ...insurancePdfStyles.infoSectionDoctorTop,
            height: '23px',
          }}
        >
          <View style={insurancePdfStyles.infoSectionDoctorTopRows}>
            <View style={insurancePdfStyles.infoSectionDoctorTopUnique}>
              <Text style={insurancePdfStyles.smallBold}>NO. UNIQUE</Text>
            </View>

            <View style={insurancePdfStyles.infoSectionDoctorTopSpec}>
              <Text style={insurancePdfStyles.smallBold}>SPÉC.</Text>
            </View>

            <View style={insurancePdfStyles.infoSectionDoctorTopOffice}>
              <Text style={insurancePdfStyles.smallBold}>
                NO DE DOSSIER DU PATIENT
              </Text>
            </View>
          </View>
        </View>

        <View style={insurancePdfStyles.infoSectionPatientMain}>
          <Text style={insurancePdfStyles.infoSectionPatientMainText}>
            D{'\n'}E{'\n'}N{'\n'}T{'\n'}I{'\n'}S{'\n'}T{'\n'}E{'\n'}
          </Text>

          <View>
            <Text
              style={insurancePdfStyles.pdfValue}
            >{`${patientDoctor?.lastName}, ${patientDoctor?.firstName}`}</Text>
            <Text style={insurancePdfStyles.pdfValue}>{doctorAddress}</Text>
            <Text
              style={{
                ...insurancePdfStyles.smallBold,
                marginTop: '2px',
              }}
            >
              TÉLÉPHONE
            </Text>
            <Text style={insurancePdfStyles.pdfValue}>
              {patientDoctor?.phone}
            </Text>
          </View>
        </View>
      </View>

      <View style={insurancePdfStyles.subscriber}>
        <View style={insurancePdfStyles.subscriberMain}>
          <Text style={insurancePdfStyles.smallBold}>
            JE CÈDE AU MÉDICIN NOMMÉ DANS LA PRÉSENTE LES INDEMNITÉS PAYABLES EN
            VERTU DE CETTE DEMANDE DE RÉGLEMENT ET JE CONSENS À CE QU’ELLES LUI
            SOIENT VERSÉES DIRECTEMENT
          </Text>
        </View>

        <Text style={insurancePdfStyles.smallBold}>
          SIGNATURE DU PARTICIPANT
        </Text>
      </View>
    </View>
  );
};

export default DoctorPatientInfoFr;
