import { Box } from '@mui/material';
import React from 'react';
import TreatmentPlan from '.';

const PhaseII = ({ patient }) => {
  return (
    <Box>
      <TreatmentPlan
        patient={patient}
        route="phaseII"
        title="patientsViewPhaseII"
        phase="phaseII"
      />
    </Box>
  );
};

export default PhaseII;
