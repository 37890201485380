import React, { useEffect, useState } from 'react';
import * as Layout from '../../../layouts';
import { Button, TextField, Grid, Box, Chip } from '@mui/material';
import { AddCircleOutline } from '@mui/icons-material';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTemplatesSearchStyles } from './styles';
import { useLayout } from '../../../contexts/LayoutContext';
import { transformParams } from '../../../utils/helpers';
import { TEMPLATES_FILTER_CHIP_TRANSLATIONS } from '../../../utils/templates';
import { v4 as uuidv4 } from 'uuid';
import MappedTemplates from './MappedTemplates';

const TemplatesSearch = () => {
  const { t } = useTranslation();
  const templatesSearchStyles = useTemplatesSearchStyles();
  const [searchParams, setSearchParams] = useSearchParams();
  const { currentLanguage, templates, setTemplates } = useLayout();
  const [isChipDeleted, setIsChipDeleted] = useState(false);
  const [filteredTemplates, setFilteredTemplates] = useState([]);
  const [activeSearchParams, setActiveSearchParams] = useState([]);
  const queryName = searchParams.get('name');
  const [searchValue, setSearchValue] = useState(queryName || '');

  const handleNewTemplate = () => {
    const newTemplate = {
      id: uuidv4(),
      name: {
        en: 'New Template',
        fr: 'Nouveau modèle',
      },
      abbreviation: {
        en: 'Ne',
        fr: 'No',
      },
      theme: '#607d8b',
      codes: [],
      precisions: [],
      reportModel: 'general',
      sections: [
        {
          id: uuidv4(),
          type: 'patientInfo',
          isSectionNameActive: true,
          name: {
            en: "Patient's Information",
            fr: 'Informations sur les patients',
          },
          options: [
            {
              id: uuidv4(),
              name: { en: 'Date of Birth', fr: 'Date de naissance' },
              type: 'dateOfBirth',
              isActive: false,
            },
            {
              id: uuidv4(),
              name: { en: 'Primary Contact', fr: 'Premier contact' },
              type: 'primaryContact',
              isActive: false,
            },
            {
              id: uuidv4(),
              name: { en: 'Doctors', fr: 'Médecins' },
              type: 'doctors',
              isActive: false,
            },
          ],
        },
        {
          id: uuidv4(),
          heading: 'templatesEditSectionName',
          name: {
            en: 'The medical history reveals',
            fr: 'Les antécédents médicaux révèlent',
          },
          isSectionNameActive: true,
          isSignatureActive: false,
          type: 'medicalHistory',
          attributes: [],
        },
      ],
    };

    setSearchValue('');
    setTemplates([newTemplate, ...templates]);
  };

  const handleDeleteSearchParam = () => {
    const param = 'name';
    const url = window.location.href;
    const urlObj = new URL(url);
    const newUrl = urlObj.toString();

    setSearchValue('');
    urlObj.searchParams.delete(param);
    window.history.replaceState({}, '', newUrl);
    setIsChipDeleted(true);
  };

  const handleFilter = (event) => {
    event?.preventDefault();
    const queryParams = {};

    if (searchValue) {
      queryParams.name = searchValue;
    }
    setSearchParams(queryParams);
    setIsChipDeleted(false);
  };

  useEffect(() => {
    const activeParams = transformParams();
    setActiveSearchParams(activeParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href]);

  useEffect(() => {
    handleFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChipDeleted]);

  useEffect(() => {
    let filteredList = templates;
    if (queryName)
      filteredList = filteredList.filter(({ name: { en, fr } }) => {
        return currentLanguage === 'en'
          ? t(en).toLowerCase().startsWith(queryName.toLowerCase())
          : t(fr).toLowerCase().startsWith(queryName.toLowerCase());
      });

    setFilteredTemplates(filteredList);
  }, [queryName, templates, t, currentLanguage]);

  return (
    <Layout.Main>
      <Grid sx={templatesSearchStyles.header}>
        <Grid item>
          <Button
            startIcon={<AddCircleOutline />}
            variant="contained"
            onClick={handleNewTemplate}
          >
            {t('templatesNewButton')}
          </Button>
        </Grid>

        <Box display="flex">
          <Box sx={templatesSearchStyles.searchParams}>
            {activeSearchParams?.map(({ param, title, value }) => {
              return (
                <Chip
                  key={title}
                  label={`${t(
                    TEMPLATES_FILTER_CHIP_TRANSLATIONS[param]
                  )}: ${value}`}
                  onDelete={() => handleDeleteSearchParam(param)}
                  sx={templatesSearchStyles.searchParamsItem}
                />
              );
            })}
          </Box>

          <form
            onSubmit={(event) => handleFilter(event)}
            style={templatesSearchStyles.searchBar}
          >
            <TextField
              fullWidth
              variant="outlined"
              onChange={({ target }) => setSearchValue(target.value)}
              value={searchValue}
              label={t('templatesSearchInputLabel')}
              size="small"
            />
          </form>
        </Box>
      </Grid>

      <MappedTemplates
        filteredTemplates={filteredTemplates}
        setSearchValue={setSearchValue}
        setFilteredTemplates={setFilteredTemplates}
      />
    </Layout.Main>
  );
};

export default TemplatesSearch;
