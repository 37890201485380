export const useAttributeViewStyles = () => {
  return {
    draggableItem: {
      bgcolor: 'white',
      display: 'flex',
      alignItems: 'center',
      paddingBlock: '8px',
      marginBottom: '8px',
      cursor: 'grab',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
    },
    checkbox: { marginBlock: '20px 24px' },
    options: { marginBottom: '32px' },
    button: {
      maxWidth: '200px',
      width: '100%',
      marginTop: '5px',
    },
    dropdown: {
      maxWidth: '500px',
      width: '100%',
      marginBottom: '12px',
    },
    sectionHeading: {
      fontWeight: 500,
      marginBottom: '12px',
    },
    textField: { marginBottom: '24px', maxWidth: '700px' },
  };
};
