import { Switch } from '@mui/material';
import React from 'react';
import AttributeTemplate from '.';

const SwitchAttribute = ({
  attributeLabel,
  attributeValue,
  setAttributeValue,
}) => {
  return (
    <AttributeTemplate attributeLabel={attributeLabel}>
      <Switch
        checked={attributeValue}
        onChange={({ target }) => setAttributeValue(target.checked)}
      />
    </AttributeTemplate>
  );
};

export default SwitchAttribute;
