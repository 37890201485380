import {
  Box,
  Typography,
  useTheme,
  List,
  ListItemButton,
  Collapse,
  IconButton,
} from '@mui/material';
import { useTemplatesEditStyles } from './styles';
import { useLayout } from '../../../contexts/LayoutContext';
import { useTranslation } from 'react-i18next';
import { Close, ExpandLess, ExpandMore } from '@mui/icons-material';
import { useState } from 'react';
import ModalOverlay from '../../../components/ModalOverlay';
import { v4 as uuidv4 } from 'uuid';

const MappedTemplate = ({
  template,
  handleCloseImportSectionModal,
  setSections,
  sections,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { currentLanguage } = useLayout();
  const templatesEditStyles = useTemplatesEditStyles();
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleImportSection = (section) => {
    const importedSection = {
      ...section,
      id: uuidv4(),
    };

    setSections([importedSection, ...sections]);
    handleCloseImportSectionModal();
  };

  return (
    <List
      component="nav"
      sx={{ borderBottom: `1px solid ${theme.palette.action.focus}` }}
    >
      <ListItemButton onClick={handleClick}>
        <Box sx={templatesEditStyles.templateName}>
          <Box
            sx={{
              ...templatesEditStyles.templateWrapper,
              bgcolor: template.theme,
            }}
          >
            <Typography>
              {currentLanguage === 'en'
                ? template.abbreviation.en
                : template.abbreviation.fr}
            </Typography>
          </Box>

          <Typography sx={templatesEditStyles.importTemplateName}>
            {currentLanguage === 'en'
              ? t(template.name.en)
              : t(template.name.fr)}
          </Typography>

          {open ? <ExpandLess /> : <ExpandMore />}
        </Box>
      </ListItemButton>

      <Collapse in={open} timeout="auto" unmountOnExit>
        {template?.sections
          ?.filter((section) => section.type !== 'patientInfo')
          .map((section) => {
            return (
              <List key={section.id} component="div" disablePadding>
                <ListItemButton
                  sx={templatesEditStyles.templateSection}
                  onClick={() => handleImportSection(section)}
                >
                  {currentLanguage === 'en' ? section.name.en : section.name.fr}
                </ListItemButton>
              </List>
            );
          })}
      </Collapse>
    </List>
  );
};

const ImportSectionModal = ({
  isImportSectionModalOpen,
  handleCloseImportSectionModal,
  setSections,
  sections,
  templateId,
}) => {
  const { t } = useTranslation();
  const { templates } = useLayout();
  const templatesEditStyles = useTemplatesEditStyles();

  return (
    <ModalOverlay
      isModalOpen={isImportSectionModalOpen}
      handleCloseModal={handleCloseImportSectionModal}
    >
      <Box sx={templatesEditStyles.importSectionWrapper}>
        <IconButton
          onClick={handleCloseImportSectionModal}
          sx={templatesEditStyles.closeButton}
          disableRipple
        >
          <Close />
        </IconButton>

        <Typography
          variant="h3"
          fontWeight={'bold'}
          sx={templatesEditStyles.modalHeading}
        >
          {t('templatesEditImportSection')}
        </Typography>

        <Box overflow="auto" height="95%">
          {templates.map((template) => {
            if (template.id !== templateId) {
              return (
                <MappedTemplate
                  key={template.id}
                  template={template}
                  setSections={setSections}
                  handleCloseImportSectionModal={handleCloseImportSectionModal}
                  sections={sections}
                />
              );
            }
            return null;
          })}
        </Box>
      </Box>
    </ModalOverlay>
  );
};

export default ImportSectionModal;
