import React from 'react';
import ModalOverlay from '../../../components/ModalOverlay';
import { useReportsEditStyles } from './styles';
import { Box, IconButton, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import transactionSummary from '../../../mock/transactionSummary.json';

const TransactionSummaryModal = ({
  isTransactionModalOpen,
  handleCloseTransactionModal,
}) => {
  const { t } = useTranslation();
  const reportsEditStyles = useReportsEditStyles();

  const transactionSummaryColumns = [
    {
      field: 'appointmentDate',
      headerName: t('patientsViewAppointmentDate'),
      flex: 0.2,
      valueGetter: ({ row: { appointmentDate } }) => {
        return moment(appointmentDate).format('lll');
      },
    },
    {
      field: 'price',
      headerName: t('reportsEditPrice'),
      flex: 0.2,
      renderCell: ({ row: { price } }) => {
        return <span>{`${price}`}</span>;
      },
    },
    {
      field: 'time',
      headerName: t('reportsEditTime'),
      flex: 0.2,
      renderCell: ({ row: { time } }) => {
        return <span>{`${Math.ceil(time)}mins`}</span>;
      },
    },
    {
      field: 'description',
      headerName: t('reportsEditDescription'),
      flex: 0.5,
    },
  ];

  return (
    <ModalOverlay
      isModalOpen={isTransactionModalOpen}
      handleCloseModal={handleCloseTransactionModal}
    >
      <Box sx={reportsEditStyles.modal}>
        <IconButton
          onClick={handleCloseTransactionModal}
          sx={reportsEditStyles.closeButton}
          disableRipple
        >
          <Close />
        </IconButton>

        <Typography
          variant="h3"
          fontWeight={'bold'}
          sx={reportsEditStyles.modalHeading}
        >
          {t('reportsEditTransactionSummary')}
        </Typography>

        <Box sx={reportsEditStyles.modalBody}>
          <DataGrid
            autoHeight
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            rows={transactionSummary}
            columns={transactionSummaryColumns}
            disableRowSelectionOnClick
            multiline
          />
        </Box>
      </Box>
    </ModalOverlay>
  );
};

export default TransactionSummaryModal;
