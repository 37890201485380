import React, { useCallback } from 'react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  TextField,
  Box,
  Typography,
  IconButton,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  useTheme,
} from '@mui/material';
import { CirclePicker } from 'react-color';
import { useTemplatesEditStyles } from './styles';
import { TEMPLATES_REPORT_MODELS } from '../../../utils/templates';

const Profile = ({
  template: {
    name: templateName,
    abbreviation: templateAbbreviation,
    theme: templateTheme,
    reportModel: templateReportModel,
  },
  formik: { values, handleChange, setFieldValue },
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const colorPickerRef = useRef(null);
  const templatesEditStyles = useTemplatesEditStyles();
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
  const isTemplateThemeChanged = values.theme !== templateTheme;
  const isReportModelChanged = values.reportModel !== templateReportModel;

  const handleToggleColorPicker = useCallback(
    () => setIsColorPickerOpen(!isColorPickerOpen),
    [isColorPickerOpen]
  );

  const handleSelectColor = (color) => {
    setFieldValue('theme', color.hex);
    setIsColorPickerOpen(false);
  };

  const NAMING_TEXT_FIELDS = [
    {
      name: 'anglophoneName',
      label: 'templatesEditAnglophoneName',
      isChanged: templateName.en !== values.anglophoneName,
      value: values.anglophoneName,
    },
    {
      name: 'francophoneName',
      label: 'templatesEditFrancophoneName',
      isChanged: templateName.fr !== values.francophoneName,
      value: values.francophoneName,
    },
    {
      name: 'anglophoneAbbreviation',
      label: 'templatesEditAnglophoneAbbr',
      isChanged: templateAbbreviation.en !== values.anglophoneAbbreviation,
      value: values.anglophoneAbbreviation,
    },
    {
      name: 'francophoneAbbreviation',
      label: 'templatesEditFrancophoneAbbr',
      isChanged: templateAbbreviation.fr !== values.francophoneAbbreviation,
      value: values.francophoneAbbreviation,
    },
  ];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        colorPickerRef.current &&
        !colorPickerRef.current.contains(event.target)
      ) {
        handleToggleColorPicker();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleToggleColorPicker]);

  return (
    <Grid container spacing={3}>
      {NAMING_TEXT_FIELDS.map(({ isChanged, name, label, value }) => {
        return (
          <Grid item xs={12} md={6} key={name}>
            <TextField
              name={name}
              label={t(label)}
              fullWidth
              variant="outlined"
              color={isChanged && 'success'}
              onChange={handleChange}
              value={value}
              InputLabelProps={{
                style: {
                  color: isChanged && theme.palette.success.light,
                },
              }}
            />
          </Grid>
        );
      })}

      <Grid item md={12} gap={3} display={'flex'} alignItems={'center'}>
        <Grid
          item
          md={2}
          sx={{
            ...templatesEditStyles.templateTheme,
            border: isTemplateThemeChanged
              ? `1px solid ${theme.palette.success.light}`
              : 'none',
          }}
        >
          <Typography>{t('templatesEditTemplateColor')}</Typography>

          <Box sx={templatesEditStyles.templateThemeIcon}>
            <IconButton onClick={handleToggleColorPicker}>
              <Box
                sx={{
                  ...templatesEditStyles.templateThemeIconColor,
                  bgcolor: values.theme,
                }}
              />
            </IconButton>

            {isColorPickerOpen && (
              <Box
                ref={colorPickerRef}
                sx={templatesEditStyles.templateThemePicker}
              >
                <CirclePicker onChange={handleSelectColor} />
              </Box>
            )}
          </Box>
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <InputLabel
              id="reportModel"
              sx={{
                color: isReportModelChanged && theme.palette.success.light,
                '&.Mui-focused': {
                  color: isReportModelChanged && theme.palette.success.light,
                },
              }}
            >
              {t('templatesEditReportModel')}
            </InputLabel>
            <Select
              labelId="reportModel"
              id="reportModel"
              label={t('templatesEditReportModel')}
              name="reportModel"
              onChange={handleChange}
              value={values.reportModel}
              color={isReportModelChanged && 'success'}
            >
              {TEMPLATES_REPORT_MODELS.map((item) => {
                return (
                  <MenuItem key={item.value} value={item.value}>
                    {t(item.name)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Profile;
