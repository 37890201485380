import Attribute from './Attribute';

const ClinicalExam = () => {
  return (
    <Attribute
      attributeName={'clinicalExam'}
      pageTitle={'patientsAttributesClinicalExam'}
    />
  );
};

export default ClinicalExam;
