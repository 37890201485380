import Home from '../../pages/Home';
import Patients from '../../pages/Patients';
import Doctors from '../../pages/Doctors';
import Attributes from '../../pages/Attributes';
import Reports from '../../pages/Reports';
import Templates from '../../pages/Templates';
import Users from '../../pages/Users';

export const NAVIGATION_ROUTES = [
  { path: '/', element: <Home /> },
  { path: '/patients/*', element: <Patients /> },
  { path: '/doctors/*', element: <Doctors /> },
  { path: '/custom-fields/*', element: <Attributes /> },
  { path: '/reports/*', element: <Reports /> },
  { path: '/templates/*', element: <Templates /> },
  // { path: '/codes/*', element: <Codes /> },
  // { path: '/precisions/*', element: <Precisions /> },
  { path: '/users/*', element: <Users /> },
];
