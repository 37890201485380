import React, { useEffect } from 'react';
import * as Layout from '../../../layouts';
import UsersForm from '../../../components/UsersForm';
import { useTranslation } from 'react-i18next';
import { useLayout } from '../../../contexts/LayoutContext';

const UsersAdd = () => {
  const { t } = useTranslation();
  const { setPageTitle } = useLayout();

  useEffect(() => {
    setPageTitle(t('usersAddHeading'));
  }, [setPageTitle, t]);

  return (
    <Layout.Main>
      <UsersForm />
    </Layout.Main>
  );
};

export default UsersAdd;
