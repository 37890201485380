import Attribute from './Attribute';

const MedicalHistory = () => {
  return (
    <Attribute
      attributeName={'medicalHistory'}
      pageTitle={'patientsAttributesMedicalHistory'}
    />
  );
};

export default MedicalHistory;
