import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@mui/material';
import { useTemplatesEditStyles } from './styles';
import { FaSort } from 'react-icons/fa';
import PatientInformation from './PatientInformation';
import CustomTemplateSection from '../../../components/CustomTemplatesSection';
import SortSectionsModal from './SortSectionsModal';
import { compare } from '../../../utils/helpers';

const Sections = ({
  template: { sections: templateSections },
  sections,
  setSections,
}) => {
  const { t } = useTranslation();
  const templatesEditStyles = useTemplatesEditStyles();
  const [isSortSectionsModalOpen, setIsSortSectionsModalOpen] = useState(false);

  const handleOpenSortSectionsModal = () => setIsSortSectionsModalOpen(true);
  const handleCloseSortSectionsModal = () => setIsSortSectionsModalOpen(false);
  const handleMoveSection = (direction, selectedSectionIndex) => {
    const clonedSections = [...sections];

    if (direction === 'up' && selectedSectionIndex > 0) {
      const temp = clonedSections[selectedSectionIndex - 1];
      clonedSections[selectedSectionIndex - 1] =
        clonedSections[selectedSectionIndex];
      clonedSections[selectedSectionIndex] = temp;
    } else if (
      direction === 'down' &&
      selectedSectionIndex < clonedSections.length - 1
    ) {
      const temp = clonedSections[selectedSectionIndex + 1];

      clonedSections[selectedSectionIndex + 1] =
        clonedSections[selectedSectionIndex];
      clonedSections[selectedSectionIndex] = temp;
    }

    setSections(clonedSections);
  };

  const handleDeleteSection = (id) => {
    const updatedSections = [...sections].filter((section) => {
      return section.id !== id;
    });

    setSections(updatedSections);
  };

  return (
    <Box sx={templatesEditStyles.sections}>
      <SortSectionsModal
        sections={sections}
        setSections={setSections}
        handleCloseSortSectionsModal={handleCloseSortSectionsModal}
        isSortSectionsModalOpen={isSortSectionsModalOpen}
      />

      <Box sx={templatesEditStyles.sortButton}>
        <Button
          startIcon={<FaSort />}
          variant="outlined"
          onClick={handleOpenSortSectionsModal}
        >
          {t('templatesEditSortButton')}
        </Button>
      </Box>

      {sections?.map((section) => {
        const indexBeforeChange = templateSections.findIndex(
          (item) => item.id === section.id
        );
        const currentIndex = sections.findIndex(
          (item) => item.id === section.id
        );
        const hasIndexChanged = indexBeforeChange !== currentIndex;
        const isSameSection = templateSections[indexBeforeChange]?.id
          ? compare(templateSections[indexBeforeChange], section)
          : false;

        return (
          <Box key={section.id}>
            {section.type === 'patientInfo' ? (
              <PatientInformation
                section={section}
                sections={sections}
                setSections={setSections}
                currentIndex={currentIndex}
                hasIndexChanged={hasIndexChanged}
                handleMoveSection={handleMoveSection}
                isSameSection={isSameSection}
              />
            ) : (
              <CustomTemplateSection
                section={section}
                sections={sections}
                setSections={setSections}
                currentIndex={currentIndex}
                isSameSection={isSameSection}
                hasIndexChanged={hasIndexChanged}
                handleMoveSection={handleMoveSection}
                handleDeleteSection={handleDeleteSection}
                showArrows
              />
            )}
          </Box>
        );
      })}
    </Box>
  );
};

export default Sections;
