import React, { useEffect } from 'react';
import * as Layout from '../../../layouts';
import PatientsForm from '../../../components/PatientsForm';
import { useTranslation } from 'react-i18next';
import { useLayout } from '../../../contexts/LayoutContext';

const PatientsAdd = () => {
  const { t } = useTranslation();
  const { setPageTitle } = useLayout();

  useEffect(() => {
    setPageTitle(t('patientsAddHeading'));
  });

  return (
    <Layout.Main>
      <PatientsForm />
    </Layout.Main>
  );
};

export default PatientsAdd;
