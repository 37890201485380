import { StyleSheet } from '@react-pdf/renderer';

export const reportsPdfEnvelopeStyles = StyleSheet.create({
  page: {
    width: 684,
    height: 306,
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 10,
    fontFamily: 'Anonymous Pro',
  },
  header: {
    marginBottom: 10,
    fontSize: 12,
    textAlign: 'center',
  },
  section: {
    marginBottom: 10,
  },
  label: {
    fontWeight: 'bold',
  },
  content: {
    marginBottom: 10,
  },
  signature: {
    marginTop: 'auto',
    fontSize: 12,
    fontStyle: 'italic',
    textAlign: 'right',
  },
});

export const reportsPdfFullStyles = StyleSheet.create({
  page: {
    padding: 40,
    fontSize: 11,
    fontFamily: 'Anonymous Pro',
    lineHeight: '1.2px',
  },
  heading: {
    marginBottom: 10,
  },
  patientInfo: {
    marginBottom: 5,
  },
  greeting: {
    marginBottom: 5,
  },
  body: {
    marginTop: 10,
  },
  sections: {
    marginBottom: 10,
  },
  block: { marginBottom: 10 },
  bold: {
    fontWeight: 700,
    fontFamily: 'Anonymous Pro',
  },
  complimentary: { marginTop: 10 },
  attribute: {
    marginLeft: 15,
    marginTop: 5,
  },
  treatmentPlan: {
    display: 'flex',
    flexDirection: 'row',
  },
  plans: {
    width: '100%',
    marginTop: 5,
    marginLeft: 15,
    marginBottom: 5,
    display: 'flex',
    justifyContent: 'space-between',
  },
  planIndicator: {
    marginTop: 5,
    marginLeft: 15,
  },
  letterHeading: {
    paddingVertical: 5,
    position: 'absolute',
    top: 6,
    left: 0,
    right: 0,
  },
  letterHeadingText: {
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: 700,
    fontFamily: 'Anonymous Pro',
  },
  reportContent: { marginTop: 24 },
});
