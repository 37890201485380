import {
  Grid,
  TextField,
  useMediaQuery,
  Autocomplete,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useReportsEditStyles } from './styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { useLayout } from '../../../contexts/LayoutContext';

const ResponsiveGrid = ({ children }) => {
  const customBreakpoint = useMediaQuery('(max-width: 1200px)');

  return (
    <Grid item xs={12} md={customBreakpoint ? 12 : 6}>
      {children}
    </Grid>
  );
};

const InputFields = ({
  formik: { values, setFieldValue, handleChange, handleBlur },
}) => {
  const { t } = useTranslation();
  const { doctors } = useLayout();
  const reportsEditStyles = useReportsEditStyles();

  return (
    <Grid container spacing={3} sx={reportsEditStyles.body}>
      <ResponsiveGrid>
        <TextField
          name="anglophoneName"
          label={t('reportsEditAnglophoneName')}
          fullWidth
          variant="outlined"
          onChange={handleChange}
          value={values.anglophoneName}
        />
      </ResponsiveGrid>

      <ResponsiveGrid>
        <TextField
          name="francophoneName"
          label={t('reportsEditFrancophoneName')}
          fullWidth
          variant="outlined"
          onChange={handleChange}
          value={values.francophoneName}
        />
      </ResponsiveGrid>

      <ResponsiveGrid>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            inputFormat="MM/DD/YYYY"
            onChange={(date) => setFieldValue('appointmentDate', date, true)}
            value={values.appointmentDate}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                name="appointmentDate"
                label={t('reportsEditAppointmentDate')}
                required
              />
            )}
          />
        </LocalizationProvider>
      </ResponsiveGrid>

      <ResponsiveGrid>
        <Autocomplete
          multiple
          id="doctors"
          options={doctors}
          getOptionLabel={(option) => `${option.lastName}, ${option.firstName}`}
          defaultValue={[]}
          onBlur={handleBlur}
          onChange={(_e, value) => setFieldValue('doctors', value)}
          value={values.doctors}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label={t('reportsEditDoctors')}
              required
              onBlur={handleBlur}
              name="doctors"
            />
          )}
        />
      </ResponsiveGrid>
      <ResponsiveGrid>
        <FormControl fullWidth>
          <InputLabel id="reportingDoctor">
            {t('reportsEditReportingDoctor')}
          </InputLabel>

          <Select
            labelId="reportingDoctor"
            id="reportingDoctor"
            name="reportingDoctor"
            value={values.reportingDoctor}
            onChange={handleChange}
            label={t('reportsEditReportingDoctor')}
          >
            {doctors.map((item) => {
              return (
                <MenuItem
                  key={item.id}
                  value={`${item.lastName} ${item.firstName}`}
                >
                  {`${item.lastName} ${item.firstName}`}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </ResponsiveGrid>
      <ResponsiveGrid>
        <TextField
          name="letterHeading"
          label={t('reportsEditLetterHeading')}
          fullWidth
          variant="outlined"
          onChange={handleChange}
          value={values.letterHeading}
          size="large"
        />
      </ResponsiveGrid>
      <ResponsiveGrid>
        <TextField
          name="notes"
          label={t('reportsEditNotes')}
          fullWidth
          variant="outlined"
          onChange={handleChange}
          value={values.notes}
          rows={8}
          size="large"
          multiline
        />
      </ResponsiveGrid>

      {/* <ResponsiveGrid>
        <TextField
          name="attachments"
          label={t('reportsEditAttachments')}
          fullWidth
          variant="outlined"
          type="file"
          InputLabelProps={{ shrink: true }}
          inputProps={{ multiple: true }}
        />
      </ResponsiveGrid> */}
    </Grid>
  );
};

export default InputFields;
