import { Routes, Route } from 'react-router-dom';
import Login from '../pages/Login';
import { useLayout } from '../contexts/LayoutContext';
import NotFound from '../pages/Unauthorized';
import { NAVIGATION_ROUTES } from '../utils/routes';

const NavigationRoutes = () => {
  const { isSignedIn } = useLayout();

  return (
    <Routes>
      {isSignedIn === 'true' ? (
        NAVIGATION_ROUTES.map(({ path, element }) => (
          <Route key={path} path={path} element={element} />
        ))
      ) : (
        <Route path="/" element={<Login />} />
      )}

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default NavigationRoutes;
