import { useTheme } from '@mui/material';

export const useTreatmentPlanStyles = () => {
  const theme = useTheme();

  return {
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '16px',
    },
    appointment: {
      marginTop: '12px',
    },
    appointmentTop: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '8px',
    },
    appointmentContent: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '18px 22px 18px 4px',
      width: '100%',
      bgcolor: 'white',
      backgroundColor: theme.palette.action.focus,
      alignItems: 'center',
    },
    templateColor: {
      height: '40px',
      width: '40px',
      borderRadius: '20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'white',
    },
    regions: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: '12px',
      cursor: 'pointer',
    },
    pdfWrapper: {
      height: '600px',
      display: 'flex',
      marginTop: '12px',
    },
    pdf: {
      width: '100%',
    },
    headerIcons: { marginRight: '5px', maxHeight: '36.5px' },
    headerButtons: {
      width: '36.5px',
      maxHeight: '36.5px',
      marginRight: '5px',
      fontSize: '18px',
    },
    regionButton: {
      boxShadow: '1px 1px 2px 0px rgba(0, 0, 0, 0.5)',
      '&.MuiButtonBase-root': {
        borderRadius: '5px',
      },
      '&.MuiButtonBase-root:hover': {
        bgcolor: '#fff38a',
      },
    },
    appointmentMoveIcons: {
      marginRight: '12px',
      flexDirection: 'column',
      display: 'flex',
    },
    proceeding: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '18px 22px 18px 4px',
      width: '100%',
      bgcolor: 'white',
      cursor: 'grab',
      backgroundColor: 'white',
      alignItems: 'flex-start',
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  };
};
