import { useTranslation } from 'react-i18next';
import mockLineChartData from '../../mock/lineChart.json';

export const useLineChart = () => {
  const { t } = useTranslation();

  const lineChartData = mockLineChartData.map((item) => {
    return {
      ...item,
      id: t(item.id),
      data: item.data.map((point) => {
        return { ...point, x: t(point.x) };
      }),
    };
  });

  return { lineChartData };
};
