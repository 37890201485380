import React from 'react';
import * as Layout from '../../../layouts';
import { useLayout } from '../../../contexts/LayoutContext';
import { useParams } from 'react-router-dom';
import TreatmentPlan from './TreatmentPlan';

const PhaseI = () => {
  const { id } = useParams();
  const { patients } = useLayout();
  const patient = patients.find((patient) => patient?.id === id);

  return (
    <Layout.Main notFound={!patient?.id}>
      <TreatmentPlan
        phase="treatmentPlan"
        patient={patient}
        title="patientsAttributesTreatmentPlan"
      />
    </Layout.Main>
  );
};

export default PhaseI;
