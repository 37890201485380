export const USERS_ACCOUNT_TYPE = [
  { name: 'usersSearchAdminUser', value: 'admin' },
  { name: 'usersSearchReportUser', value: 'report' },
];

export const USERS_FILTER_CHIP_TRANSLATIONS = {
  name: 'usersSearchName',
  email: 'usersSearchEmail',
  type: 'usersSearchType',
};
