import React, { createContext, useContext, useState, useEffect } from 'react';
import mockDoctors from '../mock/doctors.json';
import mockPatients from '../mock/patients.json';
import mockReports from '../mock/reports.json';
import mockTemplates from '../mock/templates.json';
import mockAttributes from '../mock/attributes.json';
import mockUsers from '../mock/users.json';

const LayoutContext = createContext();

export function LayoutProvider(props) {
  const [currentLanguage, setCurrentLanguage] = useState(
    localStorage.getItem('currentLanguage') || 'fr'
  );
  const [isSignedIn, setIsSignedIn] = useState(
    localStorage.getItem('isSignedIn') || false
  );
  const [patients, setPatients] = useState(
    JSON.parse(localStorage.getItem('patients')) || mockPatients
  );
  const [doctors, setDoctors] = useState(
    JSON.parse(localStorage.getItem('doctors')) || mockDoctors
  );
  const [reports, setReports] = useState(
    JSON.parse(localStorage.getItem('reports')) || mockReports
  );
  const [templates, setTemplates] = useState(
    JSON.parse(localStorage.getItem('templates')) || mockTemplates
  );
  const [attributes, setAttributes] = useState(
    JSON.parse(localStorage.getItem('attributes')) || mockAttributes
  );
  const [users, setUsers] = useState(
    JSON.parse(localStorage.getItem('users')) || mockUsers
  );
  const [deletedReports, setDeletedReports] = useState(
    JSON.parse(localStorage.getItem('deletedReports')) || []
  );
  const [pageTitle, setPageTitle] = useState('My Doctor Hub');

  useEffect(() => {
    localStorage.setItem('currentLanguage', currentLanguage);
    localStorage.setItem('isSignedIn', isSignedIn);
    localStorage.setItem('patients', JSON.stringify(patients));
    localStorage.setItem('doctors', JSON.stringify(doctors));
    localStorage.setItem('reports', JSON.stringify(reports));
    localStorage.setItem('templates', JSON.stringify(templates));
    localStorage.setItem('attributes', JSON.stringify(attributes));
    localStorage.setItem('users', JSON.stringify(users));
    localStorage.setItem('deletedReports', JSON.stringify(deletedReports));
  }, [
    currentLanguage,
    isSignedIn,
    patients,
    doctors,
    reports,
    templates,
    attributes,
    users,
    deletedReports,
  ]);

  return (
    <LayoutContext.Provider
      value={{
        isSignedIn,
        setIsSignedIn,
        patients,
        setPatients,
        doctors,
        setDoctors,
        pageTitle,
        setPageTitle,
        currentLanguage,
        setCurrentLanguage,
        reports,
        setReports,
        templates,
        setTemplates,
        attributes,
        setAttributes,
        users,
        setUsers,
        deletedReports,
        setDeletedReports,
      }}
    >
      {props.children}
    </LayoutContext.Provider>
  );
}

export function useLayout() {
  const context = useContext(LayoutContext);
  if (!context) {
    throw new Error('useLayout must be used within a LayoutProvider');
  }
  return context;
}
