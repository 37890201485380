import { useTranslation } from 'react-i18next';
import mockPieChartData from '../../mock/pieChart.json';
import { useLayout } from '../../contexts/LayoutContext';

export const usePieChart = () => {
  const { t } = useTranslation();
  const { users, patients, doctors } = useLayout();

  const amount = {
    doctors,
    patients,
    users,
  };

  const pieChartData = mockPieChartData.map((item) => {
    return {
      ...item,
      label: t(item.label),
      value: amount[item.id.toLowerCase()].length,
    };
  });

  return { pieChartData };
};
