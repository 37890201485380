import {
  Box,
  Typography,
  IconButton,
  Switch,
  useTheme,
  Divider,
} from '@mui/material';
import { useTemplatesEditStyles } from '../styles';
import { BiSolidUpArrow, BiSolidDownArrow } from 'react-icons/bi';
import { useLayout } from '../../../../contexts/LayoutContext';
import { useEffect, useState } from 'react';
import { COLORS } from '../../../../utils/colors';
import PatientInformationCard from './PatientInformationCard';

const PatientInformation = ({
  hasIndexChanged,
  section,
  handleMoveSection,
  setSections,
  sections,
  isSameSection,
  currentIndex,
}) => {
  const theme = useTheme();
  const { currentLanguage } = useLayout();
  const templatesEditStyles = useTemplatesEditStyles();
  const [isSectionNameActive, setisSectionNameActive] = useState(
    section.isSectionNameActive
  );
  const [options, setOptions] = useState(section.options);

  useEffect(() => {
    const updatedItems = [...sections];
    updatedItems[currentIndex] = {
      ...section,
      isSectionNameActive,
      options,
    };

    setSections(updatedItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSectionNameActive, options]);

  return (
    <Box mb={2}>
      <Box
        sx={{
          ...templatesEditStyles.patientInfo,
          opacity: isSectionNameActive ? 1 : 0.5,
          borderColor:
            hasIndexChanged || !isSameSection
              ? theme.palette.success.light
              : 'transparent',
        }}
        key={section.id}
      >
        <Box sx={templatesEditStyles.patientInfoHeading}>
          <Box sx={templatesEditStyles.sectionHeading}>
            <Box sx={templatesEditStyles.sectionHeadingIcons}>
              <IconButton
                size="small"
                onClick={() => handleMoveSection('up', currentIndex)}
              >
                <BiSolidUpArrow />
              </IconButton>
              <IconButton
                size="small"
                onClick={() => handleMoveSection('down', currentIndex)}
              >
                <BiSolidDownArrow />
              </IconButton>
            </Box>
            <Typography fontWeight={600}>
              {currentLanguage === 'en' ? section?.name?.en : section?.name?.fr}
            </Typography>
          </Box>

          <Switch
            checked={isSectionNameActive}
            onChange={() => setisSectionNameActive(!isSectionNameActive)}
          />
        </Box>

        {options?.map((option, idx) => {
          return (
            <PatientInformationCard
              currentIndex={idx}
              key={option?.id}
              option={option}
              isSectionNameActive={isSectionNameActive}
              options={options}
              setOptions={setOptions}
            />
          );
        })}
      </Box>
      <Divider sx={{ bgcolor: COLORS.LIGHT_GRAY }} />
    </Box>
  );
};

export default PatientInformation;
