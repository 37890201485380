import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { insurancePdfStyles } from '../styles';

const DoctorPatientInfoEn = ({
  patient,
  patientDoctor,
  patientAddress,
  doctorAddress,
}) => {
  return (
    <View style={insurancePdfStyles.section}>
      <View style={insurancePdfStyles.infoSectionPatient}>
        <View style={insurancePdfStyles.infoSectionPatientTop}>
          <Text style={insurancePdfStyles.infoSectionPatientTopText}>
            PART 1 DOCTOR
          </Text>
        </View>

        <View style={insurancePdfStyles.infoSectionPatientMain}>
          <Text style={insurancePdfStyles.infoSectionPatientMainText}>
            P{'\n'}A{'\n'}T{'\n'}I{'\n'}E{'\n'}N{'\n'}T{'\n'}
          </Text>

          <View>
            <Text
              style={insurancePdfStyles.pdfValue}
            >{`${patient?.lastName}, ${patient?.firstName}`}</Text>
            <Text style={insurancePdfStyles.pdfValue}>{patientAddress}</Text>
          </View>
        </View>
      </View>

      <View style={insurancePdfStyles.infoSectionPatient}>
        <View style={insurancePdfStyles.infoSectionDoctorTop}>
          <View style={insurancePdfStyles.infoSectionDoctorTopRows}>
            <View style={insurancePdfStyles.infoSectionDoctorTopUnique}>
              <Text style={insurancePdfStyles.smallBold}>UNIQUE NO.</Text>
            </View>

            <View style={insurancePdfStyles.infoSectionDoctorTopSpec}>
              <Text style={insurancePdfStyles.smallBold}>SPEC</Text>
            </View>

            <View style={insurancePdfStyles.infoSectionDoctorTopOffice}>
              <Text style={insurancePdfStyles.smallBold}>
                PATIENTS OFFICE ACCOUNT NO.
              </Text>
            </View>
          </View>
        </View>

        <View style={insurancePdfStyles.infoSectionPatientMain}>
          <Text style={insurancePdfStyles.infoSectionPatientMainText}>
            D{'\n'}O{'\n'}C{'\n'}T{'\n'}O{'\n'}R
          </Text>

          <View>
            <Text
              style={insurancePdfStyles.pdfValue}
            >{`${patientDoctor?.lastName}, ${patientDoctor?.firstName}`}</Text>
            <Text style={insurancePdfStyles.pdfValue}>{doctorAddress}</Text>
            <Text
              style={{
                ...insurancePdfStyles.smallBold,
                marginTop: '2px',
              }}
            >
              PHONE NO
            </Text>
            <Text style={insurancePdfStyles.pdfValue}>
              {patientDoctor?.phone}
            </Text>
          </View>
        </View>
      </View>

      <View style={insurancePdfStyles.subscriber}>
        <View style={insurancePdfStyles.subscriberMain}>
          <Text style={insurancePdfStyles.smallBold}>
            I HEREBY ASSIGN MY BENEFITS PAYABLE FROM THIS CLAIM TO THE NAMED
            DOCTOR AND AUTHORIZE PAYMENT TO HIM/HER
          </Text>
        </View>

        <Text style={insurancePdfStyles.smallBold}>
          SIGNATURE OF SUBSCRIBER
        </Text>
      </View>
    </View>
  );
};

export default DoctorPatientInfoEn;
