export const DOCTORS_ADD_SEX = [
  { value: 'Male', text: 'doctorsFormSexMale' },
  { value: 'Female', text: 'doctorsFormSexFemale' },
  { value: 'Other', text: 'doctorsFormSexOther' },
];

export const DOCTORS_ADD_PREFERRED_LANGUAGE = [
  { value: 'English', text: 'doctorsFormEnglish' },
  { value: 'French', text: 'doctorsFormFrench' },
  { value: 'Other', text: 'doctorsFormOther' },
];

export const DOCTORS_ADD_PRIMARY_CONTACT_METHOD = [
  { value: 'Email', name: 'doctorsFormEmail' },
  { value: 'Fax', name: 'doctorsFormFax' },
  { value: 'Telephone', name: 'doctorsFormTelephone' },
];
