import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { insurancePdfStyles } from '../styles';

const DoctorUseEn = () => {
  return (
    <View style={insurancePdfStyles.section}>
      <View style={insurancePdfStyles.doctorUseAdditional}>
        <Text style={insurancePdfStyles.smallBold}>
          FOR DOCTOR USE ONLY - FOR ADDITIONAL INFORMATION, DIAGNOSIS,
          PROCEDURES,OR SPECIAL{'\n'} CONSIDERATIONS.
        </Text>
      </View>

      <View style={insurancePdfStyles.doctorUseUndertaking}>
        <View style={insurancePdfStyles.doctorUseUndertakingText}>
          <Text style={insurancePdfStyles.smallBold}>
            I UNDERSTAND THAT THE FEES LISTED IN THIS CLAIM MAY NOT BE COVERED
            BY OR MAY EXCEED MY PLAN BENEFITS. I UNDERSTAND THAT I AM
            FINANCIALLY RESPONSIBLE TO MY DOCTOR FOR THE ENTIRE TREATMENT. I
            ACKNOWLEDGE THAT THE TOTAL FEE OF ${'                    '} IS
            ACCURATE AND HAS BEEN CHARGED TO ME FOR SERVICES RENDERED.{'\n'} I
            AUTHORIZE RELEASE OF THE INFORMATION CONTAINED IN THIS CLAIM FORM TO
            MY INSURING COMPANY / PLAN ADMINISTRATOR. I ALSO AUTHORIZE THE
            COMMUNICATION OF INFORMATION RELATED TO THE COVERAGE OF SERVICES
            DESCRIBED IN THIS FORM TO THE NAMED doctor?.
          </Text>

          <View style={insurancePdfStyles.doctorUseSignature}>
            <Text style={insurancePdfStyles.smallBold}>
              SIGNATURE OF PATIENT (PARENT/GUARDIAN)
            </Text>
          </View>
        </View>

        <View style={insurancePdfStyles.doctorUseVerification}>
          <Text style={insurancePdfStyles.smallBold}>OFFICE VERIFICATION</Text>
        </View>
      </View>
    </View>
  );
};

export default DoctorUseEn;
