import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import {
  Button,
  Box,
  Grid,
  TextField,
  useTheme,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { DeleteOutline, Visibility, VisibilityOff } from '@mui/icons-material';
import { PiWarningDuotone } from 'react-icons/pi';
import { useTranslation } from 'react-i18next';
import { useDeleteModalStyles } from './styles';
import HelperText from '../HelperText';
import ModalOverlay from '../ModalOverlay';

const DeleteModal = ({
  isDeleteModalOpen,
  handleCloseDeleteModal,
  handleConfirm,
  isProtected,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const deleteModalStyles = useDeleteModalStyles();
  const [password, setPassword] = useState('');
  const [hasError, setHasError] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const handleTogglePasswordVisibility = () =>
    setIsPasswordVisible(!isPasswordVisible);
  const handleModalClose = () => {
    setPassword('');
    handleCloseDeleteModal();
  };
  const handleDelete = (event) => {
    event?.preventDefault();
    if (password === 'incorrect') {
      setHasError(true);
    } else {
      handleConfirm();
      setHasError(false);
      handleModalClose();
    }
  };

  return (
    <ModalOverlay
      isModalOpen={isDeleteModalOpen}
      handleCloseModal={handleModalClose}
    >
      <Box sx={deleteModalStyles.modal}>
        <PiWarningDuotone color={theme.palette.error.main} fontSize="100px" />
        <Typography variant="h5" fontWeight={'bold'} gutterBottom>
          {t('deleteModalConfirmDeleteHeading')}
        </Typography>
        <Typography id="modal-modal-title" variant="p">
          {isProtected
            ? t('deleteModalPasswordDeleteText')
            : t('deleteModalConfirmDeleteText')}
        </Typography>

        {isProtected && (
          <form
            style={{ width: '100%', marginTop: '12px' }}
            onSubmit={(event) => handleDelete(event)}
          >
            <TextField
              sx={{ height: '90px' }}
              variant="outlined"
              fullWidth
              label={t('loginFormPassword')}
              name={'password'}
              onChange={({ target }) => setPassword(target.value)}
              value={password}
              type={isPasswordVisible ? 'text' : 'password'}
              helperText={
                hasError ? (
                  <HelperText color={theme.palette.error.main}>
                    {t('deleteModalIncorrectPassword')}
                  </HelperText>
                ) : (
                  ''
                )
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleTogglePasswordVisibility}>
                      {isPasswordVisible ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              autoComplete={'Password'}
            />
          </form>
        )}

        <Grid item xs={12} sm={12} sx={deleteModalStyles.modalButtons}>
          <Box>
            <Button
              variant="text"
              onClick={handleModalClose}
              sx={deleteModalStyles.modalButtonsCancel}
            >
              {t('deleteModalCancelButton')}
            </Button>
            <Button
              color="error"
              variant="contained"
              onClick={handleDelete}
              disabled={isProtected && password.length < 6}
              startIcon={<DeleteOutline />}
            >
              {t('deleteModalDeleteButton')}
            </Button>
          </Box>
        </Grid>
      </Box>
    </ModalOverlay>
  );
};

export default DeleteModal;
