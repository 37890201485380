import React from 'react';
import { Document, Font, Page, Text, View } from '@react-pdf/renderer';
import '../styles';
import { reportsPdfFullStyles } from '../styles';
import moment from 'moment';
import './ReportsPdfSections';
import ReportsPdfSections from './ReportsPdfSections';
import { useTheme } from '@mui/material';
import { hasInvalidAttributeValue } from '../../../../utils/helpers';

const ReportsPdfFull = ({ report, patient, currentLanguage, doctors }) => {
  const theme = useTheme();
  Font.register({
    family: 'Anonymous Pro',
    fonts: [
      {
        src: 'https://fonts.gstatic.com/s/anonymouspro/v21/rP2Bp2a15UIB7Un-bOeISG3pLlw89CH98Ko.ttf',
      },
      {
        src: 'https://fonts.gstatic.com/s/anonymouspro/v21/rP2cp2a15UIB7Un-bOeISG3pFuAT0CnW7KOywKo.ttf',
        fontWeight: 700,
      },
      {
        src: 'https://fonts.gstatic.com/s/anonymouspro/v21/rP2fp2a15UIB7Un-bOeISG3pHl428AP44Kqr2Q.ttf',
        fontStyle: 'italic',
      },
      {
        src: 'https://fonts.gstatic.com/s/anonymouspro/v21/rP2ap2a15UIB7Un-bOeISG3pHl4OTCzc6IG30KqB9Q.ttf',
        fontWeight: 700,
        fontStyle: 'italic',
      },
    ],
  });

  const patientName = `${patient?.lastName || ''} ${patient?.firstName || ''} ${
    patient?.middleName || ''
  }`;
  const primaryAddress = patient?.addresses?.find((address) => address.primary);

  return report ? (
    <Document>
      <Page style={reportsPdfFullStyles.page}>
        <View
          style={{
            ...reportsPdfFullStyles.letterHeading,
            borderBottom: `5px solid ${theme.palette.primary.light}`,
          }}
        >
          <Text style={reportsPdfFullStyles.letterHeadingText}>
            {report?.letterHeading}
          </Text>
        </View>

        <View style={reportsPdfFullStyles.reportContent}>
          <View style={reportsPdfFullStyles.heading}>
            <Text>{`${moment(report.appointmentDate).format('LL')},`}</Text>
            <Text>{`${patientName || ''}`}</Text>
            <Text>{`${primaryAddress?.addressLine?.trimEnd() || ''}`}</Text>
            <Text>{`${primaryAddress?.province?.trimEnd() || ''}`}</Text>
            <Text>{`${primaryAddress?.city?.trimEnd() || ''}`}</Text>
          </View>

          <View style={reportsPdfFullStyles.greeting}>
            <Text>
              {patient?.id
                ? `${currentLanguage === 'en' ? `Dear` : `Cher`} ${
                    patient?.firstName
                  },`
                : ''}
            </Text>
          </View>

          <View style={reportsPdfFullStyles.body}>
            <View style={reportsPdfFullStyles.sections}>
              {report?.sections?.map((section) => {
                const isNotEditableSection =
                  section?.type !== 'patientInfo' &&
                  section?.type !== 'phaseII' &&
                  section?.type !== 'treatmentPlan';
                const allDoNotHaveValidValue =
                  isNotEditableSection &&
                  section?.attributes?.every((attribute) =>
                    hasInvalidAttributeValue(attribute)
                  );

                return (
                  <View style={reportsPdfFullStyles.block} key={section.id}>
                    {section.isSectionNameActive &&
                    allDoNotHaveValidValue === false ? (
                      <Text
                        style={reportsPdfFullStyles.bold}
                      >{`• ${section.name[currentLanguage]}`}</Text>
                    ) : null}

                    <ReportsPdfSections
                      section={section}
                      currentLanguage={currentLanguage}
                      patient={patient}
                      doctors={doctors}
                      report={report}
                    />
                  </View>
                );
              })}
            </View>

            <View>
              {report?.notes ? (
                <Text>{`${currentLanguage === 'en' ? 'Notes:' : 'Remarques:'} ${
                  report?.notes
                }`}</Text>
              ) : null}
            </View>
          </View>

          <View style={reportsPdfFullStyles.complimentary}>
            <Text>
              {currentLanguage === 'en'
                ? 'Professionally,'
                : 'Professionnellement,'}
            </Text>
            <Text>{report?.reportingDoctor}</Text>
          </View>
        </View>
      </Page>
    </Document>
  ) : (
    <Document>
      <Page style={reportsPdfFullStyles.page}>
        <View>
          <Text>August 7, 2024</Text>
          <Text>Dr. Doe John</Text>
          <Text>101-5803 rue Sherbrooke E.</Text>
          <Text>Montréal, QC, H1N 1B3</Text>
        </View>

        <Text style={reportsPdfFullStyles.block}>Patient: John Doe</Text>

        <Text style={reportsPdfFullStyles.block}>Report: Reevaluation</Text>

        <Text style={reportsPdfFullStyles.greeting}>Dear Dr. Doe,</Text>

        <View>
          <Text>
            Mariella Ehmann was seen in our office on August 17, 2024. The
            following summarizes the visit.
          </Text>
          <Text>
            The patient was seen for a reevaluation following initial therapy.
          </Text>
          <Text>
            - The following observations were made after the treatment: a
            significant decrease in the number of deep pockets (6 mm or more)
            and a significant decrease in the number of sites presenting
            bleeding on probing
          </Text>
          <Text>
            - However, we noticed localized areas presenting moderate pockets (4
            to 5 mm)
          </Text>
          <Text>The patient's periodontal condition is considered stable</Text>
          <Text>Treatment plan</Text>
          <Text>- Régénération osseuse guidée 14,15,16,17</Text>
          <Text>- Implant(s) 14,16</Text>
          <Text>Maintenance Phase</Text>
          <Text>
            - The patient is currently following a 3 months maintenance phase in
            alternance between our offices
          </Text>
          <Text>
            - The next appointment in your dental office should take place
            around 17 novembre 2024
          </Text>
          <Text>
            If you require any further information, please do not hesitate to
            contact me.
          </Text>
        </View>

        <View style={reportsPdfFullStyles.complimentary}>
          <Text>Professionally,</Text>
          <Text>Dr. Doe John</Text>
        </View>
      </Page>
    </Document>
  );
};

export default ReportsPdfFull;
