export const generateRandomId = () => {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let id = '';

  for (let i = 0; i < 12; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    id += characters.charAt(randomIndex);
  }

  return id;
};

export const compareAndSetState = (newValue, originalValue, stateSetter) => {
  if (newValue !== originalValue) {
    stateSetter(true);
  } else {
    stateSetter(false);
  }
};

export const truncate = (item, length) => {
  if (item && item.length > length) {
    return item.substring(0, length) + '...';
  }
  return item;
};
