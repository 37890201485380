import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { insurancePdfStyles } from '../styles';

const EmployerInfoFr = () => {
  return (
    <View>
      <View style={insurancePdfStyles.instructionsHeading}>
        <Text style={insurancePdfStyles.instructionsHeadingText}>
          2e PARTIE - RENSEIGNEMENTS SUR L’EMPLOYÉ / PARTICIPANT AU RÉGIME
        </Text>
      </View>

      <View style={{ ...insurancePdfStyles.employeeBody, paddingTop: '3px' }}>
        <View style={insurancePdfStyles.employeeBodyContent}>
          <Text style={insurancePdfStyles.smallBold}>
            1. NODU RÉGIMED’ASSURANCE COLLECTIVE
            NO._____________________________NODE LA SECTION OU DE LA DIVISION
            ______________________________________
          </Text>

          <Text style={{ ...insurancePdfStyles.smallBold, marginTop: '5px' }}>
            EMPLOYEUR_______________________________________________________________________________________
          </Text>

          <Text style={{ ...insurancePdfStyles.smallBold, marginTop: '5px' }}>
            NOM DE L’ASSUREUR OU DU RÉGIME
            ________________________________________________________________
          </Text>
        </View>

        <View
          style={{
            ...insurancePdfStyles.employeeBodyContent,
            display: 'flex',
            alignItems: 'flex-end',
          }}
        >
          <Text style={insurancePdfStyles.smallBold}>
            2. NOM (EN LETTRES MOULÉ E
            S)_____________________________________________________________________
          </Text>

          <Text style={{ ...insurancePdfStyles.smallBold, marginTop: '5px' }}>
            NODE CERTIFICAT, D’ASSURANCE SOCIALE OU D’IDENTITÉ
            ____________________________________________
          </Text>

          <View
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Text
              style={{
                ...insurancePdfStyles.smallBold,
                marginTop: '3px',
              }}
            >
              DATE DE NAISSANCE
              _____________________________________________________________________________
            </Text>

            <Text
              style={{
                ...insurancePdfStyles.smallBold,
                marginTop: '3px',
              }}
            >
              JOUR{'        '}MOIS{'        '}ANNÉE
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
};

export default EmployerInfoFr;
