import { useState } from 'react';
import {
  Box,
  Typography,
  useTheme,
  Switch,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
} from '@mui/material';
import { DeleteOutline, ExpandMore } from '@mui/icons-material';
import { useCustomTemplateSectionStyles } from './styles';
import { useLayout } from '../../contexts/LayoutContext';
import AttributeView from '../AttributeView';
import { BiSolidUpArrow, BiSolidDownArrow } from 'react-icons/bi';
import { capitalizeFirstLetters } from '../../utils/helpers';

const SectionAttribute = ({
  attribute,
  currentIndex,
  attributes,
  setAttributes,
}) => {
  const theme = useTheme();
  const { currentLanguage } = useLayout();
  const customTemplateSectionStyles = useCustomTemplateSectionStyles();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isAttributeActive, setIsAttributeActive] = useState(
    attribute?.isAttributeActive
  );
  const handleMoveAttribute = (event, direction, selectedAttributeIndex) => {
    event.stopPropagation();
    const clonedAttributes = [...attributes];

    if (direction === 'up' && selectedAttributeIndex > 0) {
      const temp = clonedAttributes[selectedAttributeIndex - 1];
      clonedAttributes[selectedAttributeIndex - 1] =
        clonedAttributes[selectedAttributeIndex];
      clonedAttributes[selectedAttributeIndex] = temp;
    } else if (
      direction === 'down' &&
      selectedAttributeIndex < clonedAttributes.length - 1
    ) {
      const temp = clonedAttributes[selectedAttributeIndex + 1];

      clonedAttributes[selectedAttributeIndex + 1] =
        clonedAttributes[selectedAttributeIndex];
      clonedAttributes[selectedAttributeIndex] = temp;
    }

    setAttributes(clonedAttributes);
  };

  const handleDeleteAttribute = (id) => {
    const updatedAttributes = [...attributes].filter((attribute) => {
      return attribute.id !== id;
    });

    setAttributes(updatedAttributes);
  };

  const handleToggleSwitch = () => {
    setIsAttributeActive(!isAttributeActive);

    const updatedAttributes = [...attributes];
    updatedAttributes[currentIndex] = {
      ...updatedAttributes[currentIndex],
      isAttributeActive: !isAttributeActive,
    };

    setAttributes(updatedAttributes);
  };

  return (
    <Accordion
      expanded={isExpanded}
      sx={{
        ...customTemplateSectionStyles.accordion,
        opacity: isAttributeActive ? 1 : 0.5,
      }}
      onChange={() => setIsExpanded(!isExpanded)}
    >
      <AccordionSummary
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{ bgcolor: theme.palette.action.focus }}
      >
        <Box sx={customTemplateSectionStyles.accordionSummary}>
          <Box sx={customTemplateSectionStyles.accordionSummaryContent}>
            <IconButton onClick={() => setIsExpanded(!isExpanded)}>
              <ExpandMore />
            </IconButton>

            <Box sx={customTemplateSectionStyles.sectionHeadingIcons}>
              <IconButton
                size="small"
                onClick={(event) =>
                  handleMoveAttribute(event, 'up', currentIndex)
                }
              >
                <BiSolidUpArrow />
              </IconButton>
              <IconButton
                size="small"
                onClick={(event) =>
                  handleMoveAttribute(event, 'down', currentIndex)
                }
              >
                <BiSolidDownArrow />
              </IconButton>
            </Box>

            <Typography>
              {currentLanguage === 'en'
                ? attribute?.name.en
                : attribute?.name.fr}
            </Typography>

            <Box sx={customTemplateSectionStyles.accordionAttributeType}>
              <Typography>
                {capitalizeFirstLetters(attribute?.type?.replace('-', ' '))}
              </Typography>
            </Box>
          </Box>

          <Box sx={customTemplateSectionStyles.accordionSummaryContent}>
            <IconButton onClick={() => handleDeleteAttribute(attribute?.id)}>
              <DeleteOutline color="error" />
            </IconButton>

            <Switch
              checked={isAttributeActive}
              onChange={handleToggleSwitch}
              onClick={(e) => e.stopPropagation()}
            />
          </Box>
        </Box>
      </AccordionSummary>

      <AccordionDetails sx={customTemplateSectionStyles.accordionDetails}>
        <AttributeView
          templatesView
          attribute={attribute}
          attributes={attributes}
          setAttributes={setAttributes}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default SectionAttribute;
