import React from 'react';
import { useUnauthorizedStyles } from './styles';
import { Box, Typography } from '@mui/material';
import { COLORS } from '../../utils/colors';
import { useTranslation } from 'react-i18next';

const Unauthorized = () => {
  const unauthorizedStyles = useUnauthorizedStyles();
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      bgcolor={COLORS.LIGHT_GRAY}
    >
      <Typography style={unauthorizedStyles.text}>
        {t('unauthorizedNotFound')}
      </Typography>
    </Box>
  );
};

export default Unauthorized;
