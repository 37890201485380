import { Box, Typography } from '@mui/material';
import { useAttributeTypeStyles } from '../styles';

const AttributeTemplate = ({ attributeLabel, children }) => {
  const attributeTypeStyles = useAttributeTypeStyles();

  return (
    <Box sx={attributeTypeStyles.sectionAttribute}>
      <Typography
        fontWeight={500}
        sx={attributeTypeStyles.sectionAttributeName}
      >
        {attributeLabel}
      </Typography>

      {children}
    </Box>
  );
};

export default AttributeTemplate;
