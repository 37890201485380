import React from 'react';
import { View, Image, Text } from '@react-pdf/renderer';
import { insurancePdfStyles } from '../styles';
import { mainLogoEn } from '../Images/mainLogoEn';
import { secondaryLogo } from '../Images/secondaryLogo';

const LogosEn = () => {
  return (
    <View style={insurancePdfStyles.head}>
      <Image src={mainLogoEn} style={insurancePdfStyles.mainLogo} />

      <View style={insurancePdfStyles.secondary}>
        <Image src={secondaryLogo} style={insurancePdfStyles.secondaryLogo} />
        <Text style={insurancePdfStyles.secondaryText}>
          Canadian Life and Health{'\n'} Insurance Association Inc.
        </Text>
      </View>

      <Text style={insurancePdfStyles.formTitle}>
        STANDARD MEDICAL CLAIM FORM
      </Text>
    </View>
  );
};

export default LogosEn;
