import React from 'react';
import ModalOverlay from '../../../components/ModalOverlay';
import { Box, IconButton } from '@mui/material';
import { useReportsEditStyles } from './styles';
import { Close } from '@mui/icons-material';
import PatientFile from '../../../components/PatientFile';

const PatientFileModal = ({
  isPatientFileOpen,
  handleClosePatientFile,
  patient,
}) => {
  const reportsEditStyles = useReportsEditStyles();

  return (
    <ModalOverlay
      isModalOpen={isPatientFileOpen}
      handleCloseModal={handleClosePatientFile}
    >
      <Box sx={{ ...reportsEditStyles.modal, width: '90%' }}>
        <IconButton
          onClick={handleClosePatientFile}
          sx={reportsEditStyles.closeButton}
          disableRipple
        >
          <Close />
        </IconButton>

        <PatientFile patient={patient} />
      </Box>
    </ModalOverlay>
  );
};

export default PatientFileModal;
