import { Typography, Box, Button, TextField } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePatientsViewStyles } from './styles';
import { useLayout } from '../../../contexts/LayoutContext';
import { toggleToast } from '../../../utils/helpers/toast';
import { BiCheckDouble, BiSolidEditAlt } from 'react-icons/bi';

const MaintenancePhase = ({ patient }) => {
  const { t } = useTranslation();
  const { patients, setPatients } = useLayout();
  const patientsViewStyles = usePatientsViewStyles();
  const [isEditing, setIsEditing] = useState(false);
  const [maintenancePhase, setMaintenancePhase] = useState(
    patient?.maintenancePhase || ''
  );

  const handleClick = () => {
    if (isEditing) {
      const updatedItems = [...patients];
      const currentIndex = patients.findIndex((item) => item.id === patient.id);

      updatedItems[currentIndex] = {
        ...updatedItems[currentIndex],
        maintenancePhase,
      };

      setPatients(updatedItems);
      toggleToast('success', t('patientsViewSavedSuccessfully'));
      setIsEditing(false);
    } else {
      setIsEditing(true);
    }
  };

  return (
    <Box>
      <Box sx={patientsViewStyles.flexBetween}>
        <Typography variant="h4" fontWeight={500}>
          {t('patientsViewMaintenancePhase')}
        </Typography>

        <Button
          startIcon={isEditing ? <BiCheckDouble /> : <BiSolidEditAlt />}
          variant="outlined"
          size="small"
          onClick={handleClick}
        >
          {isEditing
            ? t('patientsViewSaveButton')
            : t('patientsViewEditButton')}
        </Button>
      </Box>

      {isEditing ? (
        <TextField
          rows={8}
          size="large"
          fullWidth
          label={t('patientsViewNotes')}
          multiline
          sx={{ marginTop: '12px' }}
          value={maintenancePhase}
          onChange={({ target }) => setMaintenancePhase(target.value)}
        />
      ) : (
        <Typography>{maintenancePhase}</Typography>
      )}
    </Box>
  );
};

export default MaintenancePhase;
