import { Routes, Route } from 'react-router-dom';
import MedicalHistory from './MedicalHistory';
import ClinicalExam from './ClinicalExam';
import Insurance from './TreatmentPlan/Insurance';
import PhaseI from './PhaseI';
import PhaseII from './PhaseII';

const PatientsAttributes = () => {
  return (
    <Routes>
      <Route path="medical-history" element={<MedicalHistory />} />
      <Route path="clinical-exam" element={<ClinicalExam />} />
      <Route path="treatment-plan" element={<PhaseI />} />
      <Route path="phaseII" element={<PhaseII />} />
      <Route path="treatment-plan/form" element={<Insurance />} />
    </Routes>
  );
};

export default PatientsAttributes;
