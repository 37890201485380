export const reportsEmailStyles = {
  editor: {
    height: 250,
    width: '100%',
    menubar: false,
    plugins: ['link'],
    toolbar: `bold italic underline bullist numlist link help`,
    content_style:
      'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
  },
  editorWrapper: {
    marginBlock: '24px',
    borderRadius: '10px',
  },
  sendBtn: { color: 'white', marginLeft: '12px' },
  preAttached: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '24px',
  },
  preAttachedText: {
    marginLeft: '24px',
    width: '80%',
  },
  additional: {
    marginTop: '12px',
    display: 'flex',
    flexDirection: 'column',
  },
  additionalFiles: {
    marginBottom: '12px',
  },
  optionButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: '24px',
  },
};
