import React from 'react';
import AttributeTemplate from '.';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TextField } from '@mui/material';

const DateAttribute = ({
  attributeLabel,
  attributeValue,
  setAttributeValue,
}) => {
  return (
    <AttributeTemplate attributeLabel={attributeLabel}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
          inputFormat="DD/MM/YYYY"
          value={attributeValue || new Date()}
          onChange={(newDate) => setAttributeValue(newDate)}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              id="date"
              name="date"
              error={false}
            />
          )}
        />
      </LocalizationProvider>
    </AttributeTemplate>
  );
};

export default DateAttribute;
