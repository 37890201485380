import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { insurancePdfStyles } from '../styles';

const InsuranceTotalsFr = () => {
  return (
    <View style={insurancePdfStyles.treatmentPlanCarrier}>
      <Text
        style={{
          ...insurancePdfStyles.smallBold,
          marginVertical: '1px',
        }}
      >
        RÉSERVÉ À L’ASSUREUR
      </Text>

      <View>
        <View
          style={{
            ...insurancePdfStyles.treatmentPlanCells,
            borderTop: '0.9px solid black',
          }}
        >
          <View
            style={{
              ...insurancePdfStyles.treatmentPlanCellItem,
              width: '35%',
            }}
          >
            <Text style={insurancePdfStyles.smallBold}>MONTANT ADMIS</Text>
          </View>

          <View
            style={{
              ...insurancePdfStyles.treatmentPlanCellItem,
              width: '15%',
            }}
          >
            <Text style={insurancePdfStyles.smallBold}>FRÉQ.</Text>
          </View>

          <View
            style={{
              ...insurancePdfStyles.treatmentPlanCellItem,
              width: '15%',
            }}
          >
            <Text style={insurancePdfStyles.smallBold}>%</Text>
          </View>

          <View
            style={{
              ...insurancePdfStyles.treatmentPlanCellItem,
              width: '35%',
              borderRightWidth: 0,
            }}
          >
            <Text style={insurancePdfStyles.smallBold}>
              MONTANT PAYABLE{'\n'} PAR LE PATIENT
            </Text>
          </View>
        </View>

        {Array(6)
          .fill('')
          .map((_, index) => {
            return (
              <View key={index} style={insurancePdfStyles.treatmentPlanCells}>
                <View
                  style={{
                    ...insurancePdfStyles.treatmentPlanCellItem,
                    width: '35%',
                  }}
                />

                <View
                  style={{
                    ...insurancePdfStyles.treatmentPlanCellItem,
                    width: '15%',
                  }}
                />

                <View
                  style={{
                    ...insurancePdfStyles.treatmentPlanCellItem,
                    width: '15%',
                  }}
                />

                <View
                  style={{
                    ...insurancePdfStyles.treatmentPlanCellItem,
                    width: '35%',
                    borderRightWidth: 0,
                  }}
                />
              </View>
            );
          })}

        <View
          style={{
            ...insurancePdfStyles.flexBetween,
            justifyContent: 'flex-start',
            borderBottom: '0.9px solid black',
          }}
        >
          <View
            style={{
              ...insurancePdfStyles.treatmentPlanChequeSection,
              padding: '3px 3px 6px 3px',
            }}
          >
            <Text style={insurancePdfStyles.smallBold}>NO DU CHÈQUE</Text>
          </View>

          <View
            style={{
              ...insurancePdfStyles.treatmentPlanChequeSection,
              borderRightWidth: 0,
              padding: '3px 3px 6px 3px',
            }}
          >
            <Text style={insurancePdfStyles.smallBold}>DATE</Text>
          </View>
        </View>

        <View
          style={{
            ...insurancePdfStyles.flexBetween,
            justifyContent: 'flex-start',
            borderBottom: '0.9px solid black',
          }}
        >
          <View
            style={{
              ...insurancePdfStyles.treatmentPlanDeductibleSection,
              padding: '3px 3px 12px 3px',
            }}
          >
            <Text style={insurancePdfStyles.smallBold}>FRANCHISE</Text>
          </View>

          <View
            style={{
              ...insurancePdfStyles.treatmentPlanDeductibleSection,
              padding: '3px 3px 6px 3px',
            }}
          >
            <Text style={insurancePdfStyles.smallBold}>
              PAIEMENT DU PATIENT
            </Text>
          </View>

          <View
            style={{
              ...insurancePdfStyles.treatmentPlanDeductibleSection,
              borderRightWidth: 0,
              padding: '3px 3px 6px 3px',
            }}
          >
            <Text style={insurancePdfStyles.smallBold}>
              PAIEMENT DE L’ASSUREUR
            </Text>
          </View>
        </View>

        <View style={insurancePdfStyles.flexBetween}>
          <View
            style={{
              ...insurancePdfStyles.treatmentPlanChequeSection,
              width: '80%',
              minHeight: '8px',
            }}
          >
            <Text style={insurancePdfStyles.smallBold}>
              NODE LA DEMANDE D’INDEMNISATION
            </Text>
          </View>

          <View
            style={{
              borderRightWidth: 0,
            }}
          />
        </View>
      </View>
    </View>
  );
};

export default InsuranceTotalsFr;
