import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { insurancePdfStyles } from '../styles';

const DoctorUseFr = () => {
  return (
    <View style={insurancePdfStyles.section}>
      <View style={insurancePdfStyles.doctorUseAdditional}>
        <Text style={insurancePdfStyles.smallBold}>
          RÉSERVÉ AU MÉDICIN POUR RENSEIGNEMENTS COMPLÉMENTAIRES SUR LE
          DIAGNOSTIC, LES PROCÉDURES OU AUTRES CONSIDÉRATIONS PARTICULIÈRES.
        </Text>
      </View>

      <View style={insurancePdfStyles.doctorUseUndertaking}>
        <View style={insurancePdfStyles.doctorUseUndertakingText}>
          <Text style={insurancePdfStyles.smallBold}>
            IL SE PEUT QUE LES FRAIS INDIQUÉS SUR CETTE DEMANDE NE SOIENT PAS
            COUVERTS PAR LE RÉGIME AUQUEL JE PARTICIPE OU QU’ILS NE SOIENT
            COUVERTS QU’EN PARTIE. IL M’INCOMBE DONC DE VOIR À CE QUE MON
            MÉDICIN SOIT RÉMUNÉRÉ POUR TOUS LES SOINS RENDUS. JE RECONNAIS QUE
            LE TOTAL DES HONORAIRES S’ÉLÈVE À ${'                    '}. QUE CE
            MONTANT EST EXACT ET QU’IL M’A ÉTÉ FACTURÉ POUR LES SOINS REÇUS. JE
            CONSENS À CE QUE TOUS LES RENSEIGNEMENTS CONTENUS DANS LA PRÉSENTE
            DEMANDE SOIENT DIVULGUÉS À L’ASSUREUR OU À L’ADMINISTRATEUR DU
            RÉGIME. J'AUTORISE ÉGALEMENT LA DIVULGATION DE L'INFORMATION LIÉE À
            LA COUVERTURE DES SERVICES DÉCRITS DANS LE PRÉSENT FORMULAIRE AU
            MÉDICIN NOMMÉMENT DÉSIGNÉ.
          </Text>

          <View
            style={{
              ...insurancePdfStyles.doctorUseSignature,
              marginTop: '2px',
              paddingVertical: '1px',
            }}
          >
            <Text style={insurancePdfStyles.smallBold}>
              SIGNATURE DU PATIENT, OU DU TUTEUR
            </Text>
          </View>
        </View>

        <View style={insurancePdfStyles.doctorUseVerification}>
          <Text
            style={{
              ...insurancePdfStyles.smallBold,
              marginBottom: '12px',
            }}
          >
            VÉRIFICATION
          </Text>
        </View>
      </View>
    </View>
  );
};

export default DoctorUseFr;
