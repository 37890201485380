export const TREATMENT_PLAN_TIME_OPTIONS = [
  {
    name: '10',
    value: '10',
  },
  {
    name: '15',
    value: '15',
  },
  {
    name: '20',
    value: '20',
  },
  {
    name: '30',
    value: '30',
  },
  {
    name: '40',
    value: '40',
  },
  {
    name: '45',
    value: '45',
  },
  {
    name: '50',
    value: '50',
  },
  {
    name: '60',
    value: '60',
  },
  {
    name: '70(1h10)',
    value: '70',
  },
  {
    name: '80(1h20)',
    value: '80',
  },
  {
    name: '90(1h30)',
    value: '90',
  },
  {
    name: '100(1h40)',
    value: '100',
  },
  {
    name: '110(1h50)',
    value: '110',
  },
  {
    name: '120(2h00)',
    value: '120',
  },
  {
    name: '130(2h10)',
    value: '130',
  },
  {
    name: '135(2h15)',
    value: '135',
  },
];

export const TREATMENT_PLAN_RESPONSIBILITY_OPTIONS = [
  { name: 'treatmentPlanDoctor', value: 'doctor' },
  { name: 'treatmentPlanHygienist', value: 'hygienist' },
  { name: 'treatmentPlanEither', value: 'either' },
];

export const TREATMENT_PLAN_CODES = [
  { name: 'treatmentPlanPeriodontal', value: 'periodontal' },
  { name: 'treatmentPlanConsultation', value: 'consultation' },
  { name: 'treatmentPlanReevaluation', value: 'reevaluation' },
  { name: 'treatmentPlanPanoramic', value: 'panoramic' },
  { name: 'treatmentPlanImplants', value: 'implants' },
  { name: 'treatmentPlanTissueGraft', value: 'tissue-graft' },
  { name: 'treatmentPlanFibrotomy', value: 'fibrotomy' },
  { name: 'treatmentPlanBiopsy', value: 'biopsy' },
  { name: 'treatmentPlanDistalWedge', value: 'distal-wedge' },
  { name: 'treatmentPlanAnesthesia', value: 'anesthesia' },
  { name: 'treatmentPlanDrainageIncision', value: 'drainage-incision' },
  { name: 'treatmentPlanMandibualFrenom', value: 'mandibual-frenom' },
  { name: 'treatmentPlanLingualFrenom', value: 'lingual-frenom' },
  { name: 'treatmentPlanSimpleExtraction', value: 'simple-extraction' },
  { name: 'treatmentPlanToothExposure', value: 'tooth-exposure' },
  { name: 'treatmentPlanSurgocalGuide', value: 'surgical-guide' },
];

export const TREATMENT_PLAN_PRECISIONS = [
  [
    { name: 'treatmentPlanBuccal', value: 'buccal' },
    { name: 'treatmentPlanLingual', value: 'lingual' },
  ],
  [
    { name: 'treatmentPlanWithAnesthesia', value: 'with-anesthesia' },
    { name: 'treatmentPlanWithCetacaine', value: 'with-cetacaine' },
  ],
  [
    { name: 'treatmentPlanWithAnesthesia', value: 'with-anesthesia' },
    { name: 'treatmentPlanWithCetacaine', value: 'with-cetacaine' },
  ],
  [
    { name: 'treatmentPlanFullMouth', value: 'full-mouth' },
    { name: 'treatmentPlanAffectedAreas', value: 'affected-areas' },
    { name: 'treatmentPlanCetacaine', value: 'cetacaine' },
  ],
  [
    { name: 'treatmentPlanBuccal', value: 'buccal' },
    { name: 'treatmentPlanLingual', value: 'lingual' },
  ],
  [],
  [],
  [
    { name: 'treatmentPlanFullMouth', value: 'full-mouth' },
    { name: 'treatmentPlanAffectedAreas', value: 'affected-areas' },
    { name: 'treatmentPlanCetacaine', value: 'cetacaine' },
  ],
  [
    { name: 'treatmentPlanBuccal', value: 'buccal' },
    { name: 'treatmentPlanLingual', value: 'lingual' },
  ],
  [
    { name: 'treatmentPlanWithAnesthesia', value: 'with-anesthesia' },
    { name: 'treatmentPlanWithCetacaine', value: 'with-cetacaine' },
  ],
  [],
  [
    { name: 'treatmentPlanBuccal', value: 'buccal' },
    { name: 'treatmentPlanLingual', value: 'lingual' },
  ],
  [
    { name: 'treatmentPlanFullMouth', value: 'full-mouth' },
    { name: 'treatmentPlanAffectedAreas', value: 'affected-areas' },
    { name: 'treatmentPlanCetacaine', value: 'cetacaine' },
  ],
  [],
  [],
  [
    { name: 'treatmentPlanFullMouth', value: 'full-mouth' },
    { name: 'treatmentPlanAffectedAreas', value: 'affected-areas' },
    { name: 'treatmentPlanCetacaine', value: 'cetacaine' },
  ],
];

export const TREATMENT_PLAN_INSURANCE_FORMAT = [
  { name: 'treatmentPlanEstimate', value: 'estimate' },
  { name: 'treatmentPlanClaim', value: 'claim' },
  { name: 'treatmentPlanList', value: 'list' },
];
