import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { insurancePdfStyles } from '../styles';

const TreatmentPlanFr = ({ record, totalFeeSubmitted }) => {
  return (
    <View style={insurancePdfStyles.treatmentPlanRecord}>
      <View
        style={{
          ...insurancePdfStyles.treatmentPlanRecordHeading,
          padding: '2px',
        }}
      >
        <View style={insurancePdfStyles.treatmentPlanDateOfService}>
          <Text style={insurancePdfStyles.smallBold}>DATE DU TRAITEMENT</Text>

          <View style={insurancePdfStyles.flexBetween}>
            <Text style={insurancePdfStyles.smallBold}>JOUR</Text>
            <Text style={insurancePdfStyles.smallBold}>MOIS</Text>
            <Text style={insurancePdfStyles.smallBold}>ANNÉE</Text>
          </View>
        </View>

        <Text style={insurancePdfStyles.treatmentPlanHeadingText}>
          CODE DE LA PROCÉDURE
        </Text>
        <Text style={insurancePdfStyles.treatmentPlanHeadingText}>
          CODE INT. DES DENTS
        </Text>
        <Text style={insurancePdfStyles.treatmentPlanHeadingText}>
          SURFACE DES DENTS
        </Text>
        <Text style={insurancePdfStyles.treatmentPlanHeadingText}>
          HONORAIRES DU MÉDICIN
        </Text>
        <Text style={insurancePdfStyles.treatmentPlanHeadingText}>
          FRAIS DE LABORATOIRE
        </Text>
        <Text style={insurancePdfStyles.treatmentPlanHeadingText}>
          TOTAL DES FRAIS
        </Text>
      </View>

      <View
        style={{
          ...insurancePdfStyles.treatmentPlanRecordBody,
          minHeight: '123px',
        }}
      >
        {record.map((item) => {
          return (
            <View
              style={{
                ...insurancePdfStyles.treatmentPlanRecordHeading,
                borderBottomWidth: 0,
                padding: '2px',
              }}
              key={item.id}
            >
              <Text
                style={{
                  ...insurancePdfStyles.treatmentPlanValue,
                  width: '15%',
                }}
              >
                {item.dateOfService}
              </Text>
              <Text style={insurancePdfStyles.treatmentPlanValue}>
                {item.procedureCode}
              </Text>
              <Text style={insurancePdfStyles.treatmentPlanValue}>
                {item.toothCode}
              </Text>

              <Text style={insurancePdfStyles.treatmentPlanValue}>
                {item.toothSurfaces}
              </Text>
              <Text style={insurancePdfStyles.treatmentPlanValue}>
                {item.doctorFee}
              </Text>
              <Text style={insurancePdfStyles.treatmentPlanValue}>
                {item.labCharge}
              </Text>
              <Text style={insurancePdfStyles.treatmentPlanValue}>
                {item.total}
              </Text>
            </View>
          );
        })}
      </View>

      <View
        style={{
          ...insurancePdfStyles.flexBetween,
          justifyContent: 'flex-start',
        }}
      >
        <View style={insurancePdfStyles.treatmentPlanRecordFooterLeft}>
          <Text style={insurancePdfStyles.smallBold}>
            LA PRÉSENTE EST UNE DÉCLARATION EXACTE DES SOINS RENDUS ET DES
            HONORAIRES DEMANDÉS, SAUF ERREURS OU OMISSIONS.
          </Text>
        </View>

        <View style={insurancePdfStyles.treatmentPlanTotalFee}>
          <Text
            style={{
              ...insurancePdfStyles.smallBold,
              fontSize: '7px',
            }}
          >
            TOTAL DES HONORAIRES DEMANDÉS
          </Text>

          <Text
            style={{
              ...insurancePdfStyles.pdfValue,
              marginLeft: '12px',
            }}
          >
            {`$${totalFeeSubmitted}`}
          </Text>
        </View>
      </View>
    </View>
  );
};

export default TreatmentPlanFr;
