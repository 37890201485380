import { useTheme } from '@mui/material';

export const usePatientsAttributesStyles = () => {
  const theme = useTheme();

  return {
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    buttonIcon: {
      marginRight: '4px',
    },
    button: { marginRight: '12px' },
    entryHeading: {
      padding: '12px',
      borderRadius: '5px 5px 0 0',
      bgcolor: theme.palette.action.focus,
    },
    entryBody: {
      paddingBlock: '0 12px',
    },
    entryAttribute: {
      padding: '24px 12px 24px 24px',
      display: 'flex',
      alignItems: 'center',
    },
    entryAttributeSwitch: {
      display: 'flex',
      alignItems: 'center',
      paddingBlock: '8px',
      justifyContent: 'space-between',
      paddingLeft: '24px',
    },
    entryAttributeName: { marginRight: '24px', width: '20%' },
    entryTextFields: { bgcolor: 'white', width: '80%' },
    accordionWrapper: {
      marginTop: '18px',
    },
    modal: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      overflow: 'auto',
      width: '70%',
      height: '90%',
      bgcolor: 'background.paper',
      borderRadius: 2,
      boxShadow: 24,
      p: 4,
    },
    closeButton: {
      position: 'absolute',
      top: '5px',
      right: 4,
      zIndex: 20,
    },
  };
};
