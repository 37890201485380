import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useDoctorsSearchStyles } from './styles';
import { useLayout } from '../../../contexts/LayoutContext';

export const useDoctorsSearch = () => {
  const { t } = useTranslation();
  const { patients } = useLayout();
  const doctorsSearchStyles = useDoctorsSearchStyles();

  const doctorsSearchColumns = [
    {
      field: 'fullName',
      headerName: t('doctorsSearchName'),
      flex: 0.3,
      minWidth: 150,
      renderCell: ({ row: { id, lastName, firstName } }) => (
        <Link to={`${id}`} style={doctorsSearchStyles.link}>
          <Typography sx={doctorsSearchStyles.name}>
            {lastName}, {firstName}
          </Typography>
        </Link>
      ),
      valueGetter: ({ row: { lastName, firstName } }) => {
        return `${lastName} ${firstName}`;
      },
    },
    {
      field: 'address',
      flex: 0.5,
      minWidth: 200,
      headerName: t('doctorsSearchAddress'),
      valueGetter: ({ row: { addresses } }) => {
        return addresses?.map(({ addressLine }) => addressLine).join(' | ');
      },
    },
    {
      field: 'patients',
      flex: 0.2,
      minWidth: 150,
      headerName: t('doctorsSearchPatientsCount'),
      renderCell: ({ row: { id } }) => {
        const relatedPatients = patients?.filter((patient) => {
          return patient?.doctors.find((doctorId) => id === doctorId);
        });

        return (
          <Typography sx={doctorsSearchStyles.patientsColumn}>
            {relatedPatients?.length || 0} Patient(s)
          </Typography>
        );
      },
    },
  ];

  return { doctorsSearchColumns };
};
