import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { insurancePdfStyles } from '../styles';

const HowToFr = () => {
  return (
    <View>
      <View style={insurancePdfStyles.instructionsHeading}>
        <Text style={insurancePdfStyles.instructionsHeadingText}>
          COMMENT PRÉSENTER LA DEMANDE
        </Text>
      </View>

      <View style={{ padding: '5px 3px' }}>
        <Text style={insurancePdfStyles.smallBold}>
          PUISQU’IL S’AGIT D’UN FORMULAIRE NORMALISÉ, LA DEMANDE NE PRÉCISE PAS
          L’ENDROIT OÙ ELLE DOIT ÊTRE ADRESSÉE. CE RENSEIGNEMENT DÉPEND DE
          L’ASSUREUR. AUSSI CONVIENT-IL DE CONSULTER VOTRE LIVRET D’ASSURANCE,
          LA POLICE D’ASSURANCE OU VOTRE EMPLOYEUR POUR EN CONNAÎTRE LES DÉTA
          ILS .{'\n'} SI LA DEMANDE DOIT ÊTRE ENVOYÉE DIRECTEMENT À L’ASSUREUR,
          REMPLIR SEULEMENT LES PARTIES 1, 2 ET 3 DU FORMULAIRE ET L’ADRESSER AU
          SERVICE DES DEMANDES D’INDEMNISATION INDIQUÉ PAR L’ASSURE UR. * SI LA
          DEMANDE DOIT ÊTRE REMISE À VOTRE EMPLOYEUR, VEUILLEZ LA PRÉSENTER AU
          BUREAU DU PERSONNEL OU À L’ADMINISTRATEUR DU RÉGIME QUI EN REMPLIRA LA
          PARTIE 4 ET ENVERRA LE DOCUMENT À L’ASSUREUR.
        </Text>
      </View>
    </View>
  );
};

export default HowToFr;
