import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Grid,
  styled,
  useTheme,
  Typography,
  Table,
  TableCell,
  TableRow,
  TableBody,
  Button,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLayout } from '../../../contexts/LayoutContext';
import * as Layout from '../../../layouts';
import { DeleteOutline } from '@mui/icons-material';
import DeleteModal from '../../../components/DeleteModal';
import { useDoctorsViewStyles } from './styles';
import { toggleToast } from '../../../utils/helpers/toast';
import { BiSolidEditAlt } from 'react-icons/bi';

const DoctorsView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const doctorsViewStyles = useDoctorsViewStyles();
  const { setPageTitle, doctors, patients, setDoctors, setPatients } =
    useLayout();
  const theme = useTheme();
  const doctor = doctors.find((doctor) => doctor?.id === id);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  }));
  const relatedPatients = patients?.filter((patient) =>
    patient?.doctors.find((id) => id === doctor?.id)
  );

  const handleCloseDeleteModal = () => setIsDeleteModalOpen(false);
  const handleCreateRowData = (key, value, type) => {
    return { key, value, type };
  };
  const handleDeleteDoctor = () => {
    const updatedDoctors = doctors.filter(({ id }) => id !== doctor?.id);

    setPatients((prevPatients) => {
      return prevPatients.map((patient) => {
        if (patient.doctors && patient.doctors.length > 0) {
          patient.doctors = patient.doctors.filter(
            ({ id }) => id !== doctor?.id
          );
        }
        return patient;
      });
    });
    setDoctors(updatedDoctors);
    toggleToast(
      'success',
      `${doctor?.lastName} ${t('doctorsViewDeletedSuccessfully')}`
    );
    navigate('/doctors');
  };

  const rows = [
    handleCreateRowData(
      t('doctorsViewName'),
      `${doctor?.lastName}, ${doctor?.firstName} ${doctor?.middleName}` || ''
    ),
    handleCreateRowData(
      t('doctorsViewAddress'),
      doctor?.addresses.sort((_a, b) => {
        if (b.primary) return 1;
        return -1;
      }) || []
    ),
    handleCreateRowData(
      'Contact',
      doctor?.contacts.sort((_a, b) => {
        if (b.primary) return 1;
        return -1;
      }) || []
    ),
    handleCreateRowData('Patient(s)', relatedPatients || []),
  ];

  useEffect(() => {
    setPageTitle(`${doctor?.lastName}, ${doctor?.firstName}`);
  }, [doctor, setPageTitle]);

  return (
    <Layout.Main notFound={!doctor?.id}>
      <DeleteModal
        isDeleteModalOpen={isDeleteModalOpen}
        handleCloseDeleteModal={handleCloseDeleteModal}
        handleConfirm={handleDeleteDoctor}
        isProtected
      />

      <Box display="flex" alignItems="center" justifyContent="flex-end">
        <Button
          sx={{ mr: '12px' }}
          variant="outlined"
          onClick={() => navigate(`/doctors/edit/${doctor?.id}`)}
          startIcon={<BiSolidEditAlt />}
        >
          {t('doctorsViewEditButton')}
        </Button>
        <Button
          startIcon={<DeleteOutline />}
          variant="contained"
          color="error"
          onClick={() => setIsDeleteModalOpen(true)}
        >
          {t('doctorsViewDeleteButton')}
        </Button>
      </Box>

      <Grid container>
        <Grid item xs={12} marginBottom={'24px'}>
          <Box>
            <Table sx={doctorsViewStyles.table}>
              <TableBody>
                {rows.map(({ key, value, type }) => {
                  return (
                    <StyledTableRow key={key}>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={doctorsViewStyles.tableCell}
                      >
                        {key}
                      </TableCell>

                      <TableCell component="th" scope="row">
                        {type === 'email' ? (
                          <Link
                            style={doctorsViewStyles.link}
                            to={`mailto:${value}`}
                          >
                            <Typography sx={doctorsViewStyles.link}>
                              {value}
                            </Typography>
                          </Link>
                        ) : key === 'Patient(s)' ? (
                          value.map(({ lastName, firstName, id }) => {
                            const patientName = `${lastName} ${firstName}`;

                            return (
                              <span
                                key={id}
                                style={doctorsViewStyles.patient}
                                onMouseOver={(e) => {
                                  e.target.style.color =
                                    theme.palette.info.dark;
                                }}
                                onMouseOut={(e) => {
                                  e.target.style.color =
                                    theme.palette.primary.main;
                                }}
                                onClick={() => navigate(`/patients/${id}`)}
                              >
                                {id ===
                                relatedPatients[relatedPatients?.length - 1].id
                                  ? patientName + ''
                                  : patientName + ', '}
                              </span>
                            );
                          })
                        ) : key === t('doctorsViewAddress') ? (
                          value.map((item) => {
                            const { id, city, addressLine, province } = item;
                            return (
                              <span key={id}>
                                <span>{addressLine}</span>
                                {province && <span>{`, ${province}`}</span>}
                                {city && <span>{`, ${city}`}</span>}
                                {item?.primary && (
                                  <span style={{ fontWeight: 'bold' }}>
                                    {` (${t('patientsViewPrimary')})`}
                                  </span>
                                )}
                                <span key={id}>
                                  {id ===
                                  doctor?.addresses[
                                    doctor?.addresses.length - 1
                                  ].id
                                    ? ''
                                    : ' | '}
                                </span>
                              </span>
                            );
                          })
                        ) : key === 'Contact' ? (
                          value.map((item) => {
                            const { id, value } = item;

                            return (
                              <span key={id}>
                                {`${value}`}
                                {item?.primary && (
                                  <span style={{ fontWeight: 'bold' }}>
                                    {` (${t('patientsViewPrimary')})`}
                                  </span>
                                )}
                                {id ===
                                doctor?.contacts[doctor?.contacts.length - 1].id
                                  ? ''
                                  : ', '}
                              </span>
                            );
                          })
                        ) : (
                          value
                        )}
                      </TableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
        </Grid>
      </Grid>
    </Layout.Main>
  );
};

export default DoctorsView;
