import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { insurancePdfStyles } from '../styles';

const TreatmentPlanEn = ({ totalFeeSubmitted, record }) => {
  return (
    <View style={insurancePdfStyles.treatmentPlanRecord}>
      <View style={insurancePdfStyles.treatmentPlanRecordHeading}>
        <View style={insurancePdfStyles.treatmentPlanDateOfService}>
          <Text style={insurancePdfStyles.smallBold}>DATE OF SERVICE</Text>

          <View style={insurancePdfStyles.flexBetween}>
            <Text style={insurancePdfStyles.smallBold}>DAY</Text>
            <Text style={insurancePdfStyles.smallBold}>MO.</Text>
            <Text style={insurancePdfStyles.smallBold}>YR.</Text>
          </View>
        </View>

        <Text style={insurancePdfStyles.treatmentPlanHeadingText}>
          PROCEDURE CODE
        </Text>
        <Text style={insurancePdfStyles.treatmentPlanHeadingText}>
          INTL. TOOTH CODE
        </Text>
        <Text style={insurancePdfStyles.treatmentPlanHeadingText}>
          TOOTH SURFACES
        </Text>
        <Text style={insurancePdfStyles.treatmentPlanHeadingText}>
          DOCTOR'S FEE
        </Text>
        <Text style={insurancePdfStyles.treatmentPlanHeadingText}>
          LABORATORY CHARGE
        </Text>
        <Text style={insurancePdfStyles.treatmentPlanHeadingText}>
          TOTAL CHARGES
        </Text>
      </View>

      <View style={insurancePdfStyles.treatmentPlanRecordBody}>
        {record.map((item) => {
          return (
            <View
              style={{
                ...insurancePdfStyles.treatmentPlanRecordHeading,
                borderBottomWidth: 0,
                padding: '2px',
              }}
              key={item.id}
            >
              <Text
                style={{
                  ...insurancePdfStyles.treatmentPlanValue,
                  width: '15%',
                }}
              >
                {item.dateOfService}
              </Text>
              <Text style={insurancePdfStyles.treatmentPlanValue}>
                {item.procedureCode}
              </Text>
              <Text style={insurancePdfStyles.treatmentPlanValue}>
                {item.toothCode}
              </Text>

              <Text style={insurancePdfStyles.treatmentPlanValue}>
                {item.toothSurfaces}
              </Text>
              <Text style={insurancePdfStyles.treatmentPlanValue}>
                {item.doctorFee}
              </Text>
              <Text style={insurancePdfStyles.treatmentPlanValue}>
                {item.labCharge}
              </Text>
              <Text style={insurancePdfStyles.treatmentPlanValue}>
                {item.total}
              </Text>
            </View>
          );
        })}
      </View>

      <View
        style={{
          ...insurancePdfStyles.flexBetween,
          justifyContent: 'flex-start',
        }}
      >
        <View style={insurancePdfStyles.treatmentPlanRecordFooterLeft}>
          <Text style={insurancePdfStyles.smallBold}>
            THIS IS AN ACCURATE STATEMENT OF SERVICES PERFORMED AND THE TOTAL
            FEE DUE AND PAYABLE, E & OE.
          </Text>
        </View>

        <View style={insurancePdfStyles.treatmentPlanTotalFee}>
          <Text
            style={{
              ...insurancePdfStyles.smallBold,
              fontSize: '10px',
            }}
          >
            TOTAL FEE SUBMITTED
          </Text>

          <Text
            style={{
              ...insurancePdfStyles.pdfValue,
              marginLeft: '12px',
            }}
          >
            {`$${totalFeeSubmitted}`}
          </Text>
        </View>
      </View>
    </View>
  );
};

export default TreatmentPlanEn;
