import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import GeneralInfo from '../../pages/Patients/PatientsView/GeneralInfo';
import MedicalHistory from '../../pages/Patients/PatientsView/MedicalHistory';
import ClinicalExam from '../../pages/Patients/PatientsView/ClinicalExam';
import MaintenancePhase from '../../pages/Patients/PatientsView/MaintenancePhase';
import Logs from '../../pages/Patients/PatientsView/Logs';
import PhaseI from '../../pages/Patients/PatientsView/TreatmentPlan/PhaseI';
import PhaseII from '../../pages/Patients/PatientsView/TreatmentPlan/PhaseII';

const PatientFileSections = ({ patient, sections }) => {
  const theme = useTheme();

  return (
    <Box width="100%">
      {sections.map((SectionComponent, index) => (
        <Grid
          key={index}
          item
          md={12}
          padding="12px 0"
          sx={{ borderBottom: `1px solid ${theme.palette.action.focus}` }}
        >
          <SectionComponent patient={patient} />
        </Grid>
      ))}
    </Box>
  );
};

const PatientFile = ({ patient, style }) => {
  const theme = useTheme();
  const customBreakpoint = useMediaQuery('(max-width: 1200px)');
  const sections = [ClinicalExam, PhaseI, PhaseII, MaintenancePhase, Logs];

  return (
    <Grid container sx={style || {}}>
      <Grid
        item
        rowSpacing={2}
        xs={12}
        paddingBottom="12px"
        container
        sx={{ borderBottom: `1px solid ${theme.palette.action.focus}` }}
      >
        <Grid xs={12} md={customBreakpoint ? 12 : 5.5} item>
          <GeneralInfo patient={patient} />
        </Grid>

        <Grid xs={12} md={customBreakpoint ? 12 : 6.5} item>
          <MedicalHistory patient={patient} />
        </Grid>
      </Grid>
      <PatientFileSections patient={patient} sections={sections} />
    </Grid>
  );
};

export default PatientFile;
