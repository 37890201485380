import { Button } from '@mui/material';
import React from 'react';

const CustomButton = ({
  children,
  handleClick,
  variant = 'outlined',
  style,
  disabled,
  startIcon,
}) => {
  return (
    <Button
      color="primary"
      variant={variant}
      onClick={handleClick}
      style={style}
      disabled={disabled}
      startIcon={startIcon}
    >
      {children}
    </Button>
  );
};

export default CustomButton;
