import { Box, Typography, useTheme, IconButton } from '@mui/material';
import { useTemplatesEditStyles } from './styles';
import { FaSort } from 'react-icons/fa';
import { useLayout } from '../../../contexts/LayoutContext';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import { Close } from '@mui/icons-material';
import ModalOverlay from '../../../components/ModalOverlay';

const SortSectionsModal = ({
  sections,
  isSortSectionsModalOpen,
  handleCloseSortSectionsModal,
  setSections,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dropIndex = useRef(null);
  const draggedItemIndex = useRef(null);
  const { currentLanguage } = useLayout();
  const templatesEditStyles = useTemplatesEditStyles();
  const [sortedSections, setSortedSections] = useState();
  const handleDragEnter = (index) => {
    const clonedSections = [...sections];
    const draggedItem = clonedSections.splice(draggedItemIndex.current, 1)[0];

    dropIndex.current = index;
    clonedSections.splice(dropIndex.current, 0, draggedItem);
    setSortedSections(clonedSections);
  };

  const handleDragEnd = () => {
    const clonedSections = [...sections];
    const draggedItem = clonedSections.splice(draggedItemIndex.current, 1)[0];

    clonedSections.splice(dropIndex.current, 0, draggedItem);
    draggedItemIndex.current = null;
    dropIndex.current = null;
    setSections(clonedSections);
    setSortedSections(clonedSections);
  };

  useEffect(() => {
    setSortedSections(sections);
  }, [sections]);

  return (
    <ModalOverlay
      isModalOpen={isSortSectionsModalOpen}
      handleCloseModal={handleCloseSortSectionsModal}
    >
      <Box sx={templatesEditStyles.modal}>
        <IconButton
          onClick={handleCloseSortSectionsModal}
          sx={templatesEditStyles.closeButton}
          disableRipple
        >
          <Close />
        </IconButton>

        <Typography
          variant="h3"
          fontWeight={'bold'}
          sx={templatesEditStyles.modalHeading}
        >
          {t('templatesEditSortButton')}
        </Typography>

        {sortedSections?.map((section, idx) => {
          const isHovered = dropIndex.current === idx;

          return (
            <div
              style={{
                ...templatesEditStyles.draggableOption,
                backgroundColor: isHovered
                  ? theme.palette.action.disabled
                  : theme.palette.action.focus,
              }}
              draggable
              onDragStart={() => (draggedItemIndex.current = idx)}
              onDragEnter={() => handleDragEnter(idx)}
              onDragEnd={handleDragEnd}
              onDragOver={(e) => e.preventDefault()}
              key={section.name.en}
            >
              <FaSort />

              <Typography>
                {currentLanguage === 'en' ? section.name.en : section.name.fr}
              </Typography>
            </div>
          );
        })}
      </Box>
    </ModalOverlay>
  );
};

export default SortSectionsModal;
