import React from 'react';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { useTranslation } from 'react-i18next';
import {
  REPORTS_FILTER_FORM_STATUSES,
  REPORTS_FILTER_PREFERRED_CONTACT_METHOD,
} from '../../../utils/reports';
import { reportsFilterFormStyles } from './styles';
import { useLayout } from '../../../contexts/LayoutContext';
import { MdOutlinePlaylistRemove } from 'react-icons/md';
import { LuFilter } from 'react-icons/lu';

const ReportsFilterForm = ({
  onClose,
  handleFilter,
  values,
  handleChange,
  setFieldValue,
}) => {
  const { t } = useTranslation();
  const { doctors } = useLayout();
  const handleReset = () => {
    handleChange('firstName')('');
    handleChange('lastName')('');
    setFieldValue('appointmentDate', null);
    handleChange('status')('');
    handleChange('primaryContact')('');
    handleChange('doctor')('');
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <TextField
            id="firstName"
            name="firstName"
            label={t('reportsFilterFirstName')}
            fullWidth
            variant="outlined"
            value={values.firstName}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            id="lastName"
            name="lastName"
            label={t('reportsFilterLastName')}
            fullWidth
            variant="outlined"
            value={values.lastName}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <DesktopDatePicker
            label={t('reportsSearchAppointmentDate')}
            inputFormat="DD/MM/YYYY"
            onChange={(date) => setFieldValue('appointmentDate', date, true)}
            value={values.appointmentDate}
            name="appointmentDate"
            renderInput={(params) => (
              <TextField {...params} fullWidth error={false} />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel id="status">{t('reportsSearchStatus')}</InputLabel>
            <Select
              labelId="status"
              id="status"
              name="status"
              label={t('reportsSearchStatus')}
              value={values.status}
              onChange={handleChange}
            >
              {REPORTS_FILTER_FORM_STATUSES.map((item) => {
                return (
                  <MenuItem key={item.value} value={t(item.text)}>
                    {t(item.text)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel id="status">
              {t('reportsSearchPrimaryContact')}
            </InputLabel>
            <Select
              id="primaryContact"
              name="primaryContact"
              label={t('reportsSearchPrimaryContact')}
              value={values.primaryContact}
              onChange={handleChange}
            >
              {REPORTS_FILTER_PREFERRED_CONTACT_METHOD.map((item) => {
                return (
                  <MenuItem key={item.value} value={item.value}>
                    {t(item.name)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel id="doctor">{t('patientsSearchDoctor')}</InputLabel>

            <Select
              labelId="doctor"
              id="doctor"
              name="doctor"
              value={values.doctor}
              onChange={handleChange}
              label={t('patientsSearchDoctor')}
            >
              {doctors.map((item) => {
                return (
                  <MenuItem key={item.id} value={item.id}>
                    {`${item.lastName}, ${item.firstName}`}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={12}>
          <Box sx={reportsFilterFormStyles.form}>
            <Button variant="text" onClick={onClose}>
              {t('reportsFilterCancel')}
            </Button>
            <Button
              startIcon={<MdOutlinePlaylistRemove />}
              variant="outlined"
              onClick={handleReset}
            >
              {t('reportsFilterClear')}
            </Button>
            <Button
              variant="contained"
              onClick={handleFilter}
              startIcon={<LuFilter />}
            >
              {t('reportsSearchFilter')}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
};

export default ReportsFilterForm;
