import React from 'react';
import { ResponsivePie } from '@nivo/pie';
import { usePieChart } from './usePieChart';
import { useLayout } from '../../contexts/LayoutContext';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const PieChart = () => {
  const { pieChartData } = usePieChart();
  const { patients, users, doctors } = useLayout();
  const { t } = useTranslation();

  return !patients?.length && !doctors?.length && !users?.length ? (
    <Typography>{t('pieChartDataUnavailable')}</Typography>
  ) : (
    <ResponsivePie
      data={pieChartData}
      margin={{ top: 10, right: 60, bottom: 60, left: 60 }}
      sortByValue={true}
      innerRadius={0.5}
      padAngle={2}
      cornerRadius={0}
      activeOuterRadiusOffset={8}
      colors={{ scheme: 'paired' }}
      borderWidth={1}
      borderColor={{
        from: 'color',
        modifiers: [['darker', 1]],
      }}
      enableArcLinkLabels={false}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor="#333333"
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: 'color' }}
      arcLabelsTextColor={{
        from: 'color',
        modifiers: [['darker', 5]],
      }}
      motionConfig="wobbly"
      legends={[
        {
          anchor: 'bottom',
          direction: 'row',
          justify: false,
          translateX: 18,
          translateY: 63,
          itemsSpacing: 0,
          itemWidth: 100,
          itemHeight: 100,
          itemTextColor: '#999',
          itemDirection: 'left-to-right',
          itemOpacity: 1,
          symbolSize: 21,
          symbolShape: 'circle',
          effects: [
            {
              on: 'hover',
              style: {
                itemTextColor: '#000',
              },
            },
          ],
        },
      ]}
    />
  );
};

export default PieChart;
