export const useAddAddressModalStyles = () => {
  return {
    modal: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      bgcolor: 'background.paper',
      borderRadius: 2,
      boxShadow: 24,
      p: 4,
      width: '50%',
    },
    closeButton: {
      position: 'absolute',
      top: 0,
      right: 0,
    },
    switch: {
      display: 'flex',
      flexDirection: 'column',
    },
  };
};
