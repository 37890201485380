import { useTranslation } from 'react-i18next';
import { IoDocumentText } from 'react-icons/io5';
import { TbTemplate } from 'react-icons/tb';
import moment from 'moment';
import { useLayout } from '../../contexts/LayoutContext';
import { v4 as uuidv4 } from 'uuid';

export const useHome = () => {
  const { t } = useTranslation();
  const { patients, templates, reports, deletedReports } = useLayout();
  const generateDate = (date) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const randomMonth = Math.floor(Math.random() * 12);
    const randomDay = Math.floor(Math.random() * 28) + 1;
    const randomDate = new Date(currentYear, randomMonth, randomDay);

    if (randomDate <= currentDate) {
      randomDate.setFullYear(currentYear + 1);
    }

    return randomDate;
  };

  const upcomingAppointments = patients.map((patient) => {
    return {
      id: uuidv4(),
      patient: `${patient?.lastName}, ${patient?.firstName}`,
      appointmentDate: moment(generateDate(patient?.dateOfBirth)).format('lll'),
    };
  });

  const HOME_STATS = [
    {
      title: t('homeCreatedReports'),
      icon: <IoDocumentText />,
      percentage: 8,
      value: deletedReports?.length + reports?.length + 6,
    },
    {
      title: t('homeTotalTemplates'),
      icon: <TbTemplate />,
      percentage: 12,
      value: templates?.length,
    },
  ];

  const upcomingAppointmentsColumns = [
    {
      field: 'patient',
      headerName: 'Patient',
      flex: 0.5,
    },
    {
      field: 'appointmentDate',
      flex: 0.5,
      headerName: t('homeAppointmentDate'),
    },
  ];

  return { HOME_STATS, upcomingAppointmentsColumns, upcomingAppointments };
};
