import {
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  useTheme,
  AccordionDetails,
} from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePatientsViewStyles } from '../styles';
import { ExpandMore } from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import { useTreatmentPlan } from './useTreatmentPlan';

const TreatmentPlanCard = ({ plan, patient, showNewReportButton = true }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const patientsViewStyles = usePatientsViewStyles();
  const { treatmentPlanColumns } = useTreatmentPlan(
    patient,
    showNewReportButton
  );
  const [isExpanded, setIsExpanded] = useState(false);
  const totalTime =
    plan.proceedings.reduce((accumulator, currentValue) => {
      return accumulator + Number(currentValue.time);
    }, 0) || 0;
  const totalPrice = plan.proceedings
    .reduce((accumulator, currentValue) => {
      return accumulator + Number(currentValue.price);
    }, 0)
    .toFixed(2);
  const getRowId = (row) => row.id;

  return (
    <Accordion
      sx={{ marginTop: '12px' }}
      expanded={isExpanded}
      onChange={() => setIsExpanded(!isExpanded)}
    >
      <AccordionSummary
        sx={{
          bgcolor: theme.palette.action.focus,
        }}
      >
        <Box sx={{ ...patientsViewStyles.flexBetween, margin: 0 }} width="100%">
          <ExpandMore onClick={() => setIsExpanded(!isExpanded)} />

          <Typography>
            <span style={{ fontWeight: 600 }}>
              {`${t('patientsViewRequestedDate')}:`}
            </span>

            {plan.requestedDate
              ? ` ${moment(plan.requestedDate).format('l')}`
              : ''}
          </Typography>

          <Typography>
            <span style={{ fontWeight: 600 }}>
              {t('patientsViewBookedDate')}:
            </span>

            {plan.bookedDate ? ` ${moment(plan.bookedDate).format('l')}` : ''}
          </Typography>

          <Typography>
            <span style={{ fontWeight: 600 }}>{t('patientsViewTime')}:</span>
            {` ${totalTime}min(s)`}
          </Typography>

          <Typography>
            <span style={{ fontWeight: 600 }}>{t('patientsViewPrice')}:</span>
            {` $${totalPrice}`}
          </Typography>

          <Typography>
            <span style={{ fontWeight: 600 }}>{t('patientsViewStatus')}:</span>
            {` ${
              plan.completed
                ? t('patientsViewCompleted')
                : t('patientsViewInProgress')
            }`}
          </Typography>
        </Box>
      </AccordionSummary>

      <AccordionDetails>
        <DataGrid
          autoHeight
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          rows={plan.proceedings}
          columns={treatmentPlanColumns}
          hideFooter
          disableRowSelectionOnClick
          getRowId={getRowId}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default TreatmentPlanCard;
