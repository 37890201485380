import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLayout } from '../../contexts/LayoutContext';
import { ChevronLeft } from '@mui/icons-material';
import {
  Box,
  Drawer,
  List,
  Select,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  MenuItem,
  useTheme,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { SIDEBAR_MENU_ITEMS } from '../../utils/main';
import { useTranslation } from 'react-i18next';
import { useSidebarStyles } from './styles';
import ConfirmationModal from '../ConfirmationModal';
import { LuLogOut } from 'react-icons/lu';
import mockPatients from '../../mock/patients.json';
import mockDoctors from '../../mock/doctors.json';
import mockReports from '../../mock/reports.json';
import mockTemplates from '../../mock/templates.json';
import mockAttributes from '../../mock/attributes.json';
import mockUsers from '../../mock/users.json';
import { SIDEBAR_SUB_MENU_ITEMS } from '../../utils/main/sidebar';

const Sidebar = ({
  isMobileView,
  activePage,
  isSidebarOpen,
  setIsSidebarOpen,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const sidebarStyles = useSidebarStyles();
  const {
    currentLanguage,
    setCurrentLanguage,
    setIsSignedIn,
    setPatients,
    setDoctors,
    setReports,
    setTemplates,
    setAttributes,
    setUsers,
    setDeletedReports,
  } = useLayout();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);
  const [reportsHovered, setReportsHovered] = useState(false);

  const SidebarListItem = ({ link, text, icon, subMenu }) => {
    const isActive = activePage === link;

    return (
      <ListItem
        sx={{
          ...sidebarStyles.listItem,
          bgcolor: isActive ? '#d4ebfc' : 'transparent',
          borderLeftColor: isActive
            ? theme.palette.primary.main
            : 'transparent',
        }}
        disablePadding
        key={text}
      >
        <ListItemButton
          onClick={() => navigate(link)}
          sx={{ paddingLeft: subMenu ? '48px' : '32px' }}
        >
          <ListItemIcon
            sx={{
              ...sidebarStyles.listItemIcon,
              color: isActive
                ? theme.palette.primary.main
                : theme.palette.action.active,
            }}
          >
            {icon}
          </ListItemIcon>
          <ListItemText
            sx={{
              color: isActive
                ? theme.palette.primary.main
                : theme.palette.action.active,
            }}
          >
            {t(text)}
          </ListItemText>
        </ListItemButton>
      </ListItem>
    );
  };

  const handleCloseConfirmationModal = () => setIsConfirmationModalOpen(false);
  const handleSignOut = async () => {
    setIsSignedIn('false');
    setPatients(mockPatients);
    setDoctors(mockDoctors);
    setReports(mockReports);
    setTemplates(mockTemplates);
    setAttributes(mockAttributes);
    setUsers(mockUsers);
    setDeletedReports([]);
    navigate('/');
  };

  return (
    <Box component={'nav'}>
      <ConfirmationModal
        isModalOpen={isConfirmationModalOpen}
        handleCloseModal={handleCloseConfirmationModal}
        handleConfirm={handleSignOut}
        handleCancel={handleCloseConfirmationModal}
        message={t('sidebarLogoutMessage')}
        confirmLabel={t('sidebarLogout')}
      />

      <Drawer
        open={!isMobileView ? true : isSidebarOpen}
        onClose={() => setIsSidebarOpen(false)}
        variant="persistent"
        anchor="left"
        sx={{
          ...sidebarStyles.wrapper,
          position: isMobileView ? 'absolute' : 'relative',
        }}
      >
        <Box sx={sidebarStyles.heading}>
          <Typography
            fontSize="25px"
            fontWeight={600}
            color={theme.palette.primary.main}
          >
            My Doctor Hub
          </Typography>
          {isMobileView && (
            <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
              <ChevronLeft />
            </IconButton>
          )}
        </Box>

        <List sx={sidebarStyles.list}>
          {SIDEBAR_MENU_ITEMS.map(({ text, link, icon }, index) => {
            return text === 'sidebarReports' ? (
              <Accordion
                expanded={isExpanded}
                onChange={() => setIsExpanded(!isExpanded)}
                sx={sidebarStyles.accordion}
                key={index}
              >
                <AccordionSummary
                  sx={{
                    ...sidebarStyles.accordionSummary,
                    bgcolor: reportsHovered
                      ? theme.palette.action.hover
                      : 'transparent',
                  }}
                  expandIcon={<ExpandMore />}
                  onMouseEnter={() => setReportsHovered(true)}
                  onMouseLeave={() => setReportsHovered(false)}
                >
                  <ListItem sx={sidebarStyles.listItem} disablePadding>
                    <ListItemIcon sx={sidebarStyles.listItemIcon}>
                      {icon}
                    </ListItemIcon>
                    <ListItemText
                      sx={{
                        color: theme.palette.action.active,
                      }}
                    >
                      {t(text)}
                    </ListItemText>
                  </ListItem>
                </AccordionSummary>

                <AccordionDetails sx={{ p: 0 }}>
                  {SIDEBAR_SUB_MENU_ITEMS.map(({ text, link, icon }, index) => {
                    return (
                      <SidebarListItem
                        text={text}
                        link={link}
                        icon={icon}
                        subMenu
                        key={index}
                      />
                    );
                  })}
                </AccordionDetails>
              </Accordion>
            ) : (
              <SidebarListItem
                text={text}
                link={link}
                icon={icon}
                key={index}
              />
            );
          })}

          <ListItem
            disablePadding
            sx={{
              bgcolor: theme.palette.action.hover,
            }}
          >
            <ListItemButton sx={sidebarStyles.dropdown}>
              <Select
                value={currentLanguage}
                onChange={({ target }) => setCurrentLanguage(target.value)}
                sx={{
                  ...sidebarStyles.dropdownOptions,
                  color: theme.palette.primary.main,
                }}
              >
                <MenuItem value="fr">FR</MenuItem>
                <MenuItem value="en">EN</MenuItem>
              </Select>
            </ListItemButton>
          </ListItem>
        </List>

        <ListItemButton
          onClick={() => setIsConfirmationModalOpen(true)}
          style={sidebarStyles.logout}
        >
          <ListItemIcon>
            <LuLogOut />
          </ListItemIcon>
          <ListItemText>{t('sidebarLogoutSignOut')}</ListItemText>
        </ListItemButton>
      </Drawer>
    </Box>
  );
};

export default Sidebar;
