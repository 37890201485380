import React from 'react';
import ModalOverlay from '../../../components/ModalOverlay';
import SectionAttribute from '../../../components/CustomTemplatesSection/SectionAttribute';
import { usePatientsAttributesStyles } from './styles';
import { Box, Button, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AddCircleOutline, Close } from '@mui/icons-material';
import { BiCheckDouble } from 'react-icons/bi';

const EditAttributesModal = ({
  isEditAttributeModalOpen,
  handleCloseEditAttributeModal,
  attributes,
  setAttributes,
  handleAddAttribute,
}) => {
  const patientsAttributesStyles = usePatientsAttributesStyles();
  const { t } = useTranslation();

  return (
    <ModalOverlay
      isModalOpen={isEditAttributeModalOpen}
      handleCloseModal={handleCloseEditAttributeModal}
    >
      <Box sx={{ ...patientsAttributesStyles.modal, p: 0 }}>
        <Box
          p="24px 32px 12px 32px"
          position="sticky"
          top={0}
          width="100%"
          bgcolor="white"
          zIndex={9}
        >
          <Button
            startIcon={<AddCircleOutline />}
            variant="contained"
            onClick={handleAddAttribute}
            sx={{ mr: '12px' }}
          >
            {t('patientsAttributesNewAttribute')}
          </Button>
          <Button
            startIcon={<BiCheckDouble />}
            variant="contained"
            onClick={handleCloseEditAttributeModal}
            color="success"
            sx={{ color: 'white' }}
          >
            {t('patientsAttributesSaveBtn')}
          </Button>

          <IconButton
            onClick={handleCloseEditAttributeModal}
            sx={patientsAttributesStyles.closeButton}
            disableRipple
          >
            <Close />
          </IconButton>
        </Box>

        <Box p="0 32px 32px 32px">
          {attributes.map((attribute) => {
            const currentIndex = attributes.findIndex(
              (item) => item.id === attribute.id
            );

            return (
              <div
                key={attribute.id}
                style={patientsAttributesStyles.accordionWrapper}
              >
                <SectionAttribute
                  attribute={attribute}
                  currentIndex={currentIndex}
                  attributes={attributes}
                  setAttributes={setAttributes}
                />
              </div>
            );
          })}
        </Box>
      </Box>
    </ModalOverlay>
  );
};

export default EditAttributesModal;
