import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useLayout } from '../../../contexts/LayoutContext';
import { Box, List, Typography, IconButton, Button } from '@mui/material';
import * as Layout from '../../../layouts';
import { useTranslation } from 'react-i18next';
import { usePatientsViewStyles } from './styles';
import { Close, DeleteOutline, Menu } from '@mui/icons-material';
import PatientFile from '../../../components/PatientFile';
import DeleteModal from '../../../components/DeleteModal';
import { toggleToast } from '../../../utils/helpers/toast';

const PatientsView = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const patientsViewStyles = usePatientsViewStyles();
  const { setPageTitle, templates, currentLanguage, patients, setPatients } =
    useLayout();
  const patient = patients.find((patient) => patient?.id === id);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleCloseDeleteModal = () => setIsDeleteModalOpen(false);
  const handleDeletePatient = () => {
    const updatesPatients = patients.filter(({ id }) => id !== patient?.id);
    setPatients(updatesPatients);
    toggleToast(
      'success',
      `${patient?.lastName} ${t('patientsViewDeletedSuccessfully')}`
    );
    navigate('/patients');
  };

  useEffect(() => {
    setPageTitle(
      patient?.id ? `${patient?.lastName}, ${patient?.firstName}` : ''
    );
  }, [patient, setPageTitle]);

  return (
    <Layout.Main notFound={!patient?.id}>
      <DeleteModal
        isDeleteModalOpen={isDeleteModalOpen}
        handleCloseDeleteModal={handleCloseDeleteModal}
        handleConfirm={handleDeletePatient}
        isProtected
      />

      {templates?.length ? (
        <Box
          sx={{
            ...patientsViewStyles.sidebar,
            transform: !isSidebarOpen ? 'translateX(0%)' : 'translateX(1%)',
          }}
        >
          <IconButton
            sx={patientsViewStyles.menuButton}
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
          >
            {isSidebarOpen ? <Close /> : <Menu />}
          </IconButton>

          <List sx={patientsViewStyles.list}>
            {/* <Box
            sx={{
              ...patientsViewStyles.templatesAbbr,
              bgcolor: theme.palette.action.focus,
              marginBottom: '12px',
            }}
          >
            <IconButton onClick={() => window.print()}>
              <LocalPrintshopOutlined />
            </IconButton>
          </Box> */}

            {templates.map((template) => {
              return (
                <Box key={template.id} sx={patientsViewStyles.templates}>
                  <Box
                    sx={{
                      ...patientsViewStyles.templatesAbbr,
                      bgcolor: template.theme,
                    }}
                  >
                    <IconButton
                      sx={patientsViewStyles.iconButton}
                      onClick={() =>
                        navigate(`/reports/${template.id}/edit`, {
                          state: {
                            patientId: patient?.id,
                            templateId: template.id,
                          },
                        })
                      }
                    >
                      <Typography>
                        {currentLanguage === 'en'
                          ? template.abbreviation.en
                          : template.abbreviation.fr}
                      </Typography>
                    </IconButton>
                  </Box>

                  {isSidebarOpen && (
                    <Typography
                      sx={patientsViewStyles.link}
                      onClick={() =>
                        navigate(`/reports/${template.id}/edit`, {
                          state: {
                            patientId: patient?.id,
                            templateId: template.id,
                          },
                        })
                      }
                    >
                      {t(template.name[currentLanguage])}
                    </Typography>
                  )}
                </Box>
              );
            })}
          </List>
        </Box>
      ) : null}

      <Box width="100%" display="flex" justifyContent="flex-end" pb="12px">
        <Button
          startIcon={<DeleteOutline />}
          variant="contained"
          color="error"
          onClick={() => setIsDeleteModalOpen(true)}
        >
          {t('patientsViewDeleteButton')}
        </Button>
      </Box>

      <Box maxHeight="80vh" overflow="auto" pt="24px">
        <PatientFile
          patient={patient}
          style={{ paddingLeft: templates?.length ? '62px' : '0' }}
        />
      </Box>
    </Layout.Main>
  );
};

export default PatientsView;
