import React, { useRef, useState } from 'react';
import {
  Button,
  TextField,
  Typography,
  MenuItem,
  Box,
  InputLabel,
  FormControl,
  Select,
  IconButton,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { ATTRIBUTES_TYPES } from '../../utils/attributes';
import { useAddAttributeModalStyles } from './styles';
import { AddCircleOutline, Close } from '@mui/icons-material';
import ModalOverlay from '../ModalOverlay';
import { v4 as uuidv4 } from 'uuid';
import DraggableOption from '../DraggableOption';

const AddAttributeModal = ({
  isAddAttributeModalOpen,
  handleCloseNewAttributeModal,
  setAttributes,
  attributes,
}) => {
  const { t } = useTranslation();
  const addAttributeModalStyles = useAddAttributeModalStyles();
  const dropIndex = useRef(null);
  const draggedItemIndex = useRef(null);
  const [options, setOptions] = useState([]);
  const [hasOptions, setHasOptions] = useState(false);
  const { values, handleChange, resetForm, handleBlur } = useFormik({
    initialValues: {
      anglophoneName: '',
      francophoneName: '',
      attributeType: '',
    },
  });

  const handleDragStart = (currentIndex) => {
    draggedItemIndex.current = currentIndex;
  };

  const handleDragEnter = (index) => {
    const clonedOptions = [...options];
    const draggedItem = clonedOptions.splice(draggedItemIndex.current, 1)[0];
    dropIndex.current = index;

    clonedOptions.splice(dropIndex.current, 0, draggedItem);
  };

  const handleDragEnd = () => {
    const clonedOptions = [...options];
    const draggedItem = clonedOptions.splice(draggedItemIndex.current, 1)[0];

    clonedOptions.splice(dropIndex.current, 0, draggedItem);
    draggedItemIndex.current = null;
    dropIndex.current = null;

    setOptions(clonedOptions);
  };

  const handleChangeAttributeType = (value) => {
    handleChange('attributeType')(value);

    switch (value) {
      case 'list':
      case 'checkboxes':
      case 'radio-button':
      case 'multi-select':
        setHasOptions(true);
        break;
      default:
        setHasOptions(false);
        setOptions([]);
    }
  };

  const handleAddOption = () => {
    const clonedOptions = [
      {
        name: { en: '', fr: '' },
        index: options.length,
        id: uuidv4(),
      },
      ...options,
    ];

    setOptions(clonedOptions);
  };

  const handleCloseModal = () => {
    handleCloseNewAttributeModal();
    resetForm();
    setOptions([]);
    setHasOptions(false);
  };

  const handleAddAttribute = () => {
    const attribute = {
      isAttributeActive: true,
      id: uuidv4(),
      name: {
        en: values.anglophoneName || '',
        fr: values.francophoneName || '',
      },
      reusable: false,
      type: values.attributeType,
      options,
    };

    setAttributes([attribute, ...attributes]);
    handleCloseModal();
  };

  return (
    <ModalOverlay
      isModalOpen={isAddAttributeModalOpen}
      handleCloseModal={handleCloseModal}
    >
      <Box sx={addAttributeModalStyles.modal}>
        <IconButton
          onClick={handleCloseModal}
          sx={addAttributeModalStyles.closeButton}
          disableRipple
        >
          <Close />
        </IconButton>
        <Typography variant="h2" sx={addAttributeModalStyles.modalHeading}>
          {t('addAttributeModalHeading')}
        </Typography>
        <form style={addAttributeModalStyles.form}>
          <Box>
            <Typography
              variant="h4"
              sx={addAttributeModalStyles.sectionHeading}
            >
              {t('addAttributeModalNewCustomField')}
            </Typography>

            <TextField
              id="anglophoneName"
              name="anglophoneName"
              label={t('addAttributeModalAnglophoneName')}
              fullWidth
              variant="outlined"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.anglophoneName}
              sx={addAttributeModalStyles.textField}
            />

            <TextField
              id="francophoneName"
              name="francophoneName"
              label={t('addAttributeModalFrancophoneName')}
              fullWidth
              variant="outlined"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.francophoneName}
              sx={addAttributeModalStyles.textField}
            />

            <FormControl sx={addAttributeModalStyles.dropdown}>
              <InputLabel id="attributeType">
                {t('addAttributeModalAttributeType')}
              </InputLabel>
              <Select
                labelId="attributeType"
                id="attributeType"
                label={t('addAttributeModalAttributeType')}
                onChange={({ target }) =>
                  handleChangeAttributeType(target.value)
                }
                onBlur={handleBlur}
                value={values.attributeType}
                name="attributeType"
              >
                {ATTRIBUTES_TYPES.map((type) => {
                  return (
                    <MenuItem key={type.value} value={type.value}>
                      {t(type.name)}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            {hasOptions || options?.length ? (
              <Box sx={addAttributeModalStyles.options}>
                {options.map((option, currentIndex) => {
                  const isHovered = dropIndex.current === currentIndex;

                  return (
                    <div
                      draggable
                      onDragStart={() => handleDragStart(currentIndex)}
                      onDragEnter={() => handleDragEnter(currentIndex)}
                      onDragEnd={handleDragEnd}
                      onDragOver={(e) => e.preventDefault()}
                      key={option.id}
                    >
                      <DraggableOption
                        option={option}
                        options={options}
                        setOptions={setOptions}
                        isHovered={isHovered}
                      />
                    </div>
                  );
                })}

                <Button
                  sx={addAttributeModalStyles.addOptionBtn}
                  variant="outlined"
                  onClick={handleAddOption}
                  startIcon={<AddCircleOutline />}
                >
                  {t('attributeViewAddOption')}
                </Button>
              </Box>
            ) : null}
          </Box>

          <Button
            sx={addAttributeModalStyles.submitButton}
            variant="contained"
            onClick={handleAddAttribute}
            startIcon={<AddCircleOutline />}
            disabled={
              !values.anglophoneName.length || !values.attributeType.length
            }
          >
            {t('addAttributeModalHeading')}
          </Button>
        </form>
      </Box>
    </ModalOverlay>
  );
};

export default AddAttributeModal;
