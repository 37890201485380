import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { insurancePdfStyles } from '../styles';

const HowToEn = () => {
  return (
    <View>
      <View style={insurancePdfStyles.instructionsHeading}>
        <Text style={insurancePdfStyles.instructionsHeadingText}>
          INSTRUCTIONS FOR CLAIM SUBMISSION
        </Text>
      </View>

      <View style={{ padding: '5px 3px' }}>
        <Text style={insurancePdfStyles.smallBold}>
          BEING A STANDARD FORM, THIS FORM CANNOT INCLUDE SPECIFIC INSTRUCTIONS
          ON WHERE IT SHOULD BE SENT, DEPENDING ON WHO IS THE CARRIER FOR YOUR
          PLAN. YOU CAN OBTAIN DETAILS FROM EITHER YOUR PLAN BOOKLET, YOUR
          CERTIFICATE OR FROM YOUR EMPLOYER.{'\n'} IF YOU PLAN REQUIRES
          SUBMISSION DIRECTLY TO THE CARRIER, PLEASE SEND THIS FORM WITH ONLY
          PARTS 1, 2 AND 3 COMPLETED TO THE CARRIER’S APPROPRIATE CLAIMS OFFICE.
          *IF YOUR PLAN REQUIRES SUBMISSION TO YOUR EMPLOYER, PLEASE DIRECT THIS
          FORM TO YOUR PERSONNEL OFFICE/PLAN ADMINISTRATOR WHO WILL COMPLETE
          PART 4 AND FORWARD THE FORM TO THE CARRIER.
        </Text>
      </View>
    </View>
  );
};

export default HowToEn;
