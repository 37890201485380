export const useAddAttributeModalStyles = () => {
  return {
    modal: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      overflow: 'auto',
      width: '70%',
      height: '90%',
      bgcolor: 'background.paper',
      borderRadius: 2,
      boxShadow: 24,
      p: 4,
    },
    modalHeading: {
      fontWeight: 500,
      marginBottom: '32px',
    },
    dropdown: {
      maxWidth: '500px',
      width: '100%',
      marginBottom: '24px',
    },
    sectionHeading: {
      fontWeight: 500,
      marginBottom: '12px',
    },
    textField: { marginBottom: '24px', maxWidth: '700px' },
    submitButton: {
      maxWidth: '200px',
      width: '100%',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
    },
    options: { marginBottom: '32px' },
    addOptionBtn: { maxWidth: '200px', width: '100%', marginTop: '5px' },
    closeButton: {
      position: 'absolute',
      top: '5px',
      right: 4,
    },
  };
};
