import { Typography, Box, Button } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { usePatientsViewStyles } from './styles';
import { useNavigate } from 'react-router-dom';
import MappedAttributes from './MappedAttributes';
import { BiSolidEditAlt } from 'react-icons/bi';

const ClinicalExam = ({ patient }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const patientsViewStyles = usePatientsViewStyles();
  const attributes = patient?.clinicalExam?.attributes || [];

  const handleNavigate = () => {
    navigate('attributes/clinical-exam');
  };

  return (
    <Box>
      <Box sx={patientsViewStyles.flexBetween}>
        <Typography variant="h4" fontWeight={600}>
          {t('patientsViewClinicalExam')}
        </Typography>

        <Button
          startIcon={<BiSolidEditAlt />}
          variant="outlined"
          size="small"
          onClick={handleNavigate}
        >
          {t('patientsViewEditButton')}
        </Button>
      </Box>

      <MappedAttributes attributes={attributes} />
    </Box>
  );
};

export default ClinicalExam;
