export const REPORTS_FILTER_CHIP_TRANSLATIONS = {
  firstName: 'reportsFilterFirstName',
  lastName: 'reportsFilterLastName',
  reportType: 'reportsSearchReportType',
  appointmentDate: 'reportsSearchAppointmentDate',
  region: 'reportsSearchRegion',
  status: 'reportsSearchStatus',
  primaryContact: 'reportsSearchPrimaryContact',
  doctor: 'reportsSearchDoctor',
};
