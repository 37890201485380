import { GroupsOutlined } from '@mui/icons-material';
import { FaUserDoctor } from 'react-icons/fa6';
import { FaHome, FaCheckCircle, FaUserFriends } from 'react-icons/fa';
import { IoDocumentText } from 'react-icons/io5';
import { TbTemplate } from 'react-icons/tb';

export const SIDEBAR_MENU_ITEMS = [
  { icon: <FaHome />, link: '/', text: 'sidebarHome' },
  { icon: <GroupsOutlined />, link: '/patients', text: 'Patients' },
  { icon: <FaUserDoctor />, link: '/doctors', text: 'sidebarDoctors' },
  {
    icon: <IoDocumentText />,
    link: '/reports',
    text: 'sidebarReports',
  },
  // {
  //   icon: <FaCheckCircle />,
  //   link: '/custom-fields',
  //   text: 'sidebarAttributes',
  // },
  // {
  //   icon: <TbTemplate />,
  //   link: '/templates',
  //   text: 'sidebarTemplates',
  // },
  {
    icon: <FaUserFriends />,
    link: '/users',
    text: 'sidebarUsers',
  },
];

export const SIDEBAR_SUB_MENU_ITEMS = [
  {
    icon: <IoDocumentText />,
    link: '/reports',
    text: 'sidebarAllReports',
  },
  {
    icon: <FaCheckCircle />,
    link: '/custom-fields',
    text: 'sidebarAttributes',
  },
  {
    icon: <TbTemplate />,
    link: '/templates',
    text: 'sidebarTemplates',
  },
];
