export const useDraggableOptionStyles = () => {
  return {
    option: {
      bgcolor: 'white',
      display: 'flex',
      alignItems: 'center',
      paddingBlock: '8px',
      marginBottom: '8px',
      cursor: 'grab',
    },
  };
};
