export const PATIENTS_ADD_SEX = [
  { value: 'Male', text: 'patientsFormSexMale' },
  { value: 'Female', text: 'patientsFormSexFemale' },
  { value: 'Other', text: 'patientsFormSexOther' },
];

export const PATIENTS_ADD_PREFERRED_LANGUAGE = [
  { value: 'English', text: 'patientsFormEnglish' },
  { value: 'French', text: 'patientsFormFrench' },
  { value: 'Other', text: 'patientsFormOther' },
];

export const PATIENTS_ADD_PREFERRED_CONTACT = [
  { value: 'Email', name: 'patientsFormEmail' },
  { value: 'Fax', name: 'patientsFormFax' },
  { value: 'Telephone', name: 'patientsFormTelephone' },
];

export const PATIENTS_ADD_OFFICE = [
  {
    value: 'Greenfield Park',
    text: '2060 Av Victoria, Greenfield Park, QC J4V 1M8',
  },
  {
    value: 'Rene-Levesque',
    text: '2222, boul René-Lévesque O, Montréal, QC H3H 1R6',
  },
  {
    value: 'Cote-Sainte-Catherine',
    text: '3175, ch. de la Côte-Sainte-Catherine, Montréal, QC H3T 1C5',
  },
];

export const PATIENTS_ADD_INSURANCE_PROVIDERS = [
  {
    value: 'RAMQ',
  },
  {
    value: 'Blue Cross',
  },
  {
    value: 'Manulife',
  },
  {
    value: 'Canada Life',
  },
  {
    value: 'GMS',
  },
  {
    value: 'Sun Life',
  },
];
