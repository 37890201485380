import React from 'react';
import { useLayout } from '../../../contexts/LayoutContext';
import { useTranslation } from 'react-i18next';
import AttributeTemplate from '.';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormControl,
  FormGroup,
} from '@mui/material';
import { useAttributeTypeStyles } from '../styles';
import { MdOutlinePlaylistRemove } from 'react-icons/md';

const CheckboxAttribute = ({
  attributeLabel,
  attributeValue,
  setAttributeValue,
  attribute,
}) => {
  const { t } = useTranslation();
  const { currentLanguage } = useLayout();
  const attributeTypeStyles = useAttributeTypeStyles();

  const handleChange = (event) => {
    const index = attributeValue.indexOf(event.target.value);
    if (index === -1) {
      setAttributeValue([...attributeValue, event.target.value]);
    } else {
      setAttributeValue(
        attributeValue.filter((value) => value !== event.target.value)
      );
    }
  };

  const handleRemoveAll = () => setAttributeValue([]);

  return (
    <AttributeTemplate attributeLabel={attributeLabel}>
      <Box display="flex" flexDirection="column">
        <FormControl>
          <FormGroup>
            {attribute.options.map((option, index) => {
              return (
                <FormControlLabel
                  label={option?.name[currentLanguage]}
                  key={option?.id}
                  control={
                    <Checkbox
                      value={option?.name[currentLanguage]}
                      checked={attributeValue.includes(
                        option?.name[currentLanguage]
                      )}
                      onChange={handleChange}
                    />
                  }
                />
              );
            })}
          </FormGroup>
        </FormControl>

        <Button
          variant="outlined"
          sx={attributeTypeStyles.removeAll}
          onClick={handleRemoveAll}
          startIcon={<MdOutlinePlaylistRemove />}
        >
          {t('attributeTypeRemoveAll')}
        </Button>
      </Box>
    </AttributeTemplate>
  );
};

export default CheckboxAttribute;
