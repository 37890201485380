import React from 'react';
import { View, Image, Text } from '@react-pdf/renderer';
import { insurancePdfStyles } from '../styles';
import { secondaryLogo } from '../Images/secondaryLogo';
import { mainLogoFr } from '../Images/mainLogoFr';

const LogosFr = () => {
  return (
    <View style={insurancePdfStyles.head}>
      <Image src={mainLogoFr} style={{ width: '85px' }} />

      <View style={insurancePdfStyles.secondary}>
        <Image src={secondaryLogo} style={insurancePdfStyles.secondaryLogo} />
        <Text style={insurancePdfStyles.secondaryText}>
          Association canadienne des{'\n'} compagnies d’assurances de personnes
        </Text>
      </View>

      <Text
        style={{
          ...insurancePdfStyles.formTitle,
          width: '150px',
          fontSize: '10px',
        }}
      >
        FORMULAIRE NORMALISÉ DE DEMANDE D’INDEMNISATION DES SOINS DENTAIRES
      </Text>
    </View>
  );
};

export default LogosFr;
