import { useTheme } from '@mui/material';

export const usePatientsFormStyles = () => {
  const theme = useTheme();

  return {
    newDoctorButton: {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
    },
    missingTeethLabel: { marginBottom: '12px' },
    requiredText: {
      marginBlock: '3px 20px',
      color: 'red',
      fontSize: '14px',
    },
    sectionHeading: {
      fontWeight: 500,
      marginBottom: '20px',
    },
    submitButton: {
      minWidth: '190px',
    },
    modal: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      bgcolor: 'background.paper',
      borderRadius: 2,
      boxShadow: 24,
      p: 4,
      width: '50%',
    },
    closeButton: {
      position: 'absolute',
      top: 0,
      right: 0,
      zIndex: 1,
    },
    addAddress: {
      borderRadius: '5px',
      height: '100px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
    },
    address: {
      marginBottom: '24px',
      borderRadius: '5px',
      minHeight: '100px',
      width: '100%',
      boxShadow: '1px 1px 2px 0px rgba(0, 0, 0, 0.5)',
      textAlign: 'left',
      alignItems: 'flex-start',
      textTransform: 'none',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      color: theme.palette.action.active,
      wordBreak: 'break-word',
    },
  };
};
