import { Box, Typography, Button, IconButton } from '@mui/material';
import { useTemplatesEditStyles } from './styles';
import { useLayout } from '../../../contexts/LayoutContext';
import { useTranslation } from 'react-i18next';
import { TEMPLATES_PATIENTS_SECTIONS } from '../../../utils/templates';
import { Close } from '@mui/icons-material';
import ModalOverlay from '../../../components/ModalOverlay';
import { v4 as uuidv4 } from 'uuid';

const AddPatientSectionModal = ({
  handleCloseAddPatientSection,
  isAddPatientSectionOpen,
  sections,
  setSections,
}) => {
  const { t } = useTranslation();
  const templatesEditStyles = useTemplatesEditStyles();
  const { currentLanguage } = useLayout();

  const handleAddPatientSection = (section) => {
    const patientSection = {
      id: uuidv4(),
      heading: 'templatesEditSectionName',
      name: section.name,
      isSectionNameActive: true,
      isSignatureActive: false,
      type: section.type,
      attributes: [],
    };

    setSections([patientSection, ...sections]);
    handleCloseAddPatientSection();
  };

  return (
    <ModalOverlay
      isModalOpen={isAddPatientSectionOpen}
      handleCloseModal={handleCloseAddPatientSection}
    >
      <Box sx={templatesEditStyles.modal}>
        <IconButton
          onClick={handleCloseAddPatientSection}
          sx={templatesEditStyles.closeButton}
          disableRipple
        >
          <Close />
        </IconButton>

        <Typography
          variant="h3"
          fontWeight={'bold'}
          sx={templatesEditStyles.modalHeading}
        >
          {t('templatesEditAddPatientSection')}
        </Typography>

        {TEMPLATES_PATIENTS_SECTIONS.map((section) => {
          return (
            <Box
              sx={templatesEditStyles.modalOptions}
              onClick={() => handleAddPatientSection(section)}
              key={section.name.en}
            >
              <Button variant="text" size="large" sx={{ width: '100%' }}>
                {section?.name[currentLanguage]}
              </Button>
            </Box>
          );
        })}
      </Box>
    </ModalOverlay>
  );
};

export default AddPatientSectionModal;
