import React from 'react';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import { CheckCircleOutline } from '@mui/icons-material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useReportsSearchStyles } from './styles';
import { TbProgressCheck } from 'react-icons/tb';
import { HiMiniDevicePhoneMobile } from 'react-icons/hi2';
import { IoMailOutline } from 'react-icons/io5';
import { useTheme } from '@emotion/react';
import { useLayout } from '../../../contexts/LayoutContext';
import { LuMailCheck } from 'react-icons/lu';
import { FaFax } from 'react-icons/fa6';

export const useReportsSearch = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { currentLanguage, patients, doctors } = useLayout();
  const reportsSearchStyles = useReportsSearchStyles();

  const reportsSearchColumns = [
    {
      field: 'reportColor',
      headerName: '',
      flex: 0.03,
      renderCell: ({ row: { theme, abbreviation } }) => (
        <Box
          sx={{
            ...reportsSearchStyles.reportColor,
            bgcolor: theme,
          }}
        >
          <Typography sx={reportsSearchStyles.appointmentDate}>
            {abbreviation[currentLanguage]}
          </Typography>
        </Box>
      ),
    },
    {
      field: 'appointmentDate',
      flex: 0.08,
      headerName: t('reportsSearchAppointmentDate'),
      renderCell: ({ row: { appointmentDate } }) => (
        <Typography sx={reportsSearchStyles.appointmentDate}>
          {moment(appointmentDate).format('DD/MM/YYYY')}
        </Typography>
      ),
    },
    {
      field: 'status',
      flex: 0.09,
      headerName: t('reportsSearchStatus'),
      valueGetter: ({ row: { status } }) => {
        return status === 'in-progress'
          ? t('reportsSearchInProgress')
          : status === 'published'
          ? t('reportsSearchPublished')
          : status === 'sent-by-email'
          ? t('reportsSearchPublished')
          : null;
      },
      renderCell: ({ row: { status } }) => {
        const inProgress = status === 'in-progress';
        const published = status === 'published';
        const sentByEmail = status === 'sent-by-email';
        const label = inProgress
          ? t('reportsSearchInProgress')
          : published
          ? t('reportsSearchPublished')
          : sentByEmail
          ? t('reportsSearchSentByEmail')
          : null;
        return (
          <Box
            sx={{
              ...reportsSearchStyles.preferredMethod,
              border: published
                ? `1px solid ${theme.palette.success.light}`
                : inProgress
                ? `1px solid ${theme.palette.warning.light}`
                : sentByEmail
                ? `1px solid ${theme.palette.primary.light}`
                : 'none',
              bgcolor: published
                ? '#ebfceb'
                : inProgress
                ? '#fcf0de'
                : sentByEmail
                ? '#d4e8ff'
                : null,
            }}
          >
            {published ? (
              <CheckCircleOutline
                sx={{
                  ...reportsSearchStyles.preferredMethodIcon,
                  color: theme.palette.success.light,
                }}
              />
            ) : inProgress ? (
              <TbProgressCheck
                style={{
                  ...reportsSearchStyles.preferredMethodIcon,
                  color: theme.palette.warning.light,
                }}
              />
            ) : sentByEmail ? (
              <LuMailCheck
                style={{
                  ...reportsSearchStyles.preferredMethodIcon,
                  color: theme.palette.primary.light,
                }}
              />
            ) : null}
            <Typography sx={reportsSearchStyles.appointmentDate}>
              {t(label)}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: 'patient',
      headerName: t('reportsSearchPatient'),
      flex: 0.1,
      renderCell: ({ row: { patientId } }) => {
        const patient = patients.find((patient) => patient?.id === patientId);
        return (
          <Link style={reportsSearchStyles.link} to={`/patients/${patientId}`}>
            <Typography
              sx={{
                ...reportsSearchStyles.appointmentDate,
                color: theme.palette.primary.main,
                '&:hover': { color: theme.palette.info.dark },
              }}
            >
              {patient?.lastName} {patient?.firstName}
            </Typography>
          </Link>
        );
      },
      valueGetter: ({ row: { patientId } }) => {
        const patient = patients.find((patient) => patient?.id === patientId);
        return `${patient?.lastName} ${patient?.firstName}`;
      },
    },
    {
      field: 'doctors',
      flex: 0.1,
      headerName: t('reportsSearchDoctor'),
      valueGetter: ({ row: { assignedDoctors } }) => {
        const matchedDoctors = doctors.filter((doctor) =>
          assignedDoctors.includes(doctor?.id)
        );

        return matchedDoctors
          ?.map((doctor) => `${doctor?.lastName} ${doctor?.firstName}`)
          .join(', ');
      },
    },
    {
      field: 'preferredMethod',
      flex: 0.08,
      headerName: t('reportsSearchPrimaryContact'),
      valueGetter: ({ row }) => {
        const patient = patients.find(
          (patient) => patient?.id === row?.patientId
        );
        if (patient?.id) {
          const {
            type: { value: primaryContact },
          } = patient?.contacts?.find((contact) => contact.primary);

          return primaryContact;
        }
      },
      renderCell: ({ row }) => {
        const patient = patients.find(
          (patient) => patient?.id === row?.patientId
        );
        if (patient?.id) {
          const {
            type: { value: primaryContact },
          } = patient?.contacts?.find((contact) => contact.primary);

          const emailPrimaryContact = primaryContact === 'Email';
          const faxPrimaryContact = primaryContact === 'Fax';
          const telephonePrimaryContact = primaryContact === 'Telephone';
          const label = emailPrimaryContact
            ? 'reportsSearchEmail'
            : faxPrimaryContact
            ? 'reportsSearchFax'
            : telephonePrimaryContact
            ? 'reportsSearchTelephone'
            : null;

          return patient?.id ? (
            <Box sx={reportsSearchStyles.preferredMethod}>
              {emailPrimaryContact ? (
                <IoMailOutline
                  style={reportsSearchStyles.preferredMethodIcon}
                />
              ) : faxPrimaryContact ? (
                <FaFax style={reportsSearchStyles.preferredMethodIcon} />
              ) : telephonePrimaryContact ? (
                <HiMiniDevicePhoneMobile
                  style={reportsSearchStyles.preferredMethodIcon}
                />
              ) : null}
              <Typography sx={reportsSearchStyles.appointmentDate}>
                {t(label)}
              </Typography>
            </Box>
          ) : null;
        }
      },
    },
  ];

  return { reportsSearchColumns };
};
