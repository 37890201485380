import { useTheme } from '@mui/material';
import { COLORS } from '../../../utils/colors';

export const useLoginStyles = () => {
  const theme = useTheme();

  return {
    page: {
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: COLORS.LIGHT_GRAY,
    },
    formWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '40px 30px',
      backgroundColor: 'white',
      margin: '20px',
      borderRadius: '5px',
      boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
    },
    formHeading: {
      fontSize: '24px',
      fontWeight: 600,
      marginBottom: '24px',
      textAlign: 'center',
    },
    form: {
      flexDirection: 'column',
      maxWidth: '600px',
      maxHeight: '50vh',
      overflow: 'auto',
      paddingBlock: '12px',
      marginBottom: '12px',
    },
    textFields: {
      marginBottom: '20px',
    },
    submit: {
      backgroundColor: theme.palette.primary,
      marginBottom: '12px',
      height: '45px',
      color: 'white',
    },
    errorMessage: {
      color: theme.palette.error.main,
      alignSelf: 'center',
    },
    dropdown: {
      position: 'absolute',
      top: 0,
      right: 0,
      maxWidth: '200px',
      width: '100%',
      p: 0,
    },
    dropdownOptions: {
      color: theme.palette.primary.main,
      height: '48px',
      width: '100%',
      p: '12px',

      '& div': {
        height: '100% !important',
        display: 'flex',
        alignItems: 'center',
      },
      '& .MuiOutlinedInput-input': { p: 0 },
      '.MuiOutlinedInput-notchedOutline': {
        border: 0,
      },
      '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: 0,
      },
    },
  };
};
