import React from 'react';
import { Routes, Route } from 'react-router-dom';
import DoctorsSearch from './DoctorsSearch';
import DoctorsView from './DoctorsView';
import DoctorsAdd from './DoctorsAdd';
import DoctorsEdit from './DoctorsEdit';

const Doctors = () => {
  return (
    <Routes>
      <Route index element={<DoctorsSearch />} />
      <Route path=":id" element={<DoctorsView />} />
      <Route path="add" element={<DoctorsAdd />} />
      <Route path="edit/:id" element={<DoctorsEdit />} />
    </Routes>
  );
};

export default Doctors;
