import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useReportsEditStyles } from './styles';
import { Box, Button } from '@mui/material';
import TransactionSummaryModal from './TransactionSummaryModal';
import PreviewModal from './PreviewModal';
import PatientFileModal from './PatientFileModal';
import { useLayout } from '../../../contexts/LayoutContext';
import { toggleToast } from '../../../utils/helpers/toast';
import { useNavigate } from 'react-router-dom';
import DeleteModal from '../../../components/DeleteModal';
import { FaEye } from 'react-icons/fa';
import { MdUploadFile } from 'react-icons/md';
import { FiFileText } from 'react-icons/fi';
import { BiCheckDouble } from 'react-icons/bi';
import { DeleteOutline } from '@mui/icons-material';

const HeaderButtons = ({
  sections,
  patient,
  reportTemplate,
  values,
  isExistingReport,
}) => {
  const { t } = useTranslation();
  const { reports, setReports, deletedReports, setDeletedReports } =
    useLayout();
  const reportsEditStyles = useReportsEditStyles();
  const navigate = useNavigate();
  const [isPatientFileOpen, setIsPatientFileOpen] = useState(false);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [isTransactionModalOpen, setIsTransactionModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const assignedDoctors = values.doctors.map((doctor) => doctor?.id);

  const report = {
    ...reportTemplate,
    id: values?.id,
    appointmentDate: values.appointmentDate,
    assignedDoctors,
    notes: values.notes,
    letterHeading: values.letterHeading,
    name: {
      en: values.anglophoneName,
      fr: values.francophoneName,
    },
    patientId: patient?.id,
    sections,
    status: 'in-progress',
    reportingDoctor: values.reportingDoctor,
  };

  const handleOpenPatientFile = () => setIsPatientFileOpen(true);
  const handleClosePatientFile = () => setIsPatientFileOpen(false);
  const handleOpenTransactionModal = () => setIsTransactionModalOpen(true);
  const handleCloseTransactionModal = () => setIsTransactionModalOpen(false);
  const handleOpenPreviewModal = () => setIsPreviewModalOpen(true);
  const handleClosePreviewModal = () => setIsPreviewModalOpen(false);
  const handleOpenDeleteModal = () => setIsDeleteModalOpen(true);
  const handleCloseDeleteModal = () => setIsDeleteModalOpen(false);
  const handlePreview = () => handleOpenPreviewModal();

  const handlePublishReport = () => {
    if (isExistingReport) {
      const currentIndex = reports.findIndex(
        (item) => item?.id === reportTemplate?.id
      );

      const updatedReports = [...reports];
      updatedReports[currentIndex] = {
        ...report,
        status: 'published',
      };
      setReports(updatedReports);
    } else {
      setReports([{ ...report, status: 'published' }, ...reports]);
    }

    toggleToast('success', t('reportsEditPublishedSuccessfully'));
    navigate('/reports');
  };

  const handleSaveReport = () => {
    if (isExistingReport) {
      const currentIndex = reports.findIndex(
        (item) => item?.id === reportTemplate?.id
      );

      const updatedReports = [...reports];
      updatedReports[currentIndex] = report;
      setReports(updatedReports);
    } else {
      setReports([report, ...reports]);
    }
    toggleToast('success', t('reportsEditSavedSuccessfully'));
    navigate('/reports');
  };

  const handleDeleteReport = () => {
    const updatedReports = reports?.filter(({ id }) => id !== report?.id);
    const deletedReport = reports?.find(({ id }) => id === report?.id);

    setReports(updatedReports);
    setDeletedReports([deletedReport, ...deletedReports]);
    toggleToast('success', t('reportsEditDeletedSuccessfully'));
    navigate('/reports');
  };

  return (
    <Box sx={reportsEditStyles.header}>
      <DeleteModal
        isDeleteModalOpen={isDeleteModalOpen}
        handleCloseDeleteModal={handleCloseDeleteModal}
        handleConfirm={handleDeleteReport}
        isProtected
      />
      <TransactionSummaryModal
        handleCloseTransactionModal={handleCloseTransactionModal}
        isTransactionModalOpen={isTransactionModalOpen}
      />
      <PreviewModal
        handleClosePreviewModal={handleClosePreviewModal}
        isPreviewModalOpen={isPreviewModalOpen}
        patient={patient}
        report={report}
        handlePublishReport={handlePublishReport}
        handleSaveReport={handleSaveReport}
      />
      <PatientFileModal
        isPatientFileOpen={isPatientFileOpen}
        handleClosePatientFile={handleClosePatientFile}
        patient={patient}
      />
      <Box>
        <Button
          color="secondary"
          sx={reportsEditStyles.headerButton}
          variant="contained"
          onClick={handlePreview}
          startIcon={<FaEye />}
        >
          {t('reportsEditPreview')}
        </Button>

        <Button
          startIcon={<MdUploadFile />}
          variant="contained"
          sx={reportsEditStyles.headerButton}
          onClick={handlePublishReport}
        >
          {t('reportsEditPublish')}
        </Button>

        <Button
          sx={reportsEditStyles.headerButton}
          variant="outlined"
          onClick={handleOpenPatientFile}
          startIcon={<FiFileText />}
        >
          {t('reportsEditPatientFile')}
        </Button>

        <Button
          variant="text"
          sx={reportsEditStyles.headerButton}
          onClick={handleOpenTransactionModal}
        >
          {t('reportsEditTransactionSummary')}
        </Button>
      </Box>

      <Box>
        <Button
          variant="outlined"
          color="error"
          sx={reportsEditStyles.headerButton}
          onClick={handleOpenDeleteModal}
          disabled={!isExistingReport}
          startIcon={<DeleteOutline />}
        >
          {t('reportsEditDelete')}
        </Button>

        <Button
          color="success"
          sx={{ color: 'white' }}
          variant="contained"
          startIcon={<BiCheckDouble />}
          onClick={handleSaveReport}
        >
          {t('reportsEditSave')}
        </Button>
      </Box>
    </Box>
  );
};

export default HeaderButtons;
