import React from 'react';
import {
  Button,
  Grid,
  TextField,
  Box,
  Typography,
  Autocomplete,
} from '@mui/material';
import { usePatientsFormStyles } from './styles';
import { useTranslation } from 'react-i18next';
import { PATIENTS_ADD_INSURANCE_PROVIDERS } from '../../utils/patients';
import { useLayout } from '../../contexts/LayoutContext';
import { AddCircleOutline } from '@mui/icons-material';

const MedicalInformation = ({
  formik: { values, errors, touched, handleChange, handleBlur, setFieldValue },
  handleOpenNewDoctorModal,
}) => {
  const { doctors } = useLayout();
  const { t } = useTranslation();
  const patientsFormStyles = usePatientsFormStyles();
  const FORM_FIELDS = [
    { name: 'insuranceProvider' },
    {
      name: 'referringDoctor',
      label: t('patientsFormReferringDoctor'),
    },
    {
      name: 'referringDoctorAddress',
      label: t('patientsFormReferringDoctorAddress'),
    },
    {
      name: 'referringDoctorContact',
      label: t('patientsFormReferringDoctorContact'),
      placeholder: t('patientsFormEmailOrPhone'),
    },
    { name: 'doctors' },
    { name: 'newDoctor' },
  ];

  return (
    <Box mt="24px">
      <Typography variant="h4" sx={patientsFormStyles.sectionHeading}>
        {t('patientsFormHeadingMedical')}
      </Typography>
      <Grid container spacing={3} marginBottom={'24px'}>
        {FORM_FIELDS.map(({ name, label, placeholder }) => {
          return name === 'insuranceProvider' ? (
            <Grid item xs={12} md={6} key={name}>
              <Autocomplete
                id="insuranceProvider"
                options={PATIENTS_ADD_INSURANCE_PROVIDERS}
                getOptionLabel={(option) => `${option.value}`}
                defaultValue={[]}
                onChange={(_e, value) =>
                  setFieldValue('insuranceProvider', value)
                }
                value={values.insuranceProvider}
                renderInput={(params) => (
                  <TextField
                    key={name}
                    {...params}
                    variant="outlined"
                    label={t('patientsFormInsuranceProvider')}
                  />
                )}
              />
            </Grid>
          ) : name === 'doctors' ? (
            <Grid item xs={12} md={7} key={name}>
              <Autocomplete
                multiple
                id="doctors"
                options={doctors}
                getOptionLabel={(option) =>
                  `${option.lastName}, ${option.firstName}`
                }
                defaultValue={[]}
                onBlur={handleBlur}
                onChange={(_e, value) => setFieldValue('doctors', value)}
                value={values.doctors}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={t('patientsFormDoctors')}
                    error={errors.doctors && touched.doctors}
                    helperText={
                      errors.doctors && touched.doctors && errors.doctors
                    }
                    required
                    onBlur={handleBlur}
                    name="doctors"
                  />
                )}
              />
            </Grid>
          ) : name === 'newDoctor' ? (
            <Grid item xs={12} md={5} key={name}>
              <Box sx={patientsFormStyles.newDoctorButton}>
                <Button
                  startIcon={<AddCircleOutline />}
                  variant="outlined"
                  onClick={handleOpenNewDoctorModal}
                >
                  {t('patientsFormNewDoctor')}
                </Button>
              </Box>
            </Grid>
          ) : (
            <Grid item xs={12} md={6}>
              <TextField
                name={name}
                label={label}
                fullWidth
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values[name]}
                error={errors[name] && touched[name]}
                helperText={errors[name] && touched[name] && errors[name]}
                placeholder={placeholder}
              />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default MedicalInformation;
